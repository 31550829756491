import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {MeetingItem, MeetingItemSetting} from '../../../declarations/declarations';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import MeetingItemDialog from '../../MeetingDialog/MeetingItemDialog';

interface FuncProps {
    item : MeetingItem;
    parentItem ?: MeetingItem;
    editArtifact?: (item: MeetingItemSetting) => void;
}

type Props = FuncProps 

const DecisionItemCard: React.FC<Props> = ({item, parentItem, editArtifact }) => {
    const [dialogState, setDialogState] = React.useState<boolean>(false);

    return <Grid container>
        <Grid className="decision-container" onClick={() => setDialogState(true)}>
            <Grid className="decision-spawn-heading gray5 ">
                <Grid className="inline">
                    <SubdirectoryArrowRightIcon fontSize="small" />
                </Grid>
                <Grid className="inline ">
                    <Typography className="decision-spawn-what small-text">
                        {parentItem?.what}
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <Grid className="inline gray5 vertical-align-sub decision-card-icon">
                    <ThumbUpOutlinedIcon fontSize="small" />
                </Grid>
                <Grid className="inline ">
                    <Typography className="decision-spawn-what bold-text gray4">
                        {item.what}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="decision-why">
                    <Typography className="small-text gray4">
                    {typeof item.why === 'string' ? item.why : 
                 "Why created in draftjs"}
                    </Typography>
            </Grid>
        </Grid>
        <MeetingItemDialog
            type="Decision Item"
            state={dialogState}
            dialogItem={item}
            setDialogState={setDialogState}
            editArtifact={editArtifact}
            isCurrentItem={true}
        />
    </Grid>
}


export default DecisionItemCard ;