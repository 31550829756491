import React from "react";
import { Card, Typography, Grid } from "@material-ui/core";
import { MeetingItem, MeetingItemCardOptions } from "../../../declarations/declarations";
import SelectOptions from "./options/SelectOptions";
import ResolveOption from "./options/ResolveOption";
import DeleteOption from "./options/DeleteIcon";
import VoteOption from "./options/VoteOption";
import EditOption from "./options/EditOption";
import MeetingItemDialog from "../../MeetingDialog/MeetingItemDialog";
import { getUserId } from "../../../helpers/helpers";

interface FuncProps {
  item: MeetingItem;
  options?: MeetingItemCardOptions;
  buttonFunction?: (item: MeetingItem) => void;
  isVotedItemId?: string | undefined;
  voteNumber?: number;
}

const BaseCard: React.FC<FuncProps> = ({
  item,
  options,
  buttonFunction,
  isVotedItemId,
  voteNumber
}) => {

  const [dialogState, setDialogState] = React.useState<boolean>(false);

  const loadedFunction = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    //@ts-ignore
    buttonFunction(item);
  };
  const currentUser = item.owner_id === getUserId() ? true : false;
  let ItemId= item._id.toHexString();

  const actions = () => {
    if (!options && !currentUser) {
      return;
    } else {
      switch (options) {
        case MeetingItemCardOptions.personal:
          return (
            <DeleteOption
              cardId={ItemId}
            />
          );
        case MeetingItemCardOptions.select:
          return (
            <SelectOptions
              cardId={ItemId}
              buttonFunction={loadedFunction as () => void}
            />
          );
        case MeetingItemCardOptions.resolve:
          return (
            <ResolveOption
              cardId={ItemId}
              buttonFunction={buttonFunction as () => void}
            />
          );
        case MeetingItemCardOptions.delete:
          return (
            <DeleteOption
              cardId={ItemId}
            />
          );
        case MeetingItemCardOptions.vote:
          return (
            <VoteOption
              cardId={ItemId}
              buttonFunction={loadedFunction as () => void}
              id={item._id.toHexString()}
              isVotedItemId={isVotedItemId}
              voteNumber={voteNumber}
            />
          );
        case MeetingItemCardOptions.edit:
          return <EditOption cardId={ItemId} setDialogState={setDialogState} />;
        case MeetingItemCardOptions.no_options:
          return;
        default:
          return (
            <DeleteOption
              cardId={ItemId}
            />
          );
      }
    }
  };

  return (
    <>
      <Card className="Base-Card" onClick={() => setDialogState(true)}>
        <Grid className="what-container">
          <Typography className="what bold-text">{item.what}</Typography>
        </Grid>
        <Grid className="why small-text gray3">{typeof item.why === 'string' ? item.why : 
                 "Why created in draftjs"}</Grid>
        <Grid
          item
          xs={12}
          className="card-button-container text-align-right small-text"
        >
          {actions()}
        </Grid>
      </Card>
      <MeetingItemDialog
        type="Meeting Item"
        state={dialogState}
        dialogItem={item}
        setDialogState={setDialogState}
      />
    </>
  );
};

export default BaseCard;
