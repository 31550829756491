import React from "react";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import FormInputField from "../../../components/FormInputField/FormInputField";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../store/actionTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  MeetingTemplatesReduxer,
  MetricTypeReduxer,
} from "../../../store/reduxer";
import {
  initialDataHelper
} from "../../../helpers/formHelpers";
import { getAccountId, getUserId } from "../../../helpers/helpers";
import {
  AgendaItem,
  AgendaItemTypes,
  MetricSettings,
  MetricType,
} from "../../../declarations/declarations";
import AddEditAgendaItemDialog from "../../../components/AgendaItemCard/AddEditAgendaItem/AddEditAgendaItemDialog";
import AgendaItemCards from "../../../components/AgendaItemCard/AgendaItemCards";

interface DispatchProps {
  createTemplate: (item: any) => void;
  updateTemplate: (item: any) => void;
  createMetric: (metric: MetricSettings) => any;
}

interface FuncProps
  extends RouteComponentProps<{
    action: string;
    id: string;
  }> {}

type Props = DispatchProps & FuncProps;

const validation = Yup.object().shape({
  name: Yup.string().required("Please add a name"),
  agendaItems: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.mixed().required("Please select a type"),
      })
    )
    .required("Please add an Agenda Item")
    .min(1, "At least 1 Agenda Item is required"),
});

const selectOptionsDefault: Array<[string, any]> = [
  [AgendaItemTypes.meetingItems, "Meeting Items"],
  [AgendaItemTypes.normal, "Normal"],
  [AgendaItemTypes.actionItemReview, "Action Item Review"],
  [AgendaItemTypes.metricReview, "Metric Review"],
  [AgendaItemTypes.question, "Ask a Question"],
  [AgendaItemTypes.ideas_and_organize, "Ideas and Organize"],
  [AgendaItemTypes.ideas_vote_refine, "Ideas Votes and Refine"]
];

const AddEditAgendaTemplate: React.FC<Props> = ({
  match,
  history,
  createTemplate,
  updateTemplate,
  createMetric,
}) => {
  const account_id = getAccountId() as string;
  const [dialogState, setState] = React.useState<boolean>(false);
  const [activeAgendaItem, setActiveItem] = React.useState<
    number | undefined
  >();
  const [selectedItem, setItem] = React.useState<any>(null);
  const initialData = initialDataHelper(
    "meetingTemplates",
    {
      name: "",
      agendaItems: [],
      account_id,
    },
    match.params.id
  );
  return (
    <Formik
      initialValues={initialData}
      onSubmit={async (values, actions) => {
        const questions = values.agendaItems.filter(
          (item: AgendaItem) => item.type === AgendaItemTypes.question
        );
        if (questions.length > 0) {
          for (const q of questions) {
            const metric: MetricSettings = {
              account_id: getAccountId() as string,
              owner_id: getUserId() as string,
              name: q.name,
              type: MetricType.question,
            };
            const result = await createMetric(metric);
            if (result.insertedId) {
              q.metric_id = result.insertedId.toString();
            } else {
              throw new Error(JSON.stringify(result, null, 2));
            }
          }

          if (match.params.action === "add") {
            await createTemplate(values);
          } else {
            await updateTemplate(values);
          }
          actions.resetForm();
          actions.setSubmitting(false);
          history.push("/meetings/agenda-templates");
        } else {
          match.params.action === "add"
            ? await createTemplate(values)
            : await updateTemplate(values);
          actions.resetForm();
          actions.setSubmitting(false);
          history.push("/meetings/agenda-templates");
        }
      }}
      validationSchema={validation}
    >
      {({ isSubmitting, errors, values, dirty }) => (
        <Form style={{ marginLeft: "4%", marginRight: "4%", height: "100vh" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{ paddingBottom: "10px", color: "white" }}
              >{`${
                match.params.action === "add" ? "Add" : "Edit"
              } Agenda Template`}</Typography>
            </Grid>
            <Paper
              style={{
                width: "100%",
                height: "100%",
                paddingRight: "20px",
                paddingLeft: "20px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              <FormInputField
                name="name"
                label="Template Name"
                type="text"
                xs={12}
                md={6}
              />
              <Grid item xs={12}>
                <Typography
                  color="primary"
                  variant="h5"
                  style={{ paddingBottom: "10px" }}
                >
                  Agenda Items
                </Typography>
              </Grid>
              <FieldArray
                name="agendaItems"
                render={(arrayHelpers) => (
                  <React.Fragment>
                    {values.agendaItems && values.agendaItems.length > 0 ? (
                      values.agendaItems.map(
                        (item: AgendaItem, index: number) => (
                          <AgendaItemCards
                            key={index}
                            agendaItem={item}
                            expanded={activeAgendaItem}
                            index={index}
                            setExpanded={setActiveItem}
                            editItem={() => {
                              setItem({ index, values: item });
                              setState(true);
                            }}
                            deleteItem={() => arrayHelpers.remove(index)}
                          />
                        )
                      )
                    ) : (
                      <Typography variant="subtitle2">
                        Click below to add your first item. At least one is
                        required.
                      </Typography>
                    )}
                    <Grid item xs={12} style={{ paddingTop: "20px" }}>
                      <Button
                        onClick={() => {
                          setItem(null);
                          setState(true);
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Add Agenda Item
                      </Button>
                    </Grid>
                    <AddEditAgendaItemDialog
                      state={dialogState}
                      data={selectedItem}
                      postItem={arrayHelpers.push}
                      updateItem={arrayHelpers.replace}
                      setState={setState}
                      selectOptions={selectOptionsDefault}
                    />
                  </React.Fragment>
                )}
              />
              <Grid
                item
                xs={12}
                style={{ paddingTop: "5%", textAlign: "right" }}
              >
                <Button
                  style={{ margin: "10px" }}
                  color="secondary"
                  onClick={() => history.push("/meetings/agenda-templates")}
                >
                  Back
                </Button>
                <Button
                  style={{ margin: "10px" }}
                  color="primary"
                  type="submit"
                  disabled={isSubmitting || !isEmpty(errors) || !dirty}
                >
                  {match.params.action === "add" ? "Create" : "Save"}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    createTemplate: bindActionCreators(MeetingTemplatesReduxer.post, dispatch),
    updateTemplate: bindActionCreators(
      MeetingTemplatesReduxer.update,
      dispatch
    ),
    createMetric: bindActionCreators(MetricTypeReduxer.post, dispatch),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(AddEditAgendaTemplate)
);
