import React from "react";
import { ActionItem, ActionItemState, MeetingItemTypes } from "../../../../declarations/declarations";
import { Grid, Typography } from "@material-ui/core";
import ActionItemList from "../../../../components/ActionItemList/action-item-list";
import { MeetingItemsReduxer } from "../../../../store/reduxer";
import "../../../ActionItemsPage/index.scss";

interface FuncProps {
  stream_id: string;
}


type Props = FuncProps;

const ActionItemReview: React.FC<Props> = ({ stream_id }) => {
  const [actionItem, setActionItem] = React.useState<ActionItem[]>([]);
  React.useEffect(() => {
    (async () => {
      if (stream_id) {
        let item = await MeetingItemsReduxer.database
        .find(
          {
            stream_id: stream_id,
            type: MeetingItemTypes.actionItem,
            complete:false
          }
        )
        .toArray();
        setActionItem(item);
        const MeetingItem = await MeetingItemsReduxer.database.watch();
        MeetingItem.onNext(async (event) => {
          if (event.fullDocument.stream_id === stream_id) {
            item = await MeetingItemsReduxer.database
            .find(
              {
                stream_id: stream_id,
                type: MeetingItemTypes.actionItem,
                complete:false
              }
            )
            .toArray();
            setActionItem(item);
          }
        });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className="action-item-page">
      <Grid className="action-item-page-heading">
        <Grid className="inline action-item-page-heading-icon">
          <i className="far fa-file-alt primary-blue"></i>
        </Grid>
        <Grid className="inline">
          <Typography className="bold-text primary-blue">
            Action Items Review {actionItem && actionItem.length >0 && `(${actionItem.length})`}
          </Typography>
        </Grid>
      </Grid>
      <Grid className="action-item-page-container">
        <ActionItemList
          items={actionItem}
          showOverdueItems={true}
          lists={[
            ActionItemState.blocked,
            "Overdue",
            ActionItemState.not_started,
            ActionItemState.complete,
            ActionItemState.in_progress,
          ]}
        ></ActionItemList>
      </Grid>
    </Grid>
  );
};

export default ActionItemReview
