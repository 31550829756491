import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IdeaOrganizeState, ItemList, MeetingAgendaItem } from '../../../../../declarations/declarations';
import { getUserId } from '../../../../../helpers/helpers';
import { mongoClient } from '../../../../../stitch/app';
import { ActionTypes } from '../../../../../store/actionTypes';
import { getLists, updateList } from '../../../../../store/lists/listsActionCreator';
import { SystemState } from '../../../../../store/store';
import IdeaComponent from './components/IdeaComponent';
import OrganizeComponent from './components/OrganizeComponent';
import ListNameDialog from './components/ListNameDialog';
import { hideStream } from '../../../../../store/actionCreators';
import './index.scss';
import IdeaItemList from '../../../../../components/List/IdeaItemsList';

interface StateProps {
    hideStream: boolean;
}

interface DispatchProps {
    getLists: (query: object) => void;
    updateList: (list: ItemList) => void;
}

interface FuncProps {
    meeting_id: string;
    currentAgendaItem: MeetingAgendaItem;
    addNewListToState: () => void;
    changeIdeaOrgRound: () => void;
    newOrganizeList: (name: string, items?: Array<string>) => void;
    setListName: (name: string, id: string) => void;
}

type Props = FuncProps & StateProps & DispatchProps

const IdeasAndOrganize: React.FC<Props> = ({
    getLists, 
    updateList, 
    meeting_id, 
    currentAgendaItem, 
    addNewListToState, 
    changeIdeaOrgRound, 
    newOrganizeList,
    setListName,
    hideStream
}) => {

    const state = currentAgendaItem.state as IdeaOrganizeState
    const [listNameDialog, setListNameDialog] = React.useState<boolean>(false);
    const [nameEditState, setNameEditState] = React.useState<{edit_name: string, edit_id: string} | undefined>(undefined);
    const [init, setInit] = React.useState<boolean>(false);
    
    const closeDialog = () => {
        setListNameDialog(false);
        setNameEditState(undefined);
    }

    const setEdit = (edit_name: string, edit_id: string) => {
        setNameEditState({edit_name, edit_id});
        setListNameDialog(true);
    }
    
    const checkIfListExists = (id: string, key: string) => {
        let result = false;
        state.ideaLists.forEach(idea => {
            if (idea[key] === id) {
                result = true
            }
        });
        state.organizeLists.forEach(idea => {
            if (idea === id) {
                result = true
            }
        });
        return result;
    }

    const startWatch = async () => {
        const stream  = await mongoClient.db('thenaMeet').collection('lists').watch()
        stream.onNext(event => {
            const list = event.fullDocument as ItemList
            if (list.meeting_id === meeting_id) {
                updateList(list);
            }
        })
    }

    React.useEffect(() => {
        (async () => {
            await startWatch();
            await getLists({
                location_id: {$in: state.ideaLists.map(item => item.location_id)}
            })
            if (!checkIfListExists(getUserId() as string, 'attendee_id')) {
                await addNewListToState()  
                setInit(true);  
            }  else {
                setInit(true);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <Grid container>
        <Grid item md={hideStream ? 12 : 12} xs={12}>
            <Grid container >
                {!init ? <Grid item xs={12}><CircularProgress color="secondary"/></Grid> : init && state.round === 'ideas' ? 
                   <Grid item xs={12}> <IdeaItemList 
                    location_id={init && state.ideaLists.filter(list => list.attendee_id === getUserId())[0] ? state.ideaLists.filter(list => list.attendee_id === getUserId())[0].location_id : ''}
                    title=""
                    /> </Grid>: 
                    <Grid item xs={12}><OrganizeComponent state={state} newOrganizeList={newOrganizeList}/></Grid>
                }
            </Grid>
        </Grid>
        <Grid item md={12} sm={12}>
            <Grid item xs={12} style={{marginBottom: '250px'}}>
                <Button onClick={changeIdeaOrgRound}>
                    {state.round === 'ideas' ? 'Organize' : 'Ideas'}
                </Button>
                {state.round === 'organize' ? <Button style={{color: 'white'}} onClick={() => setListNameDialog(true)}>
                    New List
                </Button> : null}
            </Grid>
        </Grid>
        <ListNameDialog state={listNameDialog} closeForm={closeDialog} newList={newOrganizeList} editList={setListName} edit={nameEditState}/>
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        hideStream: state.app.hideStream
    }
  }

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
    return {
        getLists: bindActionCreators(getLists, dispatch),
        updateList: bindActionCreators(updateList, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeasAndOrganize);