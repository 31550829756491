import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import CurrentMeetingActionItemCard from '../../../../../../../components/ItemCard/CurrentMeetingActionItemCard';
import './index.scss';
import { FilterList, Add, PlaylistAddCheck, ThumbUpOutlined, Close } from '@material-ui/icons';
import CurrentDecisionItemCard from '../../../../../../../components/MeetingItemCard/components/CurrentDecisionItemCard';
import { MeetingItem, MeetingItemSetting, MeetingItemTypes } from '../../../../../../../declarations/declarations';
import { Form, Formik } from "formik";
import FormInputField from "../../../../../../../components/FormInputField/FormInputField";
import * as Yup from "yup";
import { BSON } from "mongodb-stitch-browser-sdk";
import { getAccountId, getUserId } from '../../../../../../../helpers/helpers';
import FormSelectField from '../../../../../../../components/FormSelectField/FormSelectField';
import { isEmpty } from "lodash";
import { useMeetingState } from '../../../../../../../store/meeting/meetingState';
import RefineItemComponent from '../RefineItemComponent/RefineItemComponent';

interface FuncProps {
    items?: Array<Array<MeetingItem> | undefined>
    type: string
    parentItem?: MeetingItem
    meeting_id: string;
    attendeeOptions: Array<[string, any]>;
    addItem: string;
    setAddItem: (addItemId: string) => void;
    editArtifact: (item: MeetingItemSetting) => void;
    setCurrentRefineItem: (item: MeetingItem) => void;
    currentRefineItem: any;
}

const ArtifactItemCardList: React.FC<FuncProps> = ({ attendeeOptions, items, 
  type, parentItem, meeting_id, addItem, setAddItem, editArtifact, currentRefineItem, setCurrentRefineItem }) => {

  const meetingControl = useMeetingState(meeting_id);
  const [progress, setProgress] = React.useState(false);
  const [what, setWhat] = React.useState(currentRefineItem && currentRefineItem.what);

  let validation: any = Yup.object().shape({
    what: Yup.string().min(5, "5 character minimum"),
    why: Yup.string().min(10, "10 character minimum"),
    who: Yup.mixed().when("type", {
      is: MeetingItemTypes.actionItem,
      then: Yup.mixed().required("Who is required"),
    }),
    when: Yup.mixed().when("type", {
      is: MeetingItemTypes.actionItem,
      then: Yup.mixed().required("Date is required"),
    }),
    type: Yup.string().required("Type is required"),
  });

  const initialData = {
    _id: new BSON.ObjectID(),
    meeting_id: meeting_id,
    account_id: getAccountId() as string,
    owner_id: getUserId(),
    type: null,
    what: null,
    why: null,
    who: "",
    when: "",
    complete: false,
    state: type === MeetingItemTypes.actionItem ? 'not_started' : undefined,
  };

    const submitValue = () => {
      if (what !== currentRefineItem.what) {
        let values = currentRefineItem;
        values.what = what;
        editArtifact(values);
      }
    }

    React.useEffect(() => {
      if (currentRefineItem && currentRefineItem.what && !what) {
        setWhat(currentRefineItem.what);
      }
    }, [currentRefineItem]);

    return  <Grid container>
      <Grid container className="Card-container">
          <Grid className="action-item-list" id={`${type === "actionItem" ? 'meeting_action_items' : 'meeting_decision_items'}`}>
            <Grid container className="actions-list-header-main gray4">
              <Typography className="body-text text">
                Outcomes
              </Typography>
              <Button className="body-text adds-icon cursor-pointer gray4" onClick={() => setAddItem(type)}>
                <Add className="body-text" />
              </Button>
              <FilterList  className="body-text filters-icon" />
            </Grid>
            <Grid className="action-items">
            {addItem === type && <Grid style={{ width: '330px', padding: '15px 8px' }}> 
              <Grid className={(type === "actionItem" ? "add-action-item-container" : "add-decision-item-container") + " white-background"}>
              <Formik
              initialValues={initialData}
                validationSchema={validation}
                onSubmit={async (values, actions) => {
                  setAddItem('');
                  await meetingControl.addArtifactToCurrentItem(values);
                  actions.resetForm();
                  actions.setSubmitting(false);
                }}
              >
                {({ isSubmitting, dirty, errors, values }) => (
                  <Form style={{ width: '330px !important'}}>
                      <FormInputField 
                        name="what" 
                        label="What" 
                        type="text" 
                        md={12} 
                      />
                      <FormInputField
                        name="why"
                        label="Why"
                        type="text"
                        multiline
                        md={12}
                      />
                      <FormSelectField
                          name="type"
                          label="type"
                          options={[['actionItem', 'Action Item'], ['decision', 'Decision']]}
                          md={12}
                        />
                      {values.type === 'actionItem' ? (
                        <FormSelectField
                          name="who"
                          label="Who"
                          options={attendeeOptions}
                          md={12}
                        />
                      ) : undefined}
                      {values.type === 'actionItem' ? (
                        <FormInputField
                          name="when"
                          label="Due"
                          labelProps="shrink"
                          type="date"
                          md={12}
                        />
                      ) : undefined}
                      <Grid container className="more-vert-icon-action">
                        <Close className="gray5 cursor-pointer" onClick={() => setAddItem('')}/>
                        <Button
                          type="submit"
                          disabled={isSubmitting || !isEmpty(errors) || !dirty}
                          className="primary-blue-background add-item-btn-icon">
                            <Typography className="white add-item-btn-icon-text">
                                <Add className="white body-text add-item-btn-text" />
                                Add new item
                            </Typography>
                        </Button>
                    </Grid>
                  </Form>
                )}
              </Formik></Grid></Grid>}
              {items && items.length > 0 && items.map((outcomeItem, index) => (
                <Grid>
                  <Grid container className="actions-list-header gray4">
                    {index === 0 ? <PlaylistAddCheck className="body-text playlist-icon" /> : 
                    <ThumbUpOutlined className="body-text playlist-icon" />}
                    <Typography className="body-text text">
                      {index === 0 ? 'Action item' : 'Decisions'}
                    </Typography>
                  </Grid>
                  {outcomeItem && outcomeItem.map((ele) => (
                    <Grid key={ele._id.toString()} onClick={() => {
                      setProgress(true);
                      setWhat(ele.what);
                      setCurrentRefineItem(ele)
                      setTimeout(() => {
                        setProgress(false);
                      }, 200)
                      }}>
                      {index === 0 && <CurrentMeetingActionItemCard
                        item={ele}
                        currentItem={currentRefineItem ? 
                          currentRefineItem._id.toHexString() === ele._id.toHexString() :
                          false}
                      />}
                      {index === 1 && <CurrentDecisionItemCard
                        item={ele}
                        parentItem={parentItem}
                        currentItem={currentRefineItem ? 
                          currentRefineItem._id.toHexString() === ele._id.toHexString() :
                          false}
                      />}
                    </Grid>
                  ))}
                </Grid>
              ))}
              
            </Grid>
            </Grid>
    </Grid>
    {currentRefineItem !== undefined && !progress && <Grid className="editor-container">
        <Grid container>
            <Typography className="gray3 refine-item">
                Refine item
            </Typography>
        </Grid>
        <Grid container>
            <Typography className="gray4 what-why-title pre-title">WHAT</Typography>
            <Grid container className="">
                <TextField 
                  className="body-text title gray3 current-item-what"
                  label="" 
                  placeholder="Enter what"
                  value={what}
                  onChange={(e) => setWhat(e.target.value)}
                  onBlur={submitValue}
                  />
            </Grid>
        </Grid>
        <Grid container>
            <Typography className="gray4 what-why-title pre-title">WHY</Typography>
        </Grid>
        <RefineItemComponent 
          item={currentRefineItem}
          progress={progress}
          editArtifact={editArtifact}
          meeting_id={meeting_id}
          />
    </Grid>}
    </Grid>
}

export default ArtifactItemCardList;
