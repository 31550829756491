import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import FormLayout from '../FormLayout';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import { login } from '../../../stitch/authentication';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    email: Yup
        .string()
        .email('Must be an email address')
        .required('Please enter your email'),
    password: Yup
        .string()
        .required('Please enter your password'),
});

const initialData = {
    email: '',
    password: '',
};

interface RouteProps extends RouteComponentProps<{
    redirectLink: string;
}> {}

const Login: React.FC<RouteProps> = ({history, match}) => {

    const { enqueueSnackbar } = useSnackbar();

    return <FormLayout>
        <Formik 
            initialValues={initialData}
            onSubmit={async (values, actions) => {
                const result = await login(values.email, values.password);
                if (result.errorCode) {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    enqueueSnackbar(result.message, {variant: 'error'})
                } else {
                    if ( match.params.redirectLink) {
                        history.push(`${match.params.redirectLink.replace(/%20| /g, '/')}`);
                    }
                }
            }}
            validationSchema={validation}
            >
            {({ isSubmitting, dirty, errors }) => (
                <Form style={{marginLeft: '4%', marginRight: '4%'}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Welcome back</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">Please enter your login details.</Typography>
                    </Grid>
                    <FormInputField name="email" label="Email" type="text" md={12}/>
                    <FormInputField name="password" label="Password" type="password" md={12}/>
                    <Grid item xs={12}>
                        <Button 
                            type="submit" 
                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                            size="large" 
                            color="primary" 
                            variant="contained"
                        >
                            Login
                        </Button>
                        <Grid item xs={12} style={{marginTop: '15px'}}>
                            <Button color="primary" onClick={() => {history.push('/signup')}}>Create Account</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button style={{color: "#7482A1"}} onClick={() => {history.push('/forgot-password/your-email')}}>Forgot Password</Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    </FormLayout>
}

export default withRouter(Login);