import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import stitchApp, { getInviteForInvitee, addNewUserToExistingAccount } from '../../../stitch/app';
import { AnonymousCredential, UserPasswordCredential, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';
import { login, refreshCustomData } from '../../../stitch/authentication';
import LoadingDialog from '../../../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import { UserInvite } from '../../../declarations/declarations';
import { UserDataReduxer } from '../../../store/reduxer';
import { useSnackbar } from 'notistack';

interface RouteProps extends RouteComponentProps<{
    token: string;
    tokenId: string;
    inviteId: string;
}> {}




const RegisterUser: React.FC<RouteProps> = ({history, match}) => {
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        (async () =>  {
            await stitchApp.auth.loginWithCredential(new AnonymousCredential());
            const invite: UserInvite = await getInviteForInvitee(match.params.inviteId);
            const userData = {
                owner_id: stitchApp.auth.user?.id as string,
                account_id: [invite.account_id]
            }
            await UserDataReduxer.database.insertOne(userData);
            await addNewUserToExistingAccount(invite.account_id, stitchApp.auth.user?.id as string, invite.type)
            refreshCustomData();
            stitchApp.auth
                .getProviderClient(UserPasswordAuthProviderClient.factory)
                .confirmUser(match.params.token, match.params.tokenId).then(async () => {
                    await stitchApp.auth.user?.linkWithCredential(new UserPasswordCredential(invite.email, process.env.REACT_APP_ADD_USER as string));
                    await login(invite.email, process.env.REACT_APP_ADD_USER as string);
                }).catch(error => {
                    enqueueSnackbar(error.message, {variant: 'error'});
                    history.push('/login');
                });
          })();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
        
    return <LoadingDialog loading={true}/>

}

export default withRouter(RegisterUser);