import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
} from "@material-ui/core";
import { MeetingItem } from "../../../../declarations/declarations";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MeetingItemsReduxer } from "../../../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { getItem } from "../../../../helpers/helpers";
import { SystemState } from "../../../../store/store";
import { deleteItemState } from "../../../../store/actionCreators";
import { deleteItemFromList } from "../../../../store/lists/listsActionCreator";

interface DispatchProps {
  deleteMeetingItem: (item: MeetingItem | undefined) => void;
  updateState: (payload: {state: boolean}) => void;
}

interface StateProps {
  state: {state: boolean, itemId: string}
}

type Props = DispatchProps & StateProps;

const DeleteDialog: React.FC<Props> = ({
  updateState,
  state,
  deleteMeetingItem,
}) => {

  const close = () => {
    updateState({state: false});
  }

  return <Dialog disableEnforceFocus={true} open={state.state} onClose={close}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <Grid container>
        <Grid item xs={6}>
          <IconButton
            color="secondary"
            onClick={close}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            onClick={() => {
              deleteMeetingItem(getItem(state.itemId) as MeetingItem);
              deleteItemFromList(state.itemId);
              close();
            }}
          >
            <CheckIcon />
          </IconButton>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    state: state.app.deleteItemState as {state: boolean, itemId: string}
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    deleteMeetingItem: bindActionCreators(MeetingItemsReduxer.delete, dispatch),
    updateState: bindActionCreators(deleteItemState, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
