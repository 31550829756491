import React from 'react';
import { TargetMetric, TargetedMetricType } from '../../../../declarations/declarations';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormInputField from '../../../FormInputField/FormInputField';
import { getAccountId, getUserId } from '../../../../helpers/helpers';
import * as Yup from 'yup';
import {isEmpty} from 'lodash';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../../store/actionTypes';
import { startLoading, stopLoading } from '../../../../store/actionCreators';
import { bindActionCreators } from 'redux';
import { MetricDataReduxer } from '../../../../store/reduxer';
import moment from 'moment';
import { useSnackbar } from 'notistack';

interface FuncProps {
    metric: TargetMetric;
    state: boolean;
    close: () => void;
    createChartDate: (data: any) => void;
    addDate?: string;
}

interface DispatchProps {
    startLoading: () => void;
    stopLoading: () => void;
}

type Props = FuncProps & DispatchProps;

const AddDataDialog: React.FC<Props> = ({metric, state, close, createChartDate, startLoading, stopLoading, addDate }) => {
    
    const { enqueueSnackbar } = useSnackbar();
    const initialValues = {
        account_id: getAccountId() as string,
        owner_id: getUserId(),
        amount: '',
        date: addDate ? addDate : moment(new Date()).format('YYYY-MM-DD'),
        metric_id: metric._id.toString(),
        team: metric.team

    }

    const validation = Yup.object().shape({
        date: Yup
            .date()
            .required('Please enter a date'),
        amount: Yup
            .number()
            .required('Please input a number')
    })

    return <Dialog disableEnforceFocus={true} open={state} fullWidth onClose={close}>
        <DialogTitle>{`Add Data: ${metric.name}`}</DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={async (values, actions) => {
                    startLoading();
                    const result = await MetricDataReduxer.database.insertOne(values);
                    if (result.insertedId) {
                        enqueueSnackbar('Your data was added successfully', {variant: 'success'})
                        const dataOptions = {
                            sort: {date: 1},
                            limit: 13
                        }
                        const serverData = await MetricDataReduxer.database.find({metric_id: metric._id.toString()}, dataOptions).toArray();
                        let returnData: Array<{date: string, value: number}> = [];
                        if (serverData.length > 0) {
                            serverData.forEach((data, index) => returnData.push({date: data.date, value: data.amount}));
                            createChartDate(returnData);
                        }
                    } else {
                        enqueueSnackbar('Something went wrong adding your data, please try again', {variant: 'warning'});
                    }
                    actions.resetForm();
                    actions.setSubmitting(false);
                    close();
                    stopLoading();
                }}
            >
                {({ isSubmitting, dirty, errors }) => <Form>
                <DialogContent>
                    <Grid container>
                        <FormInputField disabled={addDate ? true : false} name="date" label="Date" type="date" required md={6} labelProps="shrink"/>
                        <FormInputField name="amount" label="Amount" type="number" required md={6} inputProps={metric.targetType === TargetedMetricType.percentage ? '%' : undefined}/>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={close}>
                        Back
                    </Button>
                    <Button color="primary" type="submit" disabled={isSubmitting || !isEmpty(errors) || !dirty} >
                        Submit
                    </Button>
                </DialogActions>
                </Form>}
            </Formik> 
    </Dialog>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(AddDataDialog)