import { ItemList } from "../../declarations/declarations";
import * as actionsNames from './listsActionCreator';
import { GetListsSuccess, PostListSuccess, UpdateList } from './listsActionCreator';
import { isEqual, uniqWith } from "lodash";

export interface ListState {
    [key: string]: any;
    lists: ItemList[];
}

const initialState: ListState = {
    lists: []
}

const get_reducer = (state: ListState, action: GetListsSuccess) => {
    return {
        ...state,
        lists: uniqWith(state.lists.concat(action.payload), isEqual)
    }
}

const post_reducer = (state: ListState, action: PostListSuccess) => {
    return {
        ...state,
        lists: [...state.lists, action.payload]
    }
}


const update_reducer = (state: ListState, action: UpdateList) => {
    return {
        ...state,
        lists: state.lists
            .filter(list => list._id.toHexString() !== action.payload._id.toHexString())
            .concat(action.payload)
    }
}

export const listsReducer = (state = initialState, action: any): ListState => {
    switch (action.type) {
        case actionsNames.GET_LISTS_SUCCESS:
            return get_reducer(state, action);
        case actionsNames.POST_LIST_SUCCESS:
            return post_reducer(state, action);
        case actionsNames.UPDATE_LIST:
            return update_reducer(state, action);
        default:
            return state;
    }
}