import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements  } from '@stripe/react-stripe-js';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import ThenaLogo from '../../../../components/ThenaLogo/ThenaLogo';
import stitchApp from '../../../../stitch/app';
import CardCollectionForm from './components/CardCollectionForm';
import LoadingDialog from '../LoadingDialog/LoadingDialog';
import Header from './components/Header';
import ExpiredHeader from './components/ExpiredHeader';

interface FuncProps {
    initState: boolean;
    trialActive: boolean
}

const StripeCardDialog: React.FC<FuncProps> = ({initState, trialActive}) => {

    const stripePromise = loadStripe('pk_test_Kj1VwilgueFANPqb84g4izy900T7eDoTIG');
    const [clientSecret, setClientSecret] = React.useState<string>('');
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [state, setState] = React.useState<boolean>(initState);

    React.useEffect(() => {
        (async () => {
            const secret = await stitchApp.callFunction('stripe_createSetupIntent', []);
            setClientSecret(secret);
            setLoaded(true)
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return loaded ? <Dialog disableEnforceFocus={true} open={state} maxWidth="lg" fullWidth>
        <DialogContent style={{textAlign: 'center'}}>
            <ThenaLogo width="20%" height="20%"/>
            {trialActive ? <Header /> : <ExpiredHeader/>}
            <br/>
            <Elements stripe={stripePromise}>
                <CardCollectionForm clientSecret={clientSecret} closeDialog={() => setState(false)}/>
            </Elements>
        </DialogContent>
        {trialActive ? <DialogActions>
            <Button onClick={() => setState(false)} color='primary'>I'll do this later</Button>
        </DialogActions> : null}
    </Dialog> : <LoadingDialog loading={true}/>
}

export default StripeCardDialog;