import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CircularProgress, Grid } from "@material-ui/core";
import { MeetingItem } from "../../../../../../../../declarations/declarations";
import IdeaItemCard from "../../../../../../../../components/MeetingItemCard/components/IdeaItemCard";
import { SystemState } from "../../../../../../../../store/store";
import { connect } from "react-redux";
import { MeetingItemsReduxer } from "../../../../../../../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../../../../../store/actionTypes";
import { bindActionCreators } from "redux";
import { BSON } from "mongodb-stitch-browser-sdk";
import '../index.scss';

interface FuncProps {
  listUiId: string;
  item_id: string;
  index: number;
  isOpenList: boolean;
}

interface StateProps {
  items: MeetingItem[]
}

interface DispatchProps {
  getItem: (query: object) => void;
}

type Props = FuncProps & StateProps & DispatchProps

const IdeaOrgItemCard: React.FC<Props> = ({ listUiId, item_id, items, index, getItem, isOpenList }) => {

  const item = items.filter(item => item._id.toHexString() === item_id)[0]
  const cardId = `${listUiId}-meetingItem-${index}`;

  React.useEffect(() => {
    if (!items.filter(item => item._id.toHexString() === item_id)[0]) {
      getItem({_id: new BSON.ObjectId(item_id)})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getClassName = () => {
    if (!isOpenList) {
      switch(index) {
        case 0:
          return 'top65';
        case 1:
          return 'top55';
        case 2: 
          return 'top45';
        case 3:
          return 'top35';
        case 4: 
          return 'top25';
        default : 
          return 'hide-extra-cards'; 
      }
    }
  }
  return !item ? <CircularProgress color="secondary" /> :
    <Draggable
      draggableId={item._id.toString()}
      index={index}
      key={item._id.toString()}
    >
      {(provided) => (
        <Grid
        id={cardId}
        item
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        innerRef={provided.innerRef}
        className="idea-item-container"
        >
          <Grid className={(getClassName())} >
            <IdeaItemCard 
              item={item}
              isListClosed = {!isOpenList}
              />
          </Grid>
        </Grid>
      )}
    </Draggable>  
};

const mapStateToProps = (state: SystemState ): StateProps => {
  return {
      items: state.data.meetingItems as MeetingItem[]
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
      getItem: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(IdeaOrgItemCard);
