import React from 'react';
import { Typography, Grid, Tab, Tabs, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DescriptionOutlined, ThumbUpAltOutlined, NotesOutlined, PlaylistAddCheckOutlined } from '@material-ui/icons';
import { MeetingItem, NoteItem } from '../../../../declarations/declarations';
import Notes from '../YourNotes';
import MeetingItemBlock from '../MeetingItemBlock';
import ActionDecisionItems from '../ActionDecisionItems';

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface FuncProps {
    notes: Array<NoteItem>;
    items: Array<MeetingItem>
}

const SummaryTabs: React.FC<FuncProps> = ({ notes, items }) => {
    const [value, setValue] = React.useState(0);

    const tabChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const getCount = (type: string) => {
        let count = items.filter((item) => 
            item.type === type
        ).length;

        return count;
    } 

    const tabDisplay = () => {
        switch (value) {
            case 0:
                return <TabPanel value={value} index={0}>
                    <Typography className="gray3">
                        <MeetingItemBlock items={items}></MeetingItemBlock>
                    </Typography>
                </TabPanel>
            case 1:
                return <TabPanel value={value} index={1}>
                    <ActionDecisionItems items={items} type="actionItem" />
                </TabPanel>
            case 2:
                return <TabPanel  value={value} index={2}>
                    <ActionDecisionItems items={items} type="decision" />
                </TabPanel>
            case 3:
                return <TabPanel  value={value} index={3}>
                    <Notes notes={notes} />
                </TabPanel>
            default: return null;
        }
    }

    return <Grid className="main-tab-container">
            <Grid container>
                <Tabs value={value} onChange={tabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className="tab-container">
                    <Tab 
                        icon={<DescriptionOutlined className="tab-img" />}
                        {...a11yProps(0)} 
                        className={value === 0 ? "primary-blue tab meeting-tab" : "gray4 tab meeting-tab"} 
                        label={`Meeting items (${getCount('meetingItem')})`} />
                    <Tab 
                        icon={<PlaylistAddCheckOutlined className="tab-img" />}
                        {...a11yProps(1)} 
                        className={value === 1 ? "primary-blue tab" : "gray4 tab"} 
                        label={`Action items (${getCount('actionItem')})`} />
                    <Tab 
                        icon={<ThumbUpAltOutlined className="tab-img" />}
                        {...a11yProps(2)} 
                        className={value === 2 ? "primary-blue tab" : "gray4 tab"} 
                        label={`Decisions (${getCount('decision')})`} />
                    <Tab 
                        icon={<NotesOutlined className="tab-img" />}
                        {...a11yProps(3)} 
                        className={value === 3 ? "primary-blue tab note-tab" : "gray4 tab note-tab"} 
                        label="Personal notes" />
                </Tabs>
            </Grid>
            <hr style={{ marginTop: '0px', border: '1px solid #E8E8E8' }} />
            <Grid className="summary-tabs">
                {tabDisplay()}
            </Grid>
        </Grid>
}

export default SummaryTabs;