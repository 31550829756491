import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IdeaVoteRefineState, ItemList, MeetingAgendaItem, MeetingItem } from '../../../../../declarations/declarations';
import { getUserId } from '../../../../../helpers/helpers';
import { mongoClient } from '../../../../../stitch/app';
import { ActionTypes } from '../../../../../store/actionTypes';
import { getLists, updateList } from '../../../../../store/lists/listsActionCreator';
import IdeaComponent from '../IdeasAndOrganize/components/IdeaComponent';
import IdeasVotesComponent from './components/IdeasVotesComponent';
import RefineComponent from './components/RefineComponent';

interface DispatchProps {
    getLists: (query: object) => void;
    updateList: (list: ItemList) => void;
}

interface FuncProps {
    meeting_id: string;
    currentAgendaItem: MeetingAgendaItem;
    isFacilitator: boolean;
    addNewListToState: () => void;
    setListName: (name: string, id: string) => void;
    addVoteListToState: (item: MeetingItem) => void;
}

type Props = FuncProps & DispatchProps

const IdeasVotesAndRefine: React.FC<Props> = ({
    getLists, 
    updateList, 
    meeting_id, 
    currentAgendaItem, 
    addNewListToState,
    isFacilitator,
    addVoteListToState
}) => {
    const state = currentAgendaItem && currentAgendaItem.state as IdeaVoteRefineState
    const [init, setInit] = React.useState<boolean>(false);
    
    const checkIfListExists = (id: string, key: string) => {
        let result = false;
        state.ideaLists.forEach(idea => {
            if (idea[key] === id) {
                result = true
            }
        });

        return result;
    }

    const startWatch = async () => {
        const stream  = await mongoClient.db('thenaMeet').collection('lists').watch()
        stream.onNext(event => {
            const list = event.fullDocument as ItemList
            if (list.meeting_id === meeting_id) {
                updateList(list);
            }
        })
    }

    React.useEffect(() => {
        (async () => {
            await startWatch();
            await getLists({
                location_id: {$in: state.ideaLists.map(item => item.location_id)}
            })
            if (!checkIfListExists(getUserId() as string, 'attendee_id')) {
                await addNewListToState()  
                setInit(true);  
            }  else {
                setInit(true);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state])

    return <Grid container>
        <Grid item md={8} xs={12}>
            <Grid container style={{justifyContent: 'center'}}>
                {!init ? <Grid item xs={12} style={{textAlign: 'center'}}><CircularProgress color="secondary"/></Grid> : init && state.round === 'vote' ? 
                    <IdeasVotesComponent state={state} addVoteListToState={addVoteListToState} /> :
                    init && state.round === 'refine'  ? 
                    <RefineComponent refineList={state.refineList} list_id={state.list_id} isFacilitator={isFacilitator} meeting_id={meeting_id} /> :
                    <IdeaComponent location_id={init && state.ideaLists.filter(list => list.attendee_id === getUserId())[0] ? state.ideaLists.filter(list => list.attendee_id === getUserId())[0].location_id : ''}/>
                }
            </Grid>
        </Grid>
    </Grid>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
    return {
        getLists: bindActionCreators(getLists, dispatch),
        updateList: bindActionCreators(updateList, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(IdeasVotesAndRefine);