import React from 'react';
import { Typography, Grid } from '@material-ui/core';

const whiteText = {color: 'white'};

const BlankState = () => <Grid container style={{textAlign: 'center', paddingTop: '10%'}}>
    <Grid style={whiteText} item xs={12}><Typography variant="h4">You have no meeting item lists</Typography></Grid>
    <Grid style={whiteText} item xs={12}><Typography variant="h4">subscribe to a stream to get started</Typography></Grid>
</Grid>

export default BlankState;