
import React from 'react';
import { Grid, MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

interface FuncProps {
    label?: string;
    name: string;
    options: Array<[string, any]>;
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?:  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    array?: string
    position?: number;
    required?: boolean;
    disabled?: boolean | false;
}

const FormSelectField: React.FC<FuncProps> = ({
  label, 
  name, 
  options, 
  xs, 
  md, 
  array, 
  position, 
  required,
  disabled,
}) => <Grid 
  item 
  xs={xs ? xs : 12} 
  md={md ? md : 12} 
  style={{
    paddingLeft: '5px', 
    paddingRight: '5px'
  }}>
    <Field
      name={!array ? name : `${array}[${position}].${name}`} 
      select
      label={label} 
      required={required}
      type="text" 
      variant="outlined" 
      fullWidth  
      margin="normal"
      size="small"
      disabled = {disabled}
      component={TextField}>
      {options.map(option => <MenuItem key={option[0]} value={option[0]}>{option[1]}</MenuItem>)}
    </Field>
</Grid>

export default FormSelectField;