import React from "react";
import {
  Card,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  Button,
  ExpansionPanelActions,
} from "@material-ui/core";
import { startCase } from "lodash";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AgendaItem } from "../../declarations/declarations";

interface FuncProps {
  agendaItem: AgendaItem;
  editItem?: () => void;
  deleteItem?: () => void;
  expanded: number | undefined;
  setExpanded: (name: number | undefined) => void;
  index: number;
}

const AgendaItemCard: React.FC<FuncProps> = ({
  agendaItem,
  index,
  editItem,
  deleteItem,
  expanded,
  setExpanded,
}) => {
  return (
    <Card style={{ marginTop: "10px" }}>
      <ExpansionPanel
        expanded={expanded === index}
        onChange={() => setExpanded(expanded !== index ? index : undefined)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">
                {startCase(agendaItem.name)}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2">{agendaItem.description}</Typography>
            </Grid>
            {deleteItem ? (
              <Grid item xs={12}>
                <Typography variant="overline">
                  <b>Type:</b> {agendaItem.type}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </ExpansionPanelDetails>

        <ExpansionPanelActions>
          {editItem ? (
            <Button
              color="secondary"
              onClick={() => {
                editItem();
                setExpanded(undefined);
              }}
            >
              Edit
            </Button>
          ) : null}
          {deleteItem ? (
            <Button
              color="secondary"
              onClick={() => {
                deleteItem();
                setExpanded(undefined);
              }}
            >
              Delete
            </Button>
          ) : null}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Card>
  );
};

export default AgendaItemCard;
