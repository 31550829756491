import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import EnvelopeImage from '../../../components/EnvelopeImage/EnvelopeImage';
import ThenaLogo from '../../../components/ThenaLogo/ThenaLogo';

const VerifyEmail: React.FC = () => <Grid container style={{textAlign: 'center', width: '90vw'}}>
    <Grid container style={{padding: '2%'}}>
        <Grid item xs={12}>
            <ThenaLogo height="5%" width="80%"/>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="h4"color="textPrimary">Check your email!</Typography>
        </Grid>
        <Grid item xs={12}>
            <EnvelopeImage width="80%" height="10%"/>
        </Grid>  
        <Grid item xs={12}>
            <Typography variant="body2" color="textPrimary">We just emailed you a verification link. That link will expire shortly, so be sure to click on it soon to get started.</Typography>
        </Grid>
    </Grid>
</Grid>

export default VerifyEmail;