import React from 'react';
import { Route, withRouter, Switch, Redirect, useLocation } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard/Dashboard';

import AccountInfo from '../../pages/auth/AccountSetup/AccountInfo';
import MeetingItemsPage from '../../pages/MeetingItemsPage/MeetingItemsPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import TeamMembersPage from '../../pages/TeamMembersPage/TeamMembersPage';
import MeetingsPage from '../../pages/MeetingsPage/MeetingsPage';
import AgendaTemplatesPage from '../../pages/AgendaTemplatePage/AgendaTemplatesPage';
import AddEditAgendaTemplate from '../../pages/AgendaTemplatePage/AddEditAgendaTemplate/AddEditAgendaTemplate';
import MeetingSummariesPage from '../../pages/MeetingSummariesPage/MeetingSummariesPage';
import MeetingSummaryPage from '../../components/MeetingSummary/MeetingSummaryPage';
import ActionItemsPage from '../../pages/ActionItemsPage/ActionItemsPage';
import StripeCardDialog from '../../layout/components/Dialogs/StripeCardDialog/StripeCardDialog';
import MetricPage from '../../pages/MetricPage/MetricPage';
import AddEditMetricPage from '../../pages/MetricPage/AddEditMetricPage/AddEditMetricPage';
import SettingsTeamPage from '../../pages/SettingsTeamsPage/SettingsTeamPage';
import UserSetup from '../../pages/auth/UserSetup/UserSetup';
import StreamsPage from '../../pages/StreamsListPage/StreamsListPage';
import StreamsIndividualPage from '../../pages/StreamPage/StreamPage';
import useIntercom from '@reclaim-ai/react-intercom-hook';
import stitchApp from '../../stitch/app';
import {getThisUserName} from '../../helpers/helpers';

const AppRoutes: React.FC = () => {

    const intercom = useIntercom({
        app_id: process.env.REACT_APP_INTERCOM_ID as string, 
        custom_launcher_selector:'#my_custom_link',
        email: `${stitchApp.auth.user?.profile.email}`,
        name: `${getThisUserName()}`,
        user_id: `${stitchApp.auth.user?.id}`
    });
    const location = useLocation();

    React.useEffect(() => {
        intercom('update')
    }, [intercom, location])

    return <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/account-setup' component={AccountInfo} />
        <Route path='/user-setup' component={UserSetup} />
        <Route path='/meetings/items' component={MeetingItemsPage} />
        <Route path='/meetings/action-items' component={ActionItemsPage} />
        <Route path='/meetings/agenda-templates/:action' exact params component={AddEditAgendaTemplate}/>
        <Route path='/meetings/agenda-templates/:action/:id'  params  component={AddEditAgendaTemplate}/>
        <Route path='/meetings/agenda-templates' exact component={AgendaTemplatesPage} />
        <Route path='/meetings/streams' exact component={StreamsPage} />
        <Route path='/meetings/streams/view/:stream_id' params component={StreamsIndividualPage} />
        <Route path='/meetings/summaries' exact component={MeetingSummariesPage} />
        <Route path="/meetings/summaries/:id" params component={MeetingSummaryPage}/>
        <Route path='/meetings/meet/:account_id/:meeting_id' component={MeetingsPage} />
        <Route path='/account/metrics' exact component={MetricPage}/>
        <Route path='/account/metrics/:action' exact component={AddEditMetricPage}/>
        <Route path='/account/metrics/:action/:id' component={AddEditMetricPage}/>
        <Route path='/settings/users' exact component={TeamMembersPage}/>
        <Route path='/settings/teams' exact component={SettingsTeamPage}/>
        <Route path='/settings' exact component={SettingsPage}/>
        <Route path='/billing/trial-is-almost-over' exact component={StripeCardDialog}/>
        <Route path='/billing/trial-is-over' exact component={StripeCardDialog}/>
        <Route render={() => <Redirect to='/dashboard'/>} />
    </Switch>
}



export default withRouter(AppRoutes);