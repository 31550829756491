import {
  Dialog,
  Typography,
  Grid,
  Avatar,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Form, Formik, FormikErrors, Field } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import * as Yup from "yup";
import { getInitials, getUserId, getThisUserName } from "../../helpers/helpers";
import { MeetingItem, ItemComment, AccountData, MeetingItemSetting } from "../../declarations/declarations";
import moment from "moment";
import { SystemState } from "../../store/store";
import { isEmpty } from "lodash";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { ActionTypes } from "../../store/actionTypes";
import stitchApp from "../../stitch/app";
import LoadingDialog from "../../layout/components/Dialogs/LoadingDialog/LoadingDialog";
import { capitalize } from "lodash";
import { useSnackbar } from "notistack";
import "./index.scss";
import { stateToHTML} from "draft-js-export-html"
import { convertFromRaw } from 'draft-js';
import Parser from 'html-react-parser';

interface FuncProps {
  state: boolean;
  type: string;
  dialogItem: MeetingItem;
  setDialogState: (state: boolean) => void;
  editArtifact?: (item: MeetingItemSetting) => void;
  isCurrentItem?: boolean;
}

interface DispatchProps {
  updateItem: (item: MeetingItem) => void;
}

interface StateProps {
  data: any;
  accountData: AccountData;
}

type Props = DispatchProps & StateProps & FuncProps;

const validation = Yup.object().shape({
  what: Yup.string()
    .min(5, "5 character minimum")
    .required("Please add your what"),
  why: Yup.string()
    .min(10, "10 character minimum")
    .required("Please add your why"),
  // team: Yup.mixed().required("Please choose a team"),
});

const validationForComment = Yup.object().shape({
  comment: Yup.string().required("Please add your comment"),
});

const MeetingItemDialog: React.FC<Props> = ({
  state,
  dialogItem,
  setDialogState,
  updateItem,
  data,
  accountData,
  type,
  editArtifact,
  isCurrentItem
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [item, setItem] = React.useState<MeetingItem>(dialogItem);
  const [name, setName] = React.useState<string>("");
  const [initial, setInitial] = React.useState<string>("");
  const [loading, setLoader] = React.useState<boolean>(false);

  const comments = dialogItem && dialogItem.comment;
  const onClose = (values: MeetingItem, errors: FormikErrors<any>) => {
    if (
      isEmpty(errors) &&
      (values.why !== dialogItem.why || values.what !== dialogItem.what || (type === "Action Item" && values.when !== dialogItem.when))
    ) {
      if (isCurrentItem && editArtifact) {
        editArtifact(values);
      } else {
        updateItem(values);
      }

      setDialogState(false);
    } else if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((k) =>
        enqueueSnackbar(errors[k], { variant: "error" })
      );
    } else {
      setDialogState(false);
    }
  };

  const setComment = async (values: any, actions: any, item: any) => {
    let meetingItemCopy = dialogItem;
    if (meetingItemCopy && !meetingItemCopy.comment) {
      meetingItemCopy.comment = [];
    }

    meetingItemCopy?.comment?.push({
      owner_id: getUserId()!,
      time: new Date(),
      comment: values.comment,
    });

    if (meetingItemCopy) {
      setLoader(true);
      if (isCurrentItem && editArtifact) {
        await editArtifact(meetingItemCopy);
      } else {
        await updateItem(meetingItemCopy);
      }
      let items: any = item;
      if (items && !items.comment) {
        items.comment = [];
      }

      items?.comment.push({
        owner_id: getUserId()!,
        time: new Date(),
        comment: values.comment,
        name: getThisUserName(),
        initial: getInitials(),
      });

      setItem(items);
      setLoader(false);
    }

    if (document.getElementById("comment") !== null) {
      document.getElementById("comment")!.style.visibility = "hidden";
    }
    // actions.resetForm();
  };

  React.useEffect(() => {
    // setLoader(false);
    if (
      document.getElementsByTagName("textarea") &&
      document.getElementsByTagName("textarea")[1]
    ) {
      document.getElementsByTagName("textarea")[1].rows = 1;
    }
  }, [comments]);

  React.useEffect(() => {
    let item = dialogItem;
    (async () => {
      setLoader(true);
      if (initial === "" || name === "") {
        const owner = await stitchApp.callFunction("getUserNames", [
          [item.owner_id],
        ]);
        setName(`${owner[0]?.firstName} ${owner[0]?.lastName}`);
        const initial = `${capitalize(
          owner[0]?.firstName?.charAt(0)
        )}${capitalize(owner[0]?.lastName.charAt(0))}`;
        setInitial(initial);
      }

      await item?.comment?.forEach(async (comment) => {
        if (comment.owner_id === item.owner_id) {
          comment.name = name;
          comment.initial = initial;
        } else {
          const commentedUser = await stitchApp.callFunction("getUserNames", [
            [comment.owner_id],
          ]);
          comment.name = `${commentedUser[0]?.firstName} ${commentedUser[0]?.lastName}`;
          comment.initial = `${capitalize(
            commentedUser[0]?.firstName?.charAt(0)
          )}${capitalize(commentedUser[0]?.lastName.charAt(0))}`;
        }
      });
      if (getUserId() !== dialogItem.owner_id && state && type === "Meeting Item") {
        const user_name = await stitchApp.callFunction("getUserNames", [
          [item.owner_id],
        ]);
        const name = `${user_name[0]?.firstName} ${user_name[0]?.lastName}`;
        enqueueSnackbar(`You can't edit this item as it was created by ${name}. You can leave a comment.`, {variant: 'warning'});
      }
      setLoader(false);
      setItem(item);
    })();

    setTimeout(() => {
      if (
        document.getElementsByTagName("textarea") &&
        document.getElementsByTagName("textarea")[1]
      ) {
        document.getElementsByTagName("textarea")[1].rows = 1;
      }
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getComment = (comment: ItemComment, even: boolean) => (
    <Grid className="comments-container">
      <Grid>
        <Grid className="comment-avatar-box vertical-top inline">
          <Avatar className="comment-avatar primary-blue-background white">{comment.initial}</Avatar>
        </Grid>
        <Grid className="comment-note-box inline">
          <Typography className="body-text gray3">
            <b>{comment.name}</b> {comment.comment}
          </Typography>
          <Grid className="comment-time-container">
            <Typography className="small-text gray4">
              {moment(new Date(comment?.time)).format("LT, DD MMM YY")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Formik
      initialValues={dialogItem}
      onSubmit={(values, actions) => {
        actions.resetForm();
        actions.setSubmitting(false);
      }}
      validationSchema={validation}
    >
      {({ values, isSubmitting, errors, dirty }) => (
        <Form className="meeting-dialog-form">
          <Dialog
            open={state}
            style={{ overflowY: "scroll" }}
            fullWidth
            className=" meeting-item-dialog-container"
            onClose={() => onClose(values, errors)}
          >
            <Grid className="meeting-item-dialog">
              {loading && <LoadingDialog loading={true} />}
              <Grid className="meeting-dialog-upper-container">
                <Grid className="meeting-item-stream-heading">
                  <Typography className="gray4 small-text">
                    {/* {getStream(dialogItem?._id?.toString()!)} / {accountData.accountName} */}
                    {type}
                  </Typography>
                </Grid>
                <Grid className="meeting-item-field-heading">
                  <Typography className="gray4 pre-title">WHAT</Typography>
                </Grid>
                <Field
                  name="what"
                  type="text"
                  variant="outlined"
                  fullWidth
                  disabled={
                    (getUserId() !== dialogItem.owner_id || dialogItem.complete) &&
                    type !== "Action Item"
                  }
                  size="small"
                  className="meeting-item-dialog-field what"
                  component={TextField}
                />

                <Grid className="meeting-item-field-heading">
                  <Typography className="gray4 pre-title">WHY</Typography>
                </Grid>
                {/* <Field
                  name="why"
                  type="text"
                  variant="outlined"
                  fullWidth
                  rows={3}
                  multiline
                  disabled={
                    (getUserId() !== dialogItem.owner_id || dialogItem.complete) &&
                    type !== "Action Item"
                  }
                  size="small"
                  className="meeting-item-dialog-field why"
                  component={TextField}
                /> */}
                {typeof dialogItem.why === 'string' ? dialogItem.why : 
                 "Why created in draftjs"}
                {type=== "Action Item" && <>
                <Grid className="meeting-item-field-heading">
                  <Typography className="gray4 pre-title">DUE DATE</Typography>
                </Grid>
                <Field
                  name="when"
                  variant="outlined"
                  type="date"
                  fullWidth
                  md={12}
                  className="meeting-item-dialog-field due"
                />
                </>}
                <Grid className="meeting-dialog-owner-title">
                  <Typography className="pre-title gray4"> OWNER</Typography>
                </Grid>
                <Grid>
                  <Typography className="bold-text gray3">{name}</Typography>
                </Grid>
                <Grid>
                  <Typography className="small-text gray5">
                    Created on{" "}
                    {moment(new Date(dialogItem?._id?.getTimestamp()!)).format(
                      "LT, Do MMM YY"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Formik
              initialValues={{
                comment: "",
                complete: false,
              }}
              onSubmit={(values, actions) => {
                setComment(values, actions, item);
                actions.resetForm();
              }}
              validationSchema={validationForComment}
            >
              {({ isSubmitting, dirty, errors }) => (
                <Form>
                  <Grid className="meeting-item-comment-dialog">
                    <Grid className="meeting-dialog-owner-title">
                      <Typography className="pre-title gray4">
                        {" "}
                        COMMENTS
                      </Typography>
                    </Grid>
                    <Grid className="dialog-comment-container">
                      {item?.comment?.map((comment, index) => (
                        <>
                          {comment.name &&
                            getComment(comment, index % 2 === 0 ? true : false)}
                        </>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid className="comment-box-container">
                      <Grid className="comment-avatar-box vertical-top inline">
                        <Avatar className="comment-avatar primary-blue-background white">
                          {getInitials()}
                        </Avatar>
                      </Grid>
                      <Grid className="comment-box inline">
                        <Button
                          id="comment"
                          className="comment-button"
                          disabled={isSubmitting || !isEmpty(errors) || !dirty}
                          type="submit"
                        >
                            <Typography className="small-text">
                                Comment
                            </Typography>
                        </Button>
                        <Field
                            name="comment"
                            type="text"
                            variant="outlined"
                            fullWidth
                            size="small"
                            className="meeting-item-dialog-field comment"
                            component={TextField}
                        />
                      </Grid>
                    </Grid>
                </Form>
              )}
            </Formik>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    data: state.data,
    accountData: state.data.accountData[0]
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    updateItem: bindActionCreators(MeetingItemsReduxer.update, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingItemDialog);
