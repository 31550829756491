import React from 'react' 
import { Card, CardHeader, Grid, Typography, CardContent } from '@material-ui/core'
import { MeetingState } from '../../../../../../declarations/declarations';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import LoadingDialog from '../../../../../../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../../../store/actionTypes";
import {
    MeetingsReduxer
  } from "../../../../../../store/reduxer";

interface FuncProps {
    meetingState: MeetingState;
}

interface DispatchProps {
    updateMeeting: (meeting: any) => void;
}

type Props = FuncProps & DispatchProps;

const useStyles = makeStyles((theme) => ({
    card: {
        margin: '0px 5px 5px 5px',
        maxWidth: '205px',
        minWidth: '150px',
        maxHeight: '88px',
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: 0,
        paddingTop: 0,
        textAlign: 'center',
        border: '1px solid #655e5e'
    },
    content: {
      marginTop: "auto",
      marginBottom: "auto",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "16px",
    },
    colorRed: {
        color: "red"
    },
    initialColor: {
        color: "#8fc3f7"
    }
}));

const TimerComponent: React.FC<Props> = ({  meetingState, updateMeeting }) => {
    const [isTimeExceede, setIsTimeExceede] = React.useState<boolean>(false);
    const [minutes, setMinutes] = React.useState<string>();
   // const [isHours, setIsHours] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const currentAgendaItem = meetingState && meetingState.currentAgendaItem;
    
    const setTimmer = (timer: number) => {
        if (!minutes) {
            let secs = timer / 1000;
            let isTimeExceede = false;
            if (secs < 0) {
                secs = secs * -1;
                isTimeExceede = true;
                setIsTimeExceede(true);
            }

            setInterval(() => {
                const formatted = secs > 3600 ? moment.utc(secs*1000).format('hh:mm:ss') : moment.utc(secs*1000).format('mm:ss');
                if (secs <= 0 && !isTimeExceede) {
                    isTimeExceede = true;
                    setIsTimeExceede(true);
                }

                secs = isTimeExceede || secs <= 0 ? secs + 1 : secs - 1;
                setMinutes(formatted);

                if (loading) {
                    setLoading(false);
                }
            }, 1000)
        }
    }

    React.useEffect(() => {
        clearInterval();
        if (currentAgendaItem.timer) {
            if (currentAgendaItem.startedAt) {
                const milliSec = Date.now() - currentAgendaItem.startedAt;
                // let sec = milliSec / 1000; //moment.duration(sec, 'milliseconds');
                const timer = (currentAgendaItem.timer * 60 * 1000) - milliSec;
                setTimmer(timer);
            } else {
                let updateMeetingState = meetingState;
                const timer = currentAgendaItem.timer * 60 * 1000;
                setTimmer(timer);
                updateMeetingState.currentAgendaItem.startedAt = Date.now();
                updateMeeting(updateMeetingState);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles();

    return <Grid container style={{color: 'white', marginTop: '20px'}}>
        <Grid xs={4}>
            </Grid>
            <Grid xs={4} >
                {loading ? <LoadingDialog loading={true}/> : <Card className={classes.card}>
                    <CardHeader
                    title={
                        <Grid style={{ textAlign: 'center' }}>
                        <Typography
                            variant="h5"
                            color="primary"
                        >
                            Timer
                        </Typography>
                        </Grid>
                    }
                    style={{ paddingBottom: '0px', paddingTop: '10px' }}
                    />
                    <CardContent className={classes.content}>
                        <Typography variant="h5" className={isTimeExceede ? classes.colorRed : classes.initialColor}>
                            {`${minutes}`}
                        </Typography>
                    </CardContent>
                </Card>}
            </Grid>
            <Grid xs={4}>
            </Grid>
        </Grid>
    }

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, ActionTypes>
    ): DispatchProps => {
    return {
        updateMeeting: bindActionCreators(MeetingsReduxer.update, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(TimerComponent);
