import React from 'react';
import envelope from '../../assets/envelope.png';

interface FuncProps {
    width: string;
    height: string;
}

const EnvelopeImage: React.FC<FuncProps> = ({width, height}) => <div style={{margin: '2%'}}>
    <img src={envelope} alt="Envelope" style={{maxWidth: '300px'}} width={width} height={height}/>
</div>

export default EnvelopeImage;