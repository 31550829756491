import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { NoteItem } from '../../../../declarations/declarations';
import { stateToHTML} from "draft-js-export-html"
import { convertFromRaw } from 'draft-js';
import Parser from 'html-react-parser';
interface FuncProps {
    notes: Array<NoteItem>;
}

const Notes: React.FC<FuncProps> = ({ notes }) => {
    const [isNote, setIsNotes] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            if (notes) {
                const noteBlocks = notes[0] && notes[0].raw && notes[0].raw.blocks;
                if (noteBlocks && noteBlocks.length > 0) {
                    noteBlocks.forEach(note => {
                        if (!isNote && note.text !== "") {
                            setIsNotes(true);
                        }
                    });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notes]);
    
    return <Grid className="white-background notes-tab">
            <Grid item xs={12}><Typography className="subtitle gray2">{isNote ? 'Your Notes' : "You didn't take any notes"}</Typography></Grid>
            {notes.map(note => <Grid item className="gray3 body-text" xs={12} key={note._id.toString()}>{Parser(stateToHTML(convertFromRaw(note.raw)))}</Grid>)}
        </Grid>
}

export default Notes;