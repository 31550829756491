import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const ExpiredHeader: React.FC = () => <React.Fragment>
    <Grid item xs={12}>
        <Typography variant="h4" style={{marginBottom: '15px'}}>Your trial has finished!</Typography>
    </Grid>
    <Grid item xs={12}>
        <Typography color="primary" variant="h6">Please provide your card information to activate your subscription!</Typography>
    </Grid>
    <Grid item xs={12}>
        <Typography color="primary" variant="subtitle1">Thena is only $25 a month for 3 users, and $5 per month for each additional user.</Typography>
    </Grid>
</React.Fragment>

export default ExpiredHeader;