import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import FormInputField from "../../../../components/FormInputField/FormInputField";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MeetingItemsReduxer } from "../../../../store/reduxer";
import { initialDataHelper } from "../../../../helpers/formHelpers";
import { getAccountId, getUserId } from "../../../../helpers/helpers";
import { SystemState } from "../../../../store/store";
import {
  MeetingItemTypes,
  ItemList,
} from "../../../../declarations/declarations";
import { addEditItemState, openMyItem } from "../../../../store/actionCreators";
import { useSnackbar } from 'notistack';
import { RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import { addItemToList } from "../../../../store/lists/listsActionCreator";
import { Close } from '@material-ui/icons';
import './index.scss';

interface DispatchProps {
  postItem: (item: any) => RemoteInsertOneResult;
  updateItem: (item: any) => void;
  setState: (payload: {state: boolean, itemId?: string}) => void;
  setDrawer: () => void;
  addItemToList: (location_id: string, item_id: string) => void;
}

interface StateProps {
  loading: boolean;
  lists: ItemList[];
  state: {state: boolean, itemId?: string, location_id?: string};
}

type Props = DispatchProps & StateProps;

const validation = Yup.object().shape({
  what: Yup.string()
    .min(5, "5 character minimum")
    .required("Please add your what"),
  why: Yup.string()
    .min(10, "10 character minimum")
    .required("Please add your why"),
  // team: Yup.mixed().required("Please choose a team"),
});

const AddEditItemDialog: React.FC<Props> = ({
  state,
  setState,
  postItem,
  updateItem,
  addItemToList,
  setDrawer
}) => {
  const account_id = getAccountId() as string;

  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setState({state: false})
  }

  const post = async (values: any) => {
    const itemResult = await postItem(values);
    await addItemToList(
      state.location_id ? state.location_id : getUserId() as string, 
      itemResult.insertedId.toHexString()
    )
    enqueueSnackbar(`Created item: ${values.what}`, {variant: 'success'})
    if (!state.location_id) {
      setDrawer()
    }
  }

  const update = (values: any) => {
    updateItem(values)
    enqueueSnackbar(`Updated item: ${values.what}`, {variant: 'success'})
  }

  return (
      <Dialog
        open={state.state}
        fullWidth
        onClose={onClose}
        disableEnforceFocus={true}
        className="add-item-pop-up"
      >
        <DialogTitle className="add-item-pop-up-header">{state.itemId ? "Edit Item" : "Add Item"}</DialogTitle>
        <Formik
          initialValues={initialDataHelper(
            "meetingItems",
            {
              account_id,
              type: MeetingItemTypes.meetingItem,
              what: "",
              why: "",
              complete: false,
            },
            state.itemId
          )}
          onSubmit={(values, actions) => {
            actions.resetForm();
            actions.setSubmitting(false);
            setState({state: false});
            state.itemId ? update(values) : post(values);
          }}
          validationSchema={validation}
        >
          {({ isSubmitting, errors, dirty }) => (
            <Form>
              <DialogContent className="add-item-pop-up-content">
                <FormInputField name="what" label="Enter new meeting item" type="text" md={12} />
                <FormInputField
                  name="why"
                  label="Enter why"
                  type="text"
                  multiline
                  md={12}
                />
              </DialogContent>
              <DialogActions className="add-btn-pop-up-actions">
              <Button
                  size="large"
                  color="secondary"
                  onClick={onClose}
                  className="close-btn"
                >
                  <Close className="gray5 cursor-pointer" />
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isEmpty(errors) || !dirty}
                  size="large"
                  color="primary"
                  variant="contained"
                  className="add-btn-pop-up"
                >
                  {state.itemId ? "Save" : "Add new item"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    //@ts-ignore
    postItem: bindActionCreators(MeetingItemsReduxer.post, dispatch),
    updateItem: bindActionCreators(MeetingItemsReduxer.update, dispatch),
    setState: bindActionCreators(addEditItemState, dispatch),
    setDrawer: bindActionCreators(openMyItem, dispatch),
    addItemToList: bindActionCreators(addItemToList, dispatch)
  };
};
const mapStateToProps = (state: SystemState): StateProps => {
  return {
    loading: state.app.loading,
    lists: state.lists.lists,
    state: state.app.addEditItemState
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditItemDialog);
