import React from 'react';
import { MeetingSummary, MeetingItem, NoteItem, Data } from '../../declarations/declarations';
import { MeetingItemsReduxer, MeetingNotesReduxer, MetricDataReduxer } from '../../store/reduxer';
import { BSON } from 'mongodb-stitch-browser-sdk';
import Summary from './components/Summary';

interface FuncProps {
    summary: MeetingSummary;
}

const MeetingSummaryDialog: React.FC<FuncProps> = ({ summary }) => {

    const [itemsLoaded, setItemsLoaded] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<Array<MeetingItem>>([] as Array<MeetingItem>);
    const [data, setData] = React.useState<Array<Data>>([] as Array<Data>);
    const [notes, setNotes] = React.useState<Array<NoteItem>>([] as Array<NoteItem>);

    React.useEffect(() => {
        (async () => {
            if (summary) {
                const itemsIds: Array<any> = [];
                const notesIds: Array<any> = [];
                const dataIds: Array<any> = [];
                summary.meetingItems.forEach(id => itemsIds.push(new BSON.ObjectID(id)));
                summary.notes.forEach(id => notesIds.push(new BSON.ObjectID(id)));
                summary.dataItems.forEach(id => dataIds.push(new BSON.ObjectID(id)));
                const itemQuery: any = {_id: {$in: itemsIds}};
                const notesQuery: any = {_id: {$in: notesIds}};
                const dataQuery: any = {_id: {$in: dataIds}};
                const itemsResult = await MeetingItemsReduxer.database.find(itemQuery).toArray();
                const notesResult = await MeetingNotesReduxer.database.find(notesQuery).toArray();
                const dataResult = await MetricDataReduxer.database.find(dataQuery).toArray();
                setItems(itemsResult);
                setNotes(notesResult);
                setItemsLoaded(true);
                setData(dataResult);
            }
        })();     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[summary])

    return itemsLoaded ? <Summary notesProps={notes} 
                summary={summary} 
                items={items} 
                data={data} />
        : null
}

export default MeetingSummaryDialog;