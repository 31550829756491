import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { MeetingAgendaItem, MeetingStateType, MeetingState } from '../../../../declarations/declarations';
import NoteButton from '../NoteButton';
import AgendaDialog from './components/AgendaDialog';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { MeetingsReduxer } from "../../../../store/reduxer";
import './index.scss';
import { getStreamName } from '../../../../helpers/helpers';
import { ArrowBack, ArrowForward, ListAltOutlined } from '@material-ui/icons';
import MeetingInviteDialog from './components/MeetingInviteDialog';
import { useMeetingState } from '../../../../store/meeting/meetingState';

interface DispatchProps {
    updateMeeting: (meeting: any) => void;
}

interface FuncProps {
    meeting_id_string: string;
    startMeeting: () => void;
    currentAgendaItem: MeetingAgendaItem;
    firstAgendaItem: string;
    moveForward: () => void;
    moveBackward: () => void;
    isLastRound: boolean;
    concludeMeeting: () => void;
    setNoteState: () => void;
    noteActive: boolean;
    handleDemo: () => void;
    controls: 'agenda' | null;
    setControls: ( value : 'agenda' | null) => void;
  }

  type Props = FuncProps & DispatchProps;

const FacilitatorBar: React.FC<Props> = ({
    meeting_id_string,
    startMeeting,
    currentAgendaItem, 
    firstAgendaItem,
    moveForward, 
    moveBackward, 
    isLastRound,
    concludeMeeting,
    setNoteState,
    noteActive,
    handleDemo,
    updateMeeting,
    controls,
    setControls
}) => {
    
    const {facilitatorCheck, accessState} = useMeetingState(meeting_id_string)
    const isFacilitator = facilitatorCheck();
    const meetingState: MeetingState = accessState('state')
    const state = meetingState.state;
    const isDemo = meetingState.demo;
    const [meetingInvite, setMeetingInvite] = React.useState<boolean>(state === MeetingStateType.pending ? true : false);

    const showAgenda = () => {
        if(controls !== "agenda"){
            if(document.getElementById('stream-list') && document.getElementById("menu-button")){
                document.getElementById("menu-button")?.click();
            }
        }
        controls === 'agenda' ? setControls(null) : setControls('agenda');
    }

    const updateTime = ( agendaArray : Array<MeetingAgendaItem>) => {
        let updateMeetingState = meetingState as MeetingState;
        updateMeetingState.agendaItems= agendaArray;
        // setControls(null);
        updateMeeting(updateMeetingState);
    }

    const closeInviteDialog = () => {
        setMeetingInvite(false);
    }

    return <Grid container className="facilitator-bar-conatianer">
        <Grid 
            className="facilitator-bar white-background"
            color="inherit"
            container
            id="meeting_facilitator_bar"
            >
            <Grid container xs={12} className="heading justify-center">
                <Typography className="bold-text gray4">{`${getStreamName(meetingState.stream_id)}: ${meetingState.name}`}</Typography>
            </Grid>
            <Grid container className="justify-center">
                <Grid className="facilitator-option">
                    <Button onClick={moveBackward} 
                    id="meeting_facilitator_bar_back_btn"
                    className={(state === MeetingStateType.pending || currentAgendaItem.name === firstAgendaItem || !isFacilitator ? "" : "gray4 ") + "next-return-btn body-text"}
                    disabled={state === MeetingStateType.pending || currentAgendaItem.name === firstAgendaItem || !isFacilitator}>
                        <ArrowBack />
                        <Typography>
                            Back
                        </Typography>
                    </Button>
                </Grid>
                <Grid className="facilitator-option">
                    <Button onClick={showAgenda} 
                    id="meeting_facilitator_bar_agenda_btn"
                    className="next-return-btn gray4 body-text agenda-item"
                     style={controls === 'agenda' ? {backgroundColor: '#68BCD566'} : undefined} >
                        <ListAltOutlined />
                        <Typography>
                            Agenda
                        </Typography>
                    </Button>
                </Grid>
                {state === MeetingStateType.pending && <Grid className="facilitator-option">
                    <Button
                    id="meeting_facilitator_bar_next_btn"
                    className={(!isFacilitator ? "" : "gray4 ") + "next-return-btn body-text"}
                    onClick={startMeeting}
                    disabled={!isFacilitator}>
                        <ArrowForward />
                        <Typography>
                            Start
                        </Typography>
                    </Button>
                </Grid>}
                {state !== MeetingStateType.pending && <Grid className="facilitator-option">
                    <Button
                    className={(!isFacilitator || isLastRound ? "" : "gray4 ") + "next-return-btn body-text"}
                    onClick={moveForward}
                    disabled={!isFacilitator || isLastRound}>
                        <ArrowForward />
                        <Typography>
                            Next
                        </Typography>
                    </Button>
                </Grid>}
            </Grid>
        </Grid>
        <AgendaDialog 
            state={state} 
            currentAgendaItem={currentAgendaItem}  
            startMeeting={startMeeting}
            moveForward={moveForward}
            moveBackward={moveBackward}
            concludeMeeting={concludeMeeting}
            handleDemo={handleDemo}
            isDemo={isDemo}
            meetingState = {meetingState}
            isFacilitator = {isFacilitator}
            updateTime = {updateTime}
            dialogState = {controls === "agenda"}
            closeDialog = {showAgenda}
        />
        <MeetingInviteDialog state={meetingInvite} setState={closeInviteDialog}></MeetingInviteDialog>
        <NoteButton noteActive={noteActive} setNoteState={setNoteState}/>
    </Grid>
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, ActionTypes>
    ): DispatchProps => {
    return {
        updateMeeting: bindActionCreators(MeetingsReduxer.update, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(FacilitatorBar);