import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  MeetingTemplate,
} from "../../../declarations/declarations";

interface FuncProps {
  state: boolean;
  data: any;
  setState: (state: boolean) => void;
}

const DeleteTeamDialog: React.FC<FuncProps> = ({ state, data, setState }) => {
  return (
    <Dialog disableEnforceFocus={true} open={state} fullWidth>
      <DialogTitle>ALERT!!</DialogTitle>
      <DialogContent>
        Please remove the team from the following agenda templates before
        deleting
        <ul>
          {data.map((ele: MeetingTemplate) => (
            <li>{ele.name}</li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button size="large" color="primary" onClick={() => setState(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTeamDialog;
