import React from 'react';
import { Grid, Typography, Button, Avatar, Select, MenuItem } from '@material-ui/core';
import { SystemState } from '../../store/store';
import { connect } from 'react-redux';
import stitchApp from '../../stitch/app';
import { capitalize } from 'lodash';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../store/actionTypes';
import { UserInviteSettings } from '../../declarations/declarations';
import { AccountDataReduxer, InvitesReduxer } from '../../store/reduxer';
import './index.scss';
import StarIcon from '@material-ui/icons/Star';
import InviteDialog from './InviteDialog';

interface StateProps {
    adminIds: Array<string>;
    userIds: Array<string>;
    accountData: any;
}

interface DispatchProps {
    updateAccountData: (accountData: any) => void;
    createInvite: (invite: UserInviteSettings) => void;
}

type Props = StateProps & DispatchProps;

const AddTeamMembersPage: React.FC<Props> = ({ adminIds, userIds, accountData, createInvite, updateAccountData }) => {
    const [inviteState, setInviteState] = React.useState<boolean>(false);
    const [adminNames, setAdminNames] = React.useState<Array<any>>([]);

    React.useEffect(() => {
        (async () => {
            let admins = await stitchApp.callFunction('getUserNames', [adminIds]);
            let users = [];
            if (userIds) {
                users = await stitchApp.callFunction('getUserNames', [userIds])
            }

            if (admins) {
                admins.forEach((admin: any, index: any) => {
                    admin.user_id = adminIds[index];
                    admin.type = 'Admin'
                });
            }
            if (users) {
                users.forEach((user: any, index: any) => {
                    user.user_id = adminIds[index];
                    user.type = 'User'
                });
            }

            const result = [...admins, ...users]
            await setAdminNames(result);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = async(event: React.ChangeEvent<{ value: any }>, data: any) => {
        let admins =  adminNames;
        admins.forEach(user => {
            if (data.user_id === user.user_id) {
                user.type = event.target.value as string;
            }
        });

        let accountsDate = accountData;
        if (event.target.value === 'Admin') {
            if (accountsDate && accountsDate[0] && accountsDate[0].users) {
                for( var i = 0; i < accountsDate[0].users.length; i++){ 
                    if ( accountsDate[0].users[i] === data.user_id) { 
                        accountsDate[0].users.splice(i, 1);
                        if (accountsDate[0].admins) {
                            accountsDate[0].admins.push(data.user_id);
                        } else {
                            accountsDate[0].admins = [data.user_id];
                        }
                    }
                }
            }
        }

        if (event.target.value === 'User') {
            if (accountsDate && accountsDate[0] && accountsDate[0].admins) {
                for( var p = 0; p < accountsDate[0].admins.length; p++){ 
                    if ( accountsDate[0].admins[p] === data.user_id) { 
                        accountsDate[0].admins.splice(p, 1); 
                        if (accountsDate[0].users) {
                            accountsDate[0].users.push(data.user_id);
                        } else {
                            accountsDate[0].users = [data.user_id];
                        }
                    }
                }
            }
        }

        setAdminNames(admins);
        await updateAccountData(accountsDate[0]);
    };

    return <Grid container style={{padding: '1%'}}>
        <Grid item xs={12} className="manage-user">
            <Typography className="black user-title ">
                Manage users
            </Typography>
        </Grid>
        <Grid container item xs={12} className="users">
            <Grid item xs={10}>
                <Typography className="gray2 title">
                    Users
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={() => setInviteState(true)} className="invites-btn primary-dark-background">
                    <Typography className="button-text white text">
                        Invite members
                    </Typography>
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={3}>
                <Typography className="col-header gray3">
                    Users
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography className="col-header gray3">
                    Users Type
                </Typography>
            </Grid>
        </Grid>
        {adminNames.length > 0 ? <Grid container>
            {adminNames.map(admin => <Grid container className="user-row">
                <Grid container item xs={3}>
                    <Avatar className="avatar">
                        {capitalize(admin.firstName ? admin.firstName.charAt(0) : 0)+capitalize(admin.lastName ? admin.lastName.charAt(0): 0)}
                    </Avatar>
                    <Grid item>
                        <Typography className="user-name">
                            {`${admin.firstName} ${admin.lastName}`}
                        </Typography>
                        <Typography className="gray4 email">
                            {`${admin.firstName}@example.com`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={3} className="select-container">
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={admin.type}
                        onChange={(event) => handleChange(event, admin)}
                        renderValue={(value) => `${value}`}
                        >
                         {admin.type === 'User' && <MenuItem value={'Admin'}>Change to admin</MenuItem>}
                         {admin.type === 'Admin' && <MenuItem value={'User'}>Change to User</MenuItem>}
                    </Select>
                    {admin.type === 'Admin' && <StarIcon className="star-icon" />}
                </Grid>
            </Grid>)}
        </Grid>
        : null}
        <InviteDialog setState={setInviteState} state={inviteState} createInvite={createInvite} />
    </Grid>
}


const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        updateAccountData: bindActionCreators(AccountDataReduxer.update, dispatch),
        createInvite: bindActionCreators(InvitesReduxer.post, dispatch)
    }
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        adminIds: state.data.accountData[0].admins,
        userIds: state.data.accountData[0].users,
        accountData: state.data.accountData
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddTeamMembersPage);