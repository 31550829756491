import React from 'react';
import { Button } from '@material-ui/core';
import store from '../../../../store/store';

interface FuncProps {
    buttonFunction: () => void | undefined;
    id: string;
    isVotedItemId: string | undefined;
    voteNumber?: number;
    cardId: string;
}

const VoteOption: React.FC<FuncProps> = ({ buttonFunction, id, isVotedItemId, voteNumber, cardId }) => {

    const votedItem = store.getState().app.myVotedItems;
    let alreadyVoted = false;
    if (votedItem) {
        for(var i = 0 ; i < votedItem.length; i++ ){
            if(votedItem[i] === id){
                alreadyVoted = true;
            }
        }
    }

    return <React.Fragment>
        {!alreadyVoted && !isVotedItemId && !voteNumber ? <Button
            color="secondary"
            onClick={buttonFunction}
            id={`${cardId}-vote-button`}
        >
            VOTE
        </Button>
        : isVotedItemId === id ? <Button
            style={{color:'grey'}}
        >
            VOTED
        </Button> : voteNumber ? <Button
            color="secondary"
        >
            {voteNumber}
        </Button>  : null }
    </React.Fragment>
}

export default VoteOption; 