import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { SystemState } from "../../store/store";
import { connect } from "react-redux";

interface FuncProps {
  addRoute: string;
}

interface StateProps {
  drawer: boolean;
}

type Props = FuncProps & StateProps & RouteComponentProps

const AddFabButton: React.FC<Props> = ({ history, drawer, addRoute }) => {
  const useStyles = makeStyles((theme) => ({
    fabButton: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(!drawer ? 2 : 50),
    },
  }));
  const classes = useStyles();

  return (
    <Fab
      color="secondary"
      size="large"
      className={classes.fabButton}
      onClick={() => history.push(addRoute)}
    >
      <AddIcon />
    </Fab>
  );
};

const mapStateToProps = (state: SystemState ): StateProps => {
  return {
      drawer: state.app.myItemsDrawer
  }
}

export default withRouter(connect(mapStateToProps)(AddFabButton));
