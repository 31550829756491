import React from 'react';
import { 
    Avatar, 
    IconButton,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemAvatar,
    Divider} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MeetingSummary } from '../../../declarations/declarations';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getStreamName } from '../../../helpers/helpers';

interface FuncProps extends RouteComponentProps {
    item: MeetingSummary;
    index: number;
    length: number;
}

const MeetingListItem: React.FC<FuncProps> = ({item, index, length, history}) => <React.Fragment>
    <ListItem>
        <ListItemAvatar>
            <Avatar>
                <NoteIcon />
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={`${moment(item.date).format('DD-MMM')}: ${item.name}`}
            //@ts-ignore
            secondary={getStreamName(item.team)}
        />
        <ListItemSecondaryAction style={{paddingRight: '5%'}}>
            <IconButton edge="end" aria-label="show" onClick={() => {history.push(`/meetings/summaries/${item._id.toString()}`)}}>
                <VisibilityIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
    {index + 1 !== length ? <Divider /> : null}
</React.Fragment>

export default withRouter(MeetingListItem);