import React from "react";
import { ItemList, MeetingItem, MeetingItemTypes } from "../../declarations/declarations";
import { CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import './index.scss';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { getAccountId, getInitials, getUserId } from "../../helpers/helpers";
import { AppStateActionTypes } from "../../store/actionTypes";
import { addItemToList } from "../../store/lists/listsActionCreator";
import { BSON, RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import { useSnackbar } from "notistack";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { SystemState } from "../../store/store";
import { getLists } from '../../store/lists/listsActionCreator';
import IdeaItemCardComponent from '../../components/IdeaItemCardComponent/IdeaItemCardComponent';
interface FuncProps {
  title: string;
  location_id: string;
}

interface DispatchProps {
  getLists: (query: object) => void;
  postItem: (item: any) => RemoteInsertOneResult;
  addItemToList: (location_id: string, item_id: string, toStart: boolean) => void;
  getItems: (query: object) => void;
}

interface StateProps {
  lists: ItemList[]
}

type Props = DispatchProps & FuncProps & StateProps;

const IdeaItemList: React.FC<Props> = ({
   location_id, 
   title,
   postItem,
   addItemToList,
   getItems,
   lists
   }) => {

    const { enqueueSnackbar } = useSnackbar();
  const account_id = getAccountId() as string;
  const [what, setWhat] = React.useState<string>('');

  const theList = lists.filter(list => list.location_id === location_id)[0];
    const [load, setLoad] = React.useState(false);
    const [isEnabled, setIsEnabled] = React.useState<boolean>(false);
    React.useEffect(() => {
      if (theList) {
        (async () => {
          await getItems({
            _id: { $in: theList.items.map((item) => new BSON.ObjectId(item)) },
          });
        })();
      }
      setLoad(true);
    }, [theList]);

  const post = async () => {
    let values = {
        account_id,
        type: MeetingItemTypes.ideaItem,
        what: what,
        why: "",
        initials: getInitials(),
        complete: false,
    }
    const itemResult = await postItem(values);
    await addItemToList(
      location_id, 
      itemResult.insertedId.toHexString(),
      true
    )
    enqueueSnackbar(`Created item: ${values.what}`, {variant: 'success'})
  }

  const setWhatValue = (event: any) => {
    setWhat(event.currentTarget.value);
    if (event.currentTarget.value !== "") {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }

  const submitValue = (event: any) => {
    if (event.charCode === 13) {
      post();
      setWhat('');
      setIsEnabled(false);
    }
  }

  return !load ? (
    <CircularProgress color="secondary" />
  ) : <Grid container className="idea-item-list">
          <Grid >
            <Typography className="gray5 small-text idea-list-title">
              {title}
            </Typography>
            <Grid className="add-field-container">
              <TextField 
                className="add-field"
                label="" 
                placeholder="Write your idea"
                value={what}
                onChange={(e) => setWhatValue(e)}
                onKeyPress={(e) => submitValue(e)}
                variant="outlined" />
              {isEnabled ? <CloseIcon className="create-icon gray5" onClick={() => {
              setIsEnabled(false);
              setWhat('');
            }} /> : 
              <CreateIcon className="create-icon gray5" />}
            </Grid>
              {theList && theList.items && theList.items.length > 0 &&
              theList.items.map(item => {
                  return (
                    <IdeaItemCardComponent item_id={item} />
                  )
              })}
          </Grid>
      </Grid>
};

const mapStateToProps = (state: SystemState ): StateProps => {
  return {
      lists: state.lists.lists
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppStateActionTypes>
): DispatchProps => {
  return {
    //@ts-ignore
    postItem: bindActionCreators(MeetingItemsReduxer.post, dispatch),
    addItemToList: bindActionCreators(addItemToList, dispatch),
    getLists: bindActionCreators(getLists, dispatch),
    getItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaItemList);
