import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { registerNewAccount } from '../../../stitch/authentication';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FormLayout from '../FormLayout';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    email: Yup
        .string()
        .email('Must be an email address')
        .required('Please enter your email'),
    password: Yup
        .string()
        .min(8, 'at least 8 chars')
        .matches(/[a-z]/, 'at least one lowercase char')
        .matches(/[A-Z]/, 'at least one uppercase char')
        .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'at least 1 number or special char (0-9, @,!,#, etc).')
        .required('Please enter your password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Passwords must match'),
});

const initialData = {
    email: '',
    password: '',
    confirmPassword: ''
};

const Signup: React.FC<RouteComponentProps> = ({history}) => {

    const { enqueueSnackbar } = useSnackbar();

    return <FormLayout>
        <Formik 
            initialValues={initialData}
            onSubmit={async (values, actions) => {
                const result = await registerNewAccount(values.email, values.password);
                actions.resetForm();
                actions.setSubmitting(false);
                if (result) {
                    enqueueSnackbar(result === 'name already in use' ? 'email already in use' : result, {variant: 'error'})
                } else {
                    history.push('/please-verify-your-email');
                }
            }}
            validationSchema={validation}
            >
            {({ isSubmitting, dirty, errors }) => (
                <Form style={{marginLeft: '4%', marginRight: '4%'}}>
                    <Typography variant="h6">Get started for free</Typography>
                    <Typography variant="body2" style={{marginBottom: "10px", color: "#7482A1"}}>Thena can be used for free up to 10 users</Typography>
                    <FormInputField name="email" label="Your Work Email" type="text" md={12}/>
                    <FormInputField name="password" label="Password" type="password" md={12}/>
                    <FormInputField name="confirmPassword" label="Confirm Password" type="password" md={12}/>
                    <Grid item xs={12}>
                        <Button 
                            type="submit" 
                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                            size="large" 
                            color="primary" 
                            variant="contained"
                        >
                            Get Started
                        </Button>
                    </Grid>
                    <Grid item xs={12}style={{marginTop: "1%"}}>
                        <Typography variant="body2" color="textSecondary" style={{marginTop: "1%"}}>By clicking the button, you agree to our <a href="https://www.thena.app/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and have read and acknowledge our <a href="https://app.termly.io/document/privacy-notice/2efd9f4a-9d36-42b5-9a00-c6c8f3a370de" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" onClick={() => {history.push('/login')}}>I already have an account</Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    </FormLayout>
}

export default withRouter(Signup);