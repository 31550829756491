import React from "react";
import {Grid, Typography } from "@material-ui/core";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import IdeaOrgItemCardList from './components/IdeaOrgItemCardList';
import './index.scss';
  interface StateProps {
    lists: string[];
    name: string;
    idPrefix: string;
  }
  
  type Props = StateProps;
  
  const IdeaOrgItemLists: React.FC<Props> = ({
    lists,
    name,
    idPrefix
  }) => {
    return (
      <Grid container id={`${idPrefix}-horizontal-list-collection`}>
        {name && <Grid container md={12} className="idea-org-item-container">
            <DescriptionOutlinedIcon className="icon" />
            <Typography className="bold-text text">
                {name}
            </Typography>
        </Grid>}
        <Grid container
        style={{width: '100%',  paddingBottom: '30px'}}>
          {lists.length > 0 && lists.map((list_id, index) => <Grid
          style={{ width: '300px', marginTop: '30px', minHeight: '1px', marginRight: '20px', position: 'relative'}}>
            <IdeaOrgItemCardList 
            listUiId={`${idPrefix}-list-${index}`}
            name={name}
            list_id={list_id}  />
          </Grid>)}
        </Grid>
      </Grid>
    );
  };

  export default IdeaOrgItemLists;
  
