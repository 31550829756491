import React from 'react';
import classes from './Layout.module.css';
import LoadingDialog from './components/Dialogs/LoadingDialog/LoadingDialog';

const NoAuthLayout: React.FC = ({children}) => {

    return <div className={classes.screen}>
        <main className={classes.content}>
            {children}
        </main>   
        <LoadingDialog />
    </div>
}

export default NoAuthLayout;