import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MeetingItemCardList from '../../../components/List/MeetingItemCardList';
import { Stream } from '../../../declarations/declarations';

interface FuncProps {
    stream: Stream
}

const IndividualStream: React.FC<FuncProps> = ({stream}) => {
    const [addItem, setAddItem] = React.useState<string>('');

    return <Grid item xs={12} md={6} lg={4}>
        <Grid container style={{border: 'solid', borderColor: 'white', borderRadius: '1px', padding: '20px', width: '400px'}}>
            <Grid item xs={12} style={{textAlign: 'center', marginBottom: '20px'}}><Typography variant="h5">Stream: {stream.name}</Typography></Grid>
            {stream.lists.map(list => <Grid item xs={12} md={stream.lists.length > 1 ? 6 : 12}>
                <MeetingItemCardList listUiId={stream.name} key={list} list_id={list}
                    addItem={addItem}
                    setAddItem={setAddItem} />
            </Grid>)}
        </Grid>
    </Grid>
}

export default IndividualStream;