import React from 'react';
import { Button, Grid, Paper, Typography, Dialog } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MetricTypeReduxer } from '../../../store/reduxer';
import { initialDataHelper, createOptionsFromEnum, getTeamsForSelection } from '../../../helpers/formHelpers';
import { getAccountId } from '../../../helpers/helpers';
import FormSelectField from '../../../components/FormSelectField/FormSelectField';
import { TargetedMetricType, TargetDuration, MetricType } from '../../../declarations/declarations';
import { getAllUserNames } from '../../../stitch/app';

interface DispatchProps {
    createMetric: (item: any) => void;
    updateMetric: (item: any) => void;
}

interface FuncProps extends RouteComponentProps<{
    action: string;
    id: string;
}> {}

type Props = DispatchProps & FuncProps

const validation = Yup.object().shape({
    name: Yup
        .string()
        .required('Please add a name'),
    description: Yup
        .string()
        .required('Please add a description'),
    target: Yup
        .number()
        .required('Please enter a target'),
    targetDuration: Yup
        .mixed()
        .required('Please select a target duration'),
    type: Yup
        .mixed()
        .required('Please select a type'),
    team: Yup
        .mixed()
        .required('Please select a team'),
    who: Yup
        .mixed()
        .required('Please select someone')

});

const AddEditMetricPage: React.FC<Props> = ({match, history, createMetric, updateMetric}) => {

    const account_id = getAccountId() as string;
    const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);
    React.useEffect(() => {
        (async () => {
            const users = await getAllUserNames()
            setAllUsers(users);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initialData = initialDataHelper(
                'metrics', {
                name: '',
                description: '',
                target: '',
                targetType: '',
                targetDuration: '',
                account_id,
                team: '',
                who: '',
                type: MetricType.target
            }, match.params.id);

    return <Dialog open={true}>
        <Formik
        initialValues={initialData}
        onSubmit={async (values, actions) => {
            match.params.action === 'add' ? await createMetric(values) : await updateMetric(values);
            actions.resetForm();
            actions.setSubmitting(false)
            history.push('/account/metrics');
        }}
        validationSchema={validation}
        >
        {({ isSubmitting, errors, dirty }) => (
            <Form style={{marginLeft: '4%', marginRight: '4%', height: '100vh', }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{paddingBottom: '10px', color: 'white'}}>{`${match.params.action === 'add' ? 'Add' : 'Edit'} Metric`}</Typography>
                    </Grid>
                    <Paper style={{width: '100%', height: '100%', paddingRight: '20px', paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px'}}>
                        <Grid container>
                            <FormInputField name="name" label="Name" type="text" xs={12} md={12} required/>
                            <FormInputField name="description" label="Description" type="text" xs={12} md={12} multiline/>
                            <FormInputField name="target" label="Target" type="number" xs={12} md={4}/>
                            <FormSelectField name="targetType" label="Type" options={createOptionsFromEnum(TargetedMetricType)} required xs={12} md={4}/>
                            <FormSelectField name="targetDuration" label="Target Duration" options={createOptionsFromEnum(TargetDuration)} xs={12} md={4}/>
                            <FormSelectField name="team" label="Team" options={getTeamsForSelection()} required xs={12} md={6}/>
                            <FormSelectField name="who" label="Who's Accountable" options={allUsers} required xs={12} md={6}/>    
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: '5%', textAlign: 'right'}}>
                            <Button style={{margin: '10px'}} color="secondary" onClick={() => history.push('/account/metrics')}>Back</Button>
                            <Button style={{margin: '10px'}} color="primary" type="submit" disabled={isSubmitting || !isEmpty(errors) || !dirty} >{match.params.action === 'add' ? 'Create' : 'Save'}</Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Form>
        )}
    </Formik>
    </Dialog>

}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        createMetric: bindActionCreators(MetricTypeReduxer.post, dispatch),
        updateMetric: bindActionCreators(MetricTypeReduxer.update, dispatch)
    }
}

export default withRouter(connect(null,mapDispatchToProps)(AddEditMetricPage));