import React from 'react';
import LoadingDialog from '../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import { getUserId } from '../helpers/helpers';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../store/actionTypes';
import { bindActionCreators } from 'redux';
import { 
    UserDataReduxer, 
    AccountDataReduxer, 
    MeetingItemsReduxer, 
    MeetingTemplatesReduxer, 
    MeetingSummariesReduxer, 
    MetricTypeReduxer, 
    TeamsReduxer, 
    StreamsReduxer 
} from '../store/reduxer';
import { MeetingTemplate, Stream } from '../declarations/declarations';
import { SystemState } from '../store/store';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getUserProviderType, logout } from '../stitch/authentication';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { getLists } from '../store/lists/listsActionCreator';

interface FuncProps {
    children: React.ReactNode;
}

interface DispatchProps {
    getUserData: () => void;
    getAccountData: (query: any, options: any) => void;
    getMeetingItems: (query: any) => void;
    getTemplates: () => void;
    getSummaries: () => void;
    getMetrics: (query: any) => void;
    getTeams: () => void;
    getLists: (query: object) => void;
    getStreams: () => void;
}

interface StateProps {
    userData: Array<any>;
    accountData: Array<any>;
    streams: Array<Stream>;
    teams: Array<any>;
    agendaTemplates: MeetingTemplate[];
}

interface RouteProps extends RouteComponentProps<{
    redirectLink: string;
}> {}

type Props = FuncProps & DispatchProps & StateProps & RouteProps

const Loader: React.FC<Props> = React.memo(({
    children, 
    getUserData, 
    getAccountData, 
    getMeetingItems,
    getTemplates,
    getSummaries,
    getMetrics,
    getTeams,
    getLists,
    getStreams,
    userData,
    accountData,
    streams,
    teams,
    agendaTemplates,
    history
}) => {

    const [accountDataLoaded, setAccountDataLoaded] = React.useState<boolean>(false)
    const [userDataLoaded, setUserDataLoaded] = React.useState<boolean>(false)
    const [initialLoadState, setInitialLoadState] = React.useState<boolean>(false);
    const [fullLoad, setFullLoad] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            const projection = { projection: {
                stripe_cus_id: 0,
                stripe_sub_id: 0,
                industry: 0,
                size: 0
            }}
            await getAccountData({},projection);
            setAccountDataLoaded(true)    
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        if (accountDataLoaded) {
            if (accountData.length > 0) {
                (async () => {
                    await getUserData()
                    setUserDataLoaded(true)
                })();     
            } else {
                history.push('/account-setup');
                setFullLoad(true);
            }
        } else if (getUserProviderType() === 'anon-user') {
            (async () => await logout())();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData, accountDataLoaded])

    React.useEffect(() => {
        if (userDataLoaded) {
            if (userData[0] && !userData[0].firstName) {
                history.push('/user-setup')
                setFullLoad(true);
            } else {
                (async () => {
                    await getStreams()
                    await getTemplates();
                    await getSummaries();
                    setInitialLoadState(true);
                })();   
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataLoaded])
    React.useEffect(() => {
        if (initialLoadState) {
            const streamListIds: BSON.ObjectId[] = [];
            streams.forEach(stream => stream.lists.forEach(id => streamListIds.push(new BSON.ObjectId(id))));
            const personalListIds = teams.map(team => team._id.toString()).concat([getUserId()]);
            getLists({$or: [{_id: {$in: streamListIds}}, {location_id: {$in: personalListIds}}]})
            setFullLoad(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialLoadState]);
    return fullLoad ? <React.Fragment>{children}</React.Fragment> : <LoadingDialog loading={true}/>
});

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        accountData: state.data.accountData,
        userData: state.data.userData,
        streams: state.data.streams,
        teams: state.data.teams,
        agendaTemplates: state.data.meetingTemplates
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        getUserData: bindActionCreators(UserDataReduxer.get, dispatch),
        getAccountData: bindActionCreators(AccountDataReduxer.getFiltered, dispatch),
        getMeetingItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
        getTemplates: bindActionCreators(MeetingTemplatesReduxer.get, dispatch),
        getSummaries: bindActionCreators(MeetingSummariesReduxer.get, dispatch),
        getMetrics: bindActionCreators(MetricTypeReduxer.getFiltered, dispatch),
        getTeams: bindActionCreators(TeamsReduxer.get, dispatch),
        getLists: bindActionCreators(getLists, dispatch),
        getStreams: bindActionCreators(StreamsReduxer.get, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loader));