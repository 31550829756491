import React from 'react';
import { QuestionData } from '../../../declarations/declarations';
import { Grid, Typography } from '@material-ui/core';
import IndividualAnswer from '../../../pages/MeetingsPage/components/AgendaComponents/components/QuestionAnsweredState/components/IndividualAnswer';

interface FuncProps {
    question: string;
    answers: Array<QuestionData>;
}

const QuestionSection: React.FC<FuncProps> = ({question, answers}) => <Grid container>
    <Grid item xs={12}><Typography variant="h6">{question}</Typography></Grid>
    <Grid container style={{textAlign: 'center'}}>
        {answers.map(ans => <IndividualAnswer key={ans._id.toString()} ans={ans} xs={2}/>)}
    </Grid>
</Grid>

export default QuestionSection;