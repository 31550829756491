import React from 'react';
import { connect } from 'react-redux';
import AuthenticatedLayout from '../layout/AuthenticatedLayout';
import NoAuthLayout from '../layout/NoAuthLayout';
import { SystemState } from '../store/store';
import { hasLoggedInUser } from '../stitch/authentication';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PubRoutes from '../routes/AppRoutes/PubRoutes';

interface StateProps {
    loggedIn: boolean;
    userData: Array<any>
}

interface FuncProps {
    children: React.ReactNode;
}

type Props = StateProps & FuncProps & RouteComponentProps;

const AuthChecker: React.FC<Props> = React.memo(({children}) => {
    const loggedInState = hasLoggedInUser();
    return loggedInState ? <AuthenticatedLayout>{children}</AuthenticatedLayout> : <NoAuthLayout><PubRoutes/></NoAuthLayout>
});

const mapStateToProps = (state: SystemState): StateProps => {
    return {
        loggedIn: state.app.loggedIn,
        userData: state.data.userData
    }
}

export default withRouter(connect(mapStateToProps)(AuthChecker))