import React from 'react';
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import { TargetMetric } from '../../declarations/declarations';
import AddDataDialog from '../MetricCard/components/AddDataDialog/AddDataDialog';

interface FuncProps {
  data: Array<{date: string, value: any}>;
  metric: TargetMetric;
  createChartDate: (data: any) => void;
}

const CustomizedAxisTick: React.FC<FuncProps> = (props: any) => {
    const { x, y, payload, metric, createChartDate } = props;
    const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
    const [addDate, setDate] = React.useState<string>('false');
    
    const onClickAdd = (addDate: string) => {
      setDate(addDate.split('-empty')[0]);
      setIsOpenDialog(true);
    }

    return ( <>
      <g transform={`translate(${x},${y})`}>
        {payload && payload.value && payload.value.includes('-empty') && 
        <circle cx="1" cy="-8" r="5" stroke="#0015D3" stroke-width="3" fill="#0015D3" />}
        <text  x={payload && payload.value && payload.value.includes('-empty') ? 5 : 30} 
         y={payload && payload.value && payload.value.includes('-empty') ? -20 : 10} 
         style={{ cursor: 'pointer'}}
         dy={16} textAnchor="end" fill="black" onClick={() => onClickAdd(payload.value)}>
           {payload && payload.value && payload.value.includes('-empty') ? '+' : payload.value}
        </text>  
        {payload && payload.value && payload.value.includes('-empty') && 
          <text x={30} dy={16} textAnchor="end" fill="black"
            y={10} 
          >{payload.value.split('-empty')[0]}</text>}
      </g>
          <AddDataDialog addDate={addDate} state={isOpenDialog} metric={metric} close={() => setIsOpenDialog(false)} createChartDate={createChartDate}/>
      </>
    );
  };

const RenderLineChart:React.FC<FuncProps> = ({ data, metric, createChartDate }) =>  {
        return ( <AreaChart
            width={2000}
            height={241}
            data={data}
            margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
            }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={<CustomizedAxisTick data={data} 
             metric={metric} createChartDate={createChartDate} />}/>
            <YAxis type="category" dataKey="value" />
            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#e2dfdf" />            {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </AreaChart>
    );
}
export default RenderLineChart;
