import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Fab } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fabButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface FuncProps {
  setDialogState: (value: boolean) => void;
}

const MetricAddButton: React.FC<FuncProps> = ({ setDialogState }) => {
  const classes = useStyles();

  return (
    <Fab
      color="secondary"
      size="large"
      className={classes.fabButton}
      onClick={() => setDialogState(true)}
    >
      <AddIcon />
    </Fab>
  );
};

export default MetricAddButton;
