
import React from "react";
import { Button, Grid, Typography, Drawer } from "@material-ui/core";
import { MeetingAgendaItem, MeetingStateType, MeetingState } from "../../../../../declarations/declarations";

interface FuncProps {
  state: MeetingStateType;
  currentAgendaItem: MeetingAgendaItem;
  startMeeting: () => void;
  moveForward: () => void;
  moveBackward: () => void;
  concludeMeeting: () => void;
  isDemo: boolean | undefined;
  handleDemo: () => void;
  meetingState: MeetingState;
  isFacilitator?: boolean;
  updateTime: (agendaArray: Array<MeetingAgendaItem>) => void;
  dialogState: boolean;
  closeDialog: () => void;
}

const AgendaDialog: React.FC<FuncProps> = ({
  state,
  currentAgendaItem,
  startMeeting,
  moveForward,
  moveBackward,
  concludeMeeting,
  isDemo,
  handleDemo,
  meetingState,
  isFacilitator,
  updateTime,
  dialogState,
  closeDialog,
}) => {
  let showTimes = true;
  let currentAgendaNumber = 0;
  let startTimes: number[] = [];
  let endTimes: number[] = [];
  let StartTime =
    meetingState.startedAt &&
    new Date(meetingState.startedAt).toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

  const [agendaArray, setAgendaArray] = React.useState<
    Array<MeetingAgendaItem>
  >(meetingState.agendaItems);
  let EndTime = 0;

  const handleChange = (value: any, index: number) => {
    agendaArray[index].timer = value;
    setAgendaArray(agendaArray);
  };

  if (meetingState && agendaArray) {
    currentAgendaNumber = agendaArray.findIndex(
      (x) => x._id === currentAgendaItem._id
    );
    if (meetingState.state === MeetingStateType.pending) {
      showTimes = false;
    } else {
      for (var i = 0; i < agendaArray.length; i++) {
        if (!agendaArray[i].timer) {
          showTimes = false;
          break;
        }

        if (showTimes) {
          let startTime =
            meetingState.startedAt && i === 0
              ? meetingState.startedAt
              : endTimes[i - 1];

          let time = meetingState.agendaItems[i].timer as number;
          let durationToMs = time * 60 * 1000;
          let endTime =
            i < currentAgendaNumber && meetingState.agendaItems[i].endedAt
              ? meetingState.agendaItems[i].endedAt
              : startTime + durationToMs;
          startTimes.push(startTime);
          endTime && endTimes.push(endTime);
          if (i === meetingState.agendaItems.length - 1) {
            EndTime = endTime ? endTime : 0;
          }
        }
      }
    }
  }

  const onBlurFunction = () => {
    updateTime(agendaArray);
  };

  const onCloseFunction = () => {
    closeDialog();
    updateTime(agendaArray);
  };
  return (
    <Drawer
      className={
        dialogState ? "meeting-agenda-drawer" : "meeting-agenda-drawer0"
      }
      classes={{
        paper: "meeting-agenda-drawerPaper light-gray3-background",
      }}
      open={dialogState}
      variant="persistent"
      anchor="left"
      id="agendaMeetingDialog"
    >
      <Grid className="agenda-meeting-dialog-close text-align-right">
        <Button
          className="agenda-meeting-dialog-close-button"
          onClick={() => onCloseFunction()}
        >
          <i className="far fa-times gray4"></i>
        </Button>
      </Grid>
      <Grid className="agenda-meeting-dialog " container>
        <Grid className="agenda-dialog-heading-container">
          <Grid className="inline agenda-dialog-heading">
            <Typography className="gray2 title">Start Time</Typography>
          </Grid>
          <Grid className="inline">
            <Typography className="gray4 title">
              {showTimes && meetingState.startedAt ? StartTime : "?"}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }}>
          {agendaArray &&
            agendaArray.map((agenda, index) => {
              return (
                <Grid>
                  <Grid key={index} className="agenda-list-item-container">
                    <Grid className="inline agenda-name-container">
                      <Typography
                        className={
                          (index < currentAgendaNumber
                            ? "gray5"
                            : index > currentAgendaNumber
                            ? "primary-blue"
                            : "accent-yellow") + " small-text agenda-name"
                        }
                      >
                        {agenda.name}
                      </Typography>
                    </Grid>
                    <Grid className="inline agenda-time-container">
                      <Typography className={
                          (index < currentAgendaNumber
                            ? "gray5"
                            : index > currentAgendaNumber
                            ? "primary-blue"
                            : "accent-yellow") + " small-text agenda-time"
                        }>
                        {showTimes
                          ? new Date(startTimes[index]).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )
                          : "?"}{" "}
                        -{" "}
                        {showTimes
                          ? new Date(endTimes[index]).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )
                          : "?"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    className={
                      (index < currentAgendaNumber
                        ? "gray5"
                        : index > currentAgendaNumber
                        ? "primary-blue"
                        : "accent-yellow") +
                      " small-text agenda-dialog-input-container"
                    }
                  >
                    <Grid className="agenda-dialog-timer-input">
                      <label
                        htmlFor="timer"
                        className="agenda-dialog-timer-label gray5"
                      >
                        {" "}
                        Minutes{" "}
                      </label>
                      {dialogState && (
                        <input
                          name="timer"
                          id="meeting-agenda-timer-input"
                          onBlur={() => onBlurFunction()}
                          type="number"
                          defaultValue={
                            index < currentAgendaNumber
                              ? Math.round(
                                  (endTimes[index] - startTimes[index]) / 60000
                                )
                              : agenda.timer
                          }
                          disabled={
                            index < currentAgendaNumber || !isFacilitator
                          }
                          onChange={(e) => handleChange(e.target.value, index)}
                          className="agenda-timer-input-box"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        <Grid className="agenda-dialog-ending-container">
          <Grid className="inline agenda-dialog-heading">
            <Typography className="gray2 title">End Time</Typography>
          </Grid>
          <Grid className="inline">
            <Typography className="gray4 title">
              {meetingState.startedAt
                ? showTimes
                  ? new Date(EndTime).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : "?"
                : "Please Start Meeting"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};
export default AgendaDialog;
