import { Grid, Paper, Typography, Button, Avatar } from "@material-ui/core";
import moment from "moment";
import React from "react";
import {
  MeetingItem,
  MeetingItemTypes,
  MeetingStreamEvent,
  MeetingStreamEventTypes,
} from "../../../../../declarations/declarations";
import stitchApp from "../../../../../stitch/app";
import MeetingItemDialog from "../../../../../components/MeetingDialog/MeetingItemDialog";
import store from "../../../../../store/store";
import { MeetingItemsReduxer} from '../../../../../store/reduxer';

interface FuncProps {
  event: MeetingStreamEvent;
}

type Props = FuncProps;

const StreamItem: React.FC<Props> = ({ event }) => {
  const time = moment(new Date(event._id.getTimestamp())).format("hh:mm a");
  const [dialogState, setDialogState] = React.useState<boolean>(false);
  const [item, setItem] = React.useState<MeetingItem>();

  const [owner, setOwnerDetails] = React.useState({
    firstName: "",
    lastName: "",
  });

  React.useEffect(() => {
    (async () => {
      const owner = await stitchApp.callFunction("getUserNames", [
        [event.user_id],
      ]);
      setOwnerDetails(owner[0]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const getText = (type: MeetingStreamEventTypes) => {
    switch (type) {
      case MeetingStreamEventTypes.meeting_started:
        return "started the meeting";
      case MeetingStreamEventTypes.meeting_ended:
        return "finished the meeting";
      case MeetingStreamEventTypes.item_selected:
        return "selected an item";
      case MeetingStreamEventTypes.item_resolved:
        return "resolved an item";
      case MeetingStreamEventTypes.action_created:
        return "created an action item";
      case MeetingStreamEventTypes.decision_created:
        return "created a decision";
      case MeetingStreamEventTypes.action_deleted:
        return "deleted an action item";
      case MeetingStreamEventTypes.decision_deleted:
        return "deleted a decision";
      case MeetingStreamEventTypes.decision_edited:
        return "edited a decision";
      case MeetingStreamEventTypes.action_edited:
        return "edited an action item";
      case MeetingStreamEventTypes.agenda_move_forward:
        return "moved forward agenda";
      case MeetingStreamEventTypes.agenda_move_backward:
        return "moved backward agenda";
      case MeetingStreamEventTypes.list_created:
        return "added a new list";
      case MeetingStreamEventTypes.action_changed:
        return "changed an action item to a decision";
      case MeetingStreamEventTypes.decision_changed:
        return "changed a decision to an action item";
      default:
        return "added event to meeting stream";
    }
  };

  const getIcon = (type: MeetingStreamEventTypes) => {
    switch (type) {
      case MeetingStreamEventTypes.meeting_started:
        return <i className="fas fa-play primary-blue"></i>;
      case MeetingStreamEventTypes.meeting_ended:
        return <i className="fas fa-stop primary-blue"></i>;
      case MeetingStreamEventTypes.item_selected:
        return <i className="fal fa-file-alt primary-blue"></i>;
      case MeetingStreamEventTypes.item_resolved:
        return <i className="far fa-check-circle primary-blue"></i>;
      case MeetingStreamEventTypes.action_created:
        return <i className="fas fa-list-ul gray4"></i>;
      case MeetingStreamEventTypes.decision_created:
        return <i className="far fa-lightbulb gray4"></i>;
      case MeetingStreamEventTypes.action_deleted:
        return <i className="far fa-trash gray4"></i>;
      case MeetingStreamEventTypes.decision_deleted:
        return <i className="far fa-trash gray4"></i>;
      case MeetingStreamEventTypes.decision_edited:
        return <i className="far fa-edit gray4"></i>;
      case MeetingStreamEventTypes.action_edited:
        return <i className="far fa-edit gray4"></i>;
      case MeetingStreamEventTypes.agenda_move_forward:
        return <i className="fas fa-forward primary-blue"></i>;
      case MeetingStreamEventTypes.agenda_move_backward:
        return <i className="fas fa-backward primary-blue"></i>;
      case MeetingStreamEventTypes.list_created:
        return <i className="fas fa-columns primary-blue"></i>;
      case MeetingStreamEventTypes.action_changed:
        return <i className="far fa-edit gray4"></i>;
      case MeetingStreamEventTypes.decision_changed:
        return <i className="far fa-edit gray4"></i>;
      default:
        return <i className="fas fa-ban"></i>;
    }
  };

  const showTicket = async () => {
    let currentArtifact = event;
    if (
      currentArtifact.event !== MeetingStreamEventTypes.meeting_started &&
      currentArtifact.event !== MeetingStreamEventTypes.meeting_ended 
    ) {
      let item = store
        .getState()
        .data.meetingItems.filter(
          (item) =>
            item.type === MeetingItemTypes.meetingItem &&
            item._id.toHexString() === currentArtifact.item_id
        )[0] as MeetingItem;
      if (item && item._id !== undefined) {
        setItem(item);
        setDialogState(true);
      }
      else {
        const itemQuery: any = {_id: {$oid:currentArtifact.item_id}};
        const itemsResult = await MeetingItemsReduxer.database.find(itemQuery).toArray() as unknown as MeetingItem[];
        setItem(itemsResult[0]);
        setDialogState(true);
      }
    }
    // } else if (
    //   currentArtifact.event !== MeetingStreamEventTypes.meeting_started &&
    //   currentArtifact.event !== MeetingStreamEventTypes.meeting_ended
    // ) {
    //   showArtifact(currentArtifact.item_id);
    // }
  };

  return (
    <Grid item xs={12} className="meeting-stream-card-container">
      <Paper className="meeting-stream-card">
        <Grid className="meeting-stream-avatar-container inline">
          <Avatar aria-label={`initials`} className="meeting-stream-avatar">
            {`${owner.firstName[0]}${owner.lastName[0]}`}
          </Avatar>
        </Grid>
        <Grid className="icon-container inline white-background">
          {getIcon(event.event)}
        </Grid>
        <Grid className="meeting-stream-text-container inline vertical-top ">
          <Button
            onClick={(e) => showTicket()}
            style={{ minWidth: "1px", padding: "0px", textTransform: "none" }}
          >
            <Typography
              className="gray4 small-text meeting-stream-card-title"
            >
              <b>{owner.firstName}</b> {getText(event.event)}
            </Typography>
          </Button>
        </Grid>
        <Grid className="inline text-align-right vertical-middle time-container">
          <Grid>
            <Grid>
              <Grid>
                <Typography className="gray4 small-text">{time}</Typography>
              </Grid>
              <Grid style={{ marginTop: "4px" }}>
                <Typography className="gray5 pre-title meeting-stream-time">
                  {moment(new Date(event._id.getTimestamp())).fromNow()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {item && (
        <MeetingItemDialog
          type={item.type === MeetingItemTypes.actionItem ? "Action Item" : item.type === MeetingItemTypes.decision ? "Decision Item" : "Meeting Item"}
          state={dialogState}
          dialogItem={item}
          setDialogState={setDialogState}
        />
      )}
    </Grid>
  );
};

export default StreamItem;
