import React from "react";
import {
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  IdeaOrganizeState,
  MeetingItemTypes,
} from "../../../../../../../declarations/declarations";
import { ThunkDispatch } from "redux-thunk";
import { AppStateActionTypes } from "../../../../../../../store/actionTypes";
import { bindActionCreators } from "redux";
import {
  getAccountId,
  getInitials,
  getUserId,
} from "../../../../../../../helpers/helpers";
import { MeetingItemsReduxer } from "../../../../../../../store/reduxer";
import IdeaOrgMeetingItemList from "./IdeaOrgMeetingItemList";
import { useSnackbar } from "notistack";
import { RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import { addItemToList } from "../../../../../../../store/lists/listsActionCreator";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";

interface FuncProps {
  state: IdeaOrganizeState;
}

interface DispatchProps {
  postItem: (item: any) => RemoteInsertOneResult;
  addItemToList: (
    location_id: string,
    item_id: string,
    toStart: boolean
  ) => void;
}

type Props = FuncProps & DispatchProps;

const IdeaUnsortedList: React.FC<Props> = ({
  state,
  postItem,
  addItemToList,
}) => {
  let ideaLists = state && state.ideaLists;
  let MyList = ideaLists.filter((list) => list.attendee_id === getUserId())[0];
  let OthersList = ideaLists.filter((list) => list.attendee_id !== getUserId());
  const [isEnabled, setIsEnabled] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const account_id = getAccountId() as string;
  const [what, setWhat] = React.useState<string>("");
  const post = async () => {
    let values = {
      account_id,
      type: MeetingItemTypes.meetingItem,
      what: what,
      why: "",
      initials: getInitials(),
      complete: false,
    };
    const itemResult = await postItem(values);
    await addItemToList(
      MyList.location_id,
      itemResult.insertedId.toHexString(),
      true
    );
    enqueueSnackbar(`Created item: ${values.what}`, { variant: "success" });
  };

  const setWhatValue = (event: any) => {
    setWhat(event.currentTarget.value);
  };

  const submitValue = (event: any) => {
    if (event.charCode === 13) {
      post();
      setWhat("");
      setIsEnabled(false);
    }
  };

  return (
    <Grid className="idea-unsorted-list">
      <Grid container className="idea-unsorted-list-heading">
        <Grid item md={6}>
          <Typography className="gray3 bold-text">Unsorted List</Typography>
        </Grid>
        <Grid item md={6} className="text-align-right">
          <Typography className="gray5 small-text italic">
            Everyone can see this
          </Typography>
        </Grid>
      </Grid>
      <Grid className="idea-list-heading">
        <Typography className="gray5 small-text ">Your Ideas</Typography>
      </Grid>
      <Grid onClick={() => setIsEnabled(true)} className="add-field-container">
        <TextField
          disabled={!isEnabled}
          className="add-field"
          label=""
          placeholder="Write your idea"
          value={what}
          onChange={(e) => setWhatValue(e)}
          onKeyPress={(e) => submitValue(e)}
          variant="outlined"
        />
        {isEnabled ? (
          <CloseIcon
            className="create-icon gray5"
            onClick={() => {
              setIsEnabled(false);
              setWhat("");
            }}
          />
        ) : (
          <CreateIcon
            className="create-icon gray5"
            onClick={() => setIsEnabled(true)}
          />
        )}
      </Grid>
      <Droppable droppableId={"unsorted-my-list"} isCombineEnabled={true}>
        {(provided) => (
          <Grid
            container
            {...provided.droppableProps}
            innerRef={provided.innerRef}
            className="idea-droppable-container"
          >
            <IdeaOrgMeetingItemList
              location_id={MyList.location_id}
            ></IdeaOrgMeetingItemList>
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
      <Grid className="idea-list-heading">
        <Typography className="gray5 small-text ">Everyone else</Typography>
      </Grid>
      <Droppable droppableId={"unsorted-others-list"} isCombineEnabled={true}>
        {(provided) => (
          <Grid
            container
            {...provided.droppableProps}
            innerRef={provided.innerRef}
            className="idea-droppable-container"
          >
            {" "}
            {OthersList &&
              OthersList.map((list, index) => {
                return (
                  <IdeaOrgMeetingItemList
                    key={index}
                    location_id={list.location_id}
                  ></IdeaOrgMeetingItemList>
                );
              })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </Grid>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppStateActionTypes>
): DispatchProps => {
  return {
    //@ts-ignore
    postItem: bindActionCreators(MeetingItemsReduxer.post, dispatch),
    addItemToList: bindActionCreators(addItemToList, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(IdeaUnsortedList);
