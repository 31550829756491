import { Grid } from '@material-ui/core';
import React from 'react';
import { IdeaOrganizeState } from '../../../../../../declarations/declarations';
import IdeaUnsortedList from './IdeaUnsortedList/IdeaUnsortedList';
import MeetingItemHorizonalCollection from '../../../../../../components/MeetingItemHorizonalCollection/MeetingItemHorizonalCollection';
import { getUserId } from "../../../../../../helpers/helpers";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import store from "../../../../../../store/store";
import { useSnackbar } from "notistack";
import IdeaOrgItemLists from './IdeaAndOrgItemList/IdeaAndOrgItemLists'
import { moveBetweenLists, moveOnSameList } from "../../../../../../store/lists/listsActionCreator";
interface FuncProps {
    state: IdeaOrganizeState
    newOrganizeList: (name: string, items?:Array<string>) => void;
}

const OrganizeComponent: React.FC<FuncProps> = ({state, newOrganizeList}) => {
    const { enqueueSnackbar } = useSnackbar();
    let ideaLists = state && state.ideaLists;
    let MyList = ideaLists.filter((list) => list.attendee_id === getUserId())[0];
    let OthersList = ideaLists.filter((list) => list.attendee_id !== getUserId())
    
    const completeDragandDrop = (event: DropResult) => {
        const { destination, source, draggableId, combine } = event;
        const item = store.getState().data.meetingItems.filter(item => item._id.toHexString() === draggableId)[0]
        const sourceList= source?.droppableId === "unsorted-my-list" || source?.droppableId === "unsorted-others-list" ? ideaLists.filter((list) => list.attendee_id === item.owner_id)[0].location_id : source?.droppableId as string
        const destList = destination?.droppableId === "unsorted-my-list" || destination?.droppableId === "unsorted-others-list" ? ideaLists.filter((list) => list.attendee_id === item.owner_id)[0].location_id : destination?.droppableId as string
        if (destination?.droppableId === getUserId() && item.owner_id !== getUserId()) {
            enqueueSnackbar(`You can not move another user's item into your list`, {variant: 'error'})
        }
        else if(combine){
            let items = [draggableId, combine.draggableId]
            newOrganizeList(item.what, items)
        }
        else if (destination?.droppableId === source.droppableId) {
            if(destination?.droppableId === "unsorted-my-list"){
                //@ts-ignore
                store.dispatch(moveOnSameList(MyList.location_id, destination.index, draggableId));
            }
            else if(destination?.droppableId === "unsorted-others-list"){
                //cannot move item of others list
                enqueueSnackbar(`You can not sort another user's item.`, {variant: 'error'})
            }
            else{               
                //@ts-ignore
                store.dispatch(moveOnSameList(destList, destination.index, draggableId));
            }
        } else {
            if(destination?.droppableId === "unsorted-my-list" || destination?.droppableId === "unsorted-others-list"){
                if(source?.droppableId === "unsorted-my-list" || source?.droppableId === "unsorted-others-list"){
                    enqueueSnackbar(`You can not move another user's item in your list or your items to other users list`, {variant: 'error'})
                }
                else{
                    //@ts-ignore
                    store.dispatch(moveBetweenLists(sourceList, destList, destination?.index as number, draggableId));
                }
            }
            else{
                //@ts-ignore
                store.dispatch(moveBetweenLists(sourceList, destList, destination?.index as number, draggableId));
            }
        }
    
      }
      
      const onDragEnd = (event: DropResult) => {
        const { destination, source, combine } = event;
        if (!destination && !combine) {
            return;
        } else if (destination?.droppableId === source?.droppableId && destination?.index === source?.index) {
            return;
        } else {
          completeDragandDrop(event);
        }
      };

    return<DragDropContext onDragEnd={onDragEnd}><Grid className="text-align-left" >
        <Grid id="ideaAndOrganize" className = "inline">
            <IdeaUnsortedList state={state} />
        </Grid>
        <Grid container className="inline vertical-top organize-list-container">
            <Grid >
                <IdeaOrgItemLists 
                 lists={state.organizeLists} 
                 name='' idPrefix="organize"></IdeaOrgItemLists>
                {/* {state.organizeLists.map(list => <Grid item sm={12} md={6} style={{ flexShrink: 0 }}><IdeaOrgMeetingItemList key={list} location_id={list} options={true} edit={setEdit}/></Grid>)} */}
            </Grid>
        </Grid>
    </Grid>
    </DragDropContext>
}

export default OrganizeComponent;