import { ProfileInfo, ItemList } from "../declarations/declarations";

export const LOGIN_USER_STARTED  = 'LOGIN_USER_STARTED';
export const LOGIN_USER_FAILED  = 'LOGIN_USER_FAILED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

interface LoginStart {
    type: typeof LOGIN_USER_STARTED
}

interface LoginFail {
    type: typeof LOGIN_USER_FAILED
}

interface LoginSuccess {
    type: typeof LOGIN_USER_SUCCESS
}

export const RESTORE_SESSION = 'RESTORE_SESSION';

interface RestoreSessionAction {
    type: typeof RESTORE_SESSION
}

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

interface LoadingStart {
    type: typeof START_LOADING
}

interface LoadingStop {
    type: typeof STOP_LOADING
}

export const REGISTER_ACCOUNT_STARTED  = 'REGISTER_ACCOUNT_STARTED';
export const REGISTER_ACCOUNT_FAILED  = 'REGISTER_ACCOUNT_FAILED';
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS ';

interface RegisterAccountStart {
    type: typeof REGISTER_ACCOUNT_STARTED,
}

interface RegisterAccountFailed {
    type: typeof REGISTER_ACCOUNT_FAILED,
}

interface RegisterAccountSuccess {
    type: typeof REGISTER_ACCOUNT_SUCCESS,
}

export const REGISTER_USER_STARTED  = 'REGISTER_USER_STARTED';
export const REGISTER_USER_FAILED  = 'REGISTER_USER_FAILED';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS ';

interface RegisterUserStart {
    type: typeof REGISTER_USER_STARTED,
}

interface RegisterUserFailed {
    type: typeof REGISTER_USER_FAILED,
}

interface RegisterUserSuccess {
    type: typeof REGISTER_USER_SUCCESS,
}

export const LOGOUT_USER_STARTED  = 'LOGOUT_USER_STARTED';
export const LOGOUT_USER_FAILED  = 'LOGOUT_USER_FAILED';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

interface LogoutStart {
    type: typeof LOGOUT_USER_STARTED
}

interface LogoutFail {
    type: typeof LOGOUT_USER_FAILED
}

interface LogoutSuccess {
    type: typeof LOGOUT_USER_SUCCESS
}

export type SessionTypes = 
    LoginStart | 
    LoginFail | 
    LoginSuccess | 
    LoadingStart |
    LoadingStop |
    RegisterAccountStart |
    RegisterAccountFailed |
    RegisterAccountSuccess |
    RegisterUserStart | 
    RegisterUserFailed |
    RegisterUserSuccess |
    LogoutStart |
    LogoutFail |
    LogoutSuccess |
    RestoreSessionAction;

export const ADD_PROFILE = 'ADD_PROFILE';
export const ALTERNATE_MYITEM_STATE = 'ALTERNATE_MYITEM_STATE';
export const OPEN_MYITEM = 'OPEN_MYITEM';
export const HIDE_STREAM = 'HIDE_STREAM';
export const CLOSE_MYITEM = 'CLOSE_MYITEM';
export const UPDATE_ADDEDITITEMSTATE = 'UPDATE_ADDEDITITEMSTATE';
export const UPDATE_MOVEITEMSTATE = 'UPDATE_MOVEITEMSTATE';
export const UPDATE_DELETEITEMSTATE = 'UPDATE_DELETEITEMSTATE';
export const UPDATE_START_MEETING_STATE = 'UPDATE_START_MEETING_STATE';
export const UPDATE_SUBSCRIBE_DIALOG_STATE = 'UPDATE_SUBSCRIBE_DIALOG_STATE';

interface AddProfileInfo {
    type: typeof ADD_PROFILE,
    payload: ProfileInfo
}

interface AlternateMyItemState {
    type: typeof ALTERNATE_MYITEM_STATE
}

interface OpenMyItem {
    type: typeof OPEN_MYITEM
}

interface HideStream {
    type: typeof HIDE_STREAM,
    payload: any;
}

interface CloseMyItem {
    type: typeof CLOSE_MYITEM
}

interface UpdateAddEditItemState {
    type: typeof UPDATE_ADDEDITITEMSTATE
    payload: any;
}

interface UpdateMoveItemState {
    type: typeof UPDATE_MOVEITEMSTATE
    payload: any;
}

interface UpdateDeleteItemState {
    type: typeof UPDATE_DELETEITEMSTATE
    payload: any
}

interface UpdateStartMeetingMenuState {
    type: typeof UPDATE_START_MEETING_STATE
    payload: boolean;
}

interface UpdateSubscribeDialogState {
    type: typeof UPDATE_SUBSCRIBE_DIALOG_STATE
    payload: boolean;
}

export type StateTypes = 
    AddProfileInfo | 
    AlternateMyItemState | 
    OpenMyItem | 
    CloseMyItem | 
    UpdateAddEditItemState | 
    UpdateStartMeetingMenuState |
    UpdateMoveItemState |
    UpdateDeleteItemState |
    HideStream|
    UpdateSubscribeDialogState;

// Meeting State Actions
export const UPDATE_MEETING_LISTS = 'UPDATE_MEETING_LISTS';

interface UpdateMeetingLists {
    type: typeof UPDATE_MEETING_LISTS
    payload: ItemList[];
}

export type MeetingTypes = UpdateMeetingLists;


export interface StartedType {
    type: string;
}

export interface FailedType {
    type: string;
}

export interface SuccessType {
    type: string;
    payload: any;
}

export type ActionTypes = StartedType | FailedType | SuccessType;

export type AppStateActionTypes = ActionTypes | SessionTypes | StateTypes | MeetingTypes;