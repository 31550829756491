import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  IconButton,
  Typography,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import {
  Team,
  MeetingTemplate,
} from "../../../declarations/declarations";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteTeamDialog from "./DeleteTeamDialog";

interface FuncProps {
  team: Team;
  allUsers: Array<[string, any]>;
  editTeam?: () => void;
  deleteTeam?: (team: Team) => void;
  meetingTemplates: Array<MeetingTemplate>;
}

const TeamCard: React.FC<FuncProps> = ({
  team,
  allUsers,
  editTeam,
  deleteTeam,
  meetingTemplates,
}) => {
  const [dialogState, setState] = React.useState<boolean>(false);
  const [selectedMeetingTemplates, setMeetingTemplates] = React.useState<
    MeetingTemplate[]
  >([]);
  return (
    <Grid item xs={12} md={4} style={{ margin: "5px" }}>
      <Card style={{ height: "100%" }}>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              {team.name}
            </Typography>
          }
          action={
            <React.Fragment>
              {editTeam ? (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    editTeam();
                  }}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
              {deleteTeam ? (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    const data = meetingTemplates.filter((ele) =>
                      team._id.equals(ele.team)
                    );
                    if (data.length > 0) {
                      setMeetingTemplates(data);
                      setState(true);
                    } else {
                      deleteTeam(team);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </React.Fragment>
          }
        />
        <CardContent>
          <Grid container>
            {allUsers.length > 0 ? (
              team.members.map((member, key) => (
                <Grid item xs={12} key={key}>
                  <Typography variant="overline">
                    {allUsers.length > 0
                      ? allUsers.filter((user) => user[0] === member)[0][1]
                      : ""}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
          <DeleteTeamDialog
            state={dialogState}
            data={selectedMeetingTemplates}
            setState={setState}
          ></DeleteTeamDialog>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TeamCard;
