import React from 'react';
import { ItemList, MeetingItemCardOptions } from '../../../../../../declarations/declarations';
import { Grid, Typography } from '@material-ui/core'
import MeetingItemCard from '../../../../../../components/MeetingItemCard/MeetingItemCard';
import { Droppable } from 'react-beautiful-dnd';
import AddIcon from '@material-ui/icons/Add';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppStateActionTypes } from '../../../../../../store/actionTypes';
import { SystemState } from '../../../../../../store/store';
import { addEditItemState } from "../../../../../../store/actionCreators";
import { makeOutcomeNewList } from '../../../../../../helpers/helpers';
import { mongoClient } from '../../../../../../stitch/app';
import { updateList } from '../../../../../../store/lists/listsActionCreator';

interface StateProps {
    lists: ItemList[];
}

interface DispatchProps {
    setAddEditState: (payload: {state: boolean, itemId?: string, location_id?: string}) => void;
    updateList: (list: ItemList) => void;
}
interface FuncProps {
    refineList: any;
    list_id: string;
    isFacilitator: boolean;
    meeting_id: string;
}

type Props = FuncProps & StateProps & DispatchProps;

const RefineComponent: React.FC<Props> = ({ refineList, setAddEditState, list_id, isFacilitator, meeting_id, updateList }) => {
    
    const [outcomeList, setOutcomeList] = React.useState<ItemList[] | undefined>();

    React.useEffect(() => {
          (async () => {
            startWatch();
            const database = mongoClient.db('thenaMeet').collection('lists');
            let list: ItemList[] = await database.find({location_id: list_id}).toArray() as ItemList[]

            if (list !== undefined && list.length !== 0) {
                setOutcomeList(list);
            } else if (isFacilitator) {
                makeOutcomeNewList(list_id, meeting_id);
            }
          })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startWatch = async () => {
        const stream  = await mongoClient.db('thenaMeet').collection('lists').watch()
        stream.onNext(event => {
            const list = event.fullDocument as ItemList
            if (list.meeting_id === meeting_id) {
                updateList(list);
                setOutcomeList([list]);
            }
        })
    }

    return <Grid item xs={12} md={11}>
        <Grid
        item xs={12} md={12}
        style={{ marginBottom: '30px' }}>
            <Grid item xs={12} md={12} style={{ textAlign: "center", marginBottom: '10px' }}>
                <Typography>Outcome</Typography>
            </Grid>
            {isFacilitator && (!outcomeList || outcomeList === [] || (outcomeList && outcomeList[0] && outcomeList[0].items.length === 0)) ?
            <Grid style={{ textAlign: "center", marginBottom: '10px' }}>
             <AddIcon 
                onClick={() => {
                    setAddEditState({ state: true, location_id: list_id });
                }}
                style={{ fontSize: '45px', cursor: 'pointer' }} /> 
                </Grid> : 
                outcomeList && outcomeList[0] && outcomeList[0].items.length > 0 ? 
                <Droppable droppableId={""}>
                {(provided) => (
                    <Grid
                        container
                        direction="row"
                        
                        {...provided.droppableProps}
                        innerRef={provided.innerRef}
                    >
                        <MeetingItemCard
                            listUiId="refine-list"
                            maxWidthContent="100%"
                            key={outcomeList[0].items[0]}
                            item_id={outcomeList[0].items[0]}
                            index={1}
                            options={isFacilitator ? MeetingItemCardOptions.edit : MeetingItemCardOptions.no_options}
                        />
                    </Grid>)}
                </Droppable> : <Grid style={{ textAlign: "center", marginBottom: '10px' }}>
                    <Typography>You can see the outcome once facilitator add it </Typography>
                </Grid>}
        </Grid>
        <Grid style={{overflowX: 'auto', display: 'flex'}}>
            <Droppable droppableId={""}>
            {(provided) => (
                <Grid
                    container
                    direction="row"
                    
                    {...provided.droppableProps}
                    innerRef={provided.innerRef}
                >
                    {refineList.map((item_id: any, i: any) => <Grid item xs={4} md={4}>
                        <MeetingItemCard
                            listUiId="refine-list"
                            maxWidthContent="100%"
                            key={item_id[0]}
                            item_id={item_id[0]}
                            index={i}
                            voteNumber={item_id[1]}
                            options={MeetingItemCardOptions.vote}
                        />
                    </Grid>
                    )}
                </Grid>)}
            </Droppable>
        </Grid>
    </Grid>
}


const mapStateToProps = (state: SystemState): StateProps => {
    return {
      lists: state.lists.lists,
    };
  };
  
  const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, AppStateActionTypes>
  ): DispatchProps => {
    return {
        updateList: bindActionCreators(updateList, dispatch),
        setAddEditState: bindActionCreators(addEditItemState, dispatch)
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(RefineComponent);
  

// export default RefineComponent;