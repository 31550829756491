import {
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    Grid,
    Typography,
    CircularProgress
  } from "@material-ui/core";
import React from "react";
import { isEmpty, toLower } from 'lodash';
import { getAccountId } from '../../helpers/helpers';
import { registerNewAccount } from '../../stitch/authentication';
import { useSnackbar } from 'notistack';
import { UserInviteSettings } from "../../declarations/declarations";
import './index.scss';
import ChipInput from 'material-ui-chip-input'

  interface FuncProps {
    state: boolean;
    setState: (state: boolean) => void;
    createInvite: (invite: UserInviteSettings) => void;
  }
  
  const InviteDialog: React.FC<FuncProps> = ({ state, setState, createInvite }) => {  
    const { enqueueSnackbar } = useSnackbar();
    const [inviteEmails, setInviteEmails] = React.useState<Array<string>>([]);
    const [dirty, setDirty] = React.useState<boolean>(true);
    const [load, setLoad] = React.useState<boolean>(false);

    const handleAddEmail = async(email: string) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            let email_list = inviteEmails;
            email_list.push(email);
            await setInviteEmails(email_list);
            setDirty(false);
        } else {
            setDirty(false);
        }
    }

    const handleDeleteEmail = (newEmail: string, index: number) => {
        let email_list = inviteEmails.filter(email => {
            return email !== newEmail;
        });

        setInviteEmails(email_list);
    }

    const submitInvites = async() => {
        setLoad(true);
        await inviteEmails.map(async(email) => {
            await createInvite({
                email: toLower(email),
                account_id: getAccountId() as string,
                type: 'admin',
                complete: false
            });
    
            const result = await registerNewAccount(email, process.env.REACT_APP_ADD_USER as string);
            if (result) {
                enqueueSnackbar(result === 'name already in use' ? `${email} Email already in use` : result, { variant: 'error' });
                setInviteEmails([]);
                setLoad(false);
            } else {
                enqueueSnackbar(`Your invite to ${email} has been sent`, { variant: 'success' });
                setInviteEmails([]);
                setLoad(false);
                setState(false);
            }
        });
    }

    return (
      <Dialog 
        open={state} 
        fullWidth 
        onClose={() => setState(false)}
        disableEnforceFocus={true}
      >
        <DialogTitle>
            <Typography className="invite-header gray2">
                Invite members 
            </Typography>
        </DialogTitle>
            <Grid container className="content-container">
                <Grid item xs={12}>
                    <Typography className="gray3 invite-text">
                        Send an email invite to join the Thena workspace
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ChipInput
                        newChipKeys={['Enter', 'Spacebar', ' ']}
                        placeholder="Enter emails to invite here"
                        variant="outlined"
                        fullWidth={true} 
                        fullWidthInput={true} 
                        value={inviteEmails}
                        onChange={() => setDirty(true)}
                        onAdd={(email) => handleAddEmail(email)}
                        onDelete={(email, index) => handleDeleteEmail(email, index)}
                        />
                </Grid>
            </Grid>
            <DialogActions>
                <Grid item>
                    <Button 
                        variant="contained" 
                        className="invite-btn"
                        type="submit"
                        onClick={submitInvites}
                        disabled={isEmpty(inviteEmails) || dirty || load}
                    >
                        {console.log(inviteEmails, inviteEmails.length)}
                        <Typography className="white invite-text">
                            {inviteEmails && inviteEmails.length >= 1 ? 'Send Invites' : 'Send Invite'}
                        </Typography>
                    </Button>
                </Grid>
            </DialogActions>
            {load && <Grid className="loader-div">
                <CircularProgress />
            </Grid>}
      </Dialog>
    );
  };
  
  export default InviteDialog;
  
