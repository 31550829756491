import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Inter'
  },
  palette: {
    primary: { 
      main: '#0066FF'
    },
    secondary: {
      main: '#FFC700'
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 17
      }
    }
  }
});

export default theme;