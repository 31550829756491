import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SettingsPersonalInfo from './components/SettingsPersonalInfo';


const SettingsPage: React.FC = () => <Grid container>
    <Grid item xs={12} style={{color: 'white'}}><Typography variant="h4">Personal</Typography></Grid>
    <SettingsPersonalInfo />
</Grid>

export default SettingsPage;