import store from "../store/store";
import { capitalize } from "lodash";
import {
  MeetingItemTypes,
  AgendaItemTypes,
  StartMeetingTypes,
  TargetDuration
} from "../declarations/declarations";
import { FormikErrors } from "formik";

export const initialDataHelper = (
  stateLocation: string,
  initialState: object,
  id?: string
) => {
  if (id) {
    const result = store
      .getState().data
      [stateLocation].filter((item: any) => item._id.toString() === id)[0];
    return result;
  } else {
    return initialState;
  }
};

export const createOptionsFromEnum = (
  options: object
): Array<[string, any]> => {
  const returnArray: Array<[string, any]> = Object.keys(
    options
  ).map((option) => [option, capitalize(option)]);
  return returnArray;
};

export const industryOptions: Array<[string, any]> = [
  ['Accounting','Accounting'],
  ['Airlines/Aviation','Airlines/Aviation'],
  ['Alternative Dispute Resolution','Alternative Dispute Resolution'],
  ['Alternative Medicine','Alternative Medicine'],
  ['Animation','Animation'],
  ['Apparel/Fashion','Apparel/Fashion'],
  ['Architecture/Planning','Architecture/Planning'],
  ['Arts/Crafts','Arts/Crafts'],
  ['Automotive','Automotive'],
  ['Aviation/Aerospace','Aviation/Aerospace'],
  ['Banking/Mortgage','Banking/Mortgage'],
  ['Biotechnology/Greentech','Biotechnology/Greentech'],
  ['Broadcast Media','Broadcast Media'],
  ['Building Materials','Building Materials'],
  ['Business Supplies/Equipment','Business Supplies/Equipment'],
  ['Capital Markets/Hedge Fund/Private Equity','Capital Markets/Hedge Fund/Private Equity'],
  ['Chemicals','Chemicals'],
  ['Civic/Social Organization','Civic/Social Organization'],
  ['Civil Engineering','Civil Engineering'],
  ['Commercial Real Estate','Commercial Real Estate'],
  ['Computer Engineering','Computer Engineering'],
  ['Computer Games','Computer Games'],
  ['Computer Hardware','Computer Hardware'],
  ['Computer Networking','Computer Networking'],
  ['Computer Software','Computer Software'],
  ['Computer/Network Security','Computer/Network Security'],
  ['Construction','Construction'],
  ['Consumer Electronics','Consumer Electronics'],
  ['Consumer Goods','Consumer Goods'],
  ['Consumer Services','Consumer Services'],
  ['Cosmetics','Cosmetics'],
  ['Dairy','Dairy'],
  ['Defense/Space','Defense/Space'],
  ['Design','Design'],
  ['E-Learning','E-Learning'],
  ['Education Management','Education Management'],
  ['Electrical/Electronic Manufacturing','Electrical/Electronic Manufacturing'],
  ['Entertainment/Movie Production','Entertainment/Movie Production'],
  ['Environmental Services','Environmental Services'],
  ['Events Services','Events Services'],
  ['Executive Office','Executive Office'],
  ['Facilities Services','Facilities Services'],
  ['Farming','Farming'],
  ['Financial Services','Financial Services'],
  ['Fine Art','Fine Art'],
  ['Fishery','Fishery'],
  ['Food Production','Food Production'],
  ['Food/Beverages','Food/Beverages'],
  ['Fundraising','Fundraising'],
  ['Furniture','Furniture'],
  ['Gambling/Casinos','Gambling/Casinos'],
  ['Glass/Ceramics/Concrete','Glass/Ceramics/Concrete'],
  ['Government Administration','Government Administration'],
  ['Government Relations','Government Relations'],
  ['Graphic Design/Web Design','Graphic Design/Web Design'],
  ['Health/Fitness','Health/Fitness'],
  ['Higher Education/Acadamia','Higher Education/Acadamia'],
  ['Hospital/Health Care','Hospital/Health Care'],
  ['Hospitality','Hospitality'],
  ['Human Resources/HR','Human Resources/HR'],
  ['Import/Export','Import/Export'],
  ['Individual/Family Services','Individual/Family Services'],
  ['Industrial Automation','Industrial Automation'],
  ['Information Services','Information Services'],
  ['Information Technology/IT','Information Technology/IT'],
  ['Insurance','Insurance'],
  ['International Affairs','International Affairs'],
  ['International Trade/Development','International Trade/Development'],
  ['Internet','Internet'],
  ['Investment Banking/Venture','Investment Banking/Venture'],
  ['Investment Management/Hedge Fund/Private Equity','Investment Management/Hedge Fund/Private Equity'],
  ['Judiciary','Judiciary'],
  ['Law Enforcement','Law Enforcement'],
  ['Law Practice/Law Firms','Law Practice/Law Firms'],
  ['Legal Services','Legal Services'],
  ['Legislative Office','Legislative Office'],
  ['Leisure/Travel','Leisure/Travel'],
  ['Library','Library'],
  ['Logistics/Procurement','Logistics/Procurement'],
  ['Luxury Goods/Jewelry','Luxury Goods/Jewelry'],
  ['Machinery','Machinery'],
  ['Management Consulting','Management Consulting'],
  ['Maritime','Maritime'],
  ['Market Research','Market Research'],
  ['Marketing/Advertising/Sales','Marketing/Advertising/Sales'],
  ['Mechanical or Industrial Engineering','Mechanical or Industrial Engineering'],
  ['Media Production','Media Production'],
  ['Medical Equipment','Medical Equipment'],
  ['Medical Practice','Medical Practice'],
  ['Mental Health Care','Mental Health Care'],
  ['Military Industry','Military Industry'],
  ['Mining/Metals','Mining/Metals'],
  ['Motion Pictures/Film','Motion Pictures/Film'],
  ['Museums/Institutions','Museums/Institutions'],
  ['Music','Music'],
  ['Nanotechnology','Nanotechnology'],
  ['Newspapers/Journalism','Newspapers/Journalism'],
  ['Non-Profit/Volunteering','Non-Profit/Volunteering'],
  ['Oil/Energy/Solar/Greentech','Oil/Energy/Solar/Greentech'],
  ['Online Publishing','Online Publishing'],
  ['Other Industry','Other Industry'],
  ['Outsourcing/Offshoring','Outsourcing/Offshoring'],
  ['Package/Freight Delivery','Package/Freight Delivery'],
  ['Packaging/Containers','Packaging/Containers'],
  ['Paper/Forest Products','Paper/Forest Products'],
  ['Performing Arts','Performing Arts'],
  ['Pharmaceuticals','Pharmaceuticals'],
  ['Philanthropy','Philanthropy'],
  ['Photography','Photography'],
  ['Plastics','Plastics'],
  ['Political Organization','Political Organization'],
  ['Primary/Secondary Education','Primary/Secondary Education'],
  ['Printing','Printing'],
  ['Professional Training','Professional Training'],
  ['Program Development','Program Development'],
  ['Public Relations/PR','Public Relations/PR'],
  ['Public Safety','Public Safety'],
  ['Publishing Industry','Publishing Industry'],
  ['Railroad Manufacture','Railroad Manufacture'],
  ['Ranching','Ranching'],
  ['Real Estate/Mortgage','Real Estate/Mortgage'],
  ['Recreational Facilities/Services','Recreational Facilities/Services'],
  ['Religious Institutions','Religious Institutions'],
  ['Renewables/Environment','Renewables/Environment'],
  ['Research Industry','Research Industry'],
  ['Restaurants','Restaurants'],
  ['Retail Industry','Retail Industry'],
  ['Security/Investigations','Security/Investigations'],
  ['Semiconductors','Semiconductors'],
  ['Shipbuilding','Shipbuilding'],
  ['Sporting Goods','Sporting Goods'],
  ['Sports','Sports'],
  ['Staffing/Recruiting','Staffing/Recruiting'],
  ['Supermarkets','Supermarkets'],
  ['Telecommunications','Telecommunications'],
  ['Textiles','Textiles'],
  ['Think Tanks','Think Tanks'],
  ['Tobacco','Tobacco'],
  ['Translation/Localization','Translation/Localization'],
  ['Transportation','Transportation'],
  ['Utilities','Utilities'],
  ['Venture Capital/VC','Venture Capital/VC'],
  ['Veterinary','Veterinary'],
  ['Warehousing','Warehousing'],
  ['Wholesale','Wholesale'],
  ['Wine/Spirits','Wine/Spirits'],
  ['Wireless','Wireless'],
  ['Writing/Editing','Writing/Editing']
];

export const companySizeOptions: Array<[string, any]> = [
  ["0to1", "0 - 1 employees"],
  ["2to10", "2 - 10 employees"],
  ["11to50", "11 - 50 employees"],
  ["51to200", "51 - 200 employees"],
  ["201to500", "201 - 500 employees"],
  ["above500", "500+ employees"],
];

export const FormMeetingItemTypesOptions: Array<[string, any]> = [
  [MeetingItemTypes.actionItem, "Action Item"],
  [MeetingItemTypes.decision, "Decision"]
];

export const DemoFormMeetingItemTypesOptions: Array<[string, any]> = [
  [MeetingItemTypes.decision, "Decision"],
  [MeetingItemTypes.actionItem, "Action Item"]
  
];

export const metricSelectOptions = (): Array<[string, any]> => {
  const returnArray: Array<[string, any]> = [];
  store
    .getState().data
    .metrics.forEach((metric) =>
      returnArray.push([metric._id.toString(), metric.name])
    );
  return returnArray;
};

export const getTeamsForSelection = (): Array<[string, any]> => {
  const result: Array<[string, any]> = [];
  store
    .getState().data
    .teams.forEach((team) => result.push([team._id.toString(), team.name]));
  return result;
};

export const getStreamsForSelection = (): Array<[string, any]> => {
  const result: Array<[string, any]> = [];
  store
    .getState().data
    .streams.forEach((stream) => result.push([stream._id.toString(), stream.name]));
  return result;
};

export const getDayforSelection = (targetDuration : TargetDuration): Array<[string, any]> => {
  var result: Array<[string, any]> = [];
  if (targetDuration === TargetDuration.weekly){
   result = [ 
    ["0" , "Sunday"],
    ["1" , "Monday"],
    ["2" , "Tuesday"],
    ["3" , "Wednesday"],
    ["4" , "Thursday"],
    ["5" , "Friday"],
    ["6" , "Saturday"],
   ];
  }

  if (targetDuration === TargetDuration.monthly){
    result = [ 
      ["1" , 1],
      ["2" , 2],
      ["3" , 3],
      ["4" , 4],
      ["5" , 5],
      ["6" , 6],
      ["7" , 7],
      ["8" , 8],
      ["9" , 9],
      ["10" , 10],
      ["11" , 11],
      ["12" , 12],
      ["13" , 13],
      ["14" , 14],
      ["15" , 15],
      ["16" , 16],
      ["17" , 17],
      ["18" , 18],
      ["19" , 19],
      ["20" , 20],
      ["21" , 21],
      ["22" , 22],
      ["23" , 23],
      ["24" , 24],
      ["25" , 25],
      ["26" , 26],
      ["27" , 27],
      ["28" , 28],
      ["29" , 29],
      ["30" , 30],
      ["31" , 31],
     ];
  }

  return result;
};

export const getWeekDaysBetweenDates = (startDate : Date, day: number) => {
  var totaldays: Array<Date> = [];
  let endDate = new Date();
  let StartDate = new Date(startDate);
  for (var i = StartDate; i <= endDate; i.setDate(i.getDate()+1)){
      if (i.getDay() === day){
        totaldays.push(new Date(i))
      }
  }
  return totaldays;
}

export const getMonthDaysBetweenDates = (startDate : Date, day: number) => {
  var totaldays: Array<Date> = [];
  let endDate = new Date();
  let StartDate = new Date(startDate);
  for (var i = StartDate; i <= endDate; i.setDate(i.getDate()+1)){
      if(day > 30 && (i.getMonth() === 3 || i.getMonth() === 5 || i.getMonth() === 8 || i.getMonth() === 10) && i.getDate() === 30){
        totaldays.push(new Date(i))
      }
      if(day > 28 && i.getMonth() === 1 && i.getDate() === 28){
        totaldays.push(new Date(i))
      }
      else if (i.getDate() === day){
        totaldays.push(new Date(i))
      }
  }
  return totaldays;
}

export const startMeetingAgendaSelection = (): Array<[string, any]> => {
  const result: Array<[string, any]> = [
    [StartMeetingTypes.quick_meeting, 'Quick Meeting'],
    [StartMeetingTypes.default, 'Stream Default']
  ];
  store.getState().data.meetingTemplates.forEach(item => {
    result.push([item._id.toString(), item.name]);
  });
  return result;
}

export const getAgendaTemplatesForSelection = (): Array<[string, any]> => {
  const result: Array<[string, any]> = [];
  store.getState().data.meetingTemplates.forEach((template) => {
    for (const item of template.agendaItems) {
      if (item.type === AgendaItemTypes.meetingItems) {
        result.push([template._id.toString(), template.name]);
        break;
      }
    }
  });
  return result;
};

export const getAgendaTemplatesFilteredForSelection = (team_id: string): Array<[string, any]> => {
  const result: Array<[string, any]> = [];
  store.getState().data.meetingTemplates.filter(agendaTemp => agendaTemp.team === team_id).forEach((template) => {
    for (const item of template.agendaItems) {
      if (item.type === AgendaItemTypes.meetingItems) {
        result.push([template._id.toString(), template.name]);
        break;
      }
    }
  });
  return result;
};


export const checkAgendaTeamMatch = (agenda_id: string, team_id: string, errors: FormikErrors<{team: string, agenda: string}>) => {
  const agenda = store.getState().data.meetingTemplates.filter(agenda => agenda._id.toHexString() === agenda_id)[0];
  if (!agenda) {
    return
  }else if (agenda.team === team_id) {
    return
  } else {
    errors.agenda = "Please update agenda"
  }
}

export const getAgendaItemsForSelection = (
  agendaTemplateId: string
): Array<[string, any]> => {
  const result: Array<[any, any]> = [];
  const templates = store.getState().data.meetingTemplates;
  const index = templates.findIndex(
    (template) => template._id.toString() === agendaTemplateId
  );
  if (index === -1) {
    return result;
  }
  templates[index].agendaItems.forEach((item,index) => {
    if (item.type === AgendaItemTypes.meetingItems) {
      result.push([index, item.name]);
    }
  });
  return result;
};
