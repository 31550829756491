import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CircularProgress, Grid } from "@material-ui/core";
import { MeetingItem, MeetingItemCardOptions } from "../../declarations/declarations";
import BaseCard from "./components/BaseCard";
import { SystemState } from "../../store/store";
import { connect } from "react-redux";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { BSON } from "mongodb-stitch-browser-sdk";
import './index.scss';

interface FuncProps {
  listUiId: string;
  item_id: string;
  index: number;
  options?: MeetingItemCardOptions;
  buttonFunction?: (item: MeetingItem) => void;
  maxWidthContent?: string;
  isVotedItemId?: string | undefined;
  voteNumber?: number;
}

interface StateProps {
  items: MeetingItem[]
}

interface DispatchProps {
  getItem: (query: object) => void;
}

type Props = FuncProps & StateProps & DispatchProps

const MeetingItemCard: React.FC<Props> = ({ listUiId, item_id, index, options, buttonFunction, items, getItem, maxWidthContent, isVotedItemId, voteNumber }) => {

  const item = items.filter(item => item._id.toHexString() === item_id)[0]
  const cardId = `${listUiId}-meetingItem-${index}`;

  React.useEffect(() => {
    if (!items.filter(item => item._id.toHexString() === item_id)[0]) {
      getItem({_id: new BSON.ObjectId(item_id)})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return !item ? <CircularProgress color="secondary" /> :
    <Draggable
      draggableId={item._id.toString()}
      index={index}
      key={item._id.toString()}
    >
      {(provided, snapshot) => (
        <Grid
          id={cardId}
          item
          xs={12}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          className={(snapshot.isDragging || snapshot.dropAnimation || snapshot.isDropAnimating ? 'draggingComponent' : '')}
        >
          <BaseCard 
            item={item} 
            options={options} 
            buttonFunction={buttonFunction}
            isVotedItemId={isVotedItemId} 
            voteNumber={voteNumber}
            />
        </Grid>
      )}
    </Draggable>
};

const mapStateToProps = (state: SystemState ): StateProps => {
  return {
      items: state.data.meetingItems as MeetingItem[]
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
      getItem: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MeetingItemCard);
