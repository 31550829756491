import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { MeetingItem, MeetingItemTypes, DiscussionRoundTypes, ActionItem, MeetingItemSetting } from '../../declarations/declarations';
import CurrentActionItemCard from '../MeetingItemCard/components/CurrentActionItemCard';

interface FuncProps {
    item: MeetingItem;
    currentItem?: Boolean;
}

const CurrentMeetingActionItemCard: React.FC<FuncProps> = ({
    item, 
    currentItem, 
}) => {
    return <Grid>
        <CurrentActionItemCard 
            item={item as ActionItem}
            currentItem={currentItem} 
        />
    </Grid>
}

export default CurrentMeetingActionItemCard;