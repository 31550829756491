import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PersonalInfo from '../Forms/PersonalInfo';
import { redirectIfCriteriaMet, getAccountId } from '../../../helpers/helpers';
import ThenaLogo from '../../../components/ThenaLogo/ThenaLogo';
import CompanyInfo from '../Forms/CompanyInfo';
import { UserDataReduxer, AccountDataReduxer, ListsReduxer, StreamsReduxer } from '../../../store/reduxer';
import store from '../../../store/store';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { refreshCustomData } from '../../../stitch/authentication';
import stitchApp from '../../../stitch/app';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { stopLoading, startLoading } from '../../../store/actionCreators';
import { bugsnagClient } from '../../../bugsnag/bugsnag';
import { useSnackbar } from 'notistack';

interface DispatchProps {
    getUserData: () => void;
    getAccountData: (query: any, filter: any) => void;
    startLoading: () => void;
    stopLoading: () => void;
    getStreams: () => void;
    getLists: () => void;
}

type Props = DispatchProps & RouteComponentProps;

const AccountInfo: React.FC<Props> = ({
    history, 
    getUserData, 
    getAccountData, 
    startLoading, 
    stopLoading, 
    getStreams, 
    getLists
}) => {

    const { enqueueSnackbar } = useSnackbar();

    const [initialFormActive, setActiveForm] = React.useState(true);
    const [ personalData, setPersonalData ] = React.useState({
        firstName: '',
        lastName: ''
    });

    const [ accountData, setAccountDataData ] = React.useState({
        accountName: '',
        industry: '',
        size: ''
    });

    const personalSubmit = (values: any) => {
        setPersonalData({
            firstName: values.firstName,
            lastName: values.lastName
        })
        setActiveForm(false);
    }

    const submitForm = async (values: any) => {

        startLoading();
        try {
            setAccountDataData({
                accountName: values.accountName,
                size: values.companySize,
                industry: values.industry
            })
            const result = await stitchApp.callFunction('createAccount',[values, personalData, stitchApp.auth.user?.id]);
            if (result.name === "Error") {
                enqueueSnackbar('Something went wrong creating your account, please contact support', { variant: 'error' })
                bugsnagClient.notify(`Account Creation Error: ${JSON.stringify(result, null, 2)}`)
            } else {
                // const meeting_id = result.meeting_id <--- until onboarding is added back in
                await refreshCustomData();
                await getUserData();
                const account_id = getAccountId() as string;
                const query = {_id: new BSON.ObjectId(account_id)};
                const projection = { projection: {
                    stripe_cus_id: 0,
                    stripe_sub_id: 0,
                    industry: 0,
                    size: 0
                }}
                await getAccountData(query, projection);
                await getStreams();
                await getLists();
                history.push(`/dashboard`);
            }
        } catch (error) {
            bugsnagClient.notify(error);
            stopLoading();
        }  
        stopLoading();
    }

    React.useEffect(() => {
        redirectIfCriteriaMet(store.getState().data.accountData[0], history, '/dashboard');
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

        return <Dialog disableEnforceFocus={true} open={true} fullWidth>
            <DialogContent style={{margin: '4%', height: '100%'}}>
                <span style={{textAlign: 'center'}}><ThenaLogo width='50%' height='4%'/></span>
                    {initialFormActive ? 
                        <PersonalInfo initialData={personalData} submit={personalSubmit}/> 
                        : <CompanyInfo initialData={accountData} submit={submitForm} />}
                </DialogContent>
        </Dialog>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        getUserData: bindActionCreators(UserDataReduxer.get, dispatch),
        getAccountData: bindActionCreators(AccountDataReduxer.getFiltered, dispatch),
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        getStreams: bindActionCreators(StreamsReduxer.get, dispatch),
        getLists: bindActionCreators(ListsReduxer.get, dispatch),
    }
}

export default withRouter(connect(null,mapDispatchToProps)(AccountInfo));