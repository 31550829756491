import store from '../../../../../store/store'
import { MeetingStreamEventSettings, MeetingStreamEventTypes } from '../../../../../declarations/declarations'
import { StreamEventReduxer } from '../../../../../store/reduxer';
import { getAccountId } from '../../../../../helpers/helpers';

export const createMeetingStreamEvent = (
    type: MeetingStreamEventTypes, 
    stream_id: string, 
    meeting_id: string,
    user_id?: string,
    item_id?: string,
    title?: string,
) => {
    const event: MeetingStreamEventSettings = {
        account_id: getAccountId() as string,
        stream_id,
        type: 'meeting',
        meeting_id,
        event: type,
        user_id,
        item_id,
        title,
    };
    //@ts-ignore
    store.dispatch(StreamEventReduxer.post(event));
}

export const getText = (type: MeetingStreamEventTypes) => {
    switch (type) {
      case MeetingStreamEventTypes.meeting_started:
        return "Meeting started";
      case MeetingStreamEventTypes.meeting_ended:
        return "Meeting finished";
      case MeetingStreamEventTypes.item_selected:
        return "Item was selected";
      case MeetingStreamEventTypes.item_resolved:
        return "Item was resolved";
      case MeetingStreamEventTypes.action_created:
        return "Action Item created";
      case MeetingStreamEventTypes.decision_created:
        return "Decision was created";
      case MeetingStreamEventTypes.action_deleted:
        return "Action Item was deleted";
      case MeetingStreamEventTypes.decision_deleted:
        return "Decision was deleted";
      case MeetingStreamEventTypes.decision_edited:
        return "Decision was edited";
      case MeetingStreamEventTypes.action_edited:
        return "Action Item was edited";
      case MeetingStreamEventTypes.agenda_move_forward:
        return "Agenda moved forward";
      case MeetingStreamEventTypes.agenda_move_backward:
        return "Agenda moved backward";
      case MeetingStreamEventTypes.list_created:
        return "New List added";
      case MeetingStreamEventTypes.action_changed:
        return "Action Item was changed to Decision";
      case MeetingStreamEventTypes.decision_changed:
        return "Decision was changed to Action Item";
      default:
        return "Event added to meeting stream";
    }
  };