import { MeetingNotesReduxer, MeetingsReduxer } from "../../../../../store/reduxer";
import { getUserId, getAccountId } from "../../../../../helpers/helpers";
import { EditorState, convertToRaw } from "draft-js";
import { BSON } from "mongodb-stitch-browser-sdk";


const findNote = async (isPrivate: boolean, meeting_id: string) => {
    const result = await MeetingNotesReduxer.database.findOne(
        {
            meeting_id,
            owner_id: getUserId(),
            private: isPrivate
        }
    )
    return result
}
export const initiateNote = async (meeting_id: string, isPrivate: boolean) => {
    
        const storedNote = await findNote(isPrivate, meeting_id);
        if (storedNote) {
            return storedNote
        } else {
            const newNoteId = await MeetingNotesReduxer.database.insertOne({
                meeting_id: meeting_id.toString(),
                account_id: getAccountId() as string,
                owner_id: getUserId(),
                raw: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                private: isPrivate
            });
            MeetingsReduxer.database.updateOne({_id: new BSON.ObjectID(meeting_id)}, { $push: { notes: newNoteId.insertedId.toString()}})
            const newNote = await MeetingNotesReduxer.database.findOne({_id: newNoteId.insertedId});
            return newNote;
        }
}