import React from 'react';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { ProfileInfo, QuestionData } from '../../../../../../../declarations/declarations';
import { getProfile } from '../../../../../../../stitch/app';

const IndividualAnswer: React.FC<{ans: QuestionData, xs?: any}> = ({ans, xs}) => {
    
    const [profile, setProfile] = React.useState<ProfileInfo>();
    React.useEffect(() => {
        (async () => {
            const newProfile = await getProfile(ans.data.user_id);
            setProfile(newProfile as ProfileInfo);
        })();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <Grid item xs={xs ? xs : 3} style={{margin: '5px'}}>
        <Card>
            <CardContent>
                <Grid item xs={12}>
                    <Avatar style={{margin: 'auto'}}>
                        {profile?.profile}
                    </Avatar>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        {ans.data.answer}
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
}

export default IndividualAnswer;