import React from 'react';
import { connect } from 'react-redux';
import { SystemState } from '../../store/store';
import { MeetingSummary, MeetingItem, NoteItem, Data } from '../../declarations/declarations';
import { RouteComponentProps } from 'react-router-dom';
import { MeetingItemsReduxer, MeetingNotesReduxer, MetricDataReduxer } from '../../store/reduxer';
import { BSON } from 'mongodb-stitch-browser-sdk';
import LoadingDialog from '../../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import Summary from './components/Summary';
import { Grid } from '@material-ui/core';

interface StateProps {
    summaries: Array<MeetingSummary>;
}

interface RouteProps extends RouteComponentProps<{
    id: string;
}> {}

type Props = StateProps & RouteProps;

const MeetingSummaryPage: React.FC<Props> = ({summaries, match, history}) => {
    const summary_id = match.params.id;
    const summary = summaries.filter(item => item._id.toString() === summary_id)[0];
    const [loadedItems, setItemsLoaded] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<Array<MeetingItem>>([] as Array<MeetingItem>);
    const [data, setData] = React.useState<Array<Data>>([] as Array<Data>);
    const [notes, setNotes] = React.useState<Array<NoteItem>>([] as Array<NoteItem>);

    React.useEffect(() => {
        (async () => {
            if (summary) {
                const itemsIds: Array<any> = [];
                const notesIds: Array<any> = [];
                const dataIds: Array<any> = [];
                summary.meetingItems.forEach(id => itemsIds.push(new BSON.ObjectID(id)));
                summary.notes.forEach(id => notesIds.push(new BSON.ObjectID(id)));
                summary.dataItems.forEach(id => dataIds.push(new BSON.ObjectID(id)));
                const itemQuery: any = {_id: {$in: itemsIds}};
                const notesQuery: any = {_id: {$in: notesIds}};
                const dataQuery: any = {_id: {$in: dataIds}};
                const itemsResult = await MeetingItemsReduxer.database.find(itemQuery).toArray();
                const notesResult = await MeetingNotesReduxer.database.find(notesQuery).toArray();
                const dataResult = await MetricDataReduxer.database.find(dataQuery).toArray();
                setItems(itemsResult);
                setNotes(notesResult);
                setItemsLoaded(true);
                setData(dataResult);
            }
        })();     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[summary])


    return !loadedItems ? <LoadingDialog loading={true}/> : <Grid container 
        >
        <Summary summary={summary} items={items} notesProps={notes} data={data} />
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        summaries: state.data.summaries
    }
}

export default connect(mapStateToProps)(MeetingSummaryPage);