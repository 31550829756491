import React from 'react';
import { Grid } from '@material-ui/core';
import { MeetingSummariesReduxer, MeetingItemsReduxer } from '../../../../store/reduxer';
import { MeetingSummary } from '../../../../declarations/declarations';
import MeetingSummaryDialog from '../../../../components/MeetingSummary/MeetingSummaryDialog';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { getUserId } from '../../../../helpers/helpers';

interface DispatchProps {
    getMeetingItems: (query: any) => void;
    getMeetingSummaries: () => void;
}

interface FuncProps {
    meeting_id: string;
}

type Props = DispatchProps & FuncProps;

const Complete: React.FC<Props> = ({ meeting_id, getMeetingItems, getMeetingSummaries }) => {

    const [summaryLoaded, setLoaded] = React.useState<boolean>(false);
    const [summary, setSummary] = React.useState<MeetingSummary>();

    React.useEffect(() => {
        (async () => {
            const result = await MeetingSummariesReduxer.database.find({meeting_id}).toArray()
            setSummary(result[0]);
            setLoaded(true);

            await getMeetingItems({owner_id: new BSON.ObjectID(getUserId() as string), complete: false})
            await getMeetingSummaries();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <Grid container>
        {summaryLoaded ? <MeetingSummaryDialog 
            summary={summary as MeetingSummary}
        /> : null}
    </Grid>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        getMeetingItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
        getMeetingSummaries: bindActionCreators(MeetingSummariesReduxer.get, dispatch),
    }
}


export default connect(null, mapDispatchToProps)(Complete);