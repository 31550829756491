import { Dialog, Grid, Typography, Button } from "@material-ui/core";
import React, { createRef } from "react";
import '../index.scss';

interface FuncProps {
  state: boolean;
  setState: () => void;
}

const MeetingInviteDialog: React.FC<FuncProps> = ({ state, setState }) => {

    const textLinkInput = createRef<HTMLTextAreaElement>();

    const copyToClipboard = () => {
       let node = textLinkInput.current;
       console.log(node, textLinkInput.current)
       if(node){
           node.select();
           document.execCommand('copy');
           if (window.getSelection) {
            window.getSelection()?.empty();
            window.getSelection()?.removeAllRanges();
          }
       }
    }

  return (
    <Dialog open={state} disableEnforceFocus={true} className="meeting-invite-dialog">
      <Grid className="meeting-invite-dialog-head-container">
        <Grid className="meeting-invite-dialog-heading inline">
          <Typography className="subtitle gray2">Add others</Typography>
        </Grid>
        <Grid className="meeting-invite-dialog-close-container inline">
            <Button className="meeting-invite-dialog-close-button" onClick={() => setState()}>
                <i className="far fa-times gray4"></i>
            </Button>
        </Grid>
      </Grid>
      <Grid className="meeting-invite-dialog-body-container">
          <Grid>
              <Typography className="gray5 body-text">
                  Share this link with people you want in the meeting.
              </Typography>
          </Grid>
          <Grid className="meeting-invite-dialog-link-container">
              <textarea autoCorrect={"false"} spellCheck={"false"} className="gray3 body-text link-box" ref={textLinkInput} value={document.URL}/>
          </Grid>
          <Grid className="meeting-invite-dialog-copy-container">
            <Button className=" meeting-invite-dialog-copy-button" onClick={copyToClipboard}>
                <Grid className="inline copy-icon-container">
                    <i className="fal fa-copy"></i>
                </Grid>
                <Grid className="inline">
                    <Typography className="title gray3">Copy joining info</Typography>
                </Grid>
            </Button>
          </Grid>
        </Grid>
    </Dialog>
  );
};

export default MeetingInviteDialog;
