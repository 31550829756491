
export interface DrawOptions {
    name: string;
    link: string;
    id: string;
    icon?: string;
}

export const options = [
    {
        title: 'MEETINGS',
        options: [
            {
                name: 'Start a Meeting',
                link: 'updateStartMeetingState',
                id: 'menu_meeting_startMeeting',
                icon: "far fa-video-plus gray3"
            },
            {
                name: 'My Action Items',
                link: '/meetings/action-items',
                id: 'menu_meeting_actionItems',
                icon: "far fa-check-circle gray3"
            },
            {
                name: 'Summaries',
                link: '/meetings/summaries',
                id: 'menu_meeting_summaries',
                icon: "fal fa-clipboard-list-check gray3"
            },
            {
                name: 'Templates (coming soon)',
                link: 'comingSoon',
                id: 'menu_meeting_agendaTemplates',
                icon: "far fa-th-large light-gray1"
            }
        ] as Array<DrawOptions>
    },

    {
        title: 'SETTINGS',
        options: [
            {
                name: 'Users',
                link: '/settings/users',
                id: 'menu_settings_users',
                icon: "far fa-user-circle gray3"
            },
        ] as Array<DrawOptions>
    }
]