import React from 'react';
import { Button } from '@material-ui/core';

interface FuncProps {
    buttonFunction: () => void | undefined
    cardId: string;
}

const SelectOptions: React.FC<FuncProps> = ({buttonFunction, cardId}) => {

    return <React.Fragment>
        <Button
            id={`${cardId}-select-button`}
            color="secondary"
            onClick={buttonFunction}
        >
            SELECT
        </Button>
    </React.Fragment>
}

export default SelectOptions;