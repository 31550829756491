import React from 'react';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';



const CloseSnackBarButton: React.FC<{key: string | number | undefined}> = ({key}) => {
    const { closeSnackbar } = useSnackbar();
    
    return <IconButton onClick={() => closeSnackbar(key)}>
        <CancelIcon/>
    </IconButton>
    }

export default CloseSnackBarButton;