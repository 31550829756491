import { Stitch, StitchAppClient, RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import store from "../store/store";
import { addProfileInfo } from "../store/actionCreators";
import { ProfileInfo, MeetingItem, MeetingItemLocation, MeetingVote, MeetingItemSetting } from "../declarations/declarations";
import { bugsnagClient } from "../bugsnag/bugsnag";

const APP_ID = process.env.REACT_APP_STICH_APP as string;

const stitchApp: StitchAppClient = Stitch.hasAppClient(APP_ID) 
    ? Stitch.getAppClient(APP_ID)
    : Stitch.initializeDefaultAppClient(APP_ID);

export const mongoClient: RemoteMongoClient = stitchApp.getServiceClient(RemoteMongoClient.factory, "mongodb-atlas");

// functions

export const getInviteForInvitee = async (invite_id: string) => {
    return await stitchApp.callFunction('getInviteForInvitee', [invite_id]);
}

export const completeInvite = async (invite_id: string, user_id: string) => {
    return await stitchApp.callFunction('completeInvite', [invite_id, user_id]);
}

export const handleMeetingRefine = async (votes: MeetingVote[], account_id: string, meeting_id: string) => {
    return await stitchApp.callFunction('handleMeetingRefine', [votes, account_id, meeting_id]);
}

export const createStream = async (streamData: any, account_id: string) => {
    return await stitchApp.callFunction('createStream', [streamData, account_id]);
}

export const getProfile = async (id: string) => {
    try {
        // do we already have the profile?
        const stateProfile = store.getState().app.profiles.filter(profile => profile.id === id);
        if (stateProfile.length > 0) {
            return stateProfile[0]
        } else {
            // grab the profile from backend
            const newProfile = await stitchApp.callFunction('getProfile', [id]);
            // add it to redux state for future use
            store.dispatch(addProfileInfo(newProfile));
            return newProfile as ProfileInfo;
        }
    } catch (error) {
        bugsnagClient.notify(error);
    }
}

export const getAllUserNames = async () => {
    return await stitchApp.callFunction('getAllUsers', []);
}

export const moveItemCard = async (item: MeetingItem, destination: MeetingItemLocation) => {
    return await stitchApp.callFunction('moveItemCard', [item, destination])
}

export const addNewUserToExistingAccount = async (accountId: string, userId: string, type: string) => {
    return await stitchApp.callFunction('addNewUserToExistingAccount', [accountId, userId, type]);
}

export const postActionItem = async (account_id: string, stream_id: string, actionItem: MeetingItemSetting) => {
    return await stitchApp.callFunction('postActionItem', [account_id, stream_id, actionItem]);
}

export default stitchApp;