import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import FormInputField from '../../../../../../components/FormInputField/FormInputField';
import { isEmpty } from 'lodash';

interface FuncProps {
    state: boolean;
    closeForm: () => void;
    newList: (name: string) => void;
    editList: (name: string, id: string) => void;
    edit?: {
        edit_name: string;
        edit_id: string
    };
}

const ListNameDialog: React.FC<FuncProps> = ({state, closeForm, newList, editList, edit}) => {
    const initialData = edit ? {name: edit.edit_name} : {name: ''}
    const validation = Yup.object().shape({
        name: Yup
            .string()
            .required('Please enter a list name')
    })
    return <Dialog disableEnforceFocus={true} open={state} onClose={closeForm} fullWidth>
        <DialogTitle>{`${!edit ? "New List" : "Edit List Name"}`}</DialogTitle>
        <Formik
        initialValues={initialData}
        //validationSchema={validation}
        onSubmit={async (values, actions) => {
            actions.resetForm();
            actions.setSubmitting(false);
            closeForm();
            if (edit) {
                editList(values.name, edit.edit_id);
            } else {
                newList(values.name);
            }
        }}
        validationSchema={validation}
        >
        {({ dirty, errors, isSubmitting}) => (
            <Form>
                <DialogContent>
                    <Grid container>
                    <FormInputField
                        name="name"
                        label="List Name"
                        type="text"
                        required
                    />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    >
                    {`${edit ? "Save" : "Create"}`}
                    </Button>
                    
                </DialogActions>
            </Form>
        )}
        </Formik>
    </Dialog>
}

export default ListNameDialog