import React from 'react';
import { Route, withRouter, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import Signup from '../../pages/auth/Signup/Signup';
import Login from '../../pages/auth/Login/Login';
import VerifyEmail from '../../pages/auth/VerifyEmail/VerifyEmail';
import RegisterAccount from '../../pages/auth/RegisterAccount/RegisterAccount';
import ForgotPasswordEmail from '../../pages/auth/ForgotPassword/ForgotPasswordEmail';
import NewPasswordScreen from '../../pages/auth/ForgotPassword/NewPasswordScreen';
import RegisterUser from '../../pages/auth/RegisterUser/RegisterUser';

const PubRoutes: React.FC<RouteComponentProps> = ({location}) => <Switch>
    <Route path='/signup' component={Signup}/>
    <Route path='/login' exact component={Login}/>
    <Route path='/' exact component={Login}/>
    <Route path='/login/redirect=:redirectLink' params component={Login}/>
    <Route path='/please-verify-your-email' component={VerifyEmail}/>
    <Route path='/confirm-email-new-account' params component={RegisterAccount} />
    <Route path='/confirm-email-new-user/:token/:tokenId/:inviteId' params component={RegisterUser} />
    <Route path='/forgot-password/your-email' params component={ForgotPasswordEmail} />
    <Route path='/forgot-password/new-password/:token/:tokenId' params component={NewPasswordScreen} />
    <Route render={() => <Redirect to={`/login/redirect=${location.pathname.replace(/\//ig, '%20')}`} />} />
</Switch>

export default withRouter(PubRoutes);

