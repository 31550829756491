import React from 'react';
import { Grid } from '@material-ui/core';
import { MeetingItem, MeetingSummary, NoteItem, Data, QuestionData, RatingSetting } from '../../../declarations/declarations';
import { MetricTypeReduxer, MetricDataReduxer } from '../../../store/reduxer';
import { BSON } from 'mongodb-stitch-browser-sdk';
import QuestionSection from './QuestionSection';
import LoadingDialog from '../../../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import SummaryHeader from './SummaryHeader/SummaryHeader';
import SummaryTabs from './SummaryTabs/SummaryTabs';
import stitchApp from '../../../stitch/app';
import { capitalize } from 'lodash';
interface FuncProps {
    summary: MeetingSummary;
    items: Array<MeetingItem>
    data: Array<Data>;
    notesProps: Array<NoteItem>;
}

const Summary: React.FC<FuncProps> = ({summary, items, data, notesProps }) => {

    const [notes, setNotes] = React.useState<Array<NoteItem>>(notesProps);
    const [questions, setQuestions] = React.useState<Array<{id: string, name: string}>>([]);
    const [averageRating, setAverageRating] = React.useState<any>();
    const [ratingData, setRatingData] = React.useState<RatingSetting[]>();
    const [loadedItems, setItemsLoaded] = React.useState<boolean>(false);
    const [initials, setInitials] = React.useState<string[]>([]);
    const meeting_id = summary.meeting_id;
    
    React.useEffect(() => {
        setNotes(notesProps);
        getInitialsOnOwnerId();
        
        const metric_ids = data.map(d => new BSON.ObjectID(d.metric_id));
        (async () => {
            const metricTypes = await MetricTypeReduxer.database.find({_id: {$in: metric_ids}}).toArray();
            setQuestions(metricTypes.map(type => { return {id: type._id.toString(),name: type.name}}));
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        (async () => {
            if (meeting_id) {
                const metricDataStream = await MetricDataReduxer.database.watch();
                let ratingData: RatingSetting[] = await getMeetingRatings(meeting_id);
                 metricDataStream.onNext(async (event) => {
                     if (event.fullDocument.meeting_id === meeting_id) {
                       ratingData = await getMeetingRatings(meeting_id);
                       setRatingData(ratingData);
                       setAverage(ratingData);
                    }
                });
            
                setRatingData(ratingData);
                setAverage(ratingData);
                setItemsLoaded(true);
            }
        })();
    }, [meeting_id]);

    const getInitialsOnOwnerId = async() => {
        if (summary && summary.attendees) {
            let initialsData: string[] = [];
            await summary.attendees.map(async(attendee) => {
                const owner = await stitchApp.callFunction('getUserNames', [[attendee]]);
                const initial = `${capitalize(owner[0]?.firstName?.charAt(0))}${capitalize(owner[0]?.lastName.charAt(0))}`;
                initialsData.push(initial);
                setInitials(initialsData);
            });

        }
    }

    const setAverage = (ratingData: RatingSetting[]) => {
        if (ratingData && ratingData.length > 0) {
            let rating = 0;
            ratingData.forEach(rate => {
                rating = rating + rate.rating;
            });

            const avrageRating = (rating / (ratingData.length * 10)) * 10;
            setAverageRating(avrageRating);
        }
    }

    const getMeetingRatings = async (meeting_id: string) => {
        const ratings = await MetricDataReduxer.database
            .find({meeting_id: meeting_id})
            .toArray();
        return ratings;
    }

    return !loadedItems ? <LoadingDialog loading={true}/> : <Grid container style={{color: 'white', padding: '0px 80px 0px 30px'}}>
        <Grid container>
            <SummaryHeader 
                initials={initials} 
                summary={summary} 
                averageRating={averageRating}
                ratingData={ratingData} />
            <SummaryTabs notes={notes} items={items} />
            {questions.map(q => <QuestionSection key={q.id} question={q.name} answers={(data.filter(d => d.metric_id === q.id) as Array<QuestionData>)} />)}
        </Grid>
    </Grid>
}

export default Summary;
