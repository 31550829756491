import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { MeetingItem } from '../../../../declarations/declarations';
import ItemCard from '../../../ItemCard/ItemCard';
import './index.scss';
import FilterListIcon from '@material-ui/icons/FilterList';
import DecisionItemCard from '../../../MeetingItemCard/components/DecisionItemCard';
interface FuncProps {
    items: Array<MeetingItem>
    type: string
}

const ActionDecisionItems: React.FC<FuncProps> = ({ items, type }) => {
    const [isActionItem, setIsActionItem] = React.useState<boolean>(false);
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
      setLoad(false);
      setIsActionItem(false);
      if (items && items.length > 0) {
        items.forEach((item) => {
          let ActionOrDecisionItems = items.filter((actionItems) => 
              actionItems.spawn_id && actionItems.spawn_id === item._id.toHexString() && actionItems.type === type
          );

          if (ActionOrDecisionItems && ActionOrDecisionItems.length > 0) {
            setIsActionItem(true);
          }
        });
      }  

      setLoad(true);
    }, [items, type]);
    return !load ? <CircularProgress color="secondary" /> : <Grid className="box-container">
      <Grid container className="Card-container">
        {items && items.length > 0 && isActionItem ? items.map((item) => {
            let ActionOrDecisionItems = items.filter((actionItems) => 
                actionItems.spawn_id && actionItems.spawn_id === item._id.toHexString() && actionItems.type === type
            );
            return item.type === "meetingItem" && ActionOrDecisionItems && ActionOrDecisionItems.length > 0 ? <Grid className="action-item-list">
            <Grid container className="actions-list-header gray4">
              <Typography className="body-text text">
                {item.what}
              </Typography>
              <FilterListIcon  className="body-text filter-icon" />
            </Grid>
            <Grid className="action-items">
              {ActionOrDecisionItems && ActionOrDecisionItems.length > 0 && ActionOrDecisionItems.map((ele, index) => (
                <Grid key={ele._id.toString()} >
                  {type === "actionItem" && <ItemCard
                    item={ele}
                  />}
                  {type === "decision" && <DecisionItemCard
                    item={ele}
                    parentItem={item}
                  />}
                </Grid>
              ))}
              {/* {!ActionOrDecisionItems || (ActionOrDecisionItems && ActionOrDecisionItems.length === 0)  && 
              } */}
            </Grid>
            </Grid> : null
        }) : <Grid><Typography className="gray3  body-text">
        {`No ${type === 'actionItem' ? 'Action items' : 'decisions'} were created`}
     </Typography></Grid>}
    </Grid>
    </Grid>
}

export default ActionDecisionItems;