import { Dispatch } from "redux";
import { bugsnagClient } from "../../bugsnag/bugsnag";
import { ItemList, ItemListSettings } from "../../declarations/declarations";
import { startLoading, stopLoading } from "../actionCreators";
import { SessionTypes } from "../actionTypes";
import { mongoClient } from '../../stitch/app';
import store from "../store";
import { BSON } from "mongodb-stitch-browser-sdk";

export const GET_LISTS_STARTED  = 'GET_LISTS_STARTED';
export const GET_LISTS_FAILED  = 'GET_LISTS_FAILED';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';

interface GetListsStarted {
    type: typeof GET_LISTS_STARTED
}
interface GetListsFailed {
    type: typeof GET_LISTS_FAILED
}
export interface GetListsSuccess {
    type: typeof GET_LISTS_SUCCESS
    payload: ItemList[]
}

export type GetTypes = GetListsStarted | GetListsFailed | GetListsSuccess

export const getLists = (query?: object, options?: object) => {
    
    const database = mongoClient.db('thenaMeet').collection('lists');

    return async (dispatch: Dispatch<GetTypes | SessionTypes>) => {
        try {
            dispatch({type: GET_LISTS_STARTED})
            dispatch(startLoading())
            const lists: ItemList[] = await database.find(query, options).toArray() as ItemList[]
            dispatch({
                type: GET_LISTS_SUCCESS,
                payload: lists
            })
            dispatch(stopLoading())
        } catch(error) {
            bugsnagClient.notify(error);
            dispatch({type: GET_LISTS_FAILED})
            dispatch(stopLoading())
        }
    }
}

export const POST_LIST_STARTED  = 'POST_LIST_STARTED';
export const POST_LIST_FAILED  = 'POST_LIST_FAILED';
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS';

interface PostListStarted {
    type: typeof POST_LIST_STARTED
}
interface PostListFailed {
    type: typeof POST_LIST_FAILED
    payload: object;
}
export interface PostListSuccess {
    type: typeof POST_LIST_SUCCESS
    payload: ItemList
}

export type PostTypes = PostListStarted | PostListFailed | PostListSuccess

export const postList = (list: ItemListSettings) => {
    const database = mongoClient.db('thenaMeet').collection('lists');

    return async (dispatch: Dispatch<PostTypes>) => {
        try {
            dispatch({type: POST_LIST_STARTED})
            const {insertedId} = await database.insertOne(list);
            const listWithId: ItemList = {
                ...list,
                _id: insertedId
            }
            dispatch({
                type: POST_LIST_SUCCESS,
                payload: listWithId
            });
            return listWithId;
        } catch(error) {
            bugsnagClient.notify(error);
            dispatch({
                type: POST_LIST_FAILED,
                payload: error
            })
        }
    }
}

export const MOVE_SAME_START = 'MOVE_SAME_START';
export const MOVE_SAME_FAILED = 'MOVE_SAME_FAILED';
export const MOVE_SAME_SUCCESS = 'MOVE_SAME_SUCCESS';
export const MOVE_BETWEEN_START = 'MOVE_BETWEEN_START';
export const MOVE_BETWEEN_FAILED = 'MOVE_BETWEEN_FAILED';
export const MOVE_BETWEEN_SUCCESS = 'MOVE_BETWEEN_SUCCESS';
export const ADD_ITEM_START = 'ADD_ITEM_START';
export const ADD_ITEM_FAILED = 'ADD_ITEM_FAILED';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const UPDATE_LIST = 'UPDATE_LIST';

interface MoveSameStarted {
    type: typeof MOVE_SAME_START
}
interface MoveSameFailed {
    type: typeof MOVE_SAME_FAILED
    payload: object;
}
interface MoveSameSuccess {
    type: typeof MOVE_SAME_SUCCESS
}
interface MoveBetweenStart {
    type: typeof MOVE_BETWEEN_START
}
interface MoveBetweenFailed {
    type: typeof MOVE_BETWEEN_FAILED;
    payload: object;
}
interface MoveBetweenSuccess {
    type: typeof MOVE_BETWEEN_SUCCESS
}
interface AddItemStarted {
    type: typeof ADD_ITEM_START
}
interface AddItemFailed {
    type: typeof ADD_ITEM_FAILED
    payload: object
}
interface AddItemSuccess {
    type: typeof ADD_ITEM_SUCCESS
}
interface MoveSameFailed {
    type: typeof MOVE_SAME_FAILED
    payload: object;
}
interface MoveSameSuccess {
    type: typeof MOVE_SAME_SUCCESS
}
export interface UpdateList {
    type: typeof UPDATE_LIST
    payload: ItemList
}

type MoveTypes = MoveSameStarted |
    MoveSameFailed |
    MoveSameSuccess |
    AddItemStarted | 
    AddItemFailed |
    AddItemSuccess |
    MoveBetweenStart |
    MoveBetweenFailed |
    MoveBetweenSuccess |
    UpdateList;

export const updateList = (list: ItemList) => {
    return async (dispatch: Dispatch<MoveTypes>) => {
        dispatch({
            type: UPDATE_LIST,
            payload: list
        });
    }
}



export const moveOnSameList = (list_id: string, destIndex: number, draggableId: string) => {
    return async (dispatch: Dispatch<MoveTypes>) => {
        try {
            dispatch({
                type: MOVE_SAME_START
            })
            const theList = getListByLocation(list_id) as ItemList;
            theList.items = theList.items.filter(item => item !== draggableId);
            theList.items.splice(destIndex,0,draggableId);
            dispatch({
                type: UPDATE_LIST,
                payload: theList
            })
            await updateBackendLists(theList, draggableId, destIndex);
            dispatch({
                type: MOVE_SAME_SUCCESS
            })

        } catch (error) {
            bugsnagClient.notify(error);
            dispatch({
                type: MOVE_SAME_FAILED,
                payload: error
            });
        }
    }
}

export const moveBetweenLists = (source_id: string, dest_id: string, destIndex: number, draggableId: string) => {
    return async (dispatch: Dispatch<MoveTypes>) => {
        try {
            dispatch({
                type: MOVE_BETWEEN_START
            });
            const sourceList = getListByLocation(source_id) as ItemList
            const destList = getListByLocation(dest_id)
            if (destList) {
                sourceList.items = sourceList.items.filter(item => item !== draggableId);
                destList.items.splice(destIndex,0,draggableId);
                dispatch({
                    type: UPDATE_LIST,
                    payload: sourceList
                })
                dispatch({
                    type: UPDATE_LIST,
                    payload: destList
                });
                await updateBackendLists(sourceList, draggableId, destIndex, destList);
                dispatch({
                    type: MOVE_BETWEEN_SUCCESS
                });
            } else {
                throw new Error('no destination list to move to');
            }
        } catch (error) {
            bugsnagClient.notify(error);
            dispatch({
                type: MOVE_BETWEEN_FAILED,
                payload: error
            });
        }
        
    }
}

export const addItemToList = (location_id: string, item_id: string, toStart?: boolean) => {
    const database = mongoClient.db('thenaMeet').collection('lists');
    return async (dispatch: Dispatch<MoveTypes>) => { 
        try {
            dispatch({
                type: ADD_ITEM_START
            });
            const list = getListByLocation(location_id) as ItemList
            toStart ? list.items.unshift(item_id) : list.items.push(item_id);
            dispatch({
                type: UPDATE_LIST,
                payload: list
            });
            toStart ? 
            await database.updateOne({_id: list._id}, {$push: {items: {$each: [item_id], $position: 0}}}) :
            await database.updateOne({_id: list._id}, {$push: {items: item_id}})
            dispatch({
                type: ADD_ITEM_SUCCESS
            });
        } catch(error) {
            dispatch({
                type: ADD_ITEM_FAILED,
                payload: error
            });
        }

    }
}

export const deleteItemFromList = (item_id: string) => {
    const database = mongoClient.db('thenaMeet').collection('lists');
    let Newlist = {} as ItemList;
    store.getState().lists.lists.forEach(list => {
        const itemExists = list.items.filter(item => item === item_id)
        if (itemExists.length > 0) {
            Newlist = list ;
        }
    });
    Newlist.items = Newlist.items.filter(item => item !== item_id);
    return async (dispatch: Dispatch<MoveTypes>) => { 
        try {
            dispatch({
                type: UPDATE_LIST, 
                payload: Newlist
            });
            await database.updateOne(
                {_id: Newlist._id}, 
                {$pull: {items: item_id}}
            );
        }
        catch(error) {

        }
    }

}

const updateBackendLists = async (sourceList: ItemList, draggableId: string, destIndex: number, destList?: ItemList) => {
    const database = mongoClient.db('thenaMeet').collection('lists');

    await database.updateOne(
        {_id: sourceList._id}, 
        {$pull: {items: draggableId}}
    );
    await database.updateOne(
        {_id: destList ? destList._id : sourceList._id}, 
        {$push: {items: {$each: [draggableId], $position: destIndex}}}
    ); 
}

export const setListName = async (name: string , location_id:string) => {
    const database = mongoClient.db('thenaMeet').collection('lists');
    let id = new BSON.ObjectId(location_id);
    try {
        await database.updateOne({_id: id}, {$set: {name: name}});
    } catch (error) {
        bugsnagClient.notify(error);
    }
}

const getListByLocation = (location_id: string): ItemList | undefined => {
    return {...store.getState().lists.lists
        .filter(list => list.location_id === location_id)[0]}
} 

export type ListActionTypes = GetTypes | PostTypes | MoveTypes;