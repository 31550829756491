import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { dataReducer, DataState } from './reducers/dataReducer';
import { appReducer, AppState } from './reducers/appReducer';
import { AppStateActionTypes } from './actionTypes';
import { listsReducer, ListState } from './lists/listsReducer';
import { AppMeetingState, meetingReducer } from './meeting/meetingReducer';

export interface SystemState {
    app: AppState;
    data: DataState;
    lists: ListState
    meeting: AppMeetingState
}


const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({app: appReducer, data: dataReducer, lists: listsReducer, meeting: meetingReducer})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<ReturnType<typeof rootReducer>, AppStateActionTypes>)))

export default store;