import { MeetingState } from "../../../declarations/declarations";
import * as actionNames from './meetingActionNames'
import * as actionTypes from './meetingActionTypes'

export const cleanUpMeeting = (): actionTypes.CleanUpMeetingState => {
    return { type: actionNames.CLEAN_UP_MEETING };
}

export const updateMeetingState = (payload: MeetingState): actionTypes.UpdateMeetingState => {
    return {
        type: actionNames.UPDATE_MEETING_STATE,
        payload
    };
}

export const updateFacilitator = (payload: boolean): actionTypes.UpdateIsFacilitator => {
    return {
        type: actionNames.SET_FACILITATOR,
        payload
    }
}