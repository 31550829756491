import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import { MeetingItem, MeetingItemTypes, DiscussionRoundTypes, ActionItem, MeetingItemSetting } from '../../declarations/declarations';
import ActionItemCard from '../MeetingItemCard/components/ActionItemCard';

interface FuncProps {
    item: MeetingItem;
    currentItem?: MeetingItem;
    isFacilitator?: boolean;
    setCurrentItem?: (item: MeetingItem) => void;
    round?: DiscussionRoundTypes;
    complete?: boolean;
    confirmCurrentItem?: () => void;
    editArtifact?: (item: MeetingItemSetting) => void;
}

const ItemCard: React.FC<FuncProps> = ({
    round,
    item, 
    currentItem, 
    isFacilitator,
    setCurrentItem,
    complete,
    confirmCurrentItem,
    editArtifact
}) => {

    const isCurrent: boolean = currentItem && item._id.toString() === (currentItem as MeetingItem)._id.toString() ? true : false;
    
    const itemActions = () => {
        if (isFacilitator && round === DiscussionRoundTypes.select) {
            return <IconButton
                        color="secondary"
                        onClick={() => setCurrentItem ? setCurrentItem(item) : null}
                    >
                        <Typography variant="subtitle2">
                            Select
                        </Typography>
                </IconButton>
        } else if (isFacilitator && isCurrent && round === DiscussionRoundTypes.discuss) {
            return  <IconButton
                        color="secondary"
                    >
                        <Typography variant="subtitle2">
                            Resolve
                        </Typography>
                </IconButton>
        } else if (isFacilitator && isCurrent && item.type === MeetingItemTypes.actionItem) {
            return  <IconButton
                        color="secondary"
                        onClick={confirmCurrentItem ? () => confirmCurrentItem() : () => {}}
                    >
                        <Typography variant="subtitle2">
                            Complete
                        </Typography>
                </IconButton>
        }
    }

    return <Grid>
        <ActionItemCard 
            item={item as ActionItem}
            isCurrent={isCurrent} 
            complete={complete} 
            itemActions={() => itemActions()} 
            editArtifact={editArtifact}
        />
    </Grid>
}

export default ItemCard;