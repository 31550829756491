import React from 'react';
import { SwipeableDrawer, List, ListItem, ListItemText, Typography, Grid} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DrawListSection from './components/DrawListSection';
import { options } from './components/options';
import { logout } from '../../../stitch/authentication';
import StreamDrawListSection from './components/StreamsDrawListSection';
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

interface FuncProps {
    state: boolean;
    toggleDrawer: () => void;
}

type Props = FuncProps & RouteComponentProps;

const MenuDrawer: React.FC<Props> = ({state, toggleDrawer, history}) => {

    return(

    <SwipeableDrawer
        open={state}
        disableEnforceFocus={true}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        id = "menu-drawer-thena"
    >
        <div style={{width: '250px', marginLeft: '10px', backgroundColor: 'white', paddingBottom:"100px"}}>
            <StreamDrawListSection state={state} toggleDrawer={toggleDrawer}/>
            <List component="nav">
                {options.map(section => <DrawListSection 
                    key={section.title} 
                    title={section.title} 
                    options={section.options}
                    toggleDrawer={toggleDrawer}
                />)}
                <a id="my_custom_link" style={{textDecoration: 'none'}}>
                    <ListItem button onClick={toggleDrawer}>
                        <i className="fal fa-question-circle gray3" style={{marginRight:"10px"}}></i>
                        <ListItemText><Typography className="body-text gray3">Help</Typography></ListItemText>
                    </ListItem>
                </a>
                <ListItem button 
                    onClick={() => {
                        history.push('/login')
                        logout();
                        toggleDrawer();}
                    }>
                    <ListItemText className="logout_button">
                        <Grid container>
                            <ExitToAppOutlinedIcon className="primary-blue" style={{width: '14px', marginRight: '10px'}}/>
                            <Typography className="primary-blue bold-text" 
                                style={{display: 'flex',
                                    alignItems: 'center'}}>
                                Logout
                            </Typography>
                        </Grid>
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    </SwipeableDrawer>
    )
}

export default withRouter(MenuDrawer);