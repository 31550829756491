import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  MenuItem,
  Grid,
} from "@material-ui/core";
import {
  MeetingStateSettings,
  ItemListSettings,
  AccountData,
  Stream,
  AgendaItemTypes,
} from "../../../../declarations/declarations";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

import { isEmpty } from "lodash";
import { getStreamsForSelection } from "../../../../helpers/formHelpers";
import { createMeetingStateSimple,getAccountId } from "../../../../helpers/helpers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { bindActionCreators } from "redux";
import { MeetingsReduxer, ListsReduxer } from "../../../../store/reduxer";
import { updateStartMeetingState } from "../../../../store/actionCreators";
import { SystemState } from "../../../../store/store";
import { RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import * as Yup from "yup";
import "./index.scss";
import CallIcon from "@material-ui/icons/Call";

interface StateProps {
  state: {
    state: boolean, 
    stream_id?: string
  };
  accounts: AccountData[];
  streams: Stream[];
}
interface DispatchProps {
  createMeeting: (meeting: MeetingStateSettings) => RemoteInsertOneResult;
  updateState: (payload: {
    state: boolean, 
    stream_id?: string
  }) => void;
  createList: (list: ItemListSettings) => void;
}

type Props = RouteComponentProps & DispatchProps & StateProps;

const StartMeetingDialog: React.FC<Props> = ({
  history,
  createMeeting,
  updateState,
  state,
  accounts,
  streams,
}) => {
  const getAccountName = (stream_id: string) => {
    let account_id = streams.filter(
      (stream) => stream._id.toHexString() === stream_id
    )[0].account_id;
    let accountName = accounts.filter(
      (account) => account._id.toHexString() === account_id
    )[0].accountName;
    return accountName;
  };

  return (
    <Dialog
      disableEnforceFocus={true}
      open={state.state}
      className="start-meeting-dialogue-container"
    >
      <DialogTitle className="dialog-title">
        <Typography className="subtitle gray2">Start a Meeting</Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          stream: state.stream_id ? state.stream_id : '',
          meetingType: AgendaItemTypes.meetingItems
        }}
        onSubmit={async (values, actions) => {
          const meetingState = createMeetingStateSimple(values.stream, values.meetingType, state.stream_id ? true : false);
          const result = await createMeeting(meetingState);
          history.push(
            `/meetings/meet/${getAccountId()}/${result.insertedId.toHexString()}`
          );
          updateState({state: false});
          actions.resetForm();
          actions.setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          stream: Yup.string().required("Please select a stream"),
          meetingType: Yup.string().required("Please select the type of meeting"),
        })}
      >
        {({ isSubmitting, errors, dirty, values }) => (
          <Form>
            <DialogContent className="dialog-content">
              {!state.stream_id ? <React.Fragment><Grid className="field-header-container">
                <Typography className="pre-title gray4">STREAMS</Typography>
              </Grid>
              <Field
                name="stream"
                select
                required={true}
                type="text"
                variant="outlined"
                fullWidth
                margin="none"
                size="small"
                component={TextField}
                className="dialog-select-field height-increase"
              >
                {getStreamsForSelection().map((option) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    <Typography className="primary-blue bold-text">
                      {option[1]}&nbsp;&nbsp;
                    </Typography>
                    <Typography className="gray4 small-text">
                      {getAccountName(option[0])}
                    </Typography>
                  </MenuItem>
                ))}
              </Field></React.Fragment> : null}
              <Grid className="field-header-container">
                <Typography className="pre-title gray4">REVIEW ACTION ITEMS</Typography>
              </Grid>
              <Field
                name="meetingType"
                select
                required={true}
                type="text"
                variant="outlined"
                fullWidth
                margin="none"
                size="small"
                component={TextField}
                className="dialog-select-field"
              >
                <MenuItem value={AgendaItemTypes.meetingItems}>
                  <Typography className="gray3 body-text">
                    Discussion
                  </Typography>
                </MenuItem>
                <MenuItem value={AgendaItemTypes.actionItemReview}>
                  <Typography className="gray3 body-text">
                    Review Action Items
                  </Typography>
                </MenuItem>
                <MenuItem value={AgendaItemTypes.ideas_and_organize}>
                  <Typography className="gray3 body-text">
                    {`Discussion & Ideas`} 
                  </Typography>
                </MenuItem>
              </Field>
              {/* <Grid className="field-header-container">
                <Typography className="pre-title gray4">AGENDA</Typography>
              </Grid>
              <Field
                name="agenda"
                select
                required={true}
                type="text"
                variant="outlined"
                fullWidth
                margin="none"
                size="small"
                component={TextField}
                className="dialog-select-field"
              >
                {startMeetingAgendaSelection().map((option) => (
                  <MenuItem key={option[0]} value={option[0]}>
                    <Typography className="gray3 body-text">
                      {option[1]}
                    </Typography>
                  </MenuItem>
                ))}
              </Field> */}
            </DialogContent>
            <DialogActions className="dialog-actions">
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    className="cancel-button"
                    onClick={() => {
                      updateState({state: false});
                    }}
                  >
                    <Typography className="button-text primary-blue">
                      Cancel
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={8} className="text-align-right">
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    className="start-button "
                  >
                    <CallIcon className="white start-icon" fontSize="inherit" />
                    <Typography className="button-text white">
                      Start meeting
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    state: state.app.startMeetingState,
    accounts: state.data.accountData,
    streams: state.data.streams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    //@ts-ignore
    createMeeting: bindActionCreators(MeetingsReduxer.post, dispatch),
    updateState: bindActionCreators(updateStartMeetingState, dispatch),
    createList: bindActionCreators(ListsReduxer.post, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StartMeetingDialog)
);
