import React, { Suspense } from "react";
import { Button, CircularProgress, Grid, GridList, GridListTile, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { addEditItemState } from "../../../store/actionCreators";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../store/actionTypes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Logo from "../../../assets/images/thena_logo.svg";
import './styles/index.scss';
import { Add } from "@material-ui/icons";

interface AppBarProps {
  myItemsOpen: boolean;
  handleMenuDrawer: () => void;
  handleMyItemDrawer: () => void;
}

interface FuncProps
  extends RouteComponentProps<{
    action: string;
    id: string;
  }> {}

interface DispatchProps {
  setAddEditState: (payload: {state: boolean, itemId?: string}) => void;
}

type Props = AppBarProps & DispatchProps & FuncProps;

const ThenaAppBar: React.FC<Props> = ({
  history,
  myItemsOpen,
  handleMenuDrawer,
  handleMyItemDrawer,
  setAddEditState
}) => {

  return (
    <Suspense fallback={<CircularProgress />}>
      <Grid container className="nav_bar">
         <Grid item xs={6} md={6} >
            <GridList className="IconBrand">
               <GridListTile>
                <Grid>
                  <img className="thena_logo" alt="thena-logo" src={Logo} />
                </Grid>
                  {/* <TopNavLogo /> */}
               </GridListTile>
               <GridListTile>
                  <Button className="thenaTitle" onClick={() => {
                    history.push('/dashboard')
                  }}>
                     Thena
                  </Button>
               </GridListTile>
               <GridListTile>
                <Grid>
                  <IconButton
                      onClick={handleMenuDrawer}
                      className="MenuButton"
                      id="menu-button"
                    >
                      <MenuIcon className="white"/>
                  </IconButton>
                </Grid>
                  {/* <TopNavBarMenuButton toggleDrawer={handleMenuDrawer} /> */}
               </GridListTile>
            </GridList>
         </Grid>
         <Grid item xs={6} md={6}>
            <GridList className="RightProfile">
               <GridListTile>
                <Grid>
                  <Button className="AddItem" variant="contained" color="primary"
                  onClick={() => {
                      setAddEditState({state: true});
                    }}>
                    <Add />
                    <Typography className="AddItemText">
                      Add Item 
                    </Typography>
                  </Button>
                </Grid>
                  {/* <AddCardButton /> */}
               </GridListTile>
               <GridListTile>
                  <Grid>
                      <Button 
                      onClick={handleMyItemDrawer}
                      id="button_myList"
                      className="ShowItem"
                      variant="contained">
                        <Typography className="ShowItemTex">
                          {`${myItemsOpen ? 'Hide' : 'Show'} Items`}
                        </Typography>
                      </Button>
                  </Grid>
                  {/* <SeeCardMenuButton sideBarState={sideBarState} setSideBarState={setSideBarState} /> */}
               </GridListTile>
               <GridListTile>
                  {/* <TopNavProfileButton /> */}
               </GridListTile>
            </GridList>
         </Grid>
      </Grid>
      </Suspense>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    setAddEditState: bindActionCreators(addEditItemState, dispatch)
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(ThenaAppBar)
);