import React from 'react' 
import { Grid } from '@material-ui/core'
import { MeetingItem, ItemList, MeetingItemCardOptions, MeetingAgendaItem, MeetingStreamEventTypes } from '../../../../../../declarations/declarations'
import { MeetingsReduxer } from '../../../../../../store/reduxer'
import { BSON } from 'mongodb-stitch-browser-sdk'
import { createMeetingStreamEvent } from '../../helpers/streamHelpers'
import { getUserId } from '../../../../../../helpers/helpers'
import MeetingItemHorizonalCollection from '../../../../../../components/MeetingItemHorizonalCollection/MeetingItemHorizonalCollection'
import store from '../../../../../../store/store';
import { useSnackbar } from "notistack";
import {getText} from '../../helpers/streamHelpers';

interface FuncProps {
  isFacilitator: boolean;
  meeting_id: string;
  stream_id: string;
  voting: boolean;
  currentAgendaItem: MeetingAgendaItem;
  theList: ItemList | undefined;
  meetingItems: MeetingItem[];
  hideStream?: boolean;
}

const MeetingItemListInMeeting: React.FC<FuncProps> = ({
  currentAgendaItem, 
  isFacilitator, 
  stream_id, 
  meeting_id, 
  voting, 
  meetingItems, 
  theList,
  hideStream
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const updateCurrentItem = async (item: MeetingItem) => {
    await MeetingsReduxer.database.updateOne({_id: new BSON.ObjectId(meeting_id)}, {$set: {
      currentAgendaItem: {
        ...currentAgendaItem,
        currentItem: item
      }
    }})
    createMeetingStreamEvent(
      MeetingStreamEventTypes.item_selected,
      stream_id,
      meeting_id,
      getUserId() as string,
      item._id.toHexString(),
      item.what,
    )
    if(store.getState().app.hideStream){
      enqueueSnackbar(`${getText(MeetingStreamEventTypes.item_selected)} `, { variant: "info" });
    }
  }
    
  return <Grid item md={currentAgendaItem.voting && !currentAgendaItem.currentItem ? (hideStream ? 10 : 8) : 4} sm={12} style={{height: '100%', minHeight: '200px'}}>
      <Grid container>
      {theList?._id && <MeetingItemHorizonalCollection 
        idPrefix="meeting-meeting-items" 
        lists={[theList?._id.toHexString()]} 
        name={'Meeting Items'} 
        buttonFunction={updateCurrentItem} 
        options={currentAgendaItem.voting ? MeetingItemCardOptions.select : MeetingItemCardOptions.personal}
      />}
      </Grid>
  </Grid>
}

export default MeetingItemListInMeeting;