import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import { NoteItem } from '../../../../declarations/declarations';
import NotesIcon from '@material-ui/icons/Notes';
import RemoveIcon from '@material-ui/icons/Remove';
import { debounce } from 'lodash';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { MeetingNotesReduxer } from '../../../../store/reduxer';

interface FuncProps {
    publicState: NoteItem;
    setNoteState: () => void;
}

const NotePanel: React.FC<FuncProps> = ({
    publicState,
    setNoteState
}) => {
    
    const [progress, setProgress] = React.useState(false);
    const [state, changeState] = React.useState<EditorState>(EditorState.createWithContent(convertFromRaw(publicState.raw)));

    const saveContent = React.useCallback(debounce((state: EditorState) => {
        setProgress(true);
        const rawContent = convertToRaw(state.getCurrentContent());
        publicState.raw = rawContent
        MeetingNotesReduxer.database.updateOne({_id: publicState._id}, publicState);
        setTimeout(() => setProgress(false), 1000);
    }, 2000),[]);

    const saveContentOnclose = (state: EditorState) => {
        setProgress(true);
        const rawContent = convertToRaw(state.getCurrentContent());
        publicState.raw = rawContent
        MeetingNotesReduxer.database.updateOne({_id: publicState._id}, publicState);
        setTimeout(() => setProgress(false), 1000);
    };

    return <Grid container className="note-section white-background">
        <Grid item xs={12} container style={{ paddingBottom: '10px'}}>
            <NotesIcon className="gray2 note-icon" /> 
            <Typography className="gray2">
                Private notes
            </Typography>
            <RemoveIcon className="gray2 remove-icon cursor-pointer" onClick={() => {
                saveContentOnclose(state);
                setNoteState();
                }} />
        </Grid>
        <RichTextEditor 
            noteState={publicState}
            state={state}
            changeState={changeState}
            progress={progress}
            setProgress={setProgress}
            saveContent={saveContent}
        />
    </Grid>
}

export default NotePanel;