import React from "react";
import { Grid } from "@material-ui/core";
import AddFabButton from "../../components/AddFabButton/AddFabButton";
import { connect } from "react-redux";
import { SystemState } from "../../store/store";
import { MeetingTemplate } from "../../declarations/declarations";
import TemplateCard from "../../components/TemplateCard/TemplateCard";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import BlankState from "./components/BlankState/BlankState";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { MeetingTemplatesReduxer } from "../../store/reduxer";
import { bindActionCreators } from "redux";
import { orgArrayAlphabetically } from "../../helpers/helpers";

interface StateProps {
  meetingTemplates: Array<MeetingTemplate>;
}

interface DispatchProps {
  deleteTemplate: (item: any) => void;
}

type Props = StateProps & DispatchProps;

const AgendaTemplatesPage: React.FC<Props> = ({
  meetingTemplates,
  deleteTemplate,
}) => {
  const [toBeDeleted, setDeleteItem] = React.useState();
  const [deleteDialogState, setDeleteState] = React.useState(false);

  const templateCardDelete = (values: any) => {
    setDeleteItem(values);
    setDeleteState(true);
  };

  const deleteFunc = async () => {
    await deleteTemplate(toBeDeleted);
  };

  return (
    <Grid container>
      {meetingTemplates.length > 0 ? (
        orgArrayAlphabetically(meetingTemplates, 'name').map((template) => (
          <TemplateCard
            key={template._id.toString()}
            template={template}
            deleteTemplate={templateCardDelete}
          />
        ))
      ) : (
        <BlankState />
      )}
      <ConfirmDialog
        text="Are you sure?"
        state={deleteDialogState}
        close={() => setDeleteState(false)}
        confirmedFunc={deleteFunc}
      />
      <AddFabButton addRoute="/meetings/agenda-templates/add"/>
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    meetingTemplates: state.data.meetingTemplates,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    deleteTemplate: bindActionCreators(MeetingTemplatesReduxer.delete, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(AgendaTemplatesPage);
