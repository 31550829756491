import React from 'react';
import { Dialog } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { redirectIfCriteriaMet } from '../../../helpers/helpers';
import ThenaLogo from '../../../components/ThenaLogo/ThenaLogo';
import store from '../../../store/store';
import UserSetupForm from '../Forms/UserSetupForm';

interface DispatchProps {
    getUserData: () => void;
    getAccountData: (query: any, filter: any) => void;
}

type Props = DispatchProps & RouteComponentProps;

const UserSetup: React.FC<Props> = ({history, getUserData, getAccountData}) => {


    React.useEffect(() => {
        redirectIfCriteriaMet(store.getState().data.userData[0].firstName, history, '/dashboard');
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

        return <Dialog disableEnforceFocus={true} open={true} fullWidth>
            <span style={{textAlign: 'center', marginTop: '15px'}}><ThenaLogo width='50%' height='4%'/></span>
            <UserSetupForm />
        </Dialog>
}

export default withRouter(UserSetup);