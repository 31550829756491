import React from 'react';
import AddEditItemDialog from './AddEditItemDialog/AddEditItemDialog';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import LoadingDialog from './LoadingDialog/LoadingDialog';
import StartMeetingDialog from './StartMeetingDialog/StartMeetingDialog';

const AppDialogs: React.FC = () => <React.Fragment>
    <AddEditItemDialog />
    <DeleteDialog />
    <LoadingDialog/>
    <StartMeetingDialog/>
</React.Fragment>

export default AppDialogs;