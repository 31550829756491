import React from 'react';
import { Dialog, Button, DialogTitle, DialogContent, Grid, Typography } from '@material-ui/core';

interface FuncProps {
    text: string;
    state: boolean;
    rateFunc: (num: number) => void;
}

const rateButtonStyle = {
    minWidth: '30px',
    color: '#0e4b89',
    fontSize: 'large',
    fontWeight: 600,
    border: '1px solid #80b3c3',
    borderRadius: '7px'
} as React.CSSProperties;

const RatingDialog: React.FC<FuncProps> = ({text, state, rateFunc}) => <Dialog id="RatingDialog" disableEnforceFocus={true} open={state}>
    <DialogTitle style={{textAlign: 'center', color: '#0e4b89', fontWeight: 600}}>{text}</DialogTitle>
    <DialogContent>
        <Grid container style={{textAlign: 'center'}}>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(1)}
                    variant="outlined"
                >
                    1
                </Button>
                <Grid item xs={12} style={{ color: '#80b3c3', }}>
                    <Typography>Poor</Typography>
                </Grid>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(2)}
                    variant="outlined"
                >
                    2
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(3)}
                    variant="outlined"
                >
                    3
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(4)}
                    variant="outlined"
                >
                    4
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(5)}
                    variant="outlined"
                >
                    5
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(6)}
                    variant="outlined"
                >
                    6
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(7)}
                    variant="outlined"
                >
                    7
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(8)}
                    variant="outlined"
                >
                    8
                </Button>
            </Grid>
            <Grid item xs={1} style={{ marginRight: '8px' }}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(9)}
                    variant="outlined"
                >
                    9
                </Button>
            </Grid>
            <Grid item xs={1}>
                <Button
                    style={rateButtonStyle}
                    onClick={() => rateFunc(10)}
                    variant="outlined"
                >
                    10
                </Button>
                <Grid item xs={12} style={{ color: '#80b3c3', }}>
                    <Typography>Great</Typography>
                </Grid>
            </Grid>
        </Grid>
    </DialogContent>
</Dialog>

export default RatingDialog