import React from 'react';
import { Card, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Grid, makeStyles} from '@material-ui/core';
import { startCase } from 'lodash';
import { AgendaItem } from '../../../../declarations/declarations';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface FuncProps {
    agendaItem: AgendaItem
    currentAgendaItem: AgendaItem;
    userSelectedItem: number;
    index: number;
    change: (index: number | undefined) => void;
}

const AgendaItemMeetingCard: React.FC<FuncProps> = ({agendaItem, currentAgendaItem, userSelectedItem, index, change}) => {

    const useStyles = makeStyles({
        root: {
          background: '#68BCD5',
        },
      });


    const isCurrent: boolean = agendaItem.name === currentAgendaItem.name ? true : false;
    const classes = useStyles();

    return <Card style={{marginTop: '7px', width: '400px'}}>
        <ExpansionPanel 
            expanded={isCurrent || index === userSelectedItem} 
            className={isCurrent ? classes.root : undefined}
            onChange={isCurrent ? undefined : () => userSelectedItem !== index ? change(index): change(undefined)}
        >
            <ExpansionPanelSummary
                expandIcon={isCurrent ? undefined : <ExpandMoreIcon />}
            >
                <Grid container>
                    <Grid item xs={12}><Typography 
                        style={isCurrent ? {color: 'white'} : undefined} 
                        color={isCurrent ? undefined : "primary"} variant="subtitle1"
                    >
                        {isCurrent ? <b>{startCase(agendaItem.name)}</b>: startCase(agendaItem.name)}
                    </Typography></Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    <Grid item xs={12}><Typography variant="body2" style={isCurrent ? {color: 'white'} : undefined}>{isCurrent ? <b>{agendaItem.description}</b> : agendaItem.description}</Typography></Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </Card>
}

export default AgendaItemMeetingCard;