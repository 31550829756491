import React from "react";
import { Typography, Grid, Dialog } from "@material-ui/core";
import AddEditForm from "./AddEditMetricDialog";

const whiteText = { color: "#0066ff" };

interface FuncProps {
  DialogState: boolean;
  setDialogState: (value: boolean) => void;
  action: "add" | "edit";
  id?: string;
}
const AddEditDialog: React.FC<FuncProps> = ({
  DialogState,
  setDialogState,
  action,
  id
}) => {

    const [tab, selectTab] = React.useState<"form"|"data">("form");
  return (
    <Dialog open={DialogState} fullWidth>
      <Grid className="add-metric-dialog-container">
        <Grid className="add-metric-dialog-header">
          <Typography className="gray2 user-title">{`${
            action === "add" ? "New" : "Edit"
          } Metric`}</Typography>
        </Grid>
        <Grid className="metric-dialog-tab-container">
            <Grid onClick={() => selectTab("form")} className={(tab === "form" ? "active primary-blue" : "gray4") + " inline metric-dialog-tab body-text"} >
                <Typography>Metric Information</Typography>
            </Grid>
            <Grid onClick={() => selectTab("data")} className={(tab === "data" ? "active primary-blue" : "gray4") + " inline metric-dialog-tab body-text"} >
                <Typography>Data</Typography>
            </Grid>
        </Grid>
        <AddEditForm setDialogState={setDialogState} action={action} id={id} tab={tab}/>
      </Grid>
    </Dialog>
  );
};
export default AddEditDialog;
