import React from 'react';
import { 
    Grid, 
    Typography, 
    List, 
    Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { SystemState } from '../../store/store';
import { MeetingSummary } from '../../declarations/declarations';
import MeetingListItem from './components/MeetingListItem';

interface StateProps {
    summaries: Array<MeetingSummary>;
}


const MeetingSummariesPage: React.FC<StateProps> = ({summaries}) => summaries.length > 0 ? <Grid style={{ width: '80%', padding: '5%'}} container>
    <Grid item xs={12}> <Typography variant="h5" color="primary" style={{ color: 'white', marginBottom: '15px'}}>Meeting Summaries</Typography></Grid>
    <Grid item xs={12}>
        <Paper>
            <List>
                {summaries.map((item, index) => <MeetingListItem 
                    key={item._id.toString()} 
                    item={item}
                    index={index}
                    length={summaries.length}
                />)}
            </List>
        </Paper>
    </Grid>
</Grid> : <Grid container>
    <Grid item xs={12}><Typography variant="h6" color="primary" style={{ color: 'white', textAlign: 'center'}}>You have no meeting summaries yet.</Typography></Grid>
    <Grid item xs={12}><Typography variant="subtitle2" color="primary" style={{ color: 'white', textAlign: 'center'}}>After you conclude a meeting their summary will appear here.</Typography></Grid>
</Grid> 

const mapStateToProps = (state: SystemState): StateProps => {
    return {
        summaries: state.data.summaries
    }
}

export default connect(mapStateToProps)(MeetingSummariesPage);