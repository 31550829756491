import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, Button, Typography } from '@material-ui/core';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import FormInputField from '../../../../../components/FormInputField/FormInputField';
import { StripeCardElement } from '@stripe/stripe-js';
import * as Yup from 'yup';

interface FuncProps {
    clientSecret: string;
    closeDialog: () => void;
}
const CardCollectionForm: React.FC<FuncProps> = ({clientSecret, closeDialog}) => {
    const stripe = useStripe();
    const elements = useElements();
    const validation = Yup.object().shape({
        name: Yup
            .string()
            .required('Please enter the name displayed on your card')
    });

    const onFormSubmit = async (values: any) => {
        if (!stripe || !elements) {
            return
        }
        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: (elements.getElement(CardElement) as StripeCardElement),
              billing_details: {
                name: values.name,
              },
            }
          }); 
        elements.getElement('card')?.clear()
        if (result.error) {
        } else {
            closeDialog()
        }
       
    }
    return <Formik 
        initialValues={{name: ''}}
        validationSchema={validation}
        onSubmit={(values, actions) => {
            onFormSubmit(values);
            actions.resetForm();
            actions.setSubmitting(false);
        }}
    >
        {() => <Form style={{marginRight: '10%', marginLeft: '10%'}}>
            <Grid container>
                <Grid item xs={12} style={{border: '1px solid #C4C4C4', paddingLeft: '8px', paddingRight: '18.5px', paddingTop: '18.5px', paddingBottom: '14px'}}>
                    <CardElement 
                        options={{
                            style: {  
                                base: {
                                    iconColor: '#68BCD5',
                                    fontFamily: 'Inter',
                                    fontSmoothing: 'antialiased',
                                    ':-webkit-autofill': {
                                        color: '#fce883',
                                    },
                                    fontSize: '16px',
                                    color: '#424770'
                                },
                                invalid: {
                                    iconColor: 'red',
                                    color: 'red',
                                },
                            }
                        }}
                    />           
                </Grid>
                <FormInputField name="name" label="Name on Card" type="text" required />
                <Grid item xs={12} style={{padding: '15px'}}>
                    <Button type="submit" color="primary" variant="contained">Submit</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="primary" variant="body2">By inputting this card information you agree to be invoiced each month for your subscription in line with our Terms of Service.</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: '25px'}}>
                    <Typography color="primary" variant="body2">You may cancel your subscription at <b>any time.</b> For more information about our billing <u>click here</u>.</Typography>
                </Grid>
            </Grid>
        </Form>}
    </Formik>
}

export default CardCollectionForm;