import React from 'react';
import { Typography, Grid } from '@material-ui/core';

const whiteText = {color: '#0066ff'};

const BlankState = () => <Grid container style={{textAlign: 'center', paddingTop: '10%'}}>
    <Grid style={whiteText} item xs={12}><Typography variant="h4">Click the</Typography></Grid>
    <Grid style={whiteText} item xs={12}><Typography variant="h2" color="secondary">+</Typography></Grid>
    <Grid style={whiteText} item xs={12}><Typography variant="h4">below to add</Typography></Grid>
    <Grid style={whiteText} item xs={12}><Typography variant="h4">a new</Typography></Grid>
    <Grid style={whiteText} item xs={12}><Typography variant="h4"><b>Metric</b></Typography></Grid>
</Grid>

export default BlankState;