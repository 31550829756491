import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MeetingItem, MeetingItemTypes } from "../../../../../../declarations/declarations";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ItemCard from "../../../../../../components/ItemCard/ItemCard";
import DecisionItemCard from "../../../../../MeetingItemCard/components/DecisionItemCard";
interface FuncProps {
  meetingItem: MeetingItem;
  artifactItems: MeetingItem[];
}

const MeetingItemDisplayCard: React.FC<FuncProps> = ({
  meetingItem,
  artifactItems
}) => {
  const [artifactTab, selectArtifactTab] = React.useState<"All" | "Action" | "Decision">("All");

  let ActionItems = artifactItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem
  );
  let DecisionItems = artifactItems.filter(
    (item) => item.type === MeetingItemTypes.decision
  );

  return (
    <Grid className="MeetingItemDisplayCard-container inline">
      <Grid className="MeetingItemDisplayCard-header">
        <DescriptionOutlinedIcon
          className="MeetingItemDisplayCard-icon inline"
          fontSize="small"
        />
        <Grid className="gray2 bold-text inline vertical-align-sub">
          <Typography className="MeetingItemDisplayCard-what">
            {meetingItem.what}
          </Typography>
        </Grid>
        <Grid className="gray3  MeetingItemDisplayCard-why-container">
          <Typography className="MeetingItemDisplayCard-why small-text">
            {meetingItem.what}
          </Typography>
        </Grid>
      </Grid>
      <Grid className="tabs">
        <Grid
          className={
            (artifactTab === "All"
              ? "tab-selected primary-blue"
              : "tab-idle gray5") + " pre-title inline item-tab"
          }
          onClick={() => selectArtifactTab("All")}
        >
          All ({artifactItems.length})
        </Grid>
        <Grid
          className={
            (artifactTab === "Action"
              ? "tab-selected primary-blue"
              : "tab-idle gray5") + " pre-title inline item-tab"
          }
          onClick={() => selectArtifactTab("Action")}
        >
          Action Items ({ActionItems.length})
        </Grid>
        <Grid
          className={
            (artifactTab === "Decision"
              ? "tab-selected primary-blue"
              : "tab-idle gray5") + " pre-title inline item-tab"
          }
          onClick={() => selectArtifactTab("Decision")}
        >
          Decisions ({DecisionItems.length})
        </Grid>
      </Grid>
      <Grid className="artifact-container">
        {(artifactTab === "All" || artifactTab === "Action") &&
          ActionItems.map((item, index) => {
            return (
              <Grid key={item._id.toString()} item xs={12}>
                <ItemCard
                  item={item}
                />
              </Grid>
            );
          })}
          {(artifactTab === "All" || artifactTab === "Decision") &&
            DecisionItems.map((item,index) => {
              return(
                <Grid key={item._id.toString()} item xs={12}>
                  <DecisionItemCard item={item} parentItem={meetingItem}/>
                </Grid>
              )
            })
          }
          {artifactTab === "All" && DecisionItems.length === 0 && ActionItems.length === 0 && <Typography className="gray3 body-text">
            {`No items were created when this was discussed`}
        </Typography>}
        {artifactTab === "Decision" && DecisionItems.length === 0 && <Typography className="gray3 body-text">
            {`No decisions were created when this was discussed`}
        </Typography>}
        {artifactTab === "Action" &&  ActionItems.length === 0 && <Typography className="gray3 body-text">
            {`No Action items were created when this was discussed`}
        </Typography>}
      </Grid>
    </Grid>
  );
};

export default MeetingItemDisplayCard;
