import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux';
import store from './store/store';
import theme from './theme/theme';
import { ErrorBoundary } from './bugsnag/bugsnag';
import AuthChecker from './hoc/AuthChecker';
import AppRoutes from './routes/AppRoutes/AppRoutes';
import Loader from './hoc/Loader';
import { SnackbarProvider } from 'notistack';
import CloseSnackBarButton from './layout/components/CloseSnackBarButton/CloseSnackBarButton';
import { BrowserRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './App.scss'

const styles = makeStyles({
    success: {backgroundColor: '#00E291', color: '#21253F'},
    error: {backgroundColor: '#FF451C'},
    warning: {backgroundColor: '#FFC700', color: '#21253F'},
    info: {backgroundColor: '#5D24FF'},
})

const App: React.FC = () => {
    const classes = styles();

    return <MuiThemeProvider theme={theme}>
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <SnackbarProvider 
                        maxSnack={3}
                        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                        action={(key) => <CloseSnackBarButton key={key}/>}
                        classes={{
                            variantSuccess: classes.success,
                            variantError: classes.error,
                            variantWarning: classes.warning,
                            variantInfo: classes.info
                        }}
                    >
                        <header className="App-header">
                            <AuthChecker>
                                <Loader>
                                    <AppRoutes />
                                </Loader>
                            </AuthChecker>
                        </header>      
                    </SnackbarProvider>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>
    </MuiThemeProvider>
}

export default App;
