import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, CircularProgress, DialogActions } from '@material-ui/core';
import launchingMeeting from "../../../assets/images/launchingMeeting.png";

interface FuncProps {
    state: boolean;
}

const InitializeMeeting: React.FC<FuncProps> = ({ state }) => <Dialog 
    disableEnforceFocus={true} 
    open={state} 
    id="meeting-confirm-dialog"
    >
    <DialogTitle></DialogTitle>
    <DialogContent>
        <Grid container style={{textAlign: 'center'}}>
            <Grid item style={{ width: '200px', margin: '20px' }} xs={12}>
                <img style={{ width: '90%' }} alt="launch-meeting" src={launchingMeeting} />
            </Grid>
            <Grid item xs={12}>
                <Typography className="gray3 body-text">
                    Launching meeting...
                </Typography>
            </Grid>
        </Grid>
    </DialogContent>
    <DialogActions>
        <Grid container>
            <CircularProgress
                style={{ 
                    position: 'absolute',
                    right: '15px',
                    bottom: '15px' 
                }}
                />
        </Grid>
    </DialogActions>
</Dialog>

export default InitializeMeeting;