import { RemoteMongoCollection, StitchUser } from "mongodb-stitch-browser-sdk";
import { Dispatch } from "redux";
import { DataState } from "./reducers/dataReducer";
import {
  StartedType,
  FailedType,
  SuccessType,
  ActionTypes,
} from "./actionTypes";
import { getCurrentUser } from "../stitch/authentication";
import { mongoClient } from "../stitch/app";
import { bugsnagClient } from "../bugsnag/bugsnag";
import { isEqual, uniqWith } from "lodash";

class StoreHandler {
  private stateName: string;
  private postStartedType: string;
  private postFailedType: string;
  private postSuccessType: string;
  private getStartedType: string;
  private getFailedType: string;
  private getSuccessType: string;
  private getFilteredStartedType: string;
  private getFilteredFailedType: string;
  private getFilteredSuccessType: string;
  private updateStartedType: string;
  private updateFailedType: string;
  private updateSuccessType: string;
  private updateQueryStartedType: string;
  private updateQueryFailedType: string;
  private updateQuerySuccessType: string;
  private deleteStartedType: string;
  private deleteFailedType: string;
  private deleteSuccessType: string;
  database: RemoteMongoCollection<any>;

  constructor(dbName: string, collectionName: string, stateName?: string) {
    this.stateName = stateName ? stateName : collectionName;
    const capital = stateName
      ? stateName.toUpperCase()
      : collectionName.toUpperCase();
    this.postStartedType = `CREATE_${capital}_STARTED`;
    this.postFailedType = `CREATE_${capital}_FAILED`;
    this.postSuccessType = `CREATE_${capital}_SUCCESS`;
    this.getStartedType = `GET_${capital}_STARTED`;
    this.getFailedType = `GET_${capital}_FAILED`;
    this.getSuccessType = `GET_${capital}_SUCCESS`;
    this.getFilteredStartedType = `GET_FILTERED_${capital}_STARTED`;
    this.getFilteredFailedType = `GET_FILTERED_${capital}_FAILED`;
    this.getFilteredSuccessType = `GET_FILTERED_${capital}_SUCCESS`;
    this.updateStartedType = `UPDATE_${capital}_STARTED`;
    this.updateFailedType = `UPDATE_${capital}_FAILED`;
    this.updateSuccessType = `UPDATE_${capital}_SUCCESS`;
    this.updateQueryStartedType = `UPDATEQUERY_${capital}_STARTED`;
    this.updateQueryFailedType = `UPDATEQUERY_${capital}_FAILED`;
    this.updateQuerySuccessType = `UPDATEQUERY_${capital}_SUCCESS`;
    this.deleteStartedType = `DELETE_${capital}_STARTED`;
    this.deleteFailedType = `DELETE_${capital}_FAILED`;
    this.deleteSuccessType = `DELETE_${capital}_SUCCESS`;
    this.database = mongoClient.db(dbName).collection(collectionName);
  }

  // GET Action Creators

  private getStarted = (): StartedType => {
    return {
      type: this.getStartedType,
    };
  };

  private getFailed = (): FailedType => {
    return {
      type: this.getFailedType,
    };
  };

  private getSuccess = (payload: any): SuccessType => {
    return {
      type: this.getSuccessType,
      payload: payload,
    };
  };

  get = () => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        dispatch(this.getStarted());
        const result: Array<any> = await this.database.find().toArray();
        dispatch(this.getSuccess(result));
        return;
      } catch (error) {
        bugsnagClient.notify(error);
        dispatch(this.getFailed());
      }
    };
  };

  private getFilteredStarted = (): StartedType => {
    return {
      type: this.getFilteredStartedType,
    };
  };

  private getFilteredFailed = (): FailedType => {
    return {
      type: this.getFilteredFailedType,
    };
  };

  private getFilteredSuccess = (payload: any): SuccessType => {
    return {
      type: this.getFilteredSuccessType,
      payload: payload,
    };
  };

  getFiltered = (query: any, options?: any) => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        dispatch(this.getFilteredStarted());
        const result: Array<any> = await this.database
          .find(query, options)
          .toArray();
        dispatch(this.getFilteredSuccess(result));
        return;
      } catch (error) {
        bugsnagClient.notify(error);
        dispatch(this.getFilteredFailed());
      }
    };
  };

  // ADD Action Creators

  private postStarted = (): StartedType => {
    return {
      type: this.postStartedType,
    };
  };

  private postFailed = (): FailedType => {
    return {
      type: this.postFailedType,
    };
  };

  private postSuccess = (payload: any): SuccessType => {
    return {
      type: this.postSuccessType,
      payload: payload,
    };
  };

  post = (settings: any) => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        dispatch(this.postStarted());
        const user: StitchUser = getCurrentUser() as StitchUser;
        const result = await this.database.insertOne({
          ...settings,
          owner_id: user.id,
        });
        const newArray: Array<any> = await this.database
          .find({ _id: result.insertedId })
          .toArray();
        dispatch(this.postSuccess(newArray[0]));
        return result;
      } catch {
        dispatch(this.postFailed());
      }
    };
  };

  // UPDATE Action Creators
  private updateStarted = (): StartedType => {
    return {
      type: this.updateStartedType,
    };
  };
  private updateFailed = (): FailedType => {
    return {
      type: this.updateFailedType,
    };
  };
  private updateSuccess = (payload: any): SuccessType => {
    return {
      type: this.updateSuccessType,
      payload: payload,
    };
  };

  update = (item: any, query?: any) => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        // dispatch(this.updateStarted());
        if (query) {
          const result: any = await this.database.findOneAndUpdate(
            { _id: item._id },
            query
          );
          const updatedItem: any = await this.database
            .find({ _id: result._id })
            .toArray();
          dispatch(this.updateSuccess(updatedItem));

        } else {
          const result: any = await this.database.findOneAndUpdate(
            { _id: item._id },
            item
          );
          const updatedItem: any = await this.database
            .find({ _id: result._id })
            .toArray();
          dispatch(this.updateSuccess(updatedItem));
        }
        return;
      } catch (error) {
        dispatch(this.updateFailed());
        bugsnagClient.notify(error);
      }
    };
  };

  // UPDATE QUERY Action Creators
  private updateQueryStarted = (): StartedType => {
    return {
      type: this.updateQueryStartedType,
    };
  };
  private updateQueryFailed = (): FailedType => {
    return {
      type: this.updateQueryFailedType,
    };
  };
  private updateQuerySuccess = (payload: any): SuccessType => {
    return {
      type: this.updateQuerySuccessType,
      payload: payload,
    };
  };

  updateQuery = (query: any, update: any) => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        dispatch(this.updateQueryStarted());
        const result: any = await this.database.updateOne(query, update);
        const updatedItem: any = await this.database.findOne({
          _id: result._id,
        });
        dispatch(this.updateQuerySuccess(updatedItem));
        return;
      } catch (error) {
        dispatch(this.updateQueryFailed());
        bugsnagClient.notify(error);
      }
    };
  };

  // DELETE Action Creators

  private deleteStarted = (): StartedType => {
    return {
      type: this.deleteStartedType,
    };
  };

  private deleteFailed = (): FailedType => {
    return {
      type: this.deleteFailedType,
    };
  };

  private deleteSuccess = (id: string): SuccessType => {
    return {
      type: this.deleteSuccessType,
      payload: {
        _id: id,
      },
    };
  };

  delete = (item: any) => {
    return async (dispatch: Dispatch<ActionTypes>) => {
      try {
        dispatch(this.deleteStarted());
        const query = { _id: item._id };
        await this.database.deleteOne(query);
        dispatch(this.deleteSuccess(query._id.toString()));
        return;
      } catch (error) {
        dispatch(this.deleteFailed());
      }
    };
  };

  // General Reducers

  private started_reducer(state: DataState): DataState {
    return {
      ...state
    };
  }

  private failed_reducer(state: DataState): DataState {
    return {
      ...state
    };
  }

  // Success Reducers

  private postGet_success_reducer(state: DataState, action: SuccessType): DataState {
    if (state[this.stateName]) {
      return {
        ...state,
        [this.stateName]: uniqWith(state[this.stateName].concat(action.payload), isEqual),
      };
    } else {
      return {
        ...state
      };
    }
  }

  private update_success_reducer(state: DataState, action: SuccessType): DataState {
      return {
          ...state,
          [this.stateName]: state[this.stateName]
              .filter((item: any) => item._id.toString() !== action.payload[0]._id.toString())
              .concat(action.payload)
      }
  }


  private delete_success_reducer(
    state: DataState,
    action: SuccessType
  ): DataState {
    return {
      ...state,
      [this.stateName]: state[this.stateName].filter(
        (item: any) => item._id.toString() !== action.payload._id
      ),
    };
  }

  reducer = (state: DataState, action: ActionTypes): DataState => {
    switch (action.type) {
      case this.getStartedType:
        return this.started_reducer(state);
      case this.getFailedType:
        return this.failed_reducer(state);
      case this.getSuccessType:
        return this.postGet_success_reducer(state, action as SuccessType);
      case this.getFilteredStartedType:
        return this.started_reducer(state);
      case this.getFilteredFailedType:
        return this.failed_reducer(state);
      case this.getFilteredSuccessType:
        return this.postGet_success_reducer(state, action as SuccessType);
      case this.postStartedType:
        return this.started_reducer(state);
      case this.postFailedType:
        return this.failed_reducer(state);
      case this.postSuccessType:
        return this.postGet_success_reducer(state, action as SuccessType);
      case this.updateStartedType:
        return this.started_reducer(state);
      case this.updateFailedType:
        return this.failed_reducer(state);
      case this.updateSuccessType:
        return this.update_success_reducer(state, action as SuccessType);
      case this.updateQueryStartedType:
        return this.started_reducer(state);
      case this.updateQueryFailedType:
        return this.failed_reducer(state);
      case this.updateQuerySuccessType:
        return this.update_success_reducer(state, action as SuccessType);
      case this.deleteStartedType:
        return this.started_reducer(state);
      case this.deleteFailedType:
        return this.failed_reducer(state);
      case this.deleteSuccessType:
        return this.delete_success_reducer(state, action as SuccessType);
      default:
        return state;

    }
  };
}

export const AccountDataReduxer: StoreHandler = new StoreHandler("accounts","accountData");
export const UserDataReduxer: StoreHandler = new StoreHandler("accounts","userData");
export const TeamsReduxer: StoreHandler = new StoreHandler("accounts", "teams");
export const InvitesReduxer: StoreHandler = new StoreHandler("accounts","invites");
export const MeetingItemsReduxer: StoreHandler = new StoreHandler("thenaMeet","meetingItems");
export const MeetingTemplatesReduxer: StoreHandler = new StoreHandler("thenaMeet","meetingTemplates");
export const MeetingsReduxer: StoreHandler = new StoreHandler("thenaMeet","meetings");
export const MeetingSummariesReduxer: StoreHandler = new StoreHandler("thenaMeet","summaries");
export const MeetingNotesReduxer: StoreHandler = new StoreHandler("thenaMeet","notes");
export const MetricTypeReduxer: StoreHandler = new StoreHandler("metrics","types","metrics");
export const MetricDataReduxer: StoreHandler = new StoreHandler("metrics","data");
export const ListsReduxer: StoreHandler = new StoreHandler('thenaMeet', 'lists');
export const StreamsReduxer: StoreHandler = new StoreHandler('thenaMeet', 'streams');
export const StreamEventReduxer: StoreHandler = new StoreHandler('thenaMeet', 'streamEvents');

