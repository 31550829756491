import React from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../Drawer/index.scss";
interface FuncProps {
  state: boolean;
  changeState: () => void;
}

const DrawerButton: React.FC<FuncProps> = ({ state, changeState }) => {
  return (
    <Grid
      className={
        state ? "  meeting-open-position  " : "meeting-closed-position  "
      }
      id="meeting_log_drawer_btn"
    >
      <Grid
        className={" drawer-button-container white-background"}
        onClick={changeState}
      >
        {state ? (
          <ArrowForwardIosIcon className="arrow-icon gray2" />
        ) : (
          <ArrowBackIosIcon className="arrow-icon gray2" />
        )}
      </Grid>
    </Grid>
  );
};

export default DrawerButton;
