import React from 'react';
import { ListItemText, ListItem, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { DrawOptions } from './options';
import { helperUpdateStartMeetingState, helperUpdateStreamDialogState } from '../../../../helpers/helpers';

interface FuncProps extends DrawOptions {
    toggleDrawer: () => void;
    icon?: string;
}

const DrawListOption: React.FC<FuncProps> = ({name, link, id, toggleDrawer, icon }) => {

    switch (link) {
        case 'updateStartMeetingState':
            return <ListItem id="menu_updateStartMeetingState" button onClick={() => {
                helperUpdateStartMeetingState(true);
            }}>
                {icon && <i className={icon} style={{marginRight:"10px"}}></i>}
                <ListItemText><Typography color="primary" variant="body2" className="body-text gray3">{name}</Typography></ListItemText>
            </ListItem>
        case 'updateStreamState':
            return <ListItem id="menu_updateStreamState" button onClick={() => {
                helperUpdateStreamDialogState(true);
            }}>
                {icon && <i className={icon} style={{marginRight:"10px"}}></i>}
                <ListItemText><Typography color="secondary" variant="body2" className="body-text gray3">{name}</Typography></ListItemText>
            </ListItem>
        case 'comingSoon' : 
            return <ListItem disabled style={{ cursor: 'unset' }}>
                {icon && <i className={icon} style={{marginRight:"10px"}}></i>}
                <ListItemText ><Typography color="primary" variant="body2" 
                className="light-gray1 body-text">
                    {name}
                </Typography></ListItemText>
            </ListItem>
        default:
            return <NavLink key={name}
                id={id}
                style={{ textDecoration: 'none', color: 'black'}} 
                to={link}
            >
                <ListItem button >
                    {icon && <i className={icon} style={{marginRight:"10px"}}></i>}
                    <ListItemText ><Typography color="primary" variant="body2" className="body-text gray3">{name}</Typography></ListItemText>
                </ListItem>
            </NavLink>
    }

}

export default DrawListOption;