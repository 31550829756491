import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Formik, Form, FieldArray } from "formik";
import { isEmpty, includes } from "lodash";
import { getAccountId } from "../../../helpers/helpers";
import FormSelectField from "../../../components/FormSelectField/FormSelectField";
import FormInputField from "../../../components/FormInputField/FormInputField";
import * as Yup from "yup";
import { TeamSettings } from "../../../declarations/declarations";
import DeleteIcon from "@material-ui/icons/Delete";

interface FuncProps {
  data: any;
  allUsers: Array<[string, any]>;
  state: boolean;
  setState: (state: boolean) => void;
}

export interface TeamInitialData {
  name: string;
  account_id: string;
  teamMember: string;
  members: [];
}

interface DispatchProps {
  createTeam: (team: TeamSettings) => void;
  updateTeam: (team: TeamSettings) => void;
}

type Props = FuncProps & DispatchProps;

const AddEditTeamDialog: React.FC<Props> = ({
  data,
  allUsers,
  createTeam,
  updateTeam,
  state,
  setState,
}) => {
  const validation = Yup.object().shape({
    name: Yup.string().required("Please input a name"),
    members: Yup.array(Yup.string())
      .min(1, "1 team members required")
      .required("Please add 1 team member"),
  });

  const initialData: TeamInitialData = {
    name: data?.name,
    account_id: data ? data.account_id : getAccountId() as string,
    teamMember: data?.teamMember,
    members: data?.members,
  };

  return (
    <Dialog disableEnforceFocus={true} open={state} onClose={() => setState(false)}>
      <DialogTitle>{`${data ? "Edit" : "Add"} Team`}</DialogTitle>
      <Formik
        initialValues={initialData}
        //validationSchema={validation}
        onSubmit={async (values, actions) => {
          delete values.teamMember;
          //await createTeam(values);
          actions.resetForm();
          actions.setSubmitting(false);
          setState(false);
          if (data) {
            const dataToUpdate = { ...values, _id: data._id };
            updateTeam(dataToUpdate);
          } else {
            createTeam(values);
          }
        }}
        validationSchema={validation}
      >
        {({ dirty, errors, isSubmitting, values }) => (
          <Form>
            <DialogContent>
              <Grid container>
                <FormInputField
                  name="name"
                  label="Team Name"
                  type="text"
                  required
                />
                <FieldArray
                  name="members"
                  render={(arrayHelpers) => (
                    <Grid container>
                      <FormSelectField
                        name="teamMember"
                        label="Add Team Member"
                        options={allUsers}
                        md={10}
                        xs={10}
                      />
                      <Grid item xs={2} style={{ margin: "auto" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            arrayHelpers.push(values.teamMember);
                          }}
                          disabled={
                            includes(values.members, values.teamMember) ||
                            !values.teamMember
                          }
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Typography variant="h6" color="primary">
                          Team Members
                        </Typography>
                      </Grid>
                      {values.members?.length > 0 ? (
                        values.members.map((member, key) => (
                          <Grid item xs={12} key={key}>
                            <Typography>
                              {
                                allUsers.filter(
                                  (user) => user[0] === member
                                )[0][1]
                              }
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  arrayHelpers.remove(key);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Typography>
                          </Grid>
                        ))
                      ) : (
                        <Typography>Please add a team member</Typography>
                      )}
                    </Grid>
                  )}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isEmpty(errors) || !dirty}
              >
                {`${data ? "Save" : "Create"}`}
              </Button>
              <Button color="secondary" onClick={() => setState(false)}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditTeamDialog;
