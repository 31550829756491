import { ItemList, MeetingState } from "../../declarations/declarations";
import * as actionNames from './actions/meetingActionNames';
import * as actionTypes from './actions/meetingActionTypes';

export interface AppMeetingState {
    [key: string]: any;
    state: MeetingState | undefined
    isFacilitator: boolean;
    lists: ItemList[];
}

const initialState: AppMeetingState = {
    state: undefined,
    isFacilitator: false,
    lists: []
}

const clean_reducer = () => {
    return initialState;
}

const update_state_reducer = (state: AppMeetingState, action: actionTypes.UpdateMeetingState) => {
    return {
        ...state,
        state: action.payload
    }
}

const set_facilitator = (state: AppMeetingState, action: actionTypes.UpdateIsFacilitator) => {
    return {
        ...state,
        isFacilitator: action.payload
    }
}


export const meetingReducer = (state = initialState, action: any): AppMeetingState => {
    switch (action.type) {
        case actionNames.CLEAN_UP_MEETING:
            return clean_reducer();
        case actionNames.UPDATE_MEETING_STATE:
            return update_state_reducer(state, action);
        case actionNames.SET_FACILITATOR:
            return set_facilitator(state, action);
        default:
            return state;
    }
}