import React from "react";
import clsx from "clsx";
import MenuDrawer from "./components/MenuDrawer/MenuDrawer";
import { makeStyles } from "@material-ui/core/styles";
import MyMeetingItemsDrawer from "./components/MyMeetingItemDrawer/MyMeetingItemDrawer";
import store, { SystemState } from "../store/store";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../store/actionTypes";
import { bindActionCreators } from "redux";
import { CssBaseline } from "@material-ui/core";
import ThenaAppBar from "./components/ThenaAppBar/ThenaAppBar";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { alternateMyItem } from "../store/actionCreators";
import AppDialogs from "./components/Dialogs/AppDialogs";
import { getUserId } from "../helpers/helpers";
import { useSnackbar } from "notistack";
import { moveBetweenLists, moveOnSameList } from "../store/lists/listsActionCreator";
import { isMobile} from 'react-device-detect';

interface StateProps {
  myItemsDrawer: boolean;
}

interface DispatchProps {
  alternateMyItemState: () => void;
}

type Props = StateProps & DispatchProps;

const AuthenticatedLayout: React.FC<Props> = ({
  myItemsDrawer,
  alternateMyItemState,
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [drawState, drawStateSetter] = React.useState<boolean>(false);
  const handleMenuDrawer = () => {
    if(!drawState){
      
      if(document.getElementById('meeting-agenda-timer-input') && document.getElementById('meeting-agenda-button') ){
        document.getElementById('meeting-agenda-button')?.click();
      }
    }
    drawStateSetter(!drawState);
  };

  const drawerWidth = 280;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
  }));
  const classes = useStyles();

  const completeDragandDrop = (event: DropResult) => {
    const { destination, source, draggableId } = event;
    const item = store.getState().data.meetingItems.filter(item => item._id.toHexString() === draggableId)[0]
    const sourceList= source?.droppableId as string
    const destList = destination?.droppableId as string
    if (destination?.droppableId === getUserId() && item.owner_id !== getUserId()) {
        enqueueSnackbar(`You can not move another user's item into your list`, {variant: 'error'})
    } else if (destination?.droppableId === source.droppableId) {
        //@ts-ignore
        store.dispatch(moveOnSameList(destList, destination.index, draggableId));
    } else {
        //@ts-ignore
        store.dispatch(moveBetweenLists(sourceList, destList, destination?.index as number, draggableId));
    }

  }
  
  const onDragEnd = (event: DropResult) => {
    const { destination, source } = event;

    if (!destination) {
        return;
    } else if (destination?.droppableId === source?.droppableId && destination?.index === source?.index) {
        return;
    } else {
      completeDragandDrop(event);
    }
  };

  return (
    <div className={classes.root}>
      <ThenaAppBar
        myItemsOpen={myItemsDrawer}
        handleMenuDrawer={handleMenuDrawer}
        handleMyItemDrawer={alternateMyItemState}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <CssBaseline />
        <main style={{ paddingBottom: '0px' }}
          className={(drawState ? "main-shifted" : "main-original") + " main-common" }
        >
          <div className="main">
            {children}
          </div>
        </main>
        <MenuDrawer toggleDrawer={handleMenuDrawer} state={drawState} />
        <MyMeetingItemsDrawer
          open={myItemsDrawer}
          drawerWidth={drawerWidth}
          toggleDrawer={alternateMyItemState}
        />
      </DragDropContext>
      <AppDialogs/>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    alternateMyItemState: bindActionCreators(alternateMyItem, dispatch),
  };
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    myItemsDrawer: state.app.myItemsDrawer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);
