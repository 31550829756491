import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import FormLayout from '../FormLayout';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { resetPasswordRequest } from '../../../stitch/authentication';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    email: Yup
        .string()
        .email('Must be an email address')
        .required('Please enter your email')
});

const initialData = {
    email: '',
};

const ForgotPasswordEmail: React.FC<RouteComponentProps> = ({history}) => {

    const { enqueueSnackbar } = useSnackbar();

    return <FormLayout>
        <Formik 
            initialValues={initialData}
            onSubmit={async (values, actions) => {
                const result = await resetPasswordRequest(values.email)
                actions.resetForm();
                actions.setSubmitting(false);
                if (result) {
                    enqueueSnackbar(result, {variant: 'error'});
                } else {
                    enqueueSnackbar('Reset successful, please login', {variant: 'success'});
                    history.push( '/login');
                }
            }}
            validationSchema={validation}
            >
            {({ isSubmitting, dirty, errors }) => (
                <Form style={{marginLeft: '4%', marginRight: '4%'}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Forgot Password?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">Please enter your email below and then click submit.</Typography>
                    </Grid>
                    <FormInputField name="email" label="Email" type="text" md={12}/>
                    <Grid item xs={12}>
                        <Button 
                            type="submit" 
                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                            size="large" 
                            color="primary" 
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    </FormLayout>
}

export default ForgotPasswordEmail;