import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ThenaLogo from '../../../../components/ThenaLogo/ThenaLogo';

interface FuncProps {
    isFacilitator: boolean;
}
const NotStarted: React.FC<FuncProps> = ({isFacilitator}) => <Grid container style={{ textAlign: 'center', color: '#7482A1'}}>
    <Grid item xs={12}><ThenaLogo height="5%" width="80%" /></Grid>
    <Grid item xs={12}><Typography variant="h4">Welcome</Typography></Grid>
    {isFacilitator ? <Grid item xs={12}><Typography variant="h5">
        Press start to begin the meeting
    </Typography></Grid> : <Grid item xs={12}><Typography variant="h5">
        We are waiting for the host to start the meeting
    </Typography></Grid>}
</Grid>

export default NotStarted;