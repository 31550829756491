import React from "react";
import { Drawer, Grid, Typography } from "@material-ui/core";
import "./index.scss";
// import MeetingEventCard from "../../../../Components/MeetingEvent";
import { FilterListTwoTone } from "@material-ui/icons";
import {MeetingStreamEvent} from '../../../../declarations/declarations';
import {StreamEventReduxer} from '../../../../store/reduxer';
import { sortByDate } from "../../../../helpers/helpers";
import StreamItem from './components/StreamItem';

interface FuncProps {
  state: boolean;
  meeting_id : string;
}

type Props = FuncProps;

const StreamDrawer: React.FC<Props> = ({ state, meeting_id }) => {
  const [meetingEvents, setEvents] = React.useState<MeetingStreamEvent[]>([]);

  const startWatch = async () => {
      const initItems = await StreamEventReduxer.database.find({meeting_id}).toArray()
      sortByDate(initItems);
      setEvents(initItems);
      const meetingStreamWatcher = await StreamEventReduxer.database
          .watch();
      meetingStreamWatcher.onNext(async (event) => {
          const newEvent = event.fullDocument
          if (newEvent.meeting_id === meeting_id) {
            let meetEvent = await StreamEventReduxer.database.find({meeting_id}).toArray();
            sortByDate(meetEvent);
            setEvents(meetEvent);
          }
      })
  }

  React.useEffect(() => {
      (async () => {
          await startWatch();
      })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Drawer
      className={state ? "meeting-stream-drawer" : "meeting-stream-drawer0"}
      classes={{
        paper: "meeting-stream-drawerPaper light-gray3-background",
      }}
      open={state}
      variant="persistent"
      anchor="right"
    >
      <Grid className="event-header-container">
        <Grid className="inline gray3 bold-text width-50"> Meeting Events log </Grid>
        <Grid className="inline width-50 text-align-right">
          <FilterListTwoTone className="gray4" fontSize="small" />
        </Grid>
      </Grid>
      <Grid className="meeting-stream-drawerItem margin-auto-horizontal">
      {meetingEvents && meetingEvents.length > 0 ? meetingEvents.map((event) => (
        <StreamItem event={event}/>
      )) : <Typography className="gray4 body-text">
          Waiting for the facilitator to start the meeting
        </Typography>}
      </Grid>
    </Drawer>
  );
};

export default StreamDrawer;
