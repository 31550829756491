import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NotStarted from "./components/AgendaComponents/NotStarted";
import {
  MeetingState,
  MeetingItem,
  MeetingItemTypes,
  MeetingItemSetting,
  MeetingStateType,
  MeetingSummarySettings,
  AgendaItemTypes,
  NoteItem,
  ActionItem,
  MeetingStreamEventTypes, 
  ItemListSettings, 
  IdeaOrganizeState, 
  IdeaVoteRefineState, 
  RatingSetting,
  AccountData,
  ActionItemState,
  MeetingToolTypes
} from "../../declarations/declarations";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import {
  MeetingsReduxer,
  MeetingItemsReduxer,
  MeetingSummariesReduxer,
  MetricDataReduxer,
} from "../../store/reduxer";
import { bindActionCreators } from "redux";
import { BSON } from "mongodb-stitch-browser-sdk";
import Agenda from "./components/Agenda/Agenda";
import { getUserId, getAccountId, getThisUserName } from "../../helpers/helpers";
import Complete from "./components/AgendaComponents/Complete";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import NoteButton from "./components/NoteButton";
import NotePanel from "./components/NotePanel/NotePanel";
import { initiateNote } from "./components/AgendaComponents/helpers/noteHandlers";
import stitchApp, { handleMeetingRefine } from '../../stitch/app';
import ActionItemReview from "./components/AgendaComponents/ActionItemReview";
import InitializeMeeting from "./components/InitializeMeeting";
import { isLastRound, master_moveForward, master_moveBackward, startMeeting } from "./components/AgendaComponents/helpers/progressionHandlers";
import { createMeetingStreamEvent } from "./components/AgendaComponents/helpers/streamHelpers";
import MeetingItemsAgendaComponent from "./components/AgendaComponents/MeetingItemsAgendaComponent/MeetingItemsAgendaComponent";
import IdeasAndOrganize from "./components/AgendaComponents/IdeasAndOrganize/IdeasAndOrganize";
import IdeasVotesAndRefine from "./components/AgendaComponents/IdeasVotesAndRefine/IdeasVotesAndRefine";
import { postList, setListName, deleteItemFromList } from "../../store/lists/listsActionCreator";
import { useSnackbar } from "notistack";
import store, { SystemState } from '../../store/store';
import {getText} from './components/AgendaComponents/helpers/streamHelpers';
import RatingDialog from "../../components/RatingDialog/RatingDialog";
import FacilitatorBar from "./components/FacilitatorBar/FacilitatorBar";
import Drawer from './components/Drawer';
import DrawerButton from './components/DrawerButton';
import MeetingTopBar from "./components/MeetingTopBar";
import './index.scss'
import { useMeetingState } from '../../store/meeting/meetingState';

interface StateProps {
  AccountData: AccountData;
}

interface FuncProps extends RouteComponentProps<{
    team: string;
    account_id: string;
    meeting_id: string;
  }> {}

interface DispatchProps {
  updateMeeting: (meeting: any) => void;
  createSummary: (item: MeetingSummarySettings) => void;
  postList: (list: ItemListSettings) => void;
  setListName: (name: string, id: string) => void;
}

type Props = FuncProps & DispatchProps & StateProps;

const MeetingsPage: React.FC<Props> = ({
  match,
  history,
  updateMeeting,
  createSummary,
  postList,
  setListName,
  AccountData
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const meetingControl = useMeetingState(match.params.meeting_id);
  const meeting_id_string = match.params.meeting_id;
  const meetingState: MeetingState = meetingControl.accessState('state');
  const isFacilitator: boolean = meetingControl.facilitatorCheck()
  const stream_id = meetingState && meetingState.stream_id;

  const [meetingLoaded, setMeetingLoaded] = React.useState<boolean>(false);
  const [noteActive, setNoteActive] = React.useState<boolean>(false);
  const [publicState, setPublicState] = React.useState<NoteItem>();
  const [attendeeOptions, setAttendeeOptions] = React.useState<Array<[string, any]>>([]);
  const [confirmResolveDialog, setConfirmResolveDialog] = React.useState<boolean>(false);
  const [ratingDialog, setRatingDialog] = React.useState<boolean>(false);
  const [isRatingDone, setIsRatingDone] = React.useState<boolean>(false);
  const [isConcluding, setConcludeStarted] = React.useState<boolean>(false);
  const stateStatus = meetingState && meetingState.state;
  const [drawerState, toggleState] = React.useState<boolean>(true);
  const [controls, setControls] = React.useState<'agenda' | null>(null);

  // init meeting
  React.useEffect(() => {
    (async () => await meetingControl.initMeeting())();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // after state is loaded
  React.useEffect(() => {
    if (meetingState) {
      (async () => {
        const initialPubNote = await initiateNote(meeting_id_string, false);
        await getAttendeeOptions();
        await setPublicState(initialPubNote);
        await setMeetingLoaded(true);
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream_id]);

  React.useEffect(() => {
    (async () => {
      if (meetingState) {
        const initialRatingData = await MetricDataReduxer.database
            .find({meeting_id: meeting_id_string})
            .toArray();

        const ratingData = initialRatingData.find(rate => {
          return rate.user_id === getUserId() as string;
        });
  
        if (ratingData) {
          setRatingDialog(false);
        } else if (stateStatus === MeetingStateType.complete && ratingDialog === false && isRatingDone === false) {
          setRatingDialog(true);
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStatus]);

  // UI & Prompt Related Functions

  const handleDemo = () => {
    history.push(`/meetings/streams/view/${stream_id}`);
  }

  const handleDrawer = () => {
    toggleState(!drawerState);
  };

  const getResolveCountForDialog = (type: MeetingItemTypes) => {
    if (meetingState.currentAgendaItem.currentArtifacts) {
      return meetingState.currentAgendaItem.currentArtifacts.filter(item => item.type === type).length;
    } else return 0;
  }

  // Options for action item who select drop down

  const getAttendeeOptions = async () => {
    const stream = await store.getState().data.streams.filter(stream => stream._id.toHexString() === stream_id)[0]
    const list = stream && stream.admins.concat(stream.subscribers);
      const options = list && await stitchApp.callFunction("getAttendeeOptions", [list]);
      setAttendeeOptions(options);
  };

  // Meeting Functionality

  const openResolveDialog = () => setConfirmResolveDialog(true);
   
  const editArtifact = async (item:MeetingItemSetting) => {
    const newState: MeetingState = { ...meetingState };
    const actionItemArray = newState.currentAgendaItem.currentArtifacts;
    let updatedValue = '';
    actionItemArray&&actionItemArray.forEach( (thisItem, index) => {
      if(item._id.toString() === thisItem._id.toString()) {
        if(item.type !== thisItem.type){
          updatedValue = item.type===MeetingItemTypes.actionItem ? MeetingItemTypes.actionItem : MeetingItemTypes.decision
        }
        actionItemArray[index] = item;
      }
    });
    let eventType: MeetingStreamEventTypes;
    if(!updatedValue){
      switch (item.type) {
        case MeetingItemTypes.actionItem: 
          eventType = MeetingStreamEventTypes.action_edited
          break;
        case MeetingItemTypes.decision:
          eventType = MeetingStreamEventTypes.decision_edited
            break;
        default:
          eventType = MeetingStreamEventTypes.item_resolved
      }
    }
    else{
      switch (item.type) {
        case MeetingItemTypes.actionItem: 
          eventType = MeetingStreamEventTypes.decision_changed
          break;
        case MeetingItemTypes.decision:
          eventType = MeetingStreamEventTypes.action_changed
            break;
        default:
          eventType = MeetingStreamEventTypes.item_resolved
      }
    }
    createMeetingStreamEvent(eventType, stream_id, meeting_id_string, getUserId() as string, item._id.toHexString(), item.what);
    if(store.getState().app.hideStream){
      enqueueSnackbar(`${getText(eventType)} `, { variant: "info" });
    }
    newState.currentAgendaItem.currentArtifacts = actionItemArray;
    await updateMeeting(newState);
  }

  const concludeMeeting = async () => {
    setConcludeStarted(true);
    const meetingItems = await MeetingItemsReduxer.database
      .find(
        {
          meeting_id: meeting_id_string,
          complete: true
        },
        { projection: { _id: 1 } }
      )
      .toArray();

      const decisionItems = await MeetingItemsReduxer.database
      .find(
          {
            meeting_id: meeting_id_string,
            type: MeetingItemTypes.decision
          },
          { projection: { _id: 1 } }
      )
      .toArray();

      const actionItems = await MeetingItemsReduxer.database
      .find(
          {
            meeting_id: meeting_id_string,
            type: MeetingItemTypes.actionItem
          },
          { projection: { _id: 1 } }
      )
      .toArray();

    actionItems.map((item) => meetingItems.push(item));
    decisionItems.map((item) => meetingItems.push(item));
    const itemIds = meetingItems.map((item) => item._id.toString());
    const newSummary: MeetingSummarySettings = {
      account_id: getAccountId() as string,
      meeting_id: meeting_id_string,
      name: meetingState.name,
      date: new Date(),
      team: [stream_id],
      meetingItems: itemIds,
      dataItems: [],
      notes: meetingState.notes,
      attendees: meetingState.attendees,
      startedAt: meetingState.startedAt,
      endedAt: Date.now()
    };
    const newState: MeetingState = { ...meetingState };
    newState.state = MeetingStateType.complete;

    setRatingDialog(true);
    await createSummary(newSummary);
    await updateMeeting(newState);

    const CompletedActionItems = await MeetingItemsReduxer.database
    .find(
      {
        stream_id: stream_id,
        type: MeetingItemTypes.actionItem,
        state: ActionItemState.complete
      }
    )
    .toArray();

    CompletedActionItems && CompletedActionItems.forEach((item: ActionItem) => {
      MeetingItemsReduxer.database.updateOne(
        { _id: item._id },
        { $set: { doneDate: new Date(), complete: true } }
      );
    })

    setConcludeStarted(false);
  };

  const addNewListToIdeaOrganizeState = async () => {
    const newMeetingState: MeetingState = {...meetingState};

    const ideaListEntry = {
      attendee_id: getUserId() as string,
      location_id: new BSON.ObjectId().toHexString()
    };
    (newMeetingState.currentAgendaItem.state as IdeaOrganizeState).ideaLists.push(ideaListEntry);
    await updateMeeting(newMeetingState);
    await postList({
      account_id: getAccountId() as string,
      location_id: ideaListEntry.location_id,
      meeting_id: meeting_id_string,
      items: [],
      name: `${getThisUserName()}'s List`
    });
  }

  const addNewListToIdeaVoteRefineState = async () => {
    const newMeetingState: MeetingState = {...meetingState};

    const ideaListEntry = {
      attendee_id: getUserId() as string,
      location_id: new BSON.ObjectId().toHexString()
    };
    (newMeetingState.currentAgendaItem.state as IdeaVoteRefineState).ideaLists.push(ideaListEntry);
    await updateMeeting(newMeetingState);

    await postList({
      account_id: getAccountId() as string,
      location_id: ideaListEntry.location_id,
      meeting_id: meeting_id_string,
      items: [],
      name: `${getThisUserName()}'s List`
    });
  }

  const addNewVoteToIdeaVoteRefineState = async (item: MeetingItem) => {
    const newMeetingState: MeetingState = {...meetingState};

    const voteListEntry = {
      user_id: getUserId() as string,
      item_id: item._id.toHexString(),
      meeting_id: meeting_id_string
    };
    (newMeetingState.currentAgendaItem.state as IdeaVoteRefineState).votes.push(voteListEntry);
    await updateMeeting(newMeetingState);
    if (newMeetingState.currentAgendaItem.state && newMeetingState.currentAgendaItem.state.votes && newMeetingState.currentAgendaItem.state.votes.length > 0) {
      await handleMeetingRefine(newMeetingState.currentAgendaItem.state.votes, getAccountId() as string, meeting_id_string);
    }
    await postList({
      account_id: getAccountId() as string,
      location_id: new BSON.ObjectId().toHexString(),
      meeting_id: meeting_id_string,
      items: [],
      name: `${getThisUserName()}'s List`
    });
  }

  const newOrganizeList = async (name: string, items?: Array<string>) => {
    const newMeetingState: MeetingState = {...meetingState};
    const organizeList_id = new BSON.ObjectId().toHexString();

    (newMeetingState.currentAgendaItem.state as IdeaOrganizeState).organizeLists.push(organizeList_id);

    if(items){
      for(var i = 0 ; i<items?.length ; i++ ){
        //@ts-ignore
        store.dispatch(deleteItemFromList(items[i]));
      }
    }
    
    await postList({
      account_id: getAccountId() as string,
      location_id: organizeList_id,
      meeting_id: meeting_id_string,
      items: items ? items : [],
      name
    });

    await updateMeeting(newMeetingState);

    await createMeetingStreamEvent(
      MeetingStreamEventTypes.list_created, 
      stream_id,
      meeting_id_string,
      getUserId() as string
    )
    if(store.getState().app.hideStream){
      enqueueSnackbar(`${getText(MeetingStreamEventTypes.list_created)} `, { variant: "info" });
    }
  }

  const changeIdeaOrgRound = async () => {
    const newMeetingState: MeetingState = {...meetingState};
    (newMeetingState.currentAgendaItem.state as IdeaOrganizeState).round === MeetingToolTypes.ideas ? 
      (newMeetingState.currentAgendaItem.state as IdeaOrganizeState).round = MeetingToolTypes.organize : 
      (newMeetingState.currentAgendaItem.state as IdeaOrganizeState).round = MeetingToolTypes.ideas
    await updateMeeting(newMeetingState);
  }
 
  const agendaDisplay = () => {
    if (meetingLoaded && meetingState) {
      let meetingNewState = meetingState.state === MeetingStateType.pending ? 
                  MeetingStateType.pending : meetingState.state === MeetingStateType.active || ratingDialog ? MeetingStateType.active :
                  meetingState.state === MeetingStateType.complete && !ratingDialog ? MeetingStateType.complete : '';
      switch (meetingNewState) {
        case MeetingStateType.pending:
          return <NotStarted isFacilitator={isFacilitator} />;
        case MeetingStateType.active:
          switch (meetingState.currentAgendaItem.type) {
            case AgendaItemTypes.meetingItems:
              return <MeetingItemsAgendaComponent 
                meeting_id={meeting_id_string}
                stream_id={stream_id}
                isFacilitator={isFacilitator}
                currentAgendaItem={meetingState.currentAgendaItem}
                openResolveDialog={openResolveDialog}
                editArtifact={editArtifact}
                meetingState={meetingState}
                attendeeOptions={attendeeOptions}
              />
            case AgendaItemTypes.actionItemReview:
              return <ActionItemReview stream_id={stream_id}/>
            case AgendaItemTypes.ideas_and_organize:
              return <IdeasAndOrganize 
                meeting_id={meeting_id_string} 
                currentAgendaItem={meetingState.currentAgendaItem}
                addNewListToState={addNewListToIdeaOrganizeState}
                changeIdeaOrgRound={changeIdeaOrgRound}
                newOrganizeList={newOrganizeList}
                setListName={setListName}
              />
            case AgendaItemTypes.ideas_vote_refine:
              return <IdeasVotesAndRefine 
                meeting_id={meeting_id_string} 
                currentAgendaItem={meetingState.currentAgendaItem}
                addNewListToState={addNewListToIdeaVoteRefineState}
                addVoteListToState={addNewVoteToIdeaVoteRefineState}
                setListName={setListName}
                isFacilitator={isFacilitator}
              />
            default:
              return (
                <Agenda
                  stream_id={stream_id}
                  meetingName={meetingState.name}
                  agendaItems={meetingState.agendaItems}
                  currentAgendaItem={
                    meetingState.currentAgendaItem
                  }
                />
              );
          }
        case MeetingStateType.complete:
          return <Complete meeting_id={meeting_id_string} />;
        default:
          return <NotStarted isFacilitator={isFacilitator} />;
      }
    }
  };

  return isConcluding ? <CircularProgress className="spinner-middles" /> : !meetingLoaded ? (
    <InitializeMeeting state={!meetingLoaded}/>
  ) : meetingState ? (
    <Grid className={controls === "agenda" ? "meeting-page-shifted" : "meeting-page-normal"} container>
      <Grid container className="meeting-page">
        {noteActive && <NotePanel
          publicState={publicState as NoteItem}
          setNoteState={() => setNoteActive(!noteActive)}
        />}
        <Grid container className={drawerState ? "top-bar-margin-add" : ""}>
          {meetingState.state !== MeetingStateType.complete && ratingDialog === false && <>
          {meetingState.currentAgendaItem && meetingState.currentAgendaItem.currentItem && meetingState.currentAgendaItem.currentItem._id
            ? <Grid /> : <MeetingTopBar 
            name={meetingState.name}
            AccountName={AccountData.accountName}
            stream_id={stream_id}
            attendees={meetingState.attendees}
            isFacilitator={isFacilitator}
            concludeMeeting={concludeMeeting}
           />}
          </>}
            {agendaDisplay()}
        </Grid>
      {ratingDialog && 
        <RatingDialog
          text="How would you rate the meeting?"
          state={ratingDialog}
          rateFunc={async(num) => {
            let values: RatingSetting = {
              account_id: getAccountId() as string,
              user_id: getUserId() as string,
              rating: num,
              stream_id: stream_id,
              meeting_id: meeting_id_string,
              type: 'rating'
            }

            await MetricDataReduxer.database.insertOne(values);
            setRatingDialog(false)
            setIsRatingDone(true);
          }}
      />
      }
      {!isFacilitator && meetingState.state !== MeetingStateType.complete ? (
        <NoteButton
          noteActive={noteActive}
          setNoteState={() => setNoteActive(!noteActive)}
        />
      ) : null}
      <DrawerButton state={drawerState} changeState={handleDrawer} />
      <Drawer state={drawerState} meeting_id={meeting_id_string}/>
      {isFacilitator && meetingState.state !== MeetingStateType.complete ? (
        <FacilitatorBar
          meeting_id_string={meeting_id_string}
          setNoteState={() => setNoteActive(!noteActive)}
          noteActive={noteActive}
          startMeeting={() => startMeeting(meetingState)}
          firstAgendaItem={meetingState.agendaItems[0].name}
          isLastRound={isLastRound(meetingState)}
          currentAgendaItem={meetingState.currentAgendaItem}
          moveForward={() =>
            master_moveForward(
              meetingState,
              [],
              () => {},
              () => {}
            )
          }
          moveBackward={() =>
            master_moveBackward(meetingState, [])
          }
          concludeMeeting={concludeMeeting}
	        handleDemo={handleDemo}
          controls= {controls}
          setControls = {setControls}
        />
      ) : null}
      <ConfirmDialog
        text={`This item has ${getResolveCountForDialog(MeetingItemTypes.actionItem)} action item${getResolveCountForDialog(MeetingItemTypes.actionItem) > 1 ? 's' : ''}
          and ${getResolveCountForDialog(MeetingItemTypes.decision)} 
          decision${getResolveCountForDialog(MeetingItemTypes.decision) > 1 ? 's' : ''}.
          Are you sure you want to resolve this item?`}
        state={confirmResolveDialog}
        close={() => setConfirmResolveDialog(false)}
        confirmedFunc={async () => await meetingControl.resolveMeetingItem()}
      />
    </Grid>
    </Grid>
  ) : (
    <Typography style={{ color: "white" }} variant="h5">
      This meeting URL does not exist
    </Typography>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    updateMeeting: bindActionCreators(MeetingsReduxer.update, dispatch),
    createSummary: bindActionCreators(MeetingSummariesReduxer.post, dispatch),
    //@ts-ignore
    postList: bindActionCreators(postList, dispatch),
    setListName: bindActionCreators(setListName, dispatch)
  };
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    AccountData: state.data.accountData[0],
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetingsPage));