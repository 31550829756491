import React from 'react';
import { IdeaVoteRefineState, MeetingItemCardOptions, MeetingItem } from '../../../../../../declarations/declarations';
import MeetingItemHorizonalCollection from '../../../../../../components/MeetingItemHorizonalCollection/MeetingItemHorizonalCollection';
import { Grid } from '@material-ui/core'
import { getUserId } from '../../../../../../helpers/helpers';

interface FuncProps {
    state: IdeaVoteRefineState
    addVoteListToState: (item: MeetingItem) => void;
}

const IdeaVotesComponent: React.FC<FuncProps> = ({ state, addVoteListToState }) => {
    
    const [list, setListName] = React.useState<string[]>([]);
    const [votedItemId, setVotedItemId] = React.useState<string>('');
    React.useEffect(() => {
        if (state && state.ideaLists) {
            let lists: string[] = [];
            state.ideaLists.forEach(idea => {
                lists.push(idea.location_id);
            });

            setListName(lists);
        }

        if (state && state.votes) {
            let votedItem = state.votes.find(item => {
                return item.user_id === getUserId() as string;
            });

            if (votedItem && votedItem.item_id) {
                setVotedItemId(votedItem.item_id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state])

    return <Grid item xs={12} md={8}>
    <Grid style={{overflowX: 'auto', display: 'flex'}}>
        <MeetingItemHorizonalCollection width={'370px'} 
            buttonFunction={addVoteListToState} 
            lists={list} 
            name='' 
            idPrefix="voting" 
            isVoting={true}
            isVotedItemId={votedItemId}
            options={MeetingItemCardOptions.vote} ></MeetingItemHorizonalCollection>
    </Grid>
</Grid>
}

export default IdeaVotesComponent;