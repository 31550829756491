import React from "react";
import { Typography, Avatar, Grid } from "@material-ui/core";
import { ActionItem, ActionItemState, MeetingItemSetting } from "../../../declarations/declarations";
import { getUserId } from "../../../helpers/helpers";
import moment from "moment";
import { Check } from "@material-ui/icons";
import "./styles/index.scss";
import { MeetingItemsReduxer } from "../../../store/reduxer";
import MeetingItemDialog from "../../MeetingDialog/MeetingItemDialog";
import stitchApp from "../../../stitch/app";
import store from "../../../store/store";

interface FuncProps {
  item: ActionItem;
  fullText?: boolean;
  itemActions?: () => any;
  isCurrent?: boolean;
  complete?: boolean;
  editArtifact?: (item: MeetingItemSetting) => void;
}

const ActionItemCard: React.FC<FuncProps> = ({
  item,
  fullText,
  itemActions,
  complete,
  isCurrent,
  editArtifact,
}) => {
  const currentUser = item.who === getUserId() ? true : false;
  const [profile, setProfile] = React.useState<string>();
  const [dialogState, setDialogState] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (!currentUser) {
        const assignedTo = await stitchApp.callFunction("getUserNames", [
          [item.who],
        ]);
        setProfile(`${assignedTo[0]?.firstName[0]}${assignedTo[0]?.lastName[0]}`);
      } else {
        const assignedTo  = store.getState().data.userData[0];
        setProfile(`${assignedTo?.firstName[0]}${assignedTo?.lastName[0]}`);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const dueStyle = () => {
    if (
      moment(item.when).isBefore(new Date()) &&
      isCurrent &&
      !complete &&
      item.state !== ActionItemState.complete
    ) {
      return { color: "red" };
    } else if (complete && isCurrent) {
      return { color: "white" };
    } else if (complete || item.state === ActionItemState.complete) {
      return { color: "grey" };
    } else if (moment(item.when).isBefore(new Date())) {
      return { color: "red" };
    } else {
      return undefined;
    }
  };

  const getDate = () => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    let date = "";
    if (item.when) {
      date = new Date(item.when).toLocaleDateString("en-GB", options);
    }

    return date;
  };

  const setDoneDate = async () => {
    await MeetingItemsReduxer.database.updateOne(
      { _id: item._id },
      { $set: { doneDate: new Date(), complete: true } }
    );
  };

  const getState = () => {
    switch (item.state) {
      case ActionItemState.blocked:
        return "Blocked";
      case ActionItemState.complete:
        return "Completed";
      case ActionItemState.in_progress:
        return "In Progress";
      case ActionItemState.not_started:
        return "Not Started";
      default:
        return "Not Started";
    }
  };

  return (
    <Grid container>
      <Grid
        container
        className="inProgress"
        onClick={() => setDialogState(true)}
      >
        <Grid container className="label-container">
          <Grid
            className={
              item.state
                ? item.state + " btn"
                : ActionItemState.not_started + " btn"
            }
          >
            <Typography className="text small-text">{getState()}</Typography>
          </Grid>
          {/* <SubdirectoryArrowRightSharp className="arrow"></SubdirectoryArrowRightSharp>
        <Typography className="next-sprint-text small-text">{stream}</Typography> */}
        </Grid>
        <Grid container className="scrum-container">
          <Typography
            className={
              (item.state === ActionItemState.complete ? "gray6" : "gray2") +
              " bold-text scrum-meeting"
            }
          >
            {item.what}
          </Typography>
        </Grid>
        <Grid container className="why-container">
          <Typography className="small-text scrum-meeting-description">
          {typeof item.why === 'string' ? item.why : 
                 "Why created in draftjs"}
          </Typography>
        </Grid>
        <Grid container className="day-date-container">
          <Avatar className="avatar-img">
            <Typography>{profile}</Typography>
          </Avatar>
          <Typography className="small-text day-date" style={dueStyle()}>
            {getDate()}
          </Typography>
          {/* <Typography className="delete pre-title">Delete</Typography> */}
          {item.who === getUserId() && (
            <Grid className="round-icon">
              <Check className="check-icon" onClick={() => setDoneDate()} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <MeetingItemDialog
        type="Action Item"
        state={dialogState}
        dialogItem={item}
        setDialogState={setDialogState}
        editArtifact={editArtifact}
        isCurrentItem={true}
      />
    </Grid>
  );
};

export default ActionItemCard;
