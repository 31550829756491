import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { MeetingSummary } from "../../../../declarations/declarations";

interface StateProps {
  ele: MeetingSummary;
  onDialogOpen: (summary: MeetingSummary) => void;
}

type Props = StateProps;

const MeetingStreamsCard: React.FC<Props> = ({ ele, onDialogOpen }) => {

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const RandomDotClassArray = [
    "accent-red-background",
    "accent-green-background",
    "accent-purple-background",
    "accent-yellow-background",
  ];

  var index = Math.floor(Math.random() * RandomDotClassArray.length);

  var RandomDotClassName = RandomDotClassArray[index];
  let EleDate = ele.date;

  let DisplayTime = `${new Date(EleDate).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })} - ${weekDays[new Date(EleDate).getDay()]}  ${new Date(
    EleDate
  ).toLocaleDateString("en-GB", {
    month: "short",
    day: "numeric",
  })}`;


  return (
    <Grid className="meeting-info-container" container>
      <Grid item xs={9}>
        <Grid>
          <Grid className="dot-container inline">
            <Grid className={"dot " + RandomDotClassName}></Grid>
          </Grid>
          <Grid className="gray2 bold-text inline">{ele.name}</Grid>
        </Grid>
        <Grid>
          <Grid className="date-container gray4 small-text">{DisplayTime}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} className="button-container">
        <Button className=" button" onClick={() => onDialogOpen(ele)}>
          <Typography className="gray5 pre-title">SUMMARY</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default MeetingStreamsCard;
