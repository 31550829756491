import React from "react";
import {
  Grid, Typography
} from "@material-ui/core";
import { AccountData } from "../../../declarations/declarations";

interface FuncProps {
  AccountData : AccountData
}

const AccountInfo: React.FC<FuncProps> = ({ AccountData }) => {
  const [timeDate, setTimeAndDate] = React.useState<string>(`${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} ${new Date().toLocaleDateString("en-GB", {month: 'short', year: 'numeric', day: 'numeric'})}`);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeAndDate(`${new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} ${new Date().toLocaleDateString("en-GB", {month: 'short', year: 'numeric', day: 'numeric'})}`);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
      <Grid className="thena-logo ">
          <Typography className="thena-logo-name subtitle primary-dark">{AccountData.accountName && AccountData.accountName[0]}</Typography>
        <Grid className="account-name">
          <Typography className="subtitle">
              {AccountData.accountName}
          </Typography>
        </Grid>
        <Grid className="account-time-date">
          <Typography className="small-text">
              {timeDate}
          </Typography>
        </Grid>
      </Grid>
  );
};

export default AccountInfo;

