import {
  CircularProgress,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import MeetingItemCard from "../MeetingItemCard/MeetingItemCard";
import { ItemList, MeetingItem, MeetingItemCardOptions, MeetingItemTypes } from "../../declarations/declarations";
import { SystemState } from "../../store/store";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppStateActionTypes } from "../../store/actionTypes";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { BSON, RemoteInsertOneResult } from "mongodb-stitch-browser-sdk";
import { getLists } from "../../store/lists/listsActionCreator";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { openMyItem } from "../../store/actionCreators";
import { Add, FilterListTwoTone, Close } from "@material-ui/icons";
import './index.scss';
import { isEmpty } from "lodash";
import { Form, Formik } from "formik";
import { initialDataHelper } from "../../helpers/formHelpers";
import { getAccountId, getUserId } from "../../helpers/helpers";
import * as Yup from "yup";
import FormInputField from "../FormInputField/FormInputField";
import { useSnackbar } from 'notistack';
import { addItemToList } from "../../store/lists/listsActionCreator";

interface FuncProps {
  list_id: string;
  border?: boolean | true;
  name?: string;
  width?: string;
  HideAddIcon?: boolean;
  options?: MeetingItemCardOptions;
  buttonFunction?: (item: MeetingItem) => void;
  isVotedItemId?: string | undefined;
  isVoting?: boolean;
  listUiId: string;
  addItem: string;
  setAddItem: (addItemId: string) => void;
}

interface StateProps {
  lists: ItemList[];
  meetingItems: MeetingItem[];
}

interface DispatchProps {
  postItem: (item: any) => RemoteInsertOneResult;
  getLists: (query: object) => void;
  setDrawer: () => void;
  getItems: (query: object) => void;
  addItemToList: (location_id: string, item_id: string, toStart: boolean) => void;

}

type Props = FuncProps & StateProps & DispatchProps;

const MeetingItemCardList: React.FC<Props> = ({
  list_id,
  border,
  lists,
  getItems,
  getLists,
  name,
  HideAddIcon,
  options,
  width,
  buttonFunction,
  isVotedItemId,
  isVoting,
  listUiId,
  addItem,
  setAddItem,
  postItem,
  setDrawer,
  addItemToList
}) => {
  const [load, setLoad] = React.useState(false);
  const account_id = getAccountId() as string;
  const { enqueueSnackbar } = useSnackbar();
  let theList = lists.filter((list) => {
    return list._id.toHexString() === list_id || list.location_id === list_id;
  })[0];
  let myList: ItemList | void;
  React.useEffect(() => {
    if (!theList) {
      (async () => {
        myList = await getLists({
          location_id: list_id,
        });
      })();
    }
    if (theList) {
      (async () => {
        await getItems({
          _id: { $in: theList.items.map((item) => new BSON.ObjectId(item)) },
        });
      })();
    } else if (myList) {
      (async () => {
        await getItems({
          _id: { $in: myList.items.map((item) => new BSON.ObjectId(item)) },
        });
      })();
    }
    setLoad(true);
  }, [theList]);

  
  const BlankState: React.FC = () => (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography className="gray3 body-text">{`You have no items in this list`}</Typography>
    </Grid>
  );

  const ErrorState: React.FC = () => (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography className="bold-text gray3">{`Oops! Something went wrong, please contact `}</Typography>
      <Typography
        style={{ cursor: "pointer" }}
        className="bold-text gray3"
        // variant="h6"
        id="my_custom_link"
      >{`Support`}</Typography>
    </Grid>
  );

  const validation = Yup.object().shape({
    what: Yup.string()
      .min(5, "5 character minimum")
      .required("Please add a what"),
    why: Yup.string()
      .required("Please add a why"),
    // team: Yup.mixed().required("Please choose a team"),
  });

  const post = async (values: any) => {
    const itemResult = await postItem(values);
    await addItemToList(
      theList && theList.location_id ? theList.location_id : getUserId() as string, 
      itemResult.insertedId.toHexString(),
      true
    )
    enqueueSnackbar(`Created item: ${values.what}`, {variant: 'success'})
    if (theList && !theList.location_id) {
      setDrawer()
    }
  }

    return ( isVoting && theList.items && theList.items.length === 0 ? null : <Grid id={listUiId}  className = "droppable-container" >
      <Grid container className="headers-container">
          {name && <Typography className="body-text title">{name}</Typography>}
          {!name && theList && theList.name && <Typography className="body-text title">
            {theList.name}
          </Typography>}
          {!HideAddIcon && <Button className="gray5 add-icon">
              <Add style={{ fontSize: '18px' }} onClick={() => setAddItem(list_id)}/>
          </Button>}
          <FilterListTwoTone className="gray5 filter-icon" />
      </Grid>
      <Grid id="add-Btn-container">
        {addItem === list_id && <Grid className="white-background add-item-container" 
        style={{ margin: 'auto', maxWidth: '329px', minWidth: '329px' }}>
          <Formik
            initialValues={initialDataHelper(
              "meetingItems",
              {
                account_id,
                type: MeetingItemTypes.meetingItem,
                what: "",
                why: "",
                complete: false,
              }
            )}
            onSubmit={(values, actions) => {
              actions.resetForm();
              actions.setSubmitting(false);
              setAddItem('')
              post(values);
            }}
            validationSchema={validation}
          >
            {({ isSubmitting, errors, dirty }) => (
            <Form>
                <FormInputField name="what" label="Enter new meeting item" type="text" md={12} />
                  <FormInputField
                    name="why"
                    label="Enter Why"
                    type="text"
                    multiline
                    md={12}
                  />
              <Grid container className="more-vert-icon">
                  <Close className="gray5 cursor-pointer" onClick={() => setAddItem('')} />
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isEmpty(errors) || !dirty}
                    className="primary-blue-background add-item-btn-icon">
                      <Typography className="white add-item-btn-icon-text">
                          <Add className="white body-text add-item-btn-text" />
                          Add new item
                      </Typography>
                  </Button>
              </Grid>
            </Form>
            )}
            </Formik>
        </Grid>}
        {/*:<Grid style={{ margin: 'auto', maxWidth: '329px' }}>
        //   <Button className="white-background add-item-btn"
        //   onClick={() => setAddItem(list_id)}>
        //       <Add className="gray5 body-text add-item-btn-text" />
        //       <Typography className="gray5 body-text add-item-btn-text">
        //           Add meeting item
        //       </Typography>
        //   </Button>
            // </Grid>*/ }
      </Grid>
      <Droppable droppableId={theList ? theList.location_id : ""}>
        {(provided) => (
          <Grid
            {...provided.droppableProps}
            innerRef={provided.innerRef}
            style={{
            }}
          >
            {!load ? (
              <CircularProgress color="secondary" />
            ) : theList && theList.items.length > 0 ? (
              theList.items.map((id, i) => (
                <MeetingItemCard
                  listUiId={listUiId}
                  maxWidthContent="100%"
                  key={id}
                  item_id={id}
                  index={i}
                  options = {options}
                  buttonFunction={buttonFunction}
                  isVotedItemId={isVotedItemId}
                />
              ))
            ) : (theList && addItem !== list_id) || (myList && addItem !== list_id)  ? (
              <BlankState />
            ) : theList || myList ? null : (
              <ErrorState />
            )}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    lists: state.lists.lists,
    meetingItems: state.data.meetingItems as MeetingItem[],
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppStateActionTypes>
): DispatchProps => {
  return {
    //@ts-ignore
    postItem: bindActionCreators(MeetingItemsReduxer.post, dispatch),
    getLists: bindActionCreators(getLists, dispatch),
    getItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
    setDrawer: bindActionCreators(openMyItem, dispatch),
    addItemToList: bindActionCreators(addItemToList, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingItemCardList);
