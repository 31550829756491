import { RawDraftContentState } from "draft-js";
import { BSON } from "mongodb-stitch-browser-sdk";

export interface Types {
  accountData: AccountData;
  agendaTemplate: MeetingTemplate
  meetingItem: MeetingItem;
  actionItem: ActionItem;
  meetingState: MeetingState;
  metric: TargetMetric;
  list: ItemList;
  stream: Stream;
  summary: MeetingSummary;
}

interface Doc {
  _id: BSON.ObjectID;
  owner_id: string;
}

interface NewDoc {
  _id: BSON.ObjectID;
}

interface BasicItemSettings {
  type: MeetingItemTypes;
  what: string;
  location: MeetingItemLocation;
  initials: string;
  complete: boolean;
  completeDate: Date | undefined;
  stream_id?: string;
}

export interface MeetingItemV2 {
  _id: BSON.ObjectID;
  owner_id: string;
  spawn_id?: string;
  meeting_id?: string;
  what: string;
  why: any;
  location: {
    agenda: string;
    list: number;
    index: number;
  };
}

export interface MeetingItemLocation {
  location: string;
  list: string;
  index: number;
}

export interface MeetingItemSetting extends BasicItemSettings,Doc {
  why: any;
  combine?: Array<string>;
  who: string;
  when?: Date;
  meeting_id?: string;
  spawn_id?: string;
}

export interface BasicItem extends Doc, BasicItemSettings {}
export interface MeetingItem extends MeetingItemSetting, BasicItem {
  comment?: Array<ItemComment>;
  loading?: boolean;
}

export enum ActionItemState {
  not_started = 'not_started',
  in_progress = 'in_progress',
  blocked = 'blocked',
  complete = 'completed'
}

export interface ActionItemList {
  name: ActionItemState | "overdue";
  actionItems: Array<ActionItem>;
}

export interface ActionItemSettings {
  team: string;
  why: any;
  doneDate: Date;
  who: string;
  when: Date;
  meeting_id: string;
  state: ActionItemState
  spawn_id: string;
}

export interface ActionItem extends ActionItemSettings, BasicItem {}

export interface FuncProps {
  lists: Array<ActionItemState | 'overdue'>;
  actionItems: ActionItem[];
}

export interface NoteItem extends Doc {
  raw: RawDraftContentState;
  meeting_id: string;
  private: boolean;
  account_id?: string;
}

export interface MeetingSummarySettings {
  account_id: string;
  meeting_id: string;
  name: string;
  team: Array<string>;
  date: Date;
  meetingItems: Array<string>;
  dataItems: Array<string>;
  notes: Array<string>;
  attendees: Array<string>;
  startedAt?: number;
  endedAt?: number;
}

export interface MeetingSummary extends Doc, MeetingSummarySettings {}

export enum MeetingItemTypes {
  decision = "decision",
  discussion = "discussion",
  announcement = "announcement",
  objective = "objective",
  actionItem = "actionItem",
  meetingItem = "meetingItem",
  ideaItem = "ideaItem",
  note = "note",
  dump = "dump",
  sort = "sort",
  data = "data",
}

export interface TimerState {
  startTime: Date;
  duration: number;
}

export enum DiscussionRoundTypes {
  select = "select",
  discuss = "discuss",
  vote = "vote",
}

export interface RatingSetting {
  account_id: string;
  user_id: string;
  rating: number;
  stream_id: string;
  meeting_id: string;
  type: 'rating';
}

export interface MeetingSortState {
  [key: string]: Array<string>;
}

export interface MeetingTemplate extends Doc {
  name: string;
  account_id: string;
  agendaItems: Array<AgendaItem>;
  team: string;
}

export interface AgendaItem {
  type: AgendaItemTypes;
  name: string;
  description?: string;
  voting?: boolean;
  answers?: Array<{ name: string }>;
  metric_id?: string;
  timer?: number;
}

export interface MeetingAgendaItem extends AgendaItem { 
  _id: string
  currentItem?: MeetingItem
  currentArtifacts?:Array<MeetingItemSetting>
  state?: AgendaItemState;
  startedAt?: number;
  endedAt?: number;
  votingEnabled?: boolean;
}

export enum MeetingToolTypes {
  ideas = 'ideas',
  organize = 'organize',
  refine = 'refine',
  vote = 'vote'
}
export interface AgendaItemState {
  [key: string]: any;
}
export interface IdeaOrganizeState extends AgendaItemState {
  round: MeetingToolTypes.ideas | MeetingToolTypes.organize;
  ideaLists: {
    [key: string]: string;
    attendee_id: string;
    location_id: string
  }[];
  organizeLists: string[];
}

export interface MeetingVote {
  user_id: string;
  item_id: string;
  meeting_id: string;
  vote_number?: number;
}

interface IdeaLists {
  [key: string]: string;
  attendee_id: string;
  location_id: string;
}

export interface IdeaVoteRefineState extends AgendaItemState {
  round: MeetingToolTypes.ideas | MeetingToolTypes.vote | MeetingToolTypes.refine;
  ideaLists: IdeaLists[];
  votes: MeetingVote[];
  refineList: string[];
  list_id: string;
}

export enum AgendaItemTypes {
  meetingItems = "meetingItems",
  actionItemReview = "actionItemReview",
  metricReview = "metricReview",
  question = "question",
  ideas_and_organize = 'ideas_and_organize',
  not_started = "not_started",
  normal = "normal",
  ideas_vote_refine="ideas_vote_refine"
}

export enum CollabAgendaItemTypes {
  discussion = "discussion",
  announcements = "announcements",
}

export enum MeetingStateType {
  active = "active",
  complete = "complete",
  pending = "pending",
}

export interface UserDataSettings {
  firstName: string;
  lastName: string;
  account_id: Array<string>;
  streams?: string[];
}

export interface UserData extends UserDataSettings, Doc {}

export interface AccountData extends Doc {
  accountName: string;
  size: string;
  industry: string;
  admins: Array<string>;
  users: Array<string>;
  activated: boolean;
  trialEndDate?: Date;
  trialReminderDate?: Date;
  action_item_integration?: {
    type: 'asana';
  }
}

export interface UserInviteSettings {
  email: string;
  account_id: string;
  type: "user" | "admin";
  complete: boolean;
}

export interface UserInvite extends UserInviteSettings, Doc {}

export interface ProfileInfo {
  id: string;
  profile: string;
}

export interface MetricSettings {
  account_id: string;
  owner_id: string;
  name: string;
  meeting_id?: string;
  type: MetricType;
}

export enum MetricType {
  target = "target",
  question = "question",
}

export interface Metric extends MetricSettings, Doc {}

export interface TargetMetricSettings extends MetricSettings {
  description: string;
  target: number;
  targetDuration: TargetDuration;
  targetType: TargetedMetricType;
  team: string;
  who: string;
  type: MetricType.target;
  day?: number;
}

export interface TargetMetric extends TargetMetricSettings, Doc {}

export interface DataSettings {
  account_id: string;
  metric_id: string;
  data: any;
  date: Date;
  who: string | null;
  team_id: string;
  meeting_id?: string;
}

export interface QuestionDataSettings extends DataSettings {
  data: {
    user_id: string;
    answer: string;
  };
  meeting_id: string;
}

export interface Data extends DataSettings, Doc {}
export interface QuestionData extends Data, Doc {
  data: {
    user_id: string;
    answer: string;
  };
  meeting_id: string;
}

export interface TeamSettings {
  account_id: string;
  name: string;
  members: Array<string>;
}

export interface Team extends TeamSettings, Doc {}

export enum TargetedMetricType {
  number = "number",
  percentage = "percentage",
  currency = "currency"
}

export enum TargetDuration {
  weekly = "weekly",
  monthly = "monthly",
}

// Options

export enum MeetingItemCardOptions {
  personal = 'personal',
  select = 'select',
  resolve = 'resolve',
  vote = 'vote',
  team_mate = 'team_mate',
  delete = 'delete',
  no_options = 'no_options',
  edit = 'edit'
}

// Lists & Boards


export interface ListItem {
  item_id: string;
  owner_id: string;
}
export interface ItemListSettings {
  location_id: string; // anything that has a list needs to have a unique id that corresponds with this.
  name?: string;
  items: string[];
  account_id?: string;
  meeting_id?: string;
}

export interface ItemList extends ItemListSettings, NewDoc {}

// Streams
export interface StreamSettings {
  name: string;
  lists: string[]; // all of the meetingItem lists pertaining to this stream
  admins: string[]; // can only be users
  subscribers: string[]; // can be a team_id, account_id, user_id
  account_id?: string;
  agenda?: { // if an agendaTemplate was used which one
    name: string;
    agendaItems: MeetingAgendaItem[]
  }; 
  
}

export enum StartMeetingTypes {
  default = 'default',
  quick_meeting = 'quick_meeting',
}

export interface Stream extends StreamSettings, Doc {}

export interface MeetingStateSettings {
  name: string;
  account_id: string;
  facilitator: Array<string>;
  stream_id: string;
  state: MeetingStateType;
  currentAgendaItem: MeetingAgendaItem;
  currentDiscussionItem: MeetingItem | undefined;
  currentActionItem: ActionItem | undefined;
  currentAnnouncement: MeetingItem | undefined;
  currentDiscussionRound: DiscussionRoundTypes;
  currentMetric: Metric | undefined;
  agendaItems: Array<MeetingAgendaItem>;
  notes: Array<string>;
  attendees: Array<string>;
  timer: TimerState | undefined;
  sortState?: MeetingSortState;
  demo?: boolean;
  startedAt:number|undefined;
}

export interface MeetingState extends Doc, MeetingStateSettings {}

// Stream Event 

export interface StreamEventSettings {
  account_id: string;
  stream_id: string;
  type: 'stream';
  meeting_id?: string;
  user_id?: string | string[] // the user this event relates to NOT it's owner
  item_id?: string // the item this event relates to.
  event: StreamEventTypes;
}

export interface MeetingStreamEventSettings {
  account_id: string;
  stream_id: string;
  type: 'meeting';
  meeting_id: string;
  user_id?: string | undefined // the user this event relates to NOT it's owner
  item_id?: string | undefined// the item this event relates to.
  event: MeetingStreamEventTypes;
  title?: string
}

export interface StreamEvent extends Doc, StreamEventSettings {}
export interface MeetingStreamEvent extends Doc, MeetingStreamEventSettings {}
export interface MeetingState extends Doc, StreamEventSettings {}

export interface ItemComment {
  owner_id: string;
  time: Date;
  comment: string;
  name?: string;
  initial?: string;
}

export enum StreamEventTypes {
  stream_created = 'stream_created',
  joined_stream = 'joined_stream',
  left_stream = 'left_stream',
  meeting_held = 'meeting_held'
}

export enum MeetingStreamEventTypes {
  meeting_started = 'meeting_started',
  meeting_ended = 'meeting_ended',
  item_selected = 'item_selected',
  item_resolved = 'item_resolved',
  item_created = 'item_created',
  decision_created = 'decision_created',
  action_created = 'action_created',
  action_deleted = 'action_deleted',
  decision_deleted = 'decision_deleted',
  action_edited = 'action_edited',
  decision_edited = 'decision_edited',
  agenda_move_forward = 'agenda_move_forward',
  agenda_move_backward = 'agenda_move_backward',
  list_created = 'list_created',
  list_title_edited = 'list_title_edited',
  action_changed = 'action_changed',
  decision_changed = 'decision_changed'
}
