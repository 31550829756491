import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import FormInputField from '../../../components/FormInputField/FormInputField';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import FormSelectField from '../../../components/FormSelectField/FormSelectField';
import { industryOptions, companySizeOptions } from '../../../helpers/formHelpers';

interface FuncProps {
    initialData: {
        accountName: string;
        industry: string;
        size: string;
    };
    submit: (values: any) => void;
}

const validation = Yup.object().shape({
    accountName: Yup
        .string()
        .min(3, 'at least 3 chars')
        .required('Please enter your email'),
    industry: Yup
        .mixed()
        .required('Please select an industry'),
    size: Yup
        .mixed()
        .required('Please select your size'),
});

const CompanyInfo: React.FC<FuncProps> = ({initialData, submit}) => <Formik 
    initialValues={initialData}
    onSubmit={(values, actions) => {
        submit(values);
        actions.resetForm();
        actions.setSubmitting(false)
        // history.push('/please-verify-your-email');
    }}
    validationSchema={validation}
    >
    {({ isSubmitting, errors }) => (
        <Form style={{marginLeft: '4%', marginRight: '4%'}}>
            <span style={{textAlign: 'center'}}>
                <Typography variant="h6">Set up your account</Typography>
                <Typography variant="body2" color="textSecondary" style={{marginBottom: "10px"}}>Company Info</Typography>
            </span>
            <FormInputField name="accountName" label="Organization Name" type="text" md={12}/>
            <FormSelectField name="industry" label="Industry" md={12} options={industryOptions}/>
            <FormSelectField name="size" label="Org Size" md={12} options={companySizeOptions}/>
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button 
                    type="submit" 
                    disabled={isSubmitting || !isEmpty(errors)} 
                    size="large" 
                    color="primary" 
                    variant="contained"
                >
                    Create Account
                </Button>
                <Grid item xs={12}>
                    <Typography variant="overline">Page 2 of 2</Typography>
                </Grid>
            </Grid>
        </Form>
    )}
</Formik>

export default CompanyInfo;