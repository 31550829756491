import React from "react";
import { Grid, Typography } from "@material-ui/core";
import MeetingItemCardList from '../List/MeetingItemCardList';
import { MeetingItemCardOptions, MeetingItem } from '../../declarations/declarations';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import './index.scss';
  interface StateProps {
    lists: string[];
    name: string;
    width?: string;
    options? : MeetingItemCardOptions;
    buttonFunction?: (item: MeetingItem) => void;
    idPrefix: string;
    isVoting?: boolean;
    isVotedItemId?: string | undefined;
  }
  
  type Props = StateProps;
  
  const MeetingItemHorizonalCollection: React.FC<Props> = ({
    lists,
    name,
    options,
    width,
    buttonFunction,
    idPrefix,
    isVoting,
    isVotedItemId
  }) => {
    const [addItem, setAddItem] = React.useState<string>('');
    return (
      <Grid container id={`${idPrefix}-horizontal-list-collection`}>
        {name && <Grid container md={12} className="meeting-item-container">
            <DescriptionOutlinedIcon className="icon" />
            <Typography className="bold-text text">
                {name}
            </Typography>
        </Grid>}
        <Grid style={{overflowX: 'auto', display: 'flex', width: '100%', paddingBottom: '30px'}}>
          {lists.length > 1 && lists.map((list_id, index) => <Grid className="meeting-list-background" 
          style={{ flexShrink: 0, marginRight: '20px'}}>
            <MeetingItemCardList
              options={options as MeetingItemCardOptions} 
              list_id={list_id} 
              listUiId={`${idPrefix}-list-${index}`}
              buttonFunction={buttonFunction}
              width={width ? width : undefined}
              HideAddIcon={isVoting ? isVoting : false}
              isVoting={isVoting}
              isVotedItemId={isVotedItemId}
              addItem={addItem}
              setAddItem={setAddItem}
            />
          </Grid>)}
          {lists.length === 1 &&
             <Grid item className="meeting-list-background" style={{ width: '350px' }}>
               <MeetingItemCardList
                options={options as MeetingItemCardOptions} 
                list_id={lists[0]}
                listUiId={`${idPrefix}-list-0`} 
                buttonFunction={buttonFunction}
                HideAddIcon={isVoting ? isVoting : false}
                isVoting={isVoting}
                isVotedItemId={isVotedItemId}
                addItem={addItem}
                setAddItem={setAddItem}
              /></Grid>}
        </Grid>
      </Grid>
    );
  };

  export default MeetingItemHorizonalCollection;
  
