import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { MeetingItem, MeetingItemCardOptions } from "../../declarations/declarations";
// import BaseCard from "./components/BaseCard";
import { SystemState } from "../../store/store";
import { connect } from "react-redux";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { BSON } from "mongodb-stitch-browser-sdk";
import IdeaItemCard from "../MeetingItemCard/components/IdeaItemCard";

interface FuncProps {
  item_id: string;
}

interface StateProps {
  items: MeetingItem[]
}

interface DispatchProps {
  getItem: (query: object) => void;
}

type Props = FuncProps & StateProps & DispatchProps

const IdeaItemCardComponent: React.FC<Props> = ({ item_id, items, getItem }) => {

  const item = items.filter(item => item._id.toHexString() === item_id)[0]
  React.useEffect(() => {
    if (!items.filter(item => item._id.toHexString() === item_id)[0]) {
      getItem({_id: new BSON.ObjectId(item_id)})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return !item ? <CircularProgress color="secondary" /> :
      <Grid>
          <IdeaItemCard item={item} />
          {/* <IdeaComponent item={item} /> */}
      </Grid>
};

const mapStateToProps = (state: SystemState ): StateProps => {
  return {
      items: state.data.meetingItems as MeetingItem[]
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
      getItem: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(IdeaItemCardComponent);
