import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import { SystemState } from '../../../../store/store';
import { connect } from 'react-redux';

interface StateProps {
    stateLoading: boolean
}

interface UserProps {
    loading?: boolean
}

type Props = StateProps & UserProps;

const LoadingDialog: React.FC<Props> = ({stateLoading, loading}) => <Dialog disableEnforceFocus={true} open={loading ? loading : stateLoading}>
    <DialogContent>
        <div style={{margin: 'auto'}}>
            <CircularProgress />
        </div>
    </DialogContent>
</Dialog>

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        stateLoading: state.app.loading
    }
}

export default connect(mapStateToProps)(LoadingDialog);