import {
    CircularProgress,
    Grid,
    TextField,
    Typography,
  } from "@material-ui/core";
  import React from "react";
  import { Droppable } from "react-beautiful-dnd";
  import { connect } from "react-redux";
  import IdeaOrgItemCard from "./IdeaOrgItemCards";
  import { ItemList, MeetingItem } from "../../../../../../../../declarations/declarations";
  import { SystemState } from "../../../../../../../../store/store";
  import { bindActionCreators } from "redux";
  import { ThunkDispatch } from "redux-thunk";
  import { AppStateActionTypes } from "../../../../../../../../store/actionTypes";
  import { MeetingItemsReduxer } from "../../../../../../../../store/reduxer";
  import { BSON } from "mongodb-stitch-browser-sdk";
  import { getLists } from "../../../../../../../../store/lists/listsActionCreator";
  import { FilterListTwoTone } from "@material-ui/icons";
  import '../index.scss';
  import {setListName} from '../../../../../../../../store/lists/listsActionCreator';
  
  interface FuncProps {
    list_id: string;
    name?: string;
    listUiId: string;
  }
  
  interface StateProps {
    lists: ItemList[];
    meetingItems: MeetingItem[];
  }
  
  interface DispatchProps {
    getLists: (query: object) => void;
    getItems: (query: object) => void;
  
  }
  
  type Props = FuncProps & StateProps & DispatchProps;
  
  const IdeaOrgItemCardList: React.FC<Props> = ({
    list_id,
    lists,
    getItems,
    getLists,
    name,
    listUiId,
  }) => {
    const [load, setLoad] = React.useState(false);
    let theList = lists.filter((list) => {
      return list._id.toHexString() === list_id || list.location_id === list_id;
    })[0];
    const [isOpenList, setOpenList] = React.useState<boolean>(false);

    let myList: ItemList | void;
    React.useEffect(() => {
      if (!theList) {
        (async () => {
          myList = await getLists({
            location_id: list_id,
          });
        })();
      }
      if (theList) {
        (async () => {
          await getItems({
            _id: { $in: theList.items.map((item) => new BSON.ObjectId(item)) },
          });
        })();
      } else if (myList) {
        (async () => {
          await getItems({
            _id: { $in: myList.items.map((item) => new BSON.ObjectId(item)) },
          });
        })();
      }
      setLoad(true);
    }, [theList]);
  
    
    const BlankState: React.FC = () => (
      <Grid item xs={12} style={{ textAlign: "center", marginTop:"20px" }}>
        <Typography className="gray3 body-text">{`You have no items in this list`}</Typography>
      </Grid>
    );
  
    const ErrorState: React.FC = () => (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography className="bold-text gray3">{`Oops! Something went wrong, please contact `}</Typography>
        <Typography
          style={{ cursor: "pointer" }}
          className="bold-text gray3"
          // variant="h6"
          id="my_custom_link"
        >{`Support`}</Typography>
      </Grid>
    );
  
    const ListNameChange = (e : any) => {
      let name = e.target.value;
      setListName(name, theList._id.toHexString());
    }

      return (<Grid id={listUiId}  className = "droppable-container">
        <Grid container >
            {name && <Typography className="body-text title gray3 list-name-truncated">{name}</Typography>}
            {!name && theList && theList.name &&
            <input 
              id={`${listUiId}-nameInput`}
              className={` body-text title gray3 list-name-truncated `}
              defaultValue={theList.name}
              onBlur= {(e) => ListNameChange(e)}
            />}
            {theList && theList.items && theList.items.length > 0 && <Grid className="card-count gray4-background">
              <Typography className="white">
                {`${theList.items.length} ideas`}
              </Typography>
            </Grid>}
            <FilterListTwoTone className="gray5 filter-icon" onClick={() => setOpenList(!isOpenList)}/>
        </Grid>
        <Grid style={{position: 'relative'}}>
          <Droppable droppableId={theList ? theList.location_id : ""}>
            {(provided) => (
              <Grid
                {...provided.droppableProps}
                innerRef={provided.innerRef}
                className="dropable-list"
              >
                {!load ? (
                  <CircularProgress color="secondary" />
                ) : theList && theList.items.length > 0 ? (
                  theList.items.map((id, i) => (
                    <Grid >
                      <IdeaOrgItemCard
                        listUiId={listUiId}
                        item_id={id}
                        index={i}
                        isOpenList={isOpenList}
                      />
                    </Grid>
                  ))
                ) : theList && theList.items.length === 0 ? (
                  <BlankState />
                ) :
                 theList || myList ? null : (
                  <ErrorState />
                )}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </Grid>
      </Grid>
    );
  };
  
  const mapStateToProps = (state: SystemState): StateProps => {
    return {
      lists: state.lists.lists,
      meetingItems: state.data.meetingItems as MeetingItem[],
    };
  };
  
  const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, AppStateActionTypes>
  ): DispatchProps => {
    return {
      //@ts-ignore
      getLists: bindActionCreators(getLists, dispatch),
      getItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch)
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(IdeaOrgItemCardList);
  