import React from 'react';
import { Grid } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import {Field} from 'formik';
import { InputAdornment } from '@material-ui/core';

interface FuncProps {
    label?: string;
    name: string;
    type: "number" | "date" | "text" | "password" | "textarea";
    inputProps?: "$" | "%" ;
    labelProps?: "shrink"
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?:  1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    array?: string
    position?: number;
    required?: boolean;
    multiline?: boolean;
    disabled?: boolean;
}

const getInputProps = (inputProps: "$" | "%" | "shrink" | undefined ) => {
    switch (inputProps) {
        case '$':
            return {
                min: 0,
                startAdornment: <InputAdornment position="start">$</InputAdornment>
            };
        case '%':
            return {
                min: 0,
                endAdornment: <InputAdornment position="end">%</InputAdornment>
            };
        default: 
            return;
    }
}

const getLabelProps = (labelProps: "shrink" | undefined) => {
    switch(labelProps) {
        case 'shrink':
            return {
                shrink: true
            };
        default: 
            return;
    }
}

const FormInputField: React.FC<FuncProps> = ({
    label,
    name, 
    type, 
    inputProps, 
    labelProps, 
    xs, 
    md, 
    array, 
    position, 
    required, 
    multiline, 
    disabled
}) => <Grid item xs={xs ? xs : 12} md={md ? md : 12} >
    <Field
        name={!array ? name : `${array}[${position}].${name}`} 
        label={label ? label : null} 
        required={required}
        type={type}
        variant="outlined" 
        fullWidth  
        InputProps={getInputProps(inputProps)}
        InputLabelProps={getLabelProps(labelProps)}
        margin="normal"
        multiline={multiline}
        rows={multiline ? "2" : undefined}
        disabled={disabled}
        size="small"
        component={TextField}/>
</Grid>

export default FormInputField;