import React from 'react';
import { ListSubheader } from '@material-ui/core';
import { DrawOptions } from './options';
import DrawListOption from './DrawListOption';

interface FuncProps {
    title?: string;
    toggleDrawer: () => void;
    options: Array<DrawOptions>
}

const DrawListSection: React.FC<FuncProps> = ({title, toggleDrawer, options}) => <React.Fragment>
    {title ? <ListSubheader className="gray4 pre-title" style={{margin:"24px 0px"}}>{title}</ListSubheader> : null}
    {options.map(option => <DrawListOption icon={option.icon} key={option.name} id={option.id}  toggleDrawer={toggleDrawer} name={option.name} link={option.link}/>)}
</React.Fragment>

export default DrawListSection;