import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MetricAddButton from './components/MetricAddButton';
import { connect } from 'react-redux';
import { SystemState } from '../../store/store';
import { TargetMetric, MetricType, AccountData } from '../../declarations/declarations';
import BlankState from './components/BlankState';
import MetricCard from '../../components/MetricCard/MetricCard';
import AddEditMetricDialog from './components/AddEditMetricDialog/index';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { 
    MetricTypeReduxer, 
} from '../../store/reduxer';

import "./index.scss";

interface StateProps {
    metrics: Array<TargetMetric>;
    accountData: AccountData
}

interface DispatchProps{
    getMetrics: (query: any) => void;
}

type Props = StateProps & DispatchProps;

const MetricPage: React.FC<Props> = ({metrics, getMetrics, accountData}) => {


    React.useEffect(() => {
        (async () => {
            await getMetrics({account_id: accountData._id.toHexString()});   
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [DialogState, setDialogState] = React.useState<boolean>(false);

    return <Grid container>
        {metrics.length > 0 ? <React.Fragment>
            <Typography variant="h4" style={{color: 'white', marginBottom: '10px'}}>Metrics</Typography>
            {metrics.filter(met => met.type === MetricType.target).map(metric => <MetricCard key={metric._id.toString()} metric={metric}/>)}
        </React.Fragment> : <BlankState/>}
        <AddEditMetricDialog DialogState={DialogState} setDialogState={setDialogState} action={"add"}></AddEditMetricDialog>
        <MetricAddButton setDialogState={setDialogState}/>
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        metrics: state.data.metrics,
        accountData: state.data.accountData[0],
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        getMetrics: bindActionCreators(MetricTypeReduxer.getFiltered, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricPage);