import React from 'react';
import StreamsCard from './components/StreamCard';
import { Grid, Button, Typography } from '@material-ui/core';
import { SystemState } from '../../store/store';
import { connect } from 'react-redux';
import { AppStateActionTypes } from '../../store/actionTypes';
import { updateSubscribeDialogState } from '../../store/actionCreators';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AddFabButton from '../../components/AddFabButton/AddFabButton';

interface StateProps {
    streams: string[] | undefined;
}

interface DispatchProps {
    updateDialogStreamState: (payload: boolean) => void;
}

type Props = StateProps & DispatchProps

const StreamsListPage: React.FC<Props> = ({streams, updateDialogStreamState}) => {

    return <Grid container style={{margin: 'auto', justifyContent: 'center'}}>
        {streams ? 
            streams.map(id => <StreamsCard stream_id={id} key={id} />) : 
            <Grid style={{textAlign: 'center', marginTop: '50px'}} item xs={12}><Typography variant="h5">You are not subscribed to any streams. Click below to set up a stream</Typography></Grid>}
        <Grid 
            item xs={12} 
            style={{
                textAlign: 'center', 
                margin: '20px'
            }}
        >
            <Button 
                size="large" 
                variant="contained" 
                color="secondary"
                onClick={() => updateDialogStreamState(true)}
            >
                Subscribe to New Stream
            </Button>
            <AddFabButton addRoute="/meetings/streams/add"/>
        </Grid>
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        streams: state.data.userData ? state.data.userData[0].streams : undefined 
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppStateActionTypes>): DispatchProps => {
    return {
        updateDialogStreamState: bindActionCreators(updateSubscribeDialogState, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamsListPage);