import React from "react";
import { Grid } from "@material-ui/core";
//import ItemsPageButton from "./components/ItemsPageButton/ItemsPageButton";
import { connect } from "react-redux";
import { SystemState } from "../../store/store";
import { Stream } from "../../declarations/declarations";
import BlankState from "./components/BlankState/BlankState";
import IndividualStream from "./components/IndividualStream";

interface StateProps {
  streams: Stream[];
}

const MeetingItemsPage: React.FC<StateProps> = ({ streams }) => {

  return (
    <Grid container style={{ height: "inherit" }}>
      {streams.length > 0 ? streams.map(stream => <IndividualStream key={stream._id.toHexString()} stream={stream} />): <BlankState />}
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    streams: state.data.streams
  };
};

export default connect(mapStateToProps)(MeetingItemsPage);
