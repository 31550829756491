import React from 'react';
import { ListSubheader, Grid } from '@material-ui/core';
import DrawListOption from './DrawListOption';
import { streamMenuOptions } from '../../../../helpers/helpers'
import AddIcon from '@material-ui/icons/Add';
import AddStreamsDialog from '../../Dialogs/AddStreamsDialog/AddStreamsDialog';
import { getAllUserNames } from '../../../../stitch/app';

interface FuncProps {
    toggleDrawer: () => void;
    state:boolean;
}

const StreamDrawListSection: React.FC<FuncProps> = ({toggleDrawer, state}) => {
    const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);
    const [dialogState, setDialogState] = React.useState<boolean>(false);
    React.useEffect(() => {
        (async () => {
          const users = await getAllUserNames();
          setAllUsers(users);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {state && <ListSubheader component="div"color="primary" id="stream-list" className="gray4 pre-title " style={{marginTop:"40px", marginBottom:"24px"}}>
               <Grid className="inline">
                STREAMS
                </Grid>
            <AddIcon 
                onClick={() => setDialogState(true)}
                style={{ position: 'absolute',
                right: '10%',
                top: '16px',
                fontSize: '14px',
                cursor: 'pointer' }} 
                className="inline"/>
            </ListSubheader>}
            {allUsers && dialogState && <AddStreamsDialog setState={setDialogState} state={dialogState} allUsers={allUsers} toggleDrawer={toggleDrawer}/>}
            {streamMenuOptions().map(option => <DrawListOption key={option.name} id={option.id}  toggleDrawer={toggleDrawer} name={option.name} link={option.link}/>)}
        </React.Fragment>
    )
}

export default StreamDrawListSection;