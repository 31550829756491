import React from 'react';
import { Button } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../../store/actionTypes';
import { deleteItemState } from '../../../../store/actionCreators';

interface FuncProps {
    cardId: string;
}

interface DispatchProps {
    setDelete: (payload: {state: boolean, itemId: string}) => void;
}

type Props = DispatchProps & FuncProps


const DeleteOption: React.FC<Props> = ({cardId, setDelete}) => {
    const del = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        setDelete({state: true, itemId:cardId});
    }
    return <React.Fragment>
        <Button
            id={`${cardId}-delete-button`}
            onClick={(event) => del(event)}
            className="accent-red card-button pre-title"
        >
            DELETE
        </Button>
    </React.Fragment>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
    return {
        setDelete: bindActionCreators(deleteItemState, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(DeleteOption);
