import stitchApp from "../stitch/app"
import store from "../store/store"
import { capitalize } from 'lodash';
import { 
    MeetingStateType, 
    AgendaItemTypes, 
    DiscussionRoundTypes, 
    AgendaItem, 
    MeetingStateSettings, 
    MeetingAgendaItem, 
    StartMeetingTypes, 
    ItemListSettings, StreamEventTypes, StreamEventSettings
 } from "../declarations/declarations";
import { updateStartMeetingState, updateSubscribeDialogState } from "../store/actionCreators";
import { BSON } from "mongodb-stitch-browser-sdk";
import { ListsReduxer } from "../store/reduxer";
import { DrawOptions } from "../layout/components/MenuDrawer/components/options";
import moment from "moment";

export const redirectIfCriteriaMet = (criteria: any, history: any, link: string) => {
    if (criteria) {
        history.push(link)
    }
}

export const isAccountOwner = () => {
    if (stitchApp.auth.user) {
        if (stitchApp.auth.user.id === store.getState().data.accountData[0].owner_id) {
            return true
        } else return false;
    } else {
        return false
    }
}

export const getInitials = () => {
    const userData = store.getState().data.userData[0];
    if (!store.getState().data.userData[0]) {
        return null
    } else {
        return `${capitalize(userData.firstName.charAt(0))}${capitalize(userData.lastName.charAt(0))}`
    }
}

export const getAccountId = () => {
    if (store.getState().data.userData.length > 0) {
        return store.getState().data.userData[0].account_id[0];
    } else {
        return null;
    }
}

export const getUserId = () => {
    if (store.getState().data.userData.length > 0) {
        return store.getState().data.userData[0].owner_id;
    } else {
        return null;
    }
    
}

export const getThisUserName = () => {
    if (store.getState().data.userData[0]) {
        return `${store.getState().data.userData[0].firstName} ${store.getState().data.userData[0].lastName}`
    } else {
        return '';
    }
}

export const getTeamName = (teamId: string) => {
    if (store.getState().data.teams.filter(team => team._id.toString() === teamId)[0]) {
        return store.getState().data.teams.filter(team => team._id.toString() === teamId)[0].name
    } else {
        return teamId;
    }
}

export const getStreamName = (stream_id: string) => {
    if (store.getState().data.streams.filter(stream => stream._id.toString() === stream_id)[0]) {
        return store.getState().data.streams.filter(stream => stream._id.toString() === stream_id)[0].name
    } else {
        return stream_id;
    }
}

export const getItem = (item_id: string) => {
    const result = store.getState().data.meetingItems.filter(item => item._id.toString() === item_id)[0]
    if (result) {
        return result
    } else {
        return new Error(`no item found ${item_id}`)
    }
}

export const orgArrayAlphabetically = (array: any[], property: string): any[] => {

    const returnArray = [...array]

    returnArray.sort((a, b) => {
        var textA = a[property].toUpperCase();
        var textB = b[property].toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return returnArray
}

export const sortByDate = (array: any[]): any[] => {
    
    array.sort((a, b) => {
        return b._id.getTimestamp() - a._id.getTimestamp();
    });

    return array;
}

export const makeOutcomeNewList = async (id: string, meeting_id: string) => {
        const newList: ItemListSettings = {
            location_id: id,
            account_id: getAccountId() as string,
            items: [],
            meeting_id: meeting_id
        }
        //@ts-ignore
        await store.dispatch(ListsReduxer.post(newList));
}

export const createMeetingStateSimple = (stream_id: string, meetingType: AgendaItemTypes, fromStream: boolean) => {
    debugger
    const makeNewList = async (agendaItem_id: string) => {
        const newList: ItemListSettings = {
            location_id: agendaItem_id,
            account_id: getAccountId() as string,
            items: []
        }
        //@ts-ignore
        store.dispatch(ListsReduxer.post(newList));
    }

    const meetingItemAgendaItem = {
        _id: fromStream ? stream_id : new BSON.ObjectID().toHexString(),
        type: AgendaItemTypes.meetingItems,
        name: "Discussion Round",
        description: 'Please add items that you want to discuss',
        voting: true
    } as MeetingAgendaItem

    const actionItemReviewAgendaItem = {
        _id: new BSON.ObjectID().toHexString(),
        type: AgendaItemTypes.actionItemReview,
        name: "Action Item Review",
        description: `Review this stream's action items`,
    } as MeetingAgendaItem

    const ideaOrganizeAgendaItem = {
        _id: new BSON.ObjectID().toHexString(),
        type: AgendaItemTypes.ideas_and_organize,
        name: "Idea and Organize",
        description: `Collaborate on your ideas`,
        state: {
            round: 'ideas',
            ideaLists: [],
            organizeLists: []
        }
    } as MeetingAgendaItem

    let agenda = [];

    switch (meetingType) {
        case AgendaItemTypes.meetingItems: 
            agenda = [meetingItemAgendaItem]
            break;
        case AgendaItemTypes.actionItemReview:
            agenda = [actionItemReviewAgendaItem, meetingItemAgendaItem]
            break;
        case AgendaItemTypes.ideas_and_organize:
            agenda = [ideaOrganizeAgendaItem, meetingItemAgendaItem]
            break;
        default: 
            agenda = [meetingItemAgendaItem]
            break;
    }

    if (!fromStream) {
        makeNewList(meetingItemAgendaItem._id)
    }

    const newMeeting: MeetingStateSettings = {
        name: `${moment(new Date()).format('DD MMM: ')} Meeting`,
        account_id: getAccountId() as string,
        stream_id,
        state: MeetingStateType.pending,
        currentAgendaItem: {
            _id: new BSON.ObjectID().toHexString(),
            name: "",
            description: "",
            type: AgendaItemTypes.not_started,
        },
        currentAnnouncement: undefined,
        currentActionItem: undefined,
        currentDiscussionItem: undefined,
        currentMetric: undefined,
        currentDiscussionRound: DiscussionRoundTypes.select,
        attendees: [getUserId() as string],
        agendaItems: agenda,
        facilitator: [getUserId() as string],
        notes: [] as Array<string>,
        timer: undefined,
        startedAt: Date.now(),
        };

    return newMeeting
}

export const createMeetingState = (stream_id: string, agenda_id: string) => {

    const stream = store.getState().data.streams.filter(s => s._id.toHexString() === stream_id)[0]
    const quickTitle = `${stream.name}: Quick Meeting`

    const quickAgendaItem = {
        type: AgendaItemTypes.meetingItems,
        name: quickTitle,
        description: 'Please add items that you want to discuss',
        voting: true
    } as AgendaItem

    const makeNewList = async (agenda: MeetingAgendaItem[]) => {
        agenda.forEach(item => {
            const newList: ItemListSettings = {
                location_id: item._id,
                account_id: getAccountId() as string,
                items: []
            }
            //@ts-ignore
            store.dispatch(ListsReduxer.post(newList));
        })
    }

    const createAgendaItems = (): MeetingAgendaItem[] => {
        switch (agenda_id) {
            case StartMeetingTypes.default:
                const streamAgenda = stream.agenda?.agendaItems
                if (streamAgenda && streamAgenda.length > 0) {
                    return streamAgenda;
                } else { 
                    const defaultAgendaReturn = [{
                        ...quickAgendaItem,
                        _id: stream_id
                    }]
                    return defaultAgendaReturn;
                } 
            case StartMeetingTypes.quick_meeting:
                const quickAgendaReturn = [{
                    ...quickAgendaItem,
                    _id: new BSON.ObjectId().toHexString()
                }]
                makeNewList(quickAgendaReturn);
                return quickAgendaReturn;
            default:
                const MeetingAgendaItems = store.getState().data.meetingTemplates
                    .filter(agenda => agenda._id.toHexString() === agenda_id)[0].agendaItems
                    .map(item => {
                        return {
                            ...item,
                            _id: new BSON.ObjectId().toHexString()
                        }
                    });
                makeNewList(MeetingAgendaItems);
                return MeetingAgendaItems;
        }
    }

    const getTemplateName = () => {

        switch (agenda_id) {
            case StartMeetingTypes.quick_meeting:
                return quickTitle
            case StartMeetingTypes.default:
                const defaultName = store.getState().data.streams
                .filter(stream => stream._id.toHexString() === stream_id)[0].agenda?.name
                
                if (defaultName) {
                    return defaultName
                } else return quickTitle
            default: 
                return store.getState().data.meetingTemplates
                .filter(agenda => agenda._id.toHexString() === agenda_id)[0].name

        }
    } 

    const agendaItems = createAgendaItems()

    const newMeeting: MeetingStateSettings = {
        name: getTemplateName(),
        account_id: getAccountId() as string,
        stream_id,
        state: agenda_id !== StartMeetingTypes.quick_meeting ? MeetingStateType.pending : MeetingStateType.active,
        currentAgendaItem: agenda_id !== StartMeetingTypes.quick_meeting ? {
            name: "",
            description: "",
            type: AgendaItemTypes.not_started,
        } as MeetingAgendaItem : agendaItems[0],
        currentAnnouncement: undefined,
        currentActionItem: undefined,
        currentDiscussionItem: undefined,
        currentMetric: undefined,
        currentDiscussionRound: DiscussionRoundTypes.select,
        attendees: [getUserId() as string],
        agendaItems: agendaItems,
        facilitator: [getUserId() as string],
        notes: [] as Array<string>,
        timer: undefined,
        startedAt: agenda_id !== StartMeetingTypes.quick_meeting ? undefined : Date.now(),
        };
    
    return newMeeting;

}

export const helperUpdateStartMeetingState = (state: boolean, stream_id?: string) => {
    store.dispatch(updateStartMeetingState({state, stream_id}));
}

export const helperUpdateStreamDialogState = (payload: boolean) => {
    store.dispatch(updateSubscribeDialogState(payload));
}

export const findAgendaTemplateByItemIndex = (index_id: string) => {
    // const result: MeetingTemplate[] = [];

    // store.getState().data.meetingTemplates
    //     .filter(agenda => agenda.agendaItems
    //         .forEach(item => {
    //             if (item._id === index_id) {
    //                 return result.push(agenda);
    //             }
    //         }));
    // return result[0];
}

export const streamMenuOptions = (): DrawOptions[] => {
    if (store.getState().data.userData[0]) {
        return store.getState().data.streams.map(stream => { return {
            name: stream.name, 
            id: `menu_${stream.name}`, 
            link: `/meetings/streams/view/${stream._id.toHexString()}`
        }});
    } else {
        return [];
    }
    
}

export const getStream = (id: string) => {
    const list_id = getList(id);
    let stream = 'No Stream';
    if (id && id !== '') {
        store.getState().data.streams.forEach((ele: any) => {
            if (ele.lists) {
                ele.lists.forEach((listId: string) => {
                    if (list_id === listId) {
                        stream = ele.name;
                    }
                });
            }
        }); 
    }

    return stream;
}

export const getList = (id: string) => {
    let list = '';
    store.getState().lists.lists.forEach((ele: any) => {
        if (ele.items) {
            ele.items.forEach((item_id: string) => {
                if (item_id === id) {
                    list = ele._id.toString();
                }
            });
        }
    });
    return list;
}

export const createStreamEvent = (
    type: StreamEventTypes, 
    stream_id: string, 
    user_id?: string,
    item_id?: string
) => {
    const event: StreamEventSettings = {
        account_id: getAccountId() as string,
        stream_id,
        type: 'stream',
        event: type,
        user_id,
        item_id
    };
    //@ts-ignore
    store.dispatch(StreamEventReduxer.post(event));
}
