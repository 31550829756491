import React from 'react';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserDataReduxer } from '../../../store/reduxer'; 
import { SystemState } from '../../../store/store';
import { UserData } from '../../../declarations/declarations';

interface DispatchProps {
    updateUserData: (item: UserData) => void;
}

interface StateProps {
    userData: UserData;
}

type Props = DispatchProps & StateProps;

const validation = Yup.object().shape({
    firstName: Yup
        .string()
        .required('Please enter your first name'),
    lastName: Yup
        .string()
        .required('Please enter your last name')
});

const SettingsPersonalInfo: React.FC<Props> = ({updateUserData, userData}) => {

    const [disabled, setDisabled] = React.useState(true);

    return <Formik 
            initialValues={userData}
            onSubmit={async (values, actions) => {
                updateUserData(values);
                actions.resetForm();
                actions.setSubmitting(false)
                setDisabled(true)
            }}
            validationSchema={validation}
            >
            {({ isSubmitting, errors }) => (
                <Card>
                    <CardContent>
                        <Form>
                            <Grid container>
                                <FormInputField name="firstName" label="First Name" type="text" md={6} disabled={disabled}/>
                                <FormInputField name="lastName" label="Last Name" type="text" multiline md={6} disabled={disabled}/>
                                <Grid item xs={12} style={{textAlign: 'right'}}>
                                    {disabled ? 
                                        <Button 
                                            style={{marginRight: 0}}
                                            size="large" 
                                            color="primary"
                                            onClick={() => setDisabled(false)}
                                        >Edit
                                    </Button> : 
                                    <Button 
                                        style={{marginRight: 0}}
                                        type="submit" 
                                        disabled={isSubmitting || !isEmpty(errors)} 
                                        size="large" 
                                        color="primary"
                                    >Save</Button>}
                                </Grid>
                            </Grid> 
                        </Form>
                    </CardContent>
                </Card>
            )}
        </Formik>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        userData: state.data.userData[0]
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        updateUserData: bindActionCreators(UserDataReduxer.post, dispatch),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SettingsPersonalInfo);