import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {MeetingItem, MeetingItemSetting} from '../../../declarations/declarations';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import MeetingItemDialog from '../../MeetingDialog/MeetingItemDialog';

interface FuncProps {
    item : MeetingItem;
    parentItem ?: MeetingItem;
    currentItem?: Boolean;
}

type Props = FuncProps 

const CurrentDecisionItemCard: React.FC<Props> = ({item, parentItem, currentItem }) => {

    return <Grid container>
        <Grid className={(!currentItem ? "border-gray" : "border-blue") + " decision-container"}>
            <Grid className="decision-spawn-heading gray5 ">
                <Grid className="inline">
                    <SubdirectoryArrowRightIcon fontSize="small" />
                </Grid>
                <Grid className="inline ">
                    <Typography className="decision-spawn-what small-text">
                        {parentItem?.what}
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <Grid className="inline gray5 vertical-align-sub decision-card-icon">
                    <ThumbUpOutlinedIcon fontSize="small" />
                </Grid>
                <Grid className="inline ">
                    <Typography className="decision-spawn-what bold-text gray4">
                        {item.what}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="decision-why">
                    <Typography className="small-text gray4">
                    {typeof item.why === 'string' ? item.why : 
                 "Why created in draftjs"}
                    </Typography>
            </Grid>
        </Grid>
    </Grid>
}


export default CurrentDecisionItemCard ;