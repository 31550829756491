import * as actionNames from './actionTypes';
import { SessionTypes, StateTypes } from './actionTypes';
import { ProfileInfo, ItemList } from '../declarations/declarations';

// Register Account

export const registerAccountStarted = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_ACCOUNT_STARTED
    }
}

export const registerAccountFailed = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_ACCOUNT_FAILED
    }
}

export const registerAccountSuccess = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_ACCOUNT_SUCCESS
    }
}

// Register New User

export const registerUserStarted = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_USER_STARTED
    }
}

export const registerUserFailed = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_USER_FAILED
    }
}

export const registerUserSuccess = (): SessionTypes => {
    return {
        type: actionNames.REGISTER_USER_SUCCESS
    }
}

// Login 

export const loginStarted = (): SessionTypes => {
    return {
        type: actionNames.LOGIN_USER_STARTED
    }
}

export const loginFailed = (): SessionTypes => {
    return {
        type: actionNames.LOGIN_USER_FAILED
    }
}

export const loginSuccess = (): SessionTypes => {
    return {
        type: actionNames.LOGIN_USER_SUCCESS
    }
}

// Logout

export const logoutStarted = (): SessionTypes => {
    return {
        type: actionNames.LOGOUT_USER_STARTED
    }
}

export const logoutFailed = (): SessionTypes => {
    return {
        type: actionNames.LOGOUT_USER_FAILED
    }
}

export const logoutSuccess = (): SessionTypes => {
    return {
        type: actionNames.LOGOUT_USER_SUCCESS
    }
}

// Loading

export const startLoading = (): SessionTypes => {
    return {
        type: actionNames.START_LOADING
    }
}

export const stopLoading = (): SessionTypes => {
    return {
        type: actionNames.STOP_LOADING
    }
}

// Restore Session

export const restoreSession = (): SessionTypes => {
    return {
        type: actionNames.RESTORE_SESSION
    }
}

// Add Profile Info

export const addProfileInfo = (payload: ProfileInfo): StateTypes => {
    return {
        type: actionNames.ADD_PROFILE,
        payload
    }
}

// My Item Drawer

export const alternateMyItem = (): StateTypes => {
    return {
        type: actionNames.ALTERNATE_MYITEM_STATE
    }
}

export const openMyItem = (): StateTypes => {
    return {
        type: actionNames.OPEN_MYITEM
    }
}

export const hideStream = (payload: boolean): StateTypes => {
    return {
        type: actionNames.HIDE_STREAM,
        payload
    }
}

export const closeMyItem = (): StateTypes => {
    return {
        type: actionNames.CLOSE_MYITEM
    }
}

// Update Add Edit Item State

export const addEditItemState = (payload: {state: boolean, itemId?: string}) => {
    return {
        type: actionNames.UPDATE_ADDEDITITEMSTATE,
        payload
    }
}

export const moveItemState = (payload: {state: boolean, itemId?: string}) => {
    return {
        type: actionNames.UPDATE_MOVEITEMSTATE,
        payload
    }
}

export const deleteItemState = (payload: {state: boolean, itemId?: string}) => {
    return {
        type: actionNames.UPDATE_DELETEITEMSTATE,
        payload
    }
}

// Update Start Meeting State

export const updateStartMeetingState = (payload: {state: boolean, stream_id?: string}) => {
    return {
        type: actionNames.UPDATE_START_MEETING_STATE,
        payload
    }
}


// Update Subscribe Dialog State

export const updateSubscribeDialogState = (payload: boolean) => {
    return {
        type: actionNames.UPDATE_SUBSCRIBE_DIALOG_STATE,
        payload
    }
}


// Update Meeting State Lists

export const updateMeetingLists = (payload: ItemList[]) => {
    return {
        type: actionNames.UPDATE_MEETING_LISTS,
        payload
    }
}
