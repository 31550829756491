import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import stitchApp from '../../../stitch/app';
import { UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';
import LoadingDialog from '../../../layout/components/Dialogs/LoadingDialog/LoadingDialog';
import { useSnackbar } from 'notistack';

const RegisterAccount: React.FC<RouteComponentProps> = ({history, location}) => {

    const { enqueueSnackbar } = useSnackbar();

    const emailPassClient = stitchApp.auth.getProviderClient(UserPasswordAuthProviderClient.factory);

    const params = new URLSearchParams(location.search);
    //@ts-ignore
    const token = params.get('token');
    const tokenId = params.get('tokenId');

    React.useEffect(() => {
        (async () =>  {
            try {
                await emailPassClient.confirmUser(token as string, tokenId as string);
                enqueueSnackbar('Congratulations! Your account has been confirmed, please log in.', {variant: 'success'})
                history.push('/login');   
            } catch (error) {
                enqueueSnackbar(error.message  ===  "userpass token is expired or invalid" ? 
                    `This token is either used, invalid or expired. Please try logging in or creating a new account` : 
                    error.message, {
                        variant: 'error'
                });
                history.push('/login');   
            }
          })();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);       
    return <LoadingDialog loading={true} />
}

export default withRouter(RegisterAccount);