import { toLower } from 'lodash';
import appStitch from './app';
import { StitchUser, UserPasswordCredential, UserPasswordAuthProviderClient } from 'mongodb-stitch-browser-sdk';
import store from '../store/store';
import { 
    loginStarted, 
    loginSuccess, 
    loginFailed, 
    logoutStarted, 
    logoutSuccess, 
    logoutFailed,
    registerAccountStarted,
    registerAccountFailed, 
    registerAccountSuccess
} from '../store/actionCreators';
import { bugsnagClient } from '../bugsnag/bugsnag';
import stitchApp from './app';

export function createCredential (email: string, password: string) {
    email = toLower(email);
    return new UserPasswordCredential (email, password);
}

export async function registerNewAccount(email: string, password: string) {
    store.dispatch(registerAccountStarted());
    try {
        email = toLower(email);
        const result = await appStitch.auth.getProviderClient(UserPasswordAuthProviderClient.factory)
            .registerWithEmail(email, password);
        store.dispatch(registerAccountSuccess());
        return result

    } catch(error) {
        store.dispatch(registerAccountFailed())
        return error.message;
    }
}

export const getUserIdStitch = () => {
    return stitchApp.auth.user?.id

}

export const getUserCustomData = () => {
    return stitchApp.auth.user?.customData

}

export const getUserProviderType = () => {
    return stitchApp.auth.user?.loggedInProviderType

}

export const refreshCustomData = async () => {
    await stitchApp.auth.refreshCustomData();
}

export const login = async (email: string, password: string) => {
   store.dispatch(loginStarted())
    try {
        email = toLower(email);
        const credential = createCredential(email,password);
        const result = await appStitch.auth.loginWithCredential(credential);
        store.dispatch(loginSuccess());
        return result;
    } catch(error) {
        store.dispatch(loginFailed());
        return error;
    }
}

export const resetPasswordRequest = async (email: string, password?: string, immediateReset?: boolean) => {
    try {
        await appStitch.auth
            .getProviderClient(UserPasswordAuthProviderClient.factory)
            .callResetPasswordFunction(email, password ? password : process.env.REACT_APP_ADD_USER as string, [immediateReset])
        return `Please check your inbox for further instructions.`;
    } catch(error) {
        return `Please check your inbox for further instructions.`;
    }
}

export const resetPassword = async (token: string, tokenId: string, password: string) => {
    try {
        await appStitch.auth.getProviderClient(UserPasswordAuthProviderClient.factory).resetPassword(token, tokenId, password)
    } catch(error) {
        return error.message;
    }
}

export const hasLoggedInUser = (): boolean => appStitch.auth.isLoggedIn;
export const getCurrentUser = () => appStitch.auth.isLoggedIn ? appStitch.auth.user : null;

export const logout = async () => {
    store.dispatch(logoutStarted());
    try {
        const user: StitchUser = getCurrentUser() as StitchUser;
        await appStitch.auth.logoutUserWithId(user.id);
        store.dispatch(logoutSuccess());
    } catch (error) {
        store.dispatch(logoutFailed());
        bugsnagClient.notify(error);
        return
    }
}