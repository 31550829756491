import React from 'react';
import { Grid, Button, CircularProgress, Typography, Menu, MenuItem, Fade } from '@material-ui/core';
import { EditorState, RichUtils, Editor, getDefaultKeyBinding } from 'draft-js';
import ButtonWrapper from './components/ButtonWrapper';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { NoteItem } from '../../declarations/declarations';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
//@ts-ignore
import ColorPicker, { colorPickerPlugin } from 'draft-js-color-picker';

import './index.scss';

interface FuncProps {
    noteState: NoteItem;
    privateNote?: boolean;
    state: EditorState;
    setProgress: (progress: boolean) => void;
    progress: boolean;
    changeState: (state: EditorState) => void;
    saveContent: (newState: EditorState) => void;
}

// Add preset colors to the picker
const presetColors = [
    '#ff00aa',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#BD10E0',
    '#9013FE',
    '#4A90E2',
    '#50E3C2',
    '#B8E986',
    '#000000',
    '#4A4A4A',
    '#9B9B9B',
    '#FFFFFF',
  ];

const RichTextEditor: React.FC<FuncProps> = ({ noteState, 
    privateNote,
    state,
    setProgress,
    progress,
    changeState,
    saveContent
}) => {

    const isBold = state.getCurrentInlineStyle().has('BOLD');
    const isUnderline = state.getCurrentInlineStyle().has('UNDERLINE');
    const isItalic = state.getCurrentInlineStyle().has('ITALIC');
    const isFontSize = RichUtils.getCurrentBlockType(state) === 'header-one' ||
                        RichUtils.getCurrentBlockType(state) === 'header-two' || 
                        RichUtils.getCurrentBlockType(state) === 'header-four' || 
                        RichUtils.getCurrentBlockType(state) === 'header-five'
                        ? true : false;
    const isH1 = RichUtils.getCurrentBlockType(state) === 'header-one' ? true : false;
    const isH2 = RichUtils.getCurrentBlockType(state) === 'header-two' ? true : false;
    const isH5 = RichUtils.getCurrentBlockType(state) === 'header-five' ? true : false;
    const isUnordered = RichUtils.getCurrentBlockType(state) === 'unordered-list-item' ? true : false;
    const isOrdered = RichUtils.getCurrentBlockType(state) === 'ordered-list-item' ? true : false;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAutSaved, setIsAutSaved] = React.useState(false);
    const open = Boolean(anchorEl);
  
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const getEditorState = () => state;

    const picker = colorPickerPlugin(changeState, getEditorState);

    React.useEffect(() => {saveContent(state)}, [saveContent, state]);
    React.useEffect(() => {
        setTimeout(() => {
            setIsAutSaved(true);
        }, 120000);
    }, []);

    const handleKeyCommand = (command: any) => {
        let newState = RichUtils.handleKeyCommand(state, command);

        if (newState) {
            changeState(newState);
            return 'handled';
        } else {
            switch(command) {
                case 'ordered-list-item':
                    changeState(RichUtils.toggleBlockType(state,'ordered-list-item'));
                    return 'handled';
                default:
                    return 'not-handled';
            }
        }
    }

    const onTab = (e: any) => {
        const maxDepth = 4;
        changeState(RichUtils.onTab(e, state, maxDepth));
    }



    const unorderedList_onClick = () => changeState(RichUtils.toggleBlockType(state,'unordered-list-item'));
    const orderedList_onClick = () => changeState(RichUtils.toggleBlockType(state,'ordered-list-item'));
    const bold_onClick = () => changeState(RichUtils.toggleInlineStyle(state, 'BOLD'));
    const h1_onClick = () => changeState(RichUtils.toggleBlockType(state, 'header-one'));
    const h2_onClick = () => changeState(RichUtils.toggleBlockType(state, 'header-two'));
    const h5_onClick = () => changeState(RichUtils.toggleBlockType(state, 'header-five'));
    const underline_onClick = () => changeState(RichUtils.toggleInlineStyle(state, 'UNDERLINE'));
    const italic_onClick = () => changeState(RichUtils.toggleInlineStyle(state, 'ITALIC'));

    return <Grid container className="notes-option-box ">
        <Grid item xs={12} className="gray3 note-options">
        {/* <ButtonWrapper toggleFunction={()=> {}}> */}
            <Button 
                onClick={handleClick}
                className="gray3"
            >
                {isFontSize ? <FormatSizeIcon 
                    color="secondary"
                    style={{
                    fontSize: 'x-large' }} /> :
                    <FormatSizeIcon 
                        style={{
                        fontSize: 'x-large'}} />}
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem style={{ fontSize: '0.83em' }} onClick={() => {
                    h5_onClick();
                    handleClose();
                    }}>small</MenuItem>
                <MenuItem style={{ fontSize: '0.875rem' }} onClick={() => {
                    // h4_onClick();
                    isH1 ? h1_onClick() : isH2 ? h2_onClick() : isH5 ? h5_onClick() : handleClose();
                    handleClose();
                    }}>Normal</MenuItem>
                <MenuItem style={{ fontSize: '1.5em' }} onClick={() => {
                    h2_onClick();
                    handleClose();
                    }}>Large</MenuItem>
                <MenuItem style={{ fontSize: '2em' }} onClick={() => {
                    h1_onClick();
                    handleClose();
                    }}>Huge</MenuItem>
            </Menu>
            {/* </ButtonWrapper> */}
            {/* <ButtonWrapper toggleFunction={h1_onClick}>
                <Button 
                    style={{ color: isH1 ? '#68bcd5' : '#ffffff'}}
                    color={isH1 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H1</b>
                    </Typography>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={h2_onClick}>
                <Button 
                    style={{ color: isH2 ? '#68bcd5' : '#ffffff'}}
                    color={isH2 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H2</b>
                    </Typography>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={h3_onClick}>
                <Button 
                    style={{ color: isH3 ? '#68bcd5' : '#ffffff'}}
                    color={isH3 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H3</b>
                    </Typography>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={h4_onClick}>
                <Button 
                    style={{ color: isH4 ? '#68bcd5' : '#ffffff'}}
                    color={isH4 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H4</b>
                    </Typography>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={h5_onClick}>
                <Button 
                    style={{ color: isH5 ? '#68bcd5' : '#ffffff'}}
                    color={isH5 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H5</b>
                    </Typography>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={h6_onClick}>
                <Button 
                    style={{ color: isH6 ? '#68bcd5' : '#ffffff'}}
                    color={isH6 ? 'secondary' : undefined}
                    variant="outlined"
                >
                    <Typography>
                        <b>H6</b>
                    </Typography>
                </Button>
            </ButtonWrapper> */}
            <ButtonWrapper toggleFunction={bold_onClick}>
                <Button className={(isBold ? "gray6-background gray3" : "gray3")}>
                    <FormatBoldIcon/>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={underline_onClick}>
                <Button className={(isUnderline ? "gray6-background gray3" : "gray3")}>
                    <FormatUnderlinedIcon/>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={italic_onClick}>
                <Button className={(isItalic ? "gray6-background gray3" : "gray3")}>
                    <FormatItalicIcon/>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={()=> {}}>
                <Button className="gray3">
                    {/* <FormatColorTextIcon style={{ color: picker.currentColor(state)}} /> */}
                    <ColorPicker
                        toggleColor={(color: any) => picker.addColor(color)}
                        presetColors={presetColors}
                        color={picker.currentColor(state)}
                        />
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={unorderedList_onClick}>
                <Button className={(isUnordered ? "gray6-background gray3" : "gray3")}>
                    <FormatListBulletedIcon/>
                </Button>
            </ButtonWrapper>
            <ButtonWrapper toggleFunction={orderedList_onClick}>
                <Button className={(isOrdered ? "gray6-background gray3" : "gray3")}>
                    <FormatListNumberedIcon/>
                </Button>
            </ButtonWrapper>
        </Grid>
        
        {/* {isColorOption && <SketchPicker color={ color } onChange={ handleChange } />} */}
        <Grid item xs={12} className="gray5 notes-editor">
            <div style={{backgroundColor: privateNote ? '#FFEAA0' : 'white', color: 'black', height:'100%', width: '100%'}}>
                <Editor 
                    editorState={state} 
                    customStyleFn={picker.customStyleFn}
                    onChange={changeState} 
                    handleKeyCommand={handleKeyCommand} 
                    onTab={onTab}
                    keyBindingFn={getDefaultKeyBinding}
                    placeholder="Write your notes here"
                />
                {isAutSaved && <Grid>
                    <Typography className="gray5 small-text padding-top-10">
                        Autosaved 2 mins ago
                    </Typography>
                </Grid>}
                { progress ? <CircularProgress style={{marginTop: '5px', color: 'white'}} size={20}/> : null}
            </div>
        </Grid>
    </Grid>
    
}

export default RichTextEditor;