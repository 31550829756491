import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

interface FuncProps {
    text: string;
    state: boolean;
    close: () => void;
    confirmedFunc: () => void | Promise<void>;
}

const ConfirmDialog: React.FC<FuncProps> = ({text, state, close, confirmedFunc}) => <Dialog 
    disableEnforceFocus={true} 
    open={state} 
    onClose={close}
    id="meeting-confirm-dialog"
>
    <DialogTitle>{text}</DialogTitle>
    <DialogContent>
        <Grid container style={{textAlign: 'center'}}>
            <Grid item xs={6}>
                <IconButton id="meeting-cd-cancel" color="secondary" onClick={() => {
                    close()
                }}><ClearIcon /></IconButton>
            </Grid>
            <Grid item xs={6}>
                <IconButton id="meeting-cd-confirm" color="primary" onClick={async () => {
                    await confirmedFunc()
                    close()
                }}><CheckIcon /></IconButton>
            </Grid>
        </Grid>
    </DialogContent>
</Dialog>

export default ConfirmDialog