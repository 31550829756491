import { 
    AccountDataReduxer, 
    UserDataReduxer, 
    MeetingItemsReduxer, 
    MeetingTemplatesReduxer, 
    MeetingSummariesReduxer, 
    InvitesReduxer, 
    MetricDataReduxer, 
    MetricTypeReduxer, 
    TeamsReduxer,
    StreamsReduxer 
} from '../reduxer';
import { 
    UserData, 
    AccountData, 
    MeetingSummary, 
    MeetingTemplate, 
    BasicItem, 
    TargetMetric, 
    Team, 
    Stream
} from '../../declarations/declarations';
import {LOGOUT_USER_SUCCESS} from '../actionTypes';

export interface DataState {
    [key: string]: any;
    userData: Array<UserData>;
    accountData: Array<AccountData>;
    teams: Array<Team>
    meetingItems:  Array<BasicItem>;
    meetingTemplates: Array<MeetingTemplate>;
    metrics: Array<TargetMetric>;
    summaries: Array<MeetingSummary>
    streams: Stream[]
}

const initialState: DataState = {
    userData: [],
    accountData: [],
    teams: [],
    meetingItems: [],
    meetingTemplates: [],
    metrics: [],
    summaries: [],
    streams: []
};

export const dataReducer = (state = initialState, action: any): DataState => {
    let returnState: DataState = {...state};
    returnState = AccountDataReduxer.reducer(returnState, action);
    returnState = TeamsReduxer.reducer(returnState, action);
    returnState = UserDataReduxer.reducer(returnState, action);
    returnState = MeetingItemsReduxer.reducer(returnState, action);
    returnState = MeetingTemplatesReduxer.reducer(returnState, action);
    returnState = MeetingSummariesReduxer.reducer(returnState, action);
    returnState = InvitesReduxer.reducer(returnState, action);
    returnState = MetricDataReduxer.reducer(returnState, action);
    returnState = MetricTypeReduxer.reducer(returnState, action);
    returnState = StreamsReduxer.reducer(returnState, action);

    switch (action.type) {
        case LOGOUT_USER_SUCCESS:
            returnState = initialState;
            return returnState;
        default:
            return returnState;
    }
}