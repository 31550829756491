import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { MeetingItem, MeetingItemSetting, MeetingState } from '../../../../../../declarations/declarations';
import CurrentItemBaseCard from '../../../../../../components/MeetingItemCard/components/CurrentItemBaseCard';
import moment from 'moment';
import ArtifactItemCardList from './ArtifactItemCardList/ArtifactItemCardList';
import { useMeetingState } from '../../../../../../store/meeting/meetingState';
import './index.scss';

interface FuncProps {
    openResolveDialog: () => void;
    noItems: () => boolean;
    selectNextItem: () => void;
    editArtifact: (item: MeetingItemSetting) => void;
    meeting_id: string;
    attendeeOptions: Array<[string, any]>;
}

const CurrentItemInMeeting: React.FC<FuncProps> = ({ 
    meeting_id, 
    attendeeOptions, 
    editArtifact,
    openResolveDialog,
    noItems,
    selectNextItem
}) => {
    const {accessState, facilitatorCheck} = useMeetingState(meeting_id);
    const isFacilitator = facilitatorCheck();
    const state: MeetingState = accessState('state');
    const startedAt = state.startedAt;
    const agendaItem = state.currentAgendaItem;
    const voting = state.currentAgendaItem.voting;
    const [currentRefineItem, setCurrentRefineItem] = React.useState<MeetingItem>();

    const [actionItems, setActionItems] = React.useState<Array<MeetingItem>>();
    const [decisiomItems, setDecisionItems] = React.useState<Array<MeetingItem>>();
    const [addItem, setAddItem] = React.useState<string>('');
    const [minutes, setMinutes] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(true);
    
    React.useEffect(() => {
        if (agendaItem.currentArtifacts) {
            let ActionItems = agendaItem.currentArtifacts.filter((item) => 
                item.type === "actionItem"
            );

            let DecisionItems =  agendaItem.currentArtifacts.filter((item) => 
                    item.type === "decision"
                );

            if (ActionItems) {
                setActionItems(ActionItems);
            }

            if (DecisionItems) {
                setDecisionItems(DecisionItems);
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agendaItem,  agendaItem.currentArtifacts]);
    const style_textCenter = {
        marginTop: '15px',
        color: 'white',
        textAlign: 'center'
    } as React.CSSProperties;

    

    const setTimmer = (timer: number) => {
        let secs = timer / 1000;
        let isTimeExceeded = true;

        setInterval(() => {
            const formatted = secs > 3600 ? moment.utc(secs*1000).format('hh:mm:ss') : moment.utc(secs*1000).format('mm:ss');
            if (secs <= 0 && !isTimeExceeded) {
                isTimeExceeded = true;
            }

            secs =  secs + 1 ;
            setMinutes(formatted);

            if (loading) {
                setLoading(false);
            }
        }, 1000)
    }

    React.useEffect(() => {
        clearInterval();
            if (startedAt) {
                const milliSec = Date.now() - startedAt;
                // let sec = milliSec / 1000; //moment.duration(sec, 'milliseconds');
                const timer = milliSec;
                setTimmer(timer);
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <Grid  md={agendaItem.voting && agendaItem.currentItem ? 10 : 6} sm={12}>
        <Grid className="current-item-container">
            <Grid className = " current-item-heading">
                <Grid className = " current-item-heading inline">
                    <Button id="meeting_item_resolve_btn"
                    onClick={openResolveDialog} className = " resolve-button inline">
                        <Grid className = "inline current-item-icon" ><i className="fas fa-chevron-left primary-blue"></i></Grid>
                        <Grid className = "inline" ><Typography className="primary-blue body-text">Resolve Current Item</Typography></Grid>
                    </Button>
                </Grid>
                {minutes && <Grid className="inline current-item-heading">
                    <i className="far fa-stopwatch inline gray4"></i>
                    <Typography className="inline gray4 bold-text">{minutes}</Typography>
                </Grid>}
            </Grid>
            <Grid
            className = " current-item-heading">
                <Grid className = "inline current-item-icon" ><i className="fal fa-file-alt gray4"></i></Grid>
                <Grid className = "inline" ><Typography className="gray4 body-text">Current Item</Typography></Grid>
            </Grid>
                {agendaItem.currentItem ? 
                    <Grid xs={12} item id="meeting_current_item">
                        <CurrentItemBaseCard item={agendaItem.currentItem}/>
                    </Grid> : 
                    <Grid xs={12} item style={style_textCenter}><Typography variant="subtitle1">The facilitator needs to select an item</Typography></Grid> 
                }
            { isFacilitator && !voting ?
                <Grid item xs={12} style={{marginTop: '20px', textAlign: 'center'}}>
                    <Button onClick={selectNextItem} disabled={noItems()} style={noItems() ? {color: 'grey'} : {color: 'white'}}>Next Item</Button>
                </Grid> :
                null
            }
        </Grid>
        <Grid container>
            <Grid style={{ width: 'max-content',  
                height:"max-content", marginRight: '105', alignItems:"flex-end"}} container>
                <Grid>
                    <Typography>
                        Outcomes
                    </Typography>
                    <ArtifactItemCardList 
                        items={[actionItems, decisiomItems]}
                        meeting_id={meeting_id}
                        attendeeOptions={attendeeOptions}
                        addItem={addItem} 
                        setAddItem={setAddItem}
                        editArtifact={editArtifact}
                        currentRefineItem={currentRefineItem}
                        setCurrentRefineItem={setCurrentRefineItem}
                        type="actionItem"/>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default CurrentItemInMeeting;