import { BSON, RemoteMongoCollection } from "mongodb-stitch-browser-sdk";
import { MeetingState } from "../../declarations/declarations";
import stitchApp, { mongoClient } from "../../stitch/app";
import * as actionCreators from './actions/meetingActionCreator';
import { useEffect } from "react"
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { OptionsObject } from "notistack";
import { SystemState } from '../store';
import { getUserId } from "../../helpers/helpers";
import { bugsnagClient } from "../../bugsnag/bugsnag";
import { useSnackbar } from 'notistack'

interface Types {
    enqSnack: (message: React.ReactNode, options?: OptionsObject | undefined) => React.ReactText;
}

class MeetingStateController {

    constructor(private meeting_id: string, private dispatch: Dispatch<any>, private enqueueSnackbar: Types["enqSnack"]){}
    
    private meetingObjectId: BSON.ObjectId = new BSON.ObjectId(this.meeting_id)

    private state = useSelector((state: SystemState) => state.meeting.state) as MeetingState
    
    private db: RemoteMongoCollection<any> = mongoClient.db('thenaMeet').collection('meetings');
    
    private onCleanUp: (()=> void)[] = [];

    public cleanup() {
        for (const cb of this.onCleanUp) {
            cb()
        }
    }

    private errorHandler(message: string) {
        bugsnagClient.notify(`${message}, userId: ${getUserId()}`);
        this.enqueueSnackbar(
            `Something went wrong with this meeting please reload the page. 
            If this issue persists please contact support`, 
            {variant: 'error'}
        );
    }

    public getMeetingIdObject = () => {
        return this.meetingObjectId;
    }

    public facilitatorCheck(){
        return useSelector((state: SystemState) => state.meeting.isFacilitator);
    }

    private checkIsFacilitator(facilitatorArray: string[]){
        const user_id = getUserId()
        if (user_id && facilitatorArray.includes(user_id)) {
            this.dispatch(actionCreators.updateFacilitator(true));
        }
        
    }

    private checkAttendees(attendeeArray: string[]){
        const user_id = getUserId()
        if (user_id && !attendeeArray.includes(user_id)) {
            this.updateState({ $push: { attendees: getUserId() } })
        }
    }

    private async setupWatch() {
        const watcher = await this.db.watch({ documentKey: { _id: this.meetingObjectId} })
        this.onCleanUp.push(()=> watcher.close());
        watcher.onNext((event) => {
            const state: MeetingState = event.fullDocument;
            this.dispatch(actionCreators.updateMeetingState(state));
            this.checkIsFacilitator(state.facilitator);
        });
    
    }

    private async getMeetingState() {
        try {
            const state: MeetingState = await this.db.findOne({_id: this.meetingObjectId});
            this.dispatch(actionCreators.updateMeetingState(state));
            this.checkAttendees(state.attendees);
            this.checkIsFacilitator(state.facilitator);
        } catch(error) {
            this.errorHandler('func: getMeetingState');
        }
    }

    public async updateState(update: object){
        try {
            const result = await this.db.findOneAndUpdate({_id: this.meetingObjectId}, update)
            if (result._id) {
                return
            } else {
                throw new Error('updateState went wrong')
            }
        } catch(error) {
            this.errorHandler('func: updateState');
        }
       
    }

    public accessState(key: string, field?: string) {
        return field ? useSelector((state: SystemState) => state.meeting[key][field]) : useSelector((state: SystemState) => state.meeting[key]);
    }

    public async resolveMeetingItem() {
        await stitchApp.callFunction('meetingState_resolveMeetingItem', [this.meeting_id, getUserId()])
    }

    public async addArtifactToCurrentItem(item: any){
        const state: MeetingState = {...this.state}
        state.currentAgendaItem.currentArtifacts ? state.currentAgendaItem.currentArtifacts.push(item) : state.currentAgendaItem.currentArtifacts = [item];
        this.dispatch(actionCreators.updateMeetingState(state))
        await stitchApp.callFunction('meetingState_addArtifactToCurrentItem', [this.meeting_id, getUserId(), item]);
        console.log('add artifact')
    }

    public async initMeeting() {
        await this.setupWatch();
        await this.getMeetingState();
        this.onCleanUp.push(() => this.dispatch(actionCreators.cleanUpMeeting()));
    }

    
}

export function useMeetingState(meeting_id: string) {
    
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const meetingState = new MeetingStateController(meeting_id, dispatch, enqueueSnackbar);

    useEffect(() => {
        return () => {
            meetingState.cleanup()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return meetingState;
}