import * as actionNames from '../actionTypes';
import { ProfileInfo } from '../../declarations/declarations';

export interface AppState {
    [key: string]: any;
    loading: boolean;
    loggedIn: boolean;
    myItemsDrawer: boolean;
    addEditItemState: {
        state: boolean;
        itemId?: string;
        location_id?: string;
    };
    moveItemState: {
        state: boolean;
        itemId?: string;
    };
    deleteItemState: {
        state: boolean;
        itemId?: string;
    };
    streamSubscribeState: boolean
    startMeetingState: {
        state: boolean;
        stream_id?: string;
    };
    profiles: Array<ProfileInfo>;
    hideStream : boolean;
}

const initialState: AppState = {
    loading: false,
    loggedIn: false,
    myItemsDrawer: false,
    hideStream : false,
    addEditItemState: {
        state: false
    },
    moveItemState: {
        state: false
    },
    deleteItemState: {
        state: false
    },
    streamSubscribeState: false,
    startMeetingState: {
        state: false
    },
    profiles: []
};

// Register New Account

const registerAccountStart = (state: AppState): AppState => {
    return {
        ...state,
        loading: true
    }
}


const registerAccountFail = (state: AppState): AppState => {
    return {
        ...state,
        loading: false
    }
}

const registerAccountSuccess = (state: AppState): AppState => {
    return {
        ...state,
        loading:false
    }
}

// Login, Logout and Restore Session

const loginStart = (state: AppState): AppState => {
    return {
        ...state,
        loading: true
    }
}

const loginFailed = (state: AppState): AppState => {
    return {
        ...state,
        loading: false
    }
}

const loginSuccess = (state: AppState): AppState => {
    return {
        ...state,
        loading: false,
        loggedIn: true
    }
}

const logoutStart = (state: AppState): AppState => {
    return {
        ...state,
        loading: true
    }
}

const logoutFailed = (state: AppState): AppState => {
    return {
        ...state,
        loading: false
    }
}

const logoutSuccess = (state: AppState): AppState => {
    return initialState;
}

const restoreSession = (state: AppState): AppState => {
    return {
        ...state,
        loading: false,
        loggedIn: true,
    }
}

const startLoading = (state: AppState): AppState => {
    return {
        ...state,
        loading: true
    }
}

const stopLoading = (state: AppState): AppState => {
    return {
        ...state,
        loading: false
    }
}

const alternateMyItemsDrawState = (state: AppState): AppState => {
    return {
        ...state,
        myItemsDrawer: !state.myItemsDrawer
    }
}


const openMyItemsDrawState = (state: AppState): AppState => {
    return {
        ...state,
        myItemsDrawer: true
    }
}

const closeMyItemsDrawState = (state: AppState): AppState => {
    return {
        ...state,
        myItemsDrawer: false
    }
}

const updateAddEditItemState = (state: AppState, action: any): AppState => {
    return {
        ...state,
        addEditItemState: action.payload
    }
}

const hideStreamFromMeeting = (state: AppState, action: any): AppState => {
    return {
        ...state,
        hideStream: action.payload
    }
}

const updateMoveItemState = (state: AppState, action: any): AppState => {
    return {
        ...state,
        moveItemState: action.payload
    }
}

const updateDeleteItemState = (state: AppState, action: any): AppState => {
    return {
        ...state,
        deleteItemState: action.payload
    }
}

const updateStartMeetingState = (state: AppState, action: any): AppState => {
    return {
        ...state,
        startMeetingState: action.payload
    }
}

const updateStreamSubscribeState = (state: AppState, action: any): AppState => {
    return {
        ...state,
        streamSubscribeState: action.payload
    }
}

const addProfile = (state: AppState, action: any): AppState => {
    return {
        ...state,
        profiles: state.profiles.filter(item => action.payload.id !== item.id).concat(action.payload)
    }
}

export const appReducer = (state = initialState, action: any): AppState => {
    let returnState: AppState = {...state};
    
    switch (action.type) {
        case actionNames.REGISTER_ACCOUNT_STARTED:
            return registerAccountStart(returnState);
        case actionNames.REGISTER_ACCOUNT_FAILED:
            return registerAccountFail(returnState);
        case actionNames.REGISTER_ACCOUNT_SUCCESS:
            return registerAccountSuccess(returnState);
        case actionNames.START_LOADING:
            return startLoading(returnState);
        case actionNames.STOP_LOADING:
            return stopLoading(returnState);
        case actionNames.LOGIN_USER_STARTED:
            return loginStart(returnState);
        case actionNames.LOGIN_USER_FAILED:
            return loginFailed(returnState);
        case actionNames.LOGIN_USER_SUCCESS:
            return loginSuccess(returnState);
        case actionNames.RESTORE_SESSION:
            return restoreSession(returnState);
        case actionNames.LOGOUT_USER_STARTED:
            return logoutStart(returnState);
        case actionNames.LOGOUT_USER_FAILED:
            return logoutFailed(returnState);
        case actionNames.LOGOUT_USER_SUCCESS:
            return logoutSuccess(returnState);
        case actionNames.ADD_PROFILE:
            return addProfile(returnState, action);
        case actionNames.ALTERNATE_MYITEM_STATE:
            return alternateMyItemsDrawState(returnState);
        case actionNames.HIDE_STREAM:
            return hideStreamFromMeeting(returnState, action);
        case actionNames.OPEN_MYITEM:
            return openMyItemsDrawState(returnState);
        case actionNames.CLOSE_MYITEM:
            return closeMyItemsDrawState(returnState);
        case actionNames.UPDATE_ADDEDITITEMSTATE:
            return updateAddEditItemState(returnState, action);
        case actionNames.UPDATE_MOVEITEMSTATE:
            return updateMoveItemState(returnState, action);
        case actionNames.UPDATE_DELETEITEMSTATE:
            return updateDeleteItemState(returnState, action);
        case actionNames.UPDATE_START_MEETING_STATE:
            return updateStartMeetingState(returnState, action);
        case actionNames.UPDATE_SUBSCRIBE_DIALOG_STATE:
            return updateStreamSubscribeState(returnState, action);
        default: 
            return returnState;
    }
}