import React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import ThenaLogo from '../../components/ThenaLogo/ThenaLogo';

const FormLayout: React.FC = ({children}) => <Grid container>
    <Grid item xs={12}>
        <Card style={{textAlign: 'center', margin: '1%', height: '100%', backgroundColor: '#F2F7FF'}}>
            <CardContent>
                <ThenaLogo width='40%' height='3%'/>
            </CardContent>
            {children}
        </Card>
    </Grid>
</Grid>

export default FormLayout;