import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { helperUpdateStartMeetingState } from "../../../helpers/helpers";
import store from "../../../store/store";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { MeetingsReduxer } from "../../../store/reduxer";
import { BSON } from "mongodb-stitch-browser-sdk";
import CallIcon from "@material-ui/icons/Call";

const JoinUsingCode: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [meetingId, setMeetingId] = React.useState("");
  let history = useHistory();

  const onJoinClick = async () => {
    if (meetingId && meetingId !== "" && meetingId.length === 24) {
      let meetingIdObject = new BSON.ObjectID(meetingId);
      const storedMeetingState = await MeetingsReduxer.database.findOne({
        _id: meetingIdObject,
      });
      if (storedMeetingState) {
        history.push(
          `/meetings/meet/${
            store.getState().data.userData[0].account_id
          }/${meetingId}`
        );
      } else {
        enqueueSnackbar("Meeting not Found", { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please Enter a valid Meeting ID", { variant: "error" });
    }
  };

  return (
    <Grid>
      <Grid container className="join-meeting margin-bottom-20">
        <Grid md={12}>
          <Typography className="join-meeting-text">Join A Meeting</Typography>
        </Grid>
        <Grid container>
          <Grid className="join-meeting-line">
            <input
              className="join-meeting-input"
              id="standard-basic"
              placeholder="Enter meeting code"
              onChange={(event) => {
                setMeetingId(event.target.value);
              }}
            />
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            className="join-btn"
            onClick={() => {
              onJoinClick();
            }}
          >
            <Typography className="join-btn-text">Join</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid className="margin-bottom-20">
        <Button
          variant="contained"
          className="start-button primary-blue-background"
          onClick={() => {
            helperUpdateStartMeetingState(true);
          }}
        >
          <CallIcon className="start-button-icon" />
          <Typography className="start-button-text">Start Meeting</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default JoinUsingCode;
