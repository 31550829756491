import React from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';
import {MeetingItem } from '../../../declarations/declarations';
import DeleteIcon from '@material-ui/icons/Delete';
import { getUserId } from '../../../helpers/helpers';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { MeetingItemsReduxer } from '../../../store/reduxer';
import { connect } from 'react-redux';

interface FuncProps {
    item : MeetingItem;
    isListClosed?: boolean;
}

interface DispatchProps {
    deleteItem: (item: MeetingItem) => void;
}

type Props = FuncProps & DispatchProps

const IdeaItemCard: React.FC<Props> = ({ item, isListClosed, deleteItem }) => {
    const user_id = getUserId();  
    const [dialogState, setDialogState] = React.useState<boolean>(false);
    
    return <Grid container>
        <Grid container className="idea-item">
            <Grid className="idea-what-text-container">
                <Typography className={(isListClosed ? "gray2 body-text idea-what-text-1" : "gray2 body-text idea-what-text-2")}>
                    {item.what}
                </Typography>
            </Grid>
            {user_id === item.owner_id ? <>
                <Avatar className="idea-item-avatar">{item.initials}</Avatar>
                <DeleteIcon className="delete-icon-on-hover" onClick={() => deleteItem(item)}></DeleteIcon>
            </> : 
            <Avatar className="idea-item-avatar-other-user">{item.initials}</Avatar>}
        </Grid>
    </Grid>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        deleteItem: bindActionCreators(MeetingItemsReduxer.delete, dispatch),
    }
}

export default connect(null, mapDispatchToProps)(IdeaItemCard);