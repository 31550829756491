import React from 'react';
import { FieldArray } from 'formik';
import { AgendaItemInitialData } from '../AddEditAgendaItemDialog';
import { Button, Grid, IconButton } from '@material-ui/core';
import FormInputField from '../../../FormInputField/FormInputField';
import CloseIcon from '@material-ui/icons/Close';

interface FuncProps {
    values: AgendaItemInitialData
}

const AnswersArray: React.FC<FuncProps> = ({ values }) => <FieldArray 
    name="answers"
    render={arrayHelpers => (
        <Grid container>
            {values.answers && values.answers.length > 0 ? 
                (values.answers.map((answer, index) => 
                    <Grid container key={index}>
                        <FormInputField name="name" label={`Answer ${index+1}`} type="text" array="answers" position={index} md={10} xs={10}/>
                        {index === 0 || index === 1 ? null : <Grid item md={2} xs={2} style={{margin: 'auto', textAlign: 'center'}}><IconButton onClick={() => arrayHelpers.remove(index)}><CloseIcon/></IconButton></Grid>}
                    </Grid>)) 
                : null}
            <Grid item xs={12}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => arrayHelpers.push({name: ''})}
                >
                    Add Answer
                </Button>
            </Grid>
        </Grid>
    )}
/>

export default AnswersArray;