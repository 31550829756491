import {
  MeetingState,
  MeetingStateType,
  AgendaItemTypes,
  MeetingItem,
  MeetingItemTypes,
  ActionItem,
  BasicItem,
  TargetMetric, 
  StreamEventTypes, MeetingStreamEventTypes
} from "../../../../../declarations/declarations";
import {
  MeetingsReduxer,
  MeetingItemsReduxer
} from "../../../../../store/reduxer";
import { BSON } from "mongodb-stitch-browser-sdk";
import store from "../../../../../store/store";
import { createStreamEvent, getUserId } from "../../../../../helpers/helpers";
import { createMeetingStreamEvent } from "./streamHelpers";

const getIndexByName = (state: MeetingState) => {
  if (state) {
    return state.agendaItems.findIndex(
      (item) => item.name === state.currentAgendaItem.name
    );
  } else return -1;
};

const getIndexOfItemById = (array: Array<any>, id: string) => {
  if (array.length > 0) {
    return array.findIndex((item) => item._id.toString() === id);
  } else return 0;
};

// Start
export const startMeeting = async (state: MeetingState) => {
  const query = {
    $set: {
      state: MeetingStateType.active,
      currentAgendaItem: state.agendaItems[0],
      startedAt: Date.now(),
    },
  };
  await createMeetingStreamEvent(
    MeetingStreamEventTypes.meeting_started, 
    state.stream_id,
    state._id.toHexString(),
    getUserId() as string
  )
  await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
};

// Agenda Move Forward

const agenda_moveForward = async (
  state: MeetingState,
  meetingItems: Array<MeetingItem>
) => {
  await createMeetingStreamEvent(
    MeetingStreamEventTypes.agenda_move_forward, 
    state.stream_id,
    state._id.toHexString(),
    getUserId() as string
  )
  if (state.currentAgendaItem.currentItem && state.currentAgendaItem.voting) {
    const returnItem = {...state.currentAgendaItem.currentItem}
    returnItem.complete = true;
    returnItem.completeDate = new Date();
  }
  if (state.agendaItems[getIndexByName(state) + 1]) {
    const newAgendaItem = state.agendaItems[getIndexByName(state) + 1];
    const newAgendaItemArray = state.agendaItems;
    newAgendaItemArray[getIndexByName(state)].endedAt = Date.now();
    const defaultQuery = { $set: { currentAgendaItem: newAgendaItem , agendaItems: newAgendaItemArray } };
    switch (newAgendaItem.type) {
      /* case AgendaItemTypes.announcements:
                const announcementQuery = { $set: {
                    currentAgendaItem: newAgendaItem,
                    currentAnnouncement: meetingItems.filter(item => item.type === MeetingItemTypes.announcement)[0]
                }}
                return await MeetingsReduxer.database.updateOne({_id: state._id}, announcementQuery); */
      case AgendaItemTypes.actionItemReview:
        const actionItemQuery = {
          $set: {
            currentAgendaItem: newAgendaItem,
            currentActionItem: actionItem_decideFirstItem(
              meetingItems as Array<ActionItem>
            ),
          },
        };
        return await MeetingsReduxer.database.updateOne(
          { _id: state._id },
          actionItemQuery
        );
      case AgendaItemTypes.metricReview:
        const metricQuery = {
          $set: {
            currentAgendaItem: newAgendaItem,
            currentMetric: store.getState().data.metrics[0],
          },
        };
        return await MeetingsReduxer.database.updateOne(
          { _id: state._id },
          metricQuery
        );
      /* case AgendaItemTypes.sort:
                if( state.sortState) {
                    return await MeetingsReduxer.database.updateOne({_id: state._id}, defaultQuery);
                } else {
                    const sortQuery = { $set: {
                        currentAgendaItem: newAgendaItem,
                        sortState: {toSort: meetingItems.filter(item => item.type === MeetingItemTypes.dump)}
                    }}
                    return await MeetingsReduxer.database.updateOne({_id: state._id}, sortQuery);
                }
                 */
      default:
        return await MeetingsReduxer.database.updateOne(
          { _id: state._id },
          defaultQuery
        );
    }
  } else {
    const query = {
      $set: {
        currentAgendaItem: {
          name: "",
          description: "",
          type: "conclude",
        },
      },
    };
    await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
    await createMeetingStreamEvent(
      MeetingStreamEventTypes.meeting_ended, 
      state.stream_id,
      state._id.toHexString(),
      getUserId() as string
    )
    await createStreamEvent(
      StreamEventTypes.meeting_held,
      state.stream_id,
    )
  }
};

//Announcements Move Forward

// const announcement_moveForward = async (
//   state: MeetingState,
//   meetingItems: Array<MeetingItem>,
//   announcementDialog: () => void
// ) => {
//   const announcements = meetingItems.filter(
//     (item) => item.type === MeetingItemTypes.announcement
//   );

//   if (announcements.length > 0) {
//     const currentAnnouncementId = (state.currentAnnouncement as MeetingItem)._id.toString() as any;
//     const lastItemId = announcements[announcements.length - 1]._id.toString();
//     if (currentAnnouncementId !== lastItemId) {
//       const query = {
//         $set: {
//           currentAnnouncement:
//             announcements[
//               getIndexOfItemById(announcements, currentAnnouncementId) + 1
//             ],
//         },
//       };
//       await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
//     } else {
//       announcementDialog();
//     }
//   } else {
//     await agenda_moveForward(state, meetingItems);
//   }
// };

const actionItem_decideFirstItem = (meetingItems: Array<ActionItem>) => {
  const outstanding = meetingItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && !item.doneDate
  );
  const completed = meetingItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && item.doneDate
  );
  if (outstanding.length > 0) {
    return outstanding[0];
  } else if (outstanding.length === 0 && completed.length > 0) {
    return completed[0];
  } else {
    return;
  }
};

const metric_moveForward = async (
  state: MeetingState,
  meetingItems: Array<MeetingItem>
) => {
  const metrics = store.getState().data.metrics;
  if (metrics.length > 0) {
    await forward_handleNextItem(
      metrics,
      (state.currentMetric as TargetMetric)._id.toString(),
      state._id,
      async () => agenda_moveForward(state, meetingItems),
      "currentMetric"
    );
  } else {
    await agenda_moveForward(state, meetingItems);
  }
};

const forward_handleNextItem = async (
  array: Array<any>,
  currentId: string,
  state_id: BSON.ObjectID,
  lastItemFunc: () => void,
  queryArray: string
) => {
  const lastId = array[array.length - 1]._id.toString();
  if (lastId !== currentId) {
    const query = {
      $set: { [queryArray]: array[getIndexOfItemById(array, currentId) + 1] },
    };
    await MeetingsReduxer.database.updateOne({ _id: state_id }, query);
  } else {
    lastItemFunc();
  }
};

// const actionItem_moveForward = async (
//   state: MeetingState,
//   meetingItems: Array<ActionItem>,
//   actionItemDialog: () => void
// ) => {
//   const outstanding = meetingItems.filter(
//     (item) => item.type === MeetingItemTypes.actionItem && !item.doneDate
//   );
//   const completed = meetingItems.filter(
//     (item) => item.type === MeetingItemTypes.actionItem && item.doneDate
//   );

//   // no action items
//   if (outstanding.length + completed.length === 0) {
//     await agenda_moveForward(state, meetingItems);

//     // only outstanding items
//   } else if (outstanding.length > 0 && completed.length === 0) {
//     const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
//     forward_handleNextItem(
//       outstanding,
//       currentItemId,
//       state._id,
//       async () => agenda_moveForward(state, meetingItems),
//       "currentActionItem"
//     );
//     // only completed items
//   } else if (completed.length > 0 && outstanding.length === 0) {
//     const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
//     forward_handleNextItem(
//       completed,
//       currentItemId,
//       state._id,
//       actionItemDialog,
//       "currentActionItem"
//     );
//     //Both completed and outstanding
//   } else {
//     const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
//     const isOutstanding =
//       outstanding.filter((item) => item._id.toString() === currentItemId)
//         .length > 0
//         ? true
//         : false;
//     const isCompleted =
//       completed.filter((item) => item._id.toString() === currentItemId).length >
//       0
//         ? true
//         : false;
//     const handoverToCompleted = async () => {
//       const query = { $set: { currentActionItem: completed[0] } };
//       await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
//     };
//     if (isOutstanding) {
//       forward_handleNextItem(
//         outstanding,
//         currentItemId,
//         state._id,
//         handoverToCompleted,
//         "currentActionItem"
//       );
//     } else if (isCompleted) {
//       forward_handleNextItem(
//         completed,
//         currentItemId,
//         state._id,
//         actionItemDialog,
//         "currentActionItem"
//       );
//     }
//   }
// };

const agenda_moveBackward = async (state: MeetingState) => {
  await createMeetingStreamEvent(
    MeetingStreamEventTypes.agenda_move_backward, 
    state.stream_id,
    state._id.toHexString(),
    getUserId() as string
  )
  if (state.agendaItems[getIndexByName(state) - 1]) {
    const newAgendaItem = state.agendaItems[getIndexByName(state) - 1];
    const newAgendaItemArray = state.agendaItems;
    newAgendaItemArray[getIndexByName(state)].endedAt = undefined;
    const query = { $set: { currentAgendaItem: newAgendaItem, agendaItems: newAgendaItemArray} };
    await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
  } else {
    const query = {
      $set: {
        currentAgendaItem: { name: "", description: "", type: "not-started" },
      },
    };
    await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
  }
};

// const announcement_moveBackward = async (
//   state: MeetingState,
//   meetingItems: Array<MeetingItem>
// ) => {
//   const announcements = meetingItems.filter(
//     (item) => item.type === MeetingItemTypes.announcement
//   );
//   if (announcements.length > 0) {
//     const currentAnnouncementId = (state.currentAnnouncement as MeetingItem)._id.toString() as any;
//     const firstItemId = announcements[0]._id.toString();
//     if (currentAnnouncementId !== firstItemId) {
//       const query = {
//         $set: {
//           currentAnnouncement:
//             announcements[
//               getIndexOfItemById(announcements, currentAnnouncementId) - 1
//             ],
//         },
//       };
//       await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
//     } else {
//       await agenda_moveBackward(state);
//     }
//   } else {
//     await agenda_moveBackward(state);
//   }
// };

const backward_handleNextItem = async (
  array: Array<any>,
  currentId: string,
  state_id: BSON.ObjectID,
  firstItemFunc: () => void,
  queryArray: string
) => {
  const firstId = array[0]._id.toString();
  if (firstId !== currentId) {
    const query = {
      $set: { [queryArray]: array[getIndexOfItemById(array, currentId) - 1] },
    };
    await MeetingsReduxer.database.updateOne({ _id: state_id }, query);
  } else {
    firstItemFunc();
  }
};

const metric_moveBackward = async (
  state: MeetingState,
  meetingItems: Array<MeetingItem>
) => {
  const metrics = store.getState().data.metrics;
  const firstId = store.getState().data.metrics[0]._id.toString();
  if (firstId !== (state.currentMetric as TargetMetric)._id.toString()) {
    await backward_handleNextItem(
      metrics,
      (state.currentMetric as TargetMetric)._id.toString(),
      state._id,
      async () => agenda_moveBackward(state),
      "currentMetric"
    );
  } else {
    await agenda_moveBackward(state);
  }
};

const actionItem_moveBackward = async (
  state: MeetingState,
  meetingItems: Array<ActionItem>
) => {
  const outstanding = meetingItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && !item.doneDate
  );
  const completed = meetingItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && item.doneDate
  );

  // no items
  if (outstanding.length + completed.length === 0) {
    await agenda_moveBackward(state);
    // only outstanding
  } else if (outstanding.length > 0 && completed.length === 0) {
    const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
    backward_handleNextItem(
      outstanding,
      currentItemId,
      state._id,
      () => agenda_moveBackward(state),
      "currentActionItem"
    );
    // only completed
  } else if (completed.length > 0 && outstanding.length === 0) {
    const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
    backward_handleNextItem(
      completed,
      currentItemId,
      state._id,
      () => agenda_moveBackward(state),
      "currentActionItem"
    );
    //Both completed and outstanding
  } else {
    const currentItemId = (state.currentActionItem as ActionItem)._id.toString() as any;
    const isOutstanding =
      outstanding.filter((item) => item._id.toString() === currentItemId)
        .length > 0
        ? true
        : false;
    const isCompleted =
      completed.filter((item) => item._id.toString() === currentItemId).length >
      0
        ? true
        : false;
    const handoverToOutstanding = async () => {
      const query = {
        $set: { currentActionItem: outstanding[outstanding.length - 1] },
      };
      await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
    };
    if (isOutstanding) {
      backward_handleNextItem(
        outstanding,
        currentItemId,
        state._id,
        () => agenda_moveBackward(state),
        "currentActionItem"
      );
    } else if (isCompleted) {
      backward_handleNextItem(
        completed,
        currentItemId,
        state._id,
        handoverToOutstanding,
        "currentActionItem"
      );
    }
  }
};

// Exports
export const master_moveForward = async (
  state: MeetingState,
  meetingItems: Array<MeetingItem>,
  announcementDialog: () => void,
  actionItemDialog: () => void
) => {
  switch (state.state) {
    case MeetingStateType.pending:
      await startMeeting(state);
      break;
    default:
      switch (state.currentAgendaItem.type) {
        /* case AgendaItemTypes.announcements:
          await announcement_moveForward(
            state,
            meetingItems,
            announcementDialog
          );
          break; */
        // case AgendaItemTypes.actionItemReview:
        //   await actionItem_moveForward(
        //     state,
        //     meetingItems as Array<ActionItem>,
        //     actionItemDialog
        //   );
        //   break;
        case AgendaItemTypes.metricReview:
          await metric_moveForward(state, meetingItems);
          break;
        default:
          await agenda_moveForward(state, meetingItems);
          break;
      }
      break;
  }
};
export const master_moveBackward = async (
  state: MeetingState,
  meetingItems: Array<BasicItem>
) => {
  switch (state.currentAgendaItem.type) {
    /* case AgendaItemTypes.announcements:
      await announcement_moveBackward(
        state,
        meetingItems as Array<MeetingItem>
      );
      break; */
    case AgendaItemTypes.actionItemReview:
      await actionItem_moveBackward(state, meetingItems as Array<ActionItem>);
      break;
    case AgendaItemTypes.metricReview:
      await metric_moveBackward(state, meetingItems as Array<MeetingItem>);
      break;
    default:
      await agenda_moveBackward(state);
      break;
  }
};

export const confirm_announcements = async (
  state: MeetingState,
  meetingItems: Array<MeetingItem>
) => {
  await meetingItems
    .filter((item) => item.type === MeetingItemTypes.announcement)
    .forEach(async (item) => {
      const query = {
        $set: {
          meeting_id: state._id.toString(),
          complete: true,
          completeDate: new Date(),
        },
      };
      await MeetingItemsReduxer.database.updateOne({ _id: item._id }, query);
    });
  await agenda_moveForward(state, meetingItems);
};

export const confirm_actionItems = async (
  state: MeetingState,
  meetingItems: Array<ActionItem>
) => {
  meetingItems
    .filter(
      (item) => item.type === MeetingItemTypes.actionItem && item.doneDate
    )
    .forEach(async (item) => {
      const query = {
        $set: {
          meeting_id: state._id.toString(),
          complete: true,
          completeDate: new Date(),
        },
      };
      await MeetingItemsReduxer.database.updateOne({ _id: item._id }, query);
    });
  if (
    meetingItems.filter((item) => item.type === MeetingItemTypes.actionItem)
      .length > 0
  ) {
    const remainingItems = meetingItems.filter(
      (item) => item.type === MeetingItemTypes.actionItem
    );
    const query = {
      $set: { currentActionItem: remainingItems[remainingItems.length - 1] },
    };
    await MeetingsReduxer.database.updateOne({ _id: state._id }, query);
  }
  await agenda_moveForward(state, meetingItems);
};

export const complete_actionItem = async (
  currentItem: ActionItem,
  actionItems: Array<ActionItem>,
  meeting_id: BSON.ObjectID
) => {
  await MeetingItemsReduxer.database.updateOne(
    { _id: currentItem._id },
    { $set: { doneDate: new Date(), complete: true } }
  );
  const outstanding = actionItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && !item.doneDate
  );
  const completed = actionItems.filter(
    (item) => item.type === MeetingItemTypes.actionItem && item.doneDate
  );

  if (outstanding.length - 1 > 0) {
    const currentActionItemId = currentItem._id.toString();
    const firstActionItemId = outstanding[0]._id.toString();
    if (currentActionItemId !== firstActionItemId) {
      const query = {
        $set: {
          currentActionItem:
            outstanding[
              getIndexOfItemById(outstanding, currentActionItemId) - 1
            ],
        },
      };
      await MeetingsReduxer.database.updateOne({ _id: meeting_id }, query);
    } else {
      const query = { $set: { currentActionItem: outstanding[1] } };
      await MeetingsReduxer.database.updateOne({ _id: meeting_id }, query);
    }
  } else if (completed.length > 0) {
    const query = { $set: { currentActionItem: completed[0] } };
    await MeetingsReduxer.database.updateOne({ _id: meeting_id }, query);
  }
};

export const isLastRound = (state: MeetingState) => {
  if (getIndexByName(state) + 1 === state.agendaItems.length) {
    return true;
  } else return false;
};
