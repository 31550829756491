import React from 'react';

interface FuncProps {
    toggleFunction: () => void;
}

const ButtonWrapper: React.FC<FuncProps> = ({toggleFunction, children}) => <span
    onMouseDown={(event) => {
        event.preventDefault();
        toggleFunction();
    }}
    >
        {children}
</span>

export default ButtonWrapper;