import React from 'react';
import { Button } from '@material-ui/core';

interface FuncProps {
    setDialogState: (state: boolean) => void;
    cardId: string;
}

const EditOption: React.FC<FuncProps> = ({ setDialogState, cardId }) => {

    return <React.Fragment>
        <Button
            id={`${cardId}-edit-button`}
            color="secondary"
            onClick={() => setDialogState(true)}
        >
            Edit
        </Button>
    </React.Fragment>
}

export default EditOption;