import React from 'react';
import { Typography, Grid, Button, DialogContent, DialogActions } from '@material-ui/core';
import FormInputField from '../../../components/FormInputField/FormInputField';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { resetPasswordRequest } from '../../../stitch/authentication';
import stitchApp from '../../../stitch/app';
import { StitchUser } from 'mongodb-stitch-browser-sdk';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { UserDataReduxer } from '../../../store/reduxer';
import { SystemState } from '../../../store/store';

interface DispatchProps {
    updateUserData: (update: any, query: any) => void;
}

interface StateProps {
    userData: Array<any>
}

type Props = RouteComponentProps & DispatchProps & StateProps;

const validation = Yup.object().shape({
    firstName: Yup
        .string()
        .required('Please enter your first name'),
    lastName: Yup
        .string()
        .required('Please enter your last name'),
    password: Yup
        .string()
        .min(8, 'at least 8 chars')
        .matches(/[a-z]/, 'at least one lowercase char')
        .matches(/[A-Z]/, 'at least one uppercase char')
        .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'at least 1 number or special char (0-9, @,!,#, etc).')
        .required('Please enter your password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Passwords must match'),
});

const UserSetupForm: React.FC<Props> = ({history, updateUserData, userData}) => <Formik
    initialValues={{
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: ''
    }}
    validationSchema={validation}
    onSubmit={async (values) => {
        delete values.confirmPassword;
        await resetPasswordRequest((stitchApp.auth.user as StitchUser).profile.email as string, values.password, true);
        await updateUserData({_id: userData[0]._id}, {$set: {firstName: values.firstName, lastName: values.lastName}});
        history.push('/dashboard');
    }}
    >
    {({isSubmitting, errors, dirty}) => <Form>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} style={{textAlign: 'center'}}><Typography variant="h5">Welcome</Typography></Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}><Typography variant="body2" color="textSecondary" style={{marginBottom: "10px"}}>Please provide your name and password to create your account</Typography></Grid>
                <FormInputField name="firstName" label="First Name" type="text" required/>
                <FormInputField name="lastName" label="Last Name" type="text" required/>
                <FormInputField name="password" label="Password" type="password" required/>
                <FormInputField name="confirmPassword" label="Confirm Password" type="password" required/>
            </Grid>  
        </DialogContent>
        <DialogActions>
            <Button 
                type="submit" 
                color="primary"
                disabled={isSubmitting || !isEmpty(errors) || !dirty}
            >
                Submit
            </Button>
        </DialogActions>
    </Form>}
</Formik>

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        userData: state.data.userData
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        updateUserData: bindActionCreators(UserDataReduxer.update, dispatch),
    }
}
           
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSetupForm))