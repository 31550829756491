import React from 'react';
import { Grid, Typography, Avatar, Tooltip } from '@material-ui/core';
import { ArrowBackIos, Add, Timer } from '@material-ui/icons';
import './index.scss';
import { MeetingSummary, AccountData, RatingSetting } from '../../../../declarations/declarations';
import { AppState } from '../../../../store/reducers/appReducer';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    getStreamName
} from "../../../../helpers/helpers";

interface StateProps {
    AccountData?: AccountData;
}

interface FuncProps {
    initials: string[];
    summary: MeetingSummary;
    averageRating?: any;
    ratingData?: RatingSetting[];
}

type Props = FuncProps & StateProps & RouteComponentProps;

const SummaryHeader: React.FC<Props> = ({ history, initials, summary, AccountData, averageRating, ratingData }) => {

    const getDate = () => {
        const options = { day: "numeric", month: "short", year:'numeric' };
        let date = '';
        if (summary && summary.date) {
          date = summary.date.toLocaleDateString(
            "en-GB",
            options
          );
        }
  
        return date;
    }

    const getTime = (milli?: number) => {
        let time = ''; 
        if (milli) {
            time = new Date(milli).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            })
        }
  
        return time;
    }

    const getDurations = () => {
        let duration = '? hr ? mins';
        if (summary && summary.startedAt && summary.endedAt) {
            const diff = summary.endedAt - summary.startedAt;
            const timeDif = new Date(diff).toISOString().slice(11,19);
            duration = `${timeDif.split(':')[0]} hr ${timeDif.split(':')[1]} mins`;
        }

        return duration;
    }

    return <Grid container>
        <Grid xs={8} md={8} container>
            <Grid container onClick={() => history.goBack()}>
                <ArrowBackIos 
                className="back-btn primary-blue body-text arrow-back-icon" />
                <Typography className="back-btn primary-blue body-text">
                    Back
                </Typography>
            </Grid>
        </Grid>
        <Grid xs={4} md={4} container>
            {initials && <Grid item xs={12} className="avatar-container">
                {initials.map((initial, index) => {
                    return (<>
                        {index < 5 ? <Grid className="inline avatar-container-single">
                        <Avatar className="avatar-circle">{initial}</Avatar>
                    </Grid>: null}
                    </>)
                })}
                {initials.length > 5 && <Grid className="inline gray4 small-text more-label">
                    <Typography>
                        <Add className="add-icon" />
                        {initials.length - 5} more
                    </Typography>
                </Grid>}
            </Grid>}
        </Grid>
        <Grid container className="name-date-container">
            <Grid>
                <Typography className="gray2 subtitle">
                    {summary && summary.name}
                </Typography>
                <Typography className="gray5 small-text">
                    {AccountData ? AccountData.accountName : ''}&nbsp; /&nbsp; 
                    {summary && summary.team && getStreamName(summary.team[0])}
                </Typography>
            </Grid>
            <Grid className="date-time-duration">
                <Grid container>
                    <Grid className="section">
                        <Typography className="gray5 pre-title">
                            DATE
                        </Typography>
                        <Typography className="gray3 small-text">
                            {getDate()}
                        </Typography>
                    </Grid>
                    <Grid className="section">
                        <Typography className="gray5 pre-title">
                            TIME
                        </Typography>
                        <Typography className="gray3 small-text">
                            {`${summary && summary.startedAt ? getTime(summary?.startedAt) : '?:??'} - 
                            ${summary && summary.endedAt ? getTime(summary?.endedAt): '?:??'}`}
                        </Typography>
                    </Grid>
                    <Grid className="section">
                        <Typography className="gray5 pre-title">
                            DURATION
                        </Typography>
                        <Grid container>
                            <Timer className="gray3 timer-icon" />
                            <Typography className="gray3 small-text">
                                {getDurations()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Tooltip 
                    placement="top"
                    title={summary.attendees && ratingData ? `${ratingData.length}/${summary.attendees ? summary.attendees.length : ''} attendees responded` : ''}>
                        <Grid className="last-section">
                            <Typography className="gray5 pre-title">
                                AVG RATING
                            </Typography>
                            <Typography className="gray3 small-text text-center">
                                {averageRating ? averageRating.toFixed(2) : ''}
                            </Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


const mapStateToProps = (state: AppState): StateProps => {
    return {
        AccountData:state.data.accountData[0],
    };
};
  
export default withRouter(connect(mapStateToProps)(SummaryHeader));