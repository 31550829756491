import React from 'react' 
import { Grid, Typography } from '@material-ui/core'
import { AgendaItem } from '../../../../declarations/declarations';
import AgendaItemMeetingCard from './AgendaItemMeetingCard';
import { getStreamName } from '../../../../helpers/helpers';

interface FuncProps {
    stream_id: string
    meetingName: string;
    agendaItems: Array<AgendaItem>;
    currentAgendaItem: AgendaItem;
}

const Agenda: React.FC<FuncProps> = ({
    stream_id, 
    meetingName, 
    agendaItems, 
    currentAgendaItem
}) => {

    const [userSelectedItem, setUserSelected] = React.useState<number | undefined>();

    return <Grid container>
        <Grid item xs={12} md={8}>
            <Grid container 
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h5" style={{color: 'white', marginTop: '20px'}}>{getStreamName(stream_id)}: {meetingName}: Meeting</Typography>
                </Grid>
                <Grid item xs={12}>
                    {agendaItems.map((item, index) => <AgendaItemMeetingCard 
                        key={item.name} 
                        change={setUserSelected}
                        currentAgendaItem={currentAgendaItem} 
                        agendaItem={item} 
                        index={index} 
                        userSelectedItem={userSelectedItem as number}
                    />)}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}


export default Agenda;