import React from 'react';
import { Card, CardHeader, Button, Grid, Typography } from '@material-ui/core';
import { SystemState } from '../../../store/store';
import { Stream } from '../../../declarations/declarations';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface FuncProps {
    stream_id: string;
}

interface StateProps {
    streams: Stream[];
}

type Props = FuncProps & StateProps & RouteComponentProps

const StreamsCard: React.FC<Props> = ({stream_id, streams, history}) => {

    const stream = streams.filter(stream => stream._id.toHexString() === stream_id)[0]

    const ActionButtons: React.FC = () => <React.Fragment>
        {/* <IconButton>
            <EditIcon />
        </IconButton>
        <IconButton>
            <DeleteIcon />
        </IconButton> */}
        <Button color="secondary" onClick={() => history.push(`/meetings/streams/view/${stream_id}`)}>
            View
        </Button>
    </React.Fragment>

    return <Grid xs={12} item style={{margin: '10px'}}>
        
        <Card>
            <CardHeader 
                title={<Typography variant="h6" color="primary">{stream.name}</Typography>}
                action={<ActionButtons/>}
            />
        </Card>
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        streams: state.data.streams
    }
}

export default withRouter(connect(mapStateToProps)(StreamsCard));