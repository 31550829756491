import React from "react";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Formik, Form, validateYupSchema } from "formik";
import FormInputField from "../../../../components/FormInputField/FormInputField";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  MetricDataReduxer,
  MetricTypeReduxer,
} from "../../../../store/reduxer";
import {
  initialDataHelper,
  createOptionsFromEnum,
  getWeekDaysBetweenDates,
  getMonthDaysBetweenDates,
} from "../../../../helpers/formHelpers";
import { getAccountId, getUserId } from "../../../../helpers/helpers";
import FormSelectField from "../../../../components/FormSelectField/FormSelectField";
import {
  TargetedMetricType,
  TargetDuration,
  MetricType,
  Data,
} from "../../../../declarations/declarations";
import { getAllUserNames } from "../../../../stitch/app";
import {
  getStreamsForSelection,
  getDayforSelection,
} from "../../../../helpers/formHelpers";
import { BSON } from "mongodb-stitch-core-sdk";

interface DispatchProps {
  createMetricData: (item: any) => void;
  updateMetricData: (item: any) => void;
  createMetric: (item: any) => void;
  updateMetric: (item: any) => void;
}

interface FuncProps {
  setDialogState: (value: boolean) => void;
  action: "add" | "edit";
  id?: string;
  tab: "form" | "data";
}

type Props = DispatchProps & FuncProps;

const validation = Yup.object().shape({
  name: Yup.string().required("Please add a name"),
  description: Yup.string().required("Please add a description"),
  target: Yup.number().required("Please enter a target"),
  targetDuration: Yup.mixed().required("Please select a target duration"),
  day: Yup.mixed().required("Please select a day to update on"),
  type: Yup.mixed().required("Please select a type"),
  stream: Yup.mixed().required("Please select a stream"),
  who: Yup.mixed().required("Please select someone"),
});

const AddEditMetricDialog: React.FC<Props> = ({
  id,
  createMetric,
  updateMetric,
  setDialogState,
  action,
  tab,
  createMetricData,
  updateMetricData
}) => {
  const account_id = getAccountId() as string;
  const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);
  const [inputArray, setInputArray] = React.useState<Array<number>>([]);
  const [MetricData, setMetricData] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [inputArrayChanged, setInputArrayChanged] = React.useState<boolean>(false);
  var dateArray: Array<Date> = [];
  React.useEffect(() => {
    (async () => {
      const users = await getAllUserNames();
      setAllUsers(users);
      const serverData = await MetricDataReduxer.database
        .find({ metric_id: id })
        .toArray();
      setMetricData(serverData);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    var tempArray = inputArray;
    MetricData &&
      MetricData.map((data: Data) => {
        dateArray &&
          dateArray.map((date: Date, index: number) => {
            if (
              date.toLocaleDateString() ===
              new Date(data.date).toLocaleDateString()
            ) {
              setInputforData(data.data, index);
            }
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateArray, MetricData]);

  const newId = new BSON.ObjectID();
  const initialData = initialDataHelper(
    "metrics",
    {
      _id: newId,
      name: "",
      description: "",
      target: "",
      targetType: "",
      targetDuration: "",
      day: "",
      account_id,
      stream: "",
      who: "",
      type: MetricType.target,
      startDate: new Date(),
    },
    id
  );


  const setInputforData = (value: any, index: number) => {
    let tempArray = inputArray;
    tempArray[index] = value;
    setInputArray(tempArray);
    setInputArrayChanged(true);
  };

  const AddMetricData = (values: any) => {
    if (action === "add") {
      inputArray.map((input, index) => {
        let object = {
          account_id,
          metric_id: values._id.toHexString(),
          data: { $numberInt: input },
          date: dateArray[index],
          who: getUserId(),
          team_id: values.stream,
        };
        if (input) {
          createMetricData(object);
        }
      });
    }
    else{
      inputArray.map((input, index) => {
        let input_added = false;
        MetricData.map((data:Data) => {
          if(data.date.toLocaleDateString() === dateArray[index].toLocaleDateString() && input !== data.data && !input_added){
           let tempObject = data;
           tempObject.data = input;
            tempObject.who = getUserId();
            input_added= true;
            updateMetricData(tempObject);
          }
          else if(!MetricData.find((data:Data) => data.date.toLocaleDateString() === dateArray[index].toLocaleDateString())&& input && !input_added){
              let object = {
                account_id,
                metric_id: values._id.toHexString(),
                data: { $numberInt: input },
                date: dateArray[index],
                who: getUserId(),
                team_id: values.stream,
              };
              input_added= true;
              createMetricData(object);
          }
        })
      });
    }
  };

  if (loading) {
    return <CircularProgress></CircularProgress>;
  } else {
    return (
      <Formik
        initialValues={initialData}
        onSubmit={async (values, actions) => {
          action === "add"
            ? await createMetric(values)
            : await updateMetric(values);
          AddMetricData(values);
          actions.resetForm();
          actions.setSubmitting(false);
          setDialogState(false);
        }}
        validationSchema={validation}
      >
        {({ isSubmitting, errors, dirty, values }) => (
          <Form>
            <Grid className={tab === "form" ? "show" : "hide"} container>
              <Typography className="pre-title gray4">METRIC NAME</Typography>
              <FormInputField
                name="name"
                type="text"
                xs={12}
                md={12}
                required
              />
              <Typography className="pre-title gray4">DESCRIPTION</Typography>
              <FormInputField
                name="description"
                type="text"
                xs={12}
                md={12}
                required
              />
              <Grid item md={6}>
                <Typography className="pre-title gray4">TARGET</Typography>
              </Grid>
              <Grid style={{ marginLeft: "6px" }} item md={5}>
                <Typography className="pre-title gray4">TYPE</Typography>
              </Grid>
              <FormInputField name="target" type="number" xs={12} md={6} />
              <FormSelectField
                name="targetType"
                options={createOptionsFromEnum(TargetedMetricType)}
                required
                xs={12}
                md={6}
              />
              <Grid item md={12}>
                <Typography className="pre-title gray4">DURATION</Typography>
              </Grid>
              <FormSelectField
                name="targetDuration"
                options={createOptionsFromEnum(TargetDuration)}
                xs={12}
                md={5}
              >
                DATE
                <input />
              </FormSelectField>
              <Grid item md={2} style={{ margin: "auto" }}>
                <Typography
                  style={{ margin: "auto", width: "max-content" }}
                  className="bold-text gray4"
                >
                  updated
                </Typography>
              </Grid>
              <FormSelectField
                name="day"
                options={getDayforSelection(values.targetDuration)}
                required
                xs={12}
                md={5}
              />
              <Typography className="pre-title gray4">STREAM</Typography>
              <FormSelectField
                name="stream"
                options={getStreamsForSelection()}
                required
                xs={12}
                md={12}
              />
              <Typography className="pre-title gray4">
                WHO IS ACCOUNTABLE
              </Typography>
              <FormSelectField
                name="who"
                options={allUsers}
                required
                xs={12}
                md={12}
              />
            </Grid>
            <Grid className={tab === "data" ? "show" : "hide"} container>
              <Grid style={{ marginLeft: "6px" }} item md={5}>
                <Typography className="pre-title gray4">START DATE</Typography>
              </Grid>
              <FormInputField name="startDate" type="date" xs={12} md={12} />
              <Grid item md={3}>
                <Typography className="pre-title gray4">DATE</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography className="pre-title gray4">INPUT</Typography>
              </Grid>
              <Grid item md={5}>
                <Typography className="pre-title gray4">PERSON</Typography>
              </Grid>
              {values.targetDuration === TargetDuration.weekly &&
                getWeekDaysBetweenDates(
                  values.startDate,
                  parseInt(values.day)
                ).map((date, index) => {
                  dateArray.push(date);
                  return (
                    <Grid key={index} container>
                      <Grid item md={3}>
                        <Grid style={{ marginTop: "16px" }}>
                          <Typography className="bold-text gray3 vertical-middle">
                            {date.getDate()} {date.getMonth()+1}{" "}
                            {date.getFullYear()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={4}>
                        <input
                          type="number"
                          defaultValue={inputArray[index]}
                          className="metric-dialog-data-input-box bold-text-gray3 vertical-middle"
                          onChange={(e) =>
                            setInputforData(e.target.value, index)
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              {values.targetDuration === TargetDuration.monthly &&
                getMonthDaysBetweenDates(
                  values.startDate,
                  parseInt(values.day)
                ).map((date, index) => {
                  dateArray[index] = date;
                  return (
                    <Grid key={index} container>
                      <Grid item md={3}>
                        <Grid style={{ marginTop: "16px" }}>
                          <Typography className="bold-text gray3 vertical-middle">
                            {date.getDate()} {date.getMonth() + 1}{" "}
                            {date.getFullYear()}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={4}>
                        <input
                          type="number"
                          defaultValue={inputArray[index]}
                          className="metric-dialog-data-input-box bold-text-gray3 vertical-middle"
                          onChange={(e) =>
                            setInputforData(e.target.value, index)
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                style={{ padding: "12px 16px", margin: "10px" }}
                className="gray4 title"
                onClick={() => setDialogState(false)}
              >
                cancel
              </Button>
              <Button
                style={{ padding: "12px 16px", margin: "10px" }}
                className="white primary-blue-background title"
                type="submit"
                disabled={(isSubmitting || !isEmpty(errors) || !dirty) && !inputArrayChanged}
              >
                {action === "add" ? "Create Metric" : "Save Metric"}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    createMetricData: bindActionCreators(MetricDataReduxer.post, dispatch),
    updateMetricData: bindActionCreators(MetricDataReduxer.update, dispatch),
    createMetric: bindActionCreators(MetricTypeReduxer.post, dispatch),
    updateMetric: bindActionCreators(MetricTypeReduxer.update, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AddEditMetricDialog);
