import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import FormLayout from '../FormLayout';
import { Formik, Form } from 'formik';
import FormInputField from '../../../components/FormInputField/FormInputField';
import * as Yup from 'yup';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { resetPassword } from '../../../stitch/authentication';
import { useSnackbar } from 'notistack';

const validation = Yup.object().shape({
    password: Yup
        .string()
        .min(8, 'at least 8 chars')
        .matches(/[a-z]/, 'at least one lowercase char')
        .matches(/[A-Z]/, 'at least one uppercase char')
        .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'at least 1 number or special char (0-9, @,!,#, etc).')
        .required('Please enter your password'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Passwords must match'),
});

const initialData = {
    password: '',
    confirmPassword: ''
};

interface RouteProps extends RouteComponentProps<{
    token: string;
    tokenId: string
}> {}

const NewPasswordScreen: React.FC<RouteProps> = ({history, match}) => {

    const { enqueueSnackbar } = useSnackbar();

    return <FormLayout>
        <Formik 
            initialValues={initialData}
            onSubmit={async (values, actions) => {
                const result = await resetPassword(match.params.token, match.params.tokenId, values.password);
                actions.resetForm();
                actions.setSubmitting(false);
                if (result) {
                    enqueueSnackbar(result, {variant: 'error'});
                } else {
                    enqueueSnackbar('Password was reset successfully', {variant: 'success'});
                    history.push( '/login');
                }
            }}
            validationSchema={validation}
            >
            {({ isSubmitting, dirty, errors }) => (
                <Form style={{marginLeft: '4%', marginRight: '4%'}}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Reset your password</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">Please input a new password</Typography>
                    </Grid>
                    <FormInputField name="password" label="Password" type="password" md={12}/>
                    <FormInputField name="confirmPassword" label="Confirm Password" type="password" md={12}/>
                    <Grid item xs={12}>
                        <Button 
                            type="submit" 
                            disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                            size="large" 
                            color="primary" 
                            variant="contained"
                        >
                            Submit
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    </FormLayout>
}


export default withRouter(NewPasswordScreen);