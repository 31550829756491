import React from "react";
import {
  Card,
  CardHeader,
  Typography,
  IconButton,
  CardContent,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { startCase } from "lodash";
import { AgendaItem } from "../../declarations/declarations";

interface FuncProps {
  agendaItem: AgendaItem;
  editItem?: () => void;
  deleteItem?: () => void;
  expanded: number | undefined;
  setExpanded: (name: number | undefined) => void;
  index: number;
}

const AgendaItemCards: React.FC<FuncProps> = ({
  agendaItem,
  index,
  editItem,
  deleteItem,
}) => {
  return (
    <Card style={{ marginTop: "10px" }}>
      <CardHeader
        title={
          <Typography color="primary" variant="h6">
            {startCase(agendaItem.name)}
          </Typography>
        }
        action={
          <React.Fragment>
            {editItem ? (
              <IconButton
                color="secondary"
                onClick={() => {
                  editItem();
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
            {deleteItem ? (
              <IconButton
                color="secondary"
                onClick={() => {
                  deleteItem();
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </React.Fragment>
        }
      />
      <CardContent>
        <Typography variant="body2">{agendaItem.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default AgendaItemCards;
