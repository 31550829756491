import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { SystemState } from "../../store/store";
import {
  MeetingItemTypes,
  ActionItem,
  ActionItemState,
} from "../../declarations/declarations";
import { getUserId } from "../../helpers/helpers";
import { MeetingItemsReduxer } from "../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import ActionItemList from "../../components/ActionItemList/action-item-list";
import "./index.scss";

interface StateProps {
  items: Array<ActionItem>;
}

interface DispatchProps {
  getMeetingItems: (query: any) => void;
}

type Props = StateProps & DispatchProps;

const ActionItemsPage: React.FC<Props> = ({
  items,
  getMeetingItems
}) => {
  
  React.useEffect(() => {
    getItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItem = async () => {
    await getMeetingItems({
      who: getUserId() as string,
      complete: false,
      type: MeetingItemTypes.actionItem,
    });
  };

  return (
    <Grid className="action-item-page">
      <Grid className="action-item-page-heading">
        <Grid className="inline action-item-page-heading-icon">
          <i className="far fa-file-alt primary-blue"></i>
        </Grid>
        <Grid className="inline">
          <Typography className="bold-text primary-blue">
            My Action Items
          </Typography>
        </Grid>
      </Grid>
      <Grid className="action-item-page-container">
        <ActionItemList
          items={items.filter(item => item.type === MeetingItemTypes.actionItem)}
          lists={[
            ActionItemState.not_started,
            ActionItemState.in_progress,
            ActionItemState.blocked,
            ActionItemState.complete,
          ]}
        ></ActionItemList>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    items: state.data.meetingItems as Array<ActionItem>,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    getMeetingItems: bindActionCreators(MeetingItemsReduxer.getFiltered,dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionItemsPage);
