import React from "react";
import { Grid } from "@material-ui/core";
import { MeetingItem } from "../../../declarations/declarations";
import { stateToHTML} from "draft-js-export-html"
import { convertFromRaw } from 'draft-js';
import Parser from 'html-react-parser';

interface FuncProps {
  item: MeetingItem;
}

const CurrentItemBaseCard: React.FC<FuncProps> = ({ item }) => {
  return (<Grid className="current-item-card">
          <Grid className="current-item-what gray2 title inline">
            {item.what}
          </Grid>
          <Grid className="current-item-why gray4 body-text inline">
          {typeof item.why === 'string' ? item.why : 
                 "Why created in draftjs"}
          </Grid>
  </Grid>
  );
};

export default CurrentItemBaseCard;
