import React from "react";
import { Drawer, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Droppable } from "react-beautiful-dnd";
import MeetingItemCard from "../../../components/MeetingItemCard/MeetingItemCard";
import { SystemState } from "../../../store/store";
import { connect } from "react-redux";
import { ItemList } from "../../../declarations/declarations";
import { getUserId } from "../../../helpers/helpers";

interface StateProps {
  lists: ItemList[];
}

interface FuncProps {
  open: boolean;
  drawerWidth: number;
  toggleDrawer: () => void;
}

type MyMeetingItemsDrawerProps = FuncProps & StateProps;

const MyMeetingItemsDrawer: React.FC<MyMeetingItemsDrawerProps> = ({
  open,
  drawerWidth,
  lists
}) => {
  const myItemsList = lists.filter(list => list.location_id === getUserId())[0]
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: 9,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: "unset",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
      backgroundColor: "white",
      borderLeft: "solid 1px",
      borderColor: "#68BCD5",
      position: "fixed",
      zIndex: 10,
      cursor: "pointer",
    },
    droppable: {
      overflowY: "auto",
      paddingTop: "75px",
    },
    title: {
      marginLeft: "15px",
    },
  }));

  const classes = useStyles();

  return (
    <Drawer
      disableEnforceFocus={true}
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >

      <Droppable droppableId={getUserId() ? getUserId() as string : ''}>
        {(provided) => (
          <Grid
            container
            // className={classes.droppable}
            {...provided.droppableProps}
            innerRef={provided.innerRef}
            style={{minHeight: '150px'}}
          >
            {myItemsList ? 
              myItemsList.items.map((id, i) => <MeetingItemCard listUiId="my-drawer-list" key={id} item_id={id} index={i} />) : undefined}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
      <Divider style={{backgroundColor: 'white', marginTop: '20px'}} />
    </Drawer>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    lists: state.lists.lists,
  };
};

export default connect(mapStateToProps)(MyMeetingItemsDrawer);
