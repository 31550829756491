import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { getUserId } from "../../helpers/helpers";
import { AppState } from "../../store/reducers/appReducer";
import {
  ActionItem,
  MeetingItemTypes,
  AccountData,
} from "../../declarations/declarations";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { MeetingItemsReduxer } from "../../store/reduxer";
import JoinUsingCode from "./Components/JoinUsingCode";
import MeetingStreams from "./Components/MeetingStream/MeetingStreams";
import ItemCard from "../../components/ItemCard/ItemCard";
import AccountInfo from "./Components/AccountInfo";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import "./index.scss";

interface StateProps {
  AccountData:AccountData;
  actionItemsWithNoDoneDate: ActionItem[];
  actionItemsWithDoneDate: ActionItem[];
}
interface DispatchProps {
  getMeetingItems: (query: any) => void;
  updateItem: (item: ActionItem, query: any) => void;
}
type Props = StateProps & DispatchProps;

const Dashboard: React.FC<Props> = ({
  AccountData,
  updateItem,
  actionItemsWithNoDoneDate,
  actionItemsWithDoneDate,
  getMeetingItems,
}) => {
  React.useEffect(() => {
    (async () => {
      await getMeetingItems({
        who: getUserId() as string,
        complete: false,
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className="dashboard-main">
      <Grid className="dashboard-container">
        <Grid className="inline vertical-top dashboard-vertical-container">
          <Grid className="margin-bottom-20">
            <AccountInfo AccountData={AccountData} />
          </Grid>
          <Grid className="margin-bottom-20">
            <JoinUsingCode />
          </Grid>
          <Grid className="margin-bottom-20">
            <MeetingStreams />
          </Grid>
        </Grid>

        <Grid className="inline vertical-top  margin-left-24 ">
          <Grid className="action-heading-container">
            <Grid className="inline gray4 vertical-top icon-action">
              <PlaylistAddCheckIcon fontSize="small" />
            </Grid>
            <Grid className="inline gray4 vertical-top">
              Outstanding Action Items
            </Grid>
          </Grid>
          <Grid className="action-item-container">
            {actionItemsWithNoDoneDate && actionItemsWithNoDoneDate.length >0 ? actionItemsWithNoDoneDate.map((ele, index) => (
              <Grid key={ele._id.toString()} item xs={12}>
                <ItemCard
                  item={ele}
                />
              </Grid>
            )): <Grid> <Typography className="gray3 body-text"> Action Items will appear here. </Typography> </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    getMeetingItems: bindActionCreators(
      MeetingItemsReduxer.getFiltered,
      dispatch
    ),
    updateItem: bindActionCreators(MeetingItemsReduxer.update, dispatch),
  };
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    AccountData:state.data.accountData[0],
    actionItemsWithNoDoneDate: (state.data
      .meetingItems as Array<ActionItem>).filter(
      (item: ActionItem) =>
        item.type === MeetingItemTypes.actionItem &&
        item.who === (getUserId() as string) &&
        !item.doneDate
    ),
    actionItemsWithDoneDate: (state.data
      .meetingItems as Array<ActionItem>).filter(
      (item: ActionItem) =>
        item.type === MeetingItemTypes.actionItem &&
        item.who === (getUserId() as string) &&
        item.doneDate
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
