import React from 'react' 
import { Grid, Button , Typography} from '@material-ui/core'
import MeetingItemListInMeeting from './components/MeetingItemListInMeeting';
import CurrentItemInMeeting from './components/CurrentItemInMeeting';
import Timer from './components/TimerComponent';
import { ItemList, MeetingAgendaItem, MeetingItem, MeetingItemSetting, MeetingState } from '../../../../../declarations/declarations';
import { MeetingItemsReduxer, MeetingsReduxer } from '../../../../../store/reduxer';
import { BSON } from 'mongodb-stitch-browser-sdk';
import { SystemState } from '../../../../../store/store';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../../../store/actionTypes';
import { bindActionCreators } from 'redux';
import { getLists, updateList } from '../../../../../store/lists/listsActionCreator';
import { connect } from 'react-redux';
import { mongoClient } from '../../../../../stitch/app';
import {hideStream} from '../../../../../store/actionCreators';

interface FuncProps {
    currentAgendaItem: MeetingAgendaItem;
    isFacilitator: boolean;
    meeting_id: string;
    openResolveDialog: () => void;
    editArtifact: (item: MeetingItemSetting) => void;
    stream_id: string;
    meetingState: MeetingState;
    attendeeOptions: Array<[string, any]>;
}

interface StateProps {
    lists: ItemList[];
    meetingItems: MeetingItem[];
    hideStream : boolean;
}

interface DispatchProps {
    getList: (query?: object) => void;
    getItems: (query?: object) => void;
    updateList: (list: ItemList) => void;
    setHideStream: (state: boolean) => void;
}

type Props = FuncProps & StateProps & DispatchProps

const MeetingItemsAgendaComponent: React.FC<Props> = ({ 
    currentAgendaItem, 
    isFacilitator, 
    meeting_id, 
    stream_id, 
    openResolveDialog, 
    lists, 
    meetingItems, 
    editArtifact,
    getList, 
    getItems, 
    updateList,
    meetingState,
    setHideStream,
    hideStream,
    attendeeOptions
}) => {
    const theList = lists.filter(list => list.location_id === currentAgendaItem._id)[0]
    const theItems = theList ? meetingItems.filter(item => theList.items.includes(item._id.toHexString())) : []
    const [listRetrieved, setRetrieved] = React.useState<boolean>(false);
    const startWatch = async () => {
        getItems({_id: {$in: theList.items.map(item => new BSON.ObjectId(item))}})
        const listChangeStream = await mongoClient.db('thenaMeet').collection('lists')
          .watch({documentKey: {_id: theList._id}});
        listChangeStream.onNext(async (event) => {
            updateList(event.fullDocument as ItemList);
            getItems({_id: {$in: (event.fullDocument as ItemList).items.map(item => new BSON.ObjectId(item))}})
          });
    };

    React.useEffect(() => {
        (async () => {
            await getList({location_id: currentAgendaItem._id})
            setRetrieved(true)
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (listRetrieved) {
            (async () => {
                await startWatch()
            })(); 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRetrieved])


    const selectNextItem = async () => {
        const list = meetingItems.filter(item => item._id.toHexString() !== currentAgendaItem.currentItem?._id.toHexString())
        if (currentAgendaItem.currentItem) {
            await MeetingItemsReduxer.database.updateOne({_id: (currentAgendaItem.currentItem as MeetingItem)._id}, {$set: {complete: true, completeDate: new Date()}})
        } 
        await MeetingsReduxer.database.updateOne({_id: new BSON.ObjectId(meeting_id)}, {$set: {'currentAgendaItem.currentItem': list[0]}})
    }

    const noItems = (): boolean => {
        const listLength = theItems.filter(item => item._id.toHexString() !== currentAgendaItem.currentItem?._id.toHexString()).length
        if (listLength > 0) {
            return false;
        } else return true;
    }

    return <Grid container style={{color: 'white'}}>
        {!currentAgendaItem.voting && <MeetingItemListInMeeting
            theList={theList}
            meetingItems={theItems}
            currentAgendaItem={currentAgendaItem} 
            isFacilitator={isFacilitator} 
            meeting_id={meeting_id}
            stream_id={stream_id}
            voting={currentAgendaItem.voting as boolean}
            hideStream = {hideStream}
        />}
        {!currentAgendaItem.voting && <CurrentItemInMeeting
            openResolveDialog={openResolveDialog}
            noItems={noItems}
            meeting_id={meeting_id}
            selectNextItem={selectNextItem}
            attendeeOptions={attendeeOptions}
            editArtifact={editArtifact}
        />}
        {currentAgendaItem.voting && !currentAgendaItem.currentItem && <MeetingItemListInMeeting 
            theList={theList}
            meetingItems={theItems}
            currentAgendaItem={currentAgendaItem} 
            isFacilitator={isFacilitator} 
            meeting_id={meeting_id}
            stream_id={stream_id}
            voting={currentAgendaItem.voting as boolean}
            hideStream = {hideStream}
        />}
        {currentAgendaItem.voting && currentAgendaItem.currentItem && <CurrentItemInMeeting 
            openResolveDialog={openResolveDialog}
            noItems={noItems}
            meeting_id={meeting_id}
            selectNextItem={selectNextItem}
            attendeeOptions={attendeeOptions}
            editArtifact={editArtifact}
        />}
        <Grid item md={2} sm={12} style={{position:'relative'}}>
            {meetingState && meetingState.currentAgendaItem &&  meetingState.currentAgendaItem.timer && <Timer 
                meetingState={meetingState}
            />}
            {hideStream && <Grid className='showStreamButton'>
                <Button onClick={(e) => setHideStream(false)}
                style={{ padding: "5px 20px", textTransform: "none" }}>
                    <Typography variant='h5' color='primary'> Open Meeting Streams </Typography>
                </Button>
            </Grid>}
        </Grid> 
    </Grid>
}

const mapStateToProps = (state: SystemState ): StateProps => {
    return {
        lists: state.lists.lists,
        meetingItems: state.data.meetingItems as MeetingItem[],
        hideStream :  state.app.hideStream,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
    return {
        getList: bindActionCreators(getLists, dispatch),
        getItems: bindActionCreators(MeetingItemsReduxer.getFiltered, dispatch),
        updateList: bindActionCreators(updateList, dispatch),
        setHideStream: bindActionCreators(hideStream, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(MeetingItemsAgendaComponent);