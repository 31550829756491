import React from "react";
import {
  ActionItem,
  ActionItemState,
  ActionItemList,
} from "../../declarations/declarations";
import { Grid, Typography } from "@material-ui/core";
import ActionItemCard from "../MeetingItemCard/components/ActionItemCard";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { MeetingItemsReduxer } from "../../store/reduxer";
import "./index.scss";
import moment from "moment";

interface FuncProps {
  lists: Array<ActionItemState | "Overdue">;
  items: Array<ActionItem>;
  showOverdueItems?: boolean;
}

const ActionItemLists: React.FC<FuncProps> = ({
  lists,
  items,
  showOverdueItems,
}) => {
  var startLists: ActionItemList[] = [];
  const [Lists, changeLists] = React.useState<Array<any>>(startLists);

  React.useEffect(() => {
    let currentList: any;
    lists &&
      lists.forEach((thisList) => {
        if (thisList !== "Overdue") {
          let currentListItems: Array<ActionItem> = [];
          if (
            thisList !== ActionItemState.complete &&
            thisList !== ActionItemState.blocked
          ) {
            if(
            thisList !== ActionItemState.not_started
            ){
              currentListItems = showOverdueItems
                ? items.filter(
                    (item) =>
                      item.state === thisList &&
                      moment(item.when).isAfter(new Date())
                  )
                : (items.filter(
                    (item) => item.state === thisList
                  ) as Array<ActionItem>);
            }
            else{
              currentListItems = showOverdueItems
                ? items.filter(
                    (item) =>
                      (item.state === thisList || !item.state) &&
                      moment(item.when).isAfter(new Date())
                  )
                : (items.filter(
                    (item) => (item.state === thisList || !item.state)
                  ) as Array<ActionItem>);
            }
          } else {
            currentListItems = items.filter(
              (item) => item.state === thisList
            ) as Array<ActionItem>;
          }
          currentList = {
            name: thisList as ActionItemState,
            actionItems: currentListItems as Array<ActionItem>,
          } as ActionItemList;
          startLists.push(currentList);
        } else {
          let currentListItems = items.filter(
            (item) =>
              moment(item.when).isBefore(new Date()) &&
              item.state !== ActionItemState.complete &&
              item.state !== ActionItemState.blocked
          ) as Array<ActionItem>;
          currentList = {
            name: thisList as ActionItemState,
            actionItems: currentListItems as Array<ActionItem>,
          } as ActionItemList;
          startLists.push(currentList);
        }
      });
    changeLists(startLists);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);

  const setNewState = async (item: ActionItem, newState: ActionItemState) => {
    await MeetingItemsReduxer.database.updateOne(
      { _id: item._id },
      { $set: { state: newState } }
    );
  };

  const onDragEnd = async (result: DropResult) => {
    const { destination, source } = result;
    let tempList = Lists;

    let destinationListNo = Lists.findIndex(
      (list) => list.name === destination?.droppableId
    );
    let sourceListNo = Lists.findIndex(
      (list) => list.name === source.droppableId
    );
    let MovedItem = tempList[sourceListNo].actionItems[source.index];

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index !== source.index
    ) {
      tempList[destinationListNo].actionItems.splice(source.index, 1);
      tempList[destinationListNo].actionItems.splice(
        destination.index,
        0,
        MovedItem
      );
      changeLists(tempList);
    }

    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === "Overdue") {
        return;
      }
      if (
        source.droppableId === "Overdue" &&
        destination.droppableId !== ActionItemState.complete &&
        destination.droppableId !== ActionItemState.blocked
      ) {
        MovedItem.state = destination.droppableId as ActionItemState;
        tempList[sourceListNo].actionItems.splice(source.index, 1);
        tempList[sourceListNo].actionItems.splice(source.index, 0, MovedItem);
      } else {
        MovedItem.state = destination.droppableId as ActionItemState;

        if (
          MovedItem.state !== ActionItemState.complete &&
          MovedItem.state !== ActionItemState.blocked &&
          moment(MovedItem.when).isBefore(new Date()) &&
          showOverdueItems
        ) {
          let overdueListNo = Lists.findIndex(
            (list) => list.name === "Overdue"
          );
          tempList[overdueListNo].actionItems.splice(0, 0, MovedItem);
        } else {
          tempList[destinationListNo].actionItems.splice(
            destination.index,
            0,
            MovedItem
          );
        }
        tempList[sourceListNo].actionItems.splice(source.index, 1);
      }
      setNewState(MovedItem, destination.droppableId as ActionItemState);
      changeLists(tempList);
    }
  };

  const getState = (state: ActionItemState | "Overdue") => {
    switch (state) {
      case ActionItemState.blocked:
        return "Blocked";
      case ActionItemState.complete:
        return "Completed";
      case ActionItemState.in_progress:
        return "In Progress";
      case ActionItemState.not_started:
        return "Not Started";
      case "Overdue":
        return "Overdue";
      default:
        return "Not Started";
    }
  };

  const getIcon = (state: ActionItemState | "Overdue") => {
    switch (state) {
      case ActionItemState.blocked:
        return <i className="fas fa-ban"></i>;
      case ActionItemState.complete:
        return <i className="far fa-check-circle"></i>;
      case ActionItemState.in_progress:
        return <i className="fas fa-spinner"></i>;
      case ActionItemState.not_started:
        return <i className="fas fa-list-ul"></i>;
      case "Overdue":
        return <i className="far fa-calendar-exclamation"></i>;
      default:
        return "Not Started";
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid className="dragdrop-context">
        {Lists &&
          Lists.map((List) => {
            return (
              <Droppable droppableId={List.name} ignoreContainerClipping={true}>
                {(provided) => (
                  <Grid
                    container
                    {...provided.droppableProps}
                    innerRef={provided.innerRef}
                    className="dnd-action-item-list inline"
                  >
                    <Grid className="action-item-list-heading">
                      <Grid className="action-item-list-icon gray4 inline">
                        {getIcon(List.name)}
                      </Grid>
                      <Grid className="action-item-list-text inline">
                        <Typography className="gray4 body-text">
                          {getState(List.name)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {List.actionItems.length > 0 ? (
                      List.actionItems.map(
                        (item: ActionItem, index: number) => (
                          <Draggable
                            draggableId={item._id.toString()}
                            index={index}
                            key={item._id.toString()}
                          >
                            {(provided, snapshot) => (
                              <Grid
                                item
                                xs={12}
                                {...provided.draggableProps}
                                innerRef={provided.innerRef}
                                {...provided.dragHandleProps}
                                className={
                                  snapshot.isDragging ||
                                  snapshot.dropAnimation ||
                                  snapshot.isDropAnimating
                                    ? "draggingActionItem"
                                    : ""
                                }
                              >
                                <ActionItemCard item={item as ActionItem} />
                              </Grid>
                            )}
                          </Draggable>
                        )
                      )
                    ) : (
                      <Grid className="empty-state-container">
                        <Typography className="gray3 body-text">
                          No Action Items {getState(List.name)}
                        </Typography>
                      </Grid>
                    )}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            );
          })}
      </Grid>
    </DragDropContext>
  );
};

export default ActionItemLists;
