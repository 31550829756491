import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';

interface FuncProps {
    onClick: (state: true) => void;
}
const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
}));

const TeamAddButton: React.FC<FuncProps> = ({onClick}) => {
  const classes = useStyles();

  return <Fab color="secondary" size="large" className={classes.fabButton} onClick={() =>  onClick(true)}><AddIcon /></Fab>

}

export default TeamAddButton;