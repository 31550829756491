import React from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AddEditMetricDialog from '../../../pages/MetricPage/components/AddEditMetricDialog/index';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { MetricTypeReduxer } from '../../../store/reduxer';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionTypes } from '../../../store/actionTypes';
import { TargetMetric } from '../../../declarations/declarations';

interface FuncProps extends RouteComponentProps {
    id: string;
    metric: TargetMetric;
    setDialog: (state: boolean) => void
}

interface DispatchProps {
    deleteMetric: (item: any) => void;
}
  
type Props = DispatchProps & FuncProps;

const MetricCardMenu: React.FC<Props> = ({id, metric, setDialog, history, deleteMetric}) => {

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const [DialogState, setDialogState] = React.useState<boolean>(false);
    
    const openMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const onClose = () => {
        setAnchorEl(null);
    }

    const onCloseNavigate = (url: string) => {
        setDialogState(true);
    }

    return <React.Fragment>
        <Button className="gray5" aria-controls={`metric-menu-${id}`} aria-haspopup="true" onClick={openMenu}>
            <MoreVertIcon />
        </Button>
        <Menu
            id={`metric-menu-${id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <MenuItem onClick={() => deleteMetric(metric)} className="delete-btn-metric small-text">Delete</MenuItem>
            <MenuItem className="small-text" onClick={() => onCloseNavigate(`/account/metrics/edit/${id}`)}>Edit</MenuItem>

        </Menu>
        <AddEditMetricDialog DialogState={DialogState} setDialogState={setDialogState} action={"edit"} id={id}></AddEditMetricDialog>

    </React.Fragment>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any,ActionTypes>): DispatchProps => {
    return {
        deleteMetric: bindActionCreators(MetricTypeReduxer.delete, dispatch)
    }
}
export default withRouter(connect(null, mapDispatchToProps)(MetricCardMenu));