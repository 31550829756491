import React from "react";
import {
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  GridListTile,
} from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { SystemState } from "../../../../../../../store/store";
import { ItemList } from "../../../../../../../declarations/declarations";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../../../../store/actionTypes";
import { getLists } from "../../../../../../../store/lists/listsActionCreator";
import { addEditItemState } from "../../../../../../../store/actionCreators";
import { bindActionCreators } from "redux";
import MeetingItemCard from "../IdeaAndOrgItemList/components/IdeaOrgItemCards";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";

interface FuncProps {
  location_id: string;
}

interface StateProps {
  lists: ItemList[];
}

interface DispatchProps {
  getLists: (query: object) => void;
  setAddState: (payload: { state: boolean; location_id: string }) => void;
}

type Props = FuncProps & StateProps & DispatchProps;

const IdeaOrgMeetingItemList: React.FC<Props> = ({
  location_id,
  lists,
  getLists,
  setAddState,
}) => {
  const theList = lists.filter((list) => list.location_id === location_id)[0];
  const items = theList ? theList.items : [];
  React.useEffect(() => {
    if (!lists.filter((list) => list.location_id === location_id)[0]) {
      (async () => await getLists({ location_id }))();
    }
  });

  return ( <React.Fragment>
      {!theList ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : items.length > 0 ? (
        items.map((id, i) => (
          <MeetingItemCard
            listUiId="idea-org-list"
            key={id}
            index={i}
            item_id={id}
            isOpenList={true}
          />
        ))
      ) : (
        <Grid item xs={12} style={{ textAlign: "center" }}>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    lists: state.lists.lists,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    getLists: bindActionCreators(getLists, dispatch),
    setAddState: bindActionCreators(addEditItemState, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdeaOrgMeetingItemList);
