import React from 'react';
import { Grid, Card, CardHeader, Typography, CardContent, Avatar, useMediaQuery, useTheme, CircularProgress } from '@material-ui/core';
import { TargetMetric, ProfileInfo, TargetedMetricType, TargetDuration } from '../../declarations/declarations';
import stitchApp, { getProfile } from '../../stitch/app';
import MetricCardMenu from './components/MetricCardMenu';
import AddDataDialog from './components/AddDataDialog/AddDataDialog';
import { MetricDataReduxer } from '../../store/reduxer';
import Chart from '../../components/Chart/Chart';
import { forEach } from 'lodash';
import './index.scss';
interface FuncProps {
    metric: TargetMetric
    dataLoaded?: boolean;
    setDataLoaded?: () => void;
    currentMetric?: TargetMetric
}

const MetricCard: React.FC<FuncProps> = ({metric, dataLoaded, setDataLoaded, currentMetric}) => {

    const [profile, setProfile] = React.useState<ProfileInfo>();
    const [dialogState, setDialogState] = React.useState<boolean>(false);
    const [data, setData] = React.useState<Array<{date: string, value: any}>>([]);
    const [loaded, setLoad] = React.useState<boolean>(false);
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const isCurrentMetric: boolean = currentMetric?._id.toString() === metric._id.toString() ? true : false;
    const [owner, setOwnerDetails] = React.useState({
        firstName: "",
        lastName: "",
    });

    React.useEffect(() => {
        (async () => {
            //if (loaded) {
                setLoad(false);
            // }
            let result = await getProfile(metric.who);
            const owner = await stitchApp.callFunction("getUserNames", [
                [metric.who],
            ]);
            setOwnerDetails(owner[0]);
            setProfile(result);
            const dataOptions = {
                sort: {date: 1},
                limit: 13
            }
            const serverData = await MetricDataReduxer.database.find({metric_id: metric._id.toString()}, dataOptions).toArray();
            let returnData: Array<{date: string, value: number}> = [];
            if (serverData.length > 0) {
                serverData.forEach((data, index) => returnData.push({date: data.date, value: data.amount}));
                createChartDate(returnData);
                // setData(returnData);
            }
            if (setDataLoaded) {
                setDataLoaded();
            }
            setLoad(true);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoaded]);

    const createChartDate = (returnData: Array<{date: string, value: number}>) => {
        if (metric && metric.targetDuration === "weekly" && returnData && metric.day) {
            let chartData = [];
            let today = new Date();
            let Sunday = new Date(today.getTime() - today.getDay() * 24 * 3600 * 1000);
            let day = new Date(Sunday.getTime() - Sunday.getDay() + metric.day * 24 * 3600 * 1000);
            if (new Date() < new Date(day.toISOString().slice(0,10))) {
                day = new Date(day.getTime() - day.getDay() - 7 * 24 * 3600 * 1000);
            }
            
            let dayInStr = day.toISOString().slice(0,10);
            let lastDayInSelected = dayInStr;
            chartData.push({date: lastDayInSelected + '-empty', value: null as any });
            for(let limit = 1; limit <= 12; limit++) {
                lastDayInSelected = getLastWeekDay(limit, day);
                chartData.push({date: lastDayInSelected + '-empty', value: null as any });
            }

            getChartData(chartData, returnData);
        } else if (metric && metric.targetDuration === "monthly" && returnData && metric.day) {
            let chartData = [];
            let today = new Date();
            let selectedDateInCurrentMonth = `${today.getFullYear()}-${(today.getMonth()+1) <= 9 ? '0' + (today.getMonth()+1) : (today.getMonth()+1)}-${metric.day}`;
            let dayInStr = today.toISOString().slice(0,10);
            if (today > new Date(selectedDateInCurrentMonth)) {
                dayInStr = new Date(selectedDateInCurrentMonth).toISOString().slice(0,10);
            } else {
                selectedDateInCurrentMonth = `${today.getFullYear()}-${(today.getMonth()) <= 9 ? '0' + (today.getMonth()) : (today.getMonth())}-${metric.day}`;
                dayInStr = new Date(selectedDateInCurrentMonth).toISOString().slice(0,10);
            }

            let lastDayInSelected = dayInStr;
            chartData.push({date: lastDayInSelected + '-empty', value: null as any });

            for(let limit = 1; limit <= 12; limit++) {
                lastDayInSelected = getLastMonthDay(lastDayInSelected);
                chartData.push({date: lastDayInSelected + '-empty', value: null as any });
            }

            getChartData(chartData, returnData);
        }
    }

    const getChartData = (chartData: Array<{date: string, value: any}>,
        returnData: Array<{date: string, value: number}>) => {
        chartData.forEach(data => {
            let metricData = returnData.find(obj => {
                return obj.date + '-empty' == data.date;
            })

            if (metricData) {
                data.date = data.date.split('-empty')[0];
                data.value = metric.targetType === 'percentage' ? metricData.value+'%' :
                    metric.targetType === 'currency' ? '$' + metricData.value : metricData.value;
            }
        });

        setData(chartData);
    }

    const getLastWeekDay = (num: number, day: Date) => {
        let lastWeekDay = new Date(day.getTime() - day.getDay() - 7 * num * 24 * 3600 * 1000);
        return lastWeekDay.toISOString().slice(0,10);
    }

    const getLastMonthDay = ( day: string) => {
        var lastMonthDay = new Date(day);
        lastMonthDay = new Date(lastMonthDay.setMonth(lastMonthDay.getMonth() - 1));

        return lastMonthDay.toISOString().slice(0,10);
    }

    const getLatest = () => {
        let latest = '';
        if (data && data!.length > 0) {
            const latestObj = data!.find((obj) => {
                return obj.value !== null && obj.value !== undefined;
            });

            latest = latestObj!.value;
        }

        return latest
    }

    return <Grid item xs={12} style={{marginBottom: '15px'}}>
        <Grid container>
            <Grid item md={2} xs={12} style={{marginBottom: '5px'}}>
                <Card className="metric-card-data">
                    <CardHeader 
                        title={<Typography className="gray2 bold-text">{metric.name}</Typography>}
                        subheader={<Typography className="gray4 small-text">{metric.description}</Typography>}
                        action={!setDataLoaded ? <MetricCardMenu metric={metric} id={metric._id.toString()} setDialog={setDialogState}/> : null}
                    />
                    <CardContent style={{paddingTop: '0px'}}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Grid item xs={12}>
                                    <Typography className="gray5 pre-title target-text">
                                        Target
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="body-text gray3">
                                        {`${metric.target}${metric.targetType === TargetedMetricType.percentage ? '%' : ''} per ${metric.targetDuration === TargetDuration.monthly ? 'month' : 'week'}`} 
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} className="latest-block">
                                <Grid item xs={12}>
                                    <Typography className="gray5 pre-title target-text">
                                        Latest
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="latest-value gray3">
                                    {getLatest()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item md={10} xs={10} className="latest-block">
                                <Avatar className="metric-avtar">
                                    {profile?.profile}
                                </Avatar>
                                <Typography className="who-firstName small-text gray4">
                                    {owner.firstName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {/* <Grid item md={2} xs={3} style={isSmallScreen ? {margin: 'auto', maxHeight: '100px'} : {margin: 'auto', paddingLeft: '1%'}}>
                <Card style={isCurrentMetric ? {backgroundColor: '#68BCD5', color: 'white'} : undefined}>
                    {!loaded ? <CardContent><CircularProgress/></CardContent> : <MetricCardLatestNumber isCurrentMetric={isCurrentMetric} type={metric.targetType} latestNumber={data.length > 0 ? data[data.length-1].value : undefined} />
                    }
                </Card>
            </Grid> */}
            <Grid item md={10} xs={12} style={{margin: 'auto', overflowX: 'auto', paddingLeft: '1%'}}>
                {!loaded ? <CircularProgress/> : 
                    <Chart data={data} metric={metric} createChartDate={createChartDate} />}
                    
            </Grid>
        </Grid>
        <AddDataDialog state={dialogState} metric={metric} close={() => setDialogState(false)} createChartDate={createChartDate}/>
    </Grid>
}

export default MetricCard;