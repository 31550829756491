import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MeetingItem, MeetingItemTypes } from "../../../../declarations/declarations";
import "./index.scss";
import MeetingItemDisplayCard from "./components/MeetingItems";


interface FuncProps {
  items: MeetingItem[];
}

type Props = FuncProps;

const MeetingItemBlock: React.FC<Props> = ({ items }) => {

  let MeetingItems = items.filter(
    (item) => item.type === MeetingItemTypes.meetingItem
  );

  return (
    <Grid container>
      {MeetingItems && MeetingItems.length > 0 ? <Grid className="meeting-item-block-container">
        {MeetingItems.map((item) => {
              let ArtifactItems = items.filter(
                  (artifact) =>
                    artifact.spawn_id && artifact.spawn_id === item._id.toHexString()
                );
                return (
                  <MeetingItemDisplayCard
                    meetingItem={item}
                    artifactItems={ArtifactItems}
                  ></MeetingItemDisplayCard>
                )
          })}
      </Grid> :
      <Typography className="gray3 body-text">
          {`Nothing was resolved during this meeting`}
      </Typography>}
  </Grid>
  );
};

export default MeetingItemBlock;
