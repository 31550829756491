import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { MeetingSummary } from "../../../../declarations/declarations";
import moment from "moment";
import { AppState } from "../../../../store/reducers/appReducer";
import MeetingStreamCard from "./meetingStreamCard";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface StateProps {
  summaries?: MeetingSummary[];
}

type Props = StateProps & RouteComponentProps;

const MeetingStreams: React.FC<Props> = ({ history, summaries }) => {

  const last5 = summaries && summaries.sort((a, b) => moment(b.date).diff(moment(a.date))).slice(0, 5);

  const onDialogOpen = (summary: MeetingSummary) => {
    history.push(`/meetings/summaries/${summary._id.toHexString()}`);
  };

  return (
    <Grid>
      <Grid className="pre-title gray4 meeting-list-title">
        PREVIOUS MEETINGS
      </Grid>
      <Grid>
        {last5 && last5.length > 0 ? (
          last5.map((ele) => (
            <MeetingStreamCard ele={ele} onDialogOpen={onDialogOpen} />
          ))
        ) : (
          <Grid>
            <Typography className="gray3 body-text">
              Previous meetings will appear once you have hosted them.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    summaries: state.data.summaries,
  };
};

export default withRouter(connect(mapStateToProps, null)(MeetingStreams));
