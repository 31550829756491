import React from "react";
import { Avatar, Button, Grid, Typography } from "@material-ui/core";
import {
  getStreamName
} from "../../../../helpers/helpers";
import './index.scss';
import { CallEnd } from '@material-ui/icons';
import { getAllUserNames } from "../../../../stitch/app";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";
import { withRouter, RouteComponentProps } from "react-router-dom";

interface FuncProps {
  AccountName: string;
  name: string;
  stream_id: string;
  attendees: Array<string>;
  concludeMeeting: () => void;
  isFacilitator: boolean;
}

type Props = FuncProps & RouteComponentProps;
const MeetingTopBar: React.FC<Props> = ({ history, AccountName, name, stream_id, attendees, concludeMeeting, isFacilitator }) => {
  const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);
  const [confirmLeave, setStateToConfirm] = React.useState<boolean>(false);
  const [allAttendees, setAllAttendees] = React.useState<
          Array<{ firstName: string; lastName: string }>
        >([]);

  React.useEffect(() => {
    (async () => {
      const users = await getAllUserNames();
      setAllUsers(users);
    })();
  }, []);

  React.useEffect(() => {
    (() => {
      let attendeesArray = [];
      for (var count = 0; count < attendees.length; count++) {
        const index = allUsers.findIndex(
          (user) => user[0] === attendees[count]
        );
        let adminName = allUsers[index] && allUsers[index][1];
        let FirstName = adminName && adminName.split(" ")[0];
        let LastName = adminName && adminName.split(" ")[1];
        let attendee = { firstName: FirstName, lastName: LastName };
        attendeesArray.push(attendee);
      }

      setAllAttendees(attendeesArray);
    })();
  }, [allUsers, attendees]);

  const leaveMeeting = () => {
      setStateToConfirm(true);
  } 

  const closeDialog = () => {
    setStateToConfirm(false);
  } 

  const confirmedLeave = () => {
    if (isFacilitator) {
      concludeMeeting();
      closeDialog();
    } else {
      history.push('/dashboard');
    }
  }

  return (<Grid container className="top-bar-main-container">
    <Grid item xs={4}>
      <Grid className="meeting-top-bar-container">
        <Grid className="inline" id="meeting_team_info">
          <Grid className="gray2 subtitle">
            <Typography className="subtitle">
              {name && name.split(':')[1] ? name.split(':')[1] : name.split(':')[0] ? name.split(':')[0] : ''}
              </Typography>
          </Grid>
          <Grid className="">
            <Typography className="small-text gray4">
              {`${AccountName} / ${getStreamName(stream_id)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={8} className="text-align-right leave-meeting-container">
      <Grid className="avatar-container inline vertical-middle">
        {allAttendees &&
          allAttendees.map((item) => {
            return (
              <Grid className="inline avatar-container-single vertical-top">
                {item.firstName && item.lastName && (
                  <Avatar
                    title={`${item.firstName}${item.lastName}`}
                    children={`${item.firstName[0]}${item.lastName[0]}`}
                    className="avatar-circle vertical-top"
                  />
                )}
              </Grid>
            );
          })}
      </Grid>
      <Grid className="inline">
        <Button
          id="leave_meeting_button"
          variant="contained"
          className="leave-button-main accent-red-background"
          onClick={() => leaveMeeting()}
        >
          <CallEnd className="call-icon white" />
          <Typography className="button-text white">
            Leave meeting
          </Typography>
        </Button>
      </Grid>
    </Grid>
    <ConfirmDialog text={'Are you sure'} state={confirmLeave}
      close={closeDialog} confirmedFunc={confirmedLeave} />
  </Grid>
  );
};

export default withRouter(MeetingTopBar);
