import React from "react";
import { Grid } from "@material-ui/core";
import { getAllUserNames } from "../../stitch/app";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { bindActionCreators } from "redux";
import { TeamsReduxer } from "../../store/reduxer";
import {
  TeamSettings,
  Team,
  MeetingTemplate,
} from "../../declarations/declarations";
import AddEditTeamDialog from "./AddEditTeam/AddEditTeamDialog";
import { SystemState } from "../../store/store";
import TeamAddButton from "./components/TeamAddButton";
import TeamCard from "./components/TeamCard";
import { orgArrayAlphabetically } from "../../helpers/helpers";
import BlankState from "./components/BlankState";

interface DispatchProps {
  createTeam: (values: TeamSettings) => void;
  updateTeam: (values: TeamSettings) => void;
  deleteTeam: (item: any) => void;
}

interface StateProps {
  teams: Array<Team>;
  meetingTemplates: Array<MeetingTemplate>;
}

type Props = DispatchProps & StateProps;

const SettingsTeamPage: React.FC<Props> = ({
  createTeam,
  updateTeam,
  deleteTeam,
  teams,
  meetingTemplates,
}) => {
  const [allUsers, setAllUsers] = React.useState<Array<[string, any]>>([]);
  const [dialogState, setDialogState] = React.useState<boolean>(false);
  const [selectedItem, setItem] = React.useState<any>(null);

  React.useEffect(() => {
    (async () => {
      const users = await getAllUserNames();
      setAllUsers(users);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      {teams.length > 0 ? orgArrayAlphabetically(teams, 'name').map((team, key) => (
        <TeamCard
          key={key}
          team={team}
          meetingTemplates={meetingTemplates}
          allUsers={allUsers}
          editTeam={() => {
            setItem(team);
            setDialogState(true);
          }}
          deleteTeam={deleteTeam}
        />
      )) : <BlankState/>}
      <AddEditTeamDialog
        allUsers={allUsers}
        createTeam={createTeam}
        state={dialogState}
        setState={setDialogState}
        data={selectedItem}
        updateTeam={updateTeam}
      />
      <TeamAddButton
        onClick={() => {
          setItem(null);
          setDialogState(true);
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    teams: state.data.teams,
    meetingTemplates: state.data.meetingTemplates,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    createTeam: bindActionCreators(TeamsReduxer.post, dispatch),
    deleteTeam: bindActionCreators(TeamsReduxer.delete, dispatch),
    updateTeam: bindActionCreators(TeamsReduxer.update, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTeamPage);
