import React from 'react';
import logo from '../../assets/thena-logo-full.svg';
import whiteLogo from '../../assets/thena-logo-full-white.svg';

interface FuncProps {
    width: string;
    height: string;
    white?: boolean;
}

const ThenaLogo: React.FC<FuncProps> = ({width, height, white}) => <div style={{marginTop: '1%'}}>
    <img src={white ? whiteLogo : logo} alt="Thena Logo" style={{maxWidth: '200px'}} width={width} height={height}/>
</div>

export default ThenaLogo;