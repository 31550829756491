import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";
import {
  MeetingTemplate,
  MeetingStateSettings
} from "../../declarations/declarations";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AgendaItemCard from "../AgendaItemCard/AgendaItemCard";
import { bindActionCreators } from "redux";
import { MeetingsReduxer } from "../../store/reduxer";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../store/actionTypes";
import { connect } from "react-redux";

interface FuncProps {
  template: MeetingTemplate;
  deleteTemplate: (template: MeetingTemplate) => void;
}

interface DispatchProps {
  createMeeting: (meeting: MeetingStateSettings) => void;
}

type Props = RouteComponentProps & FuncProps & DispatchProps;

// const useStyles = makeStyles(theme => ({
//     root: {
//       width: '100%',
//     },
//     heading: {
//       fontSize: theme.typography.pxToRem(15),
//       fontWeight: theme.typography.fontWeightRegular,
//     },
//   }));

const TemplateCard: React.FC<Props> = ({
  template,
  history,
  deleteTemplate,
}) => {

  // const classes = useStyles();
  const [expandedItem, setExpanded] = React.useState<number | undefined>();

  return (
    <Grid item style={{ padding: "10px" }} xs={12} md={4}>
      <Card style={{ width: "100%", height: "100%" }}>
        <CardHeader
          title={
            <Typography color="primary" variant="h6">
              {template.name}
            </Typography>
          }
          action={<React.Fragment>
            <IconButton
              color="secondary"
              onClick={() => {
                history.push(
                  `/meetings/agenda-templates/edit/${template._id}`
                );
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => {
                deleteTemplate(template);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </React.Fragment>}
        />
        <CardContent>
          {template.agendaItems.map((item: any, i: number) => (
            <AgendaItemCard
              key={i}
              agendaItem={item}
              expanded={expandedItem}
              setExpanded={setExpanded}
              index={i}
            />
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, ActionTypes>
): DispatchProps => {
  return {
    createMeeting: bindActionCreators(MeetingsReduxer.post, dispatch),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(TemplateCard));
