import React from "react";
import { Formik, Form } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  AgendaItem,
  AgendaItemTypes,
  IdeaOrganizeState,
  IdeaVoteRefineState,
  MeetingToolTypes,
} from "../../../declarations/declarations";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import FormInputField from "../../FormInputField/FormInputField";
import FormSelectField from "../../FormSelectField/FormSelectField";
import AnswersArray from "./components/AnswersArray";
import { BSON } from "mongodb-stitch-browser-sdk";

var valueAlreadySet = false;
interface FuncProps {
  state: boolean;
  data: any;
  updateItem: (index: number, item: AgendaItem) => void;
  setState: (state: boolean) => void;
  postItem: (item: AgendaItem) => void;
  selectOptions: Array<[string, any]>;
}

export interface AgendaItemInitialData {
  name: string;
  description: string;
  type: AgendaItemTypes | string;
  answers?: Array<{ name: string }>;
  timer: number | undefined;
  voting: boolean;
  state?: IdeaOrganizeState;
  ideasVoteRefineState?: IdeaVoteRefineState;
}

const AddEditAgendaItemDialog: React.FC<FuncProps> = ({
  state,
  data,
  updateItem,
  setState,
  postItem,
  selectOptions,
}) => {
  const [type, setType] = React.useState<AgendaItemTypes | string>("");
  const voting = data && data.values && data.values.vote ? true : false;
  const [isVoting, setVoting] = React.useState<boolean>(voting);
  let validation = Yup.object().shape({
    type: Yup.mixed().required("Please select a type"),
    name: Yup.string()
      .when("type", {
        is: AgendaItemTypes.meetingItems,
        then: Yup.string().required("Please add a name"),
      })
      .when("type", {
        is: AgendaItemTypes.normal,
        then: Yup.string().required("Please add a name"),
      })
      .when("type", {
        is: AgendaItemTypes.question,
        then: Yup.string().required("Please add a name"),
      }),
    description: Yup.string()
      .min(5, "5 character minimum")
      .nullable()
      .when("type", {
        is: AgendaItemTypes.meetingItems,
        then: Yup.string().required("Please add a description"),
      })
      .when("type", {
        is: AgendaItemTypes.normal,
        then: Yup.string().required("Please add a description"),
      }),
    timer: Yup.number().min(0, "must be more than 0 seconds"),
    answers: Yup.array(
      Yup.object().shape({
        name: Yup.string().when("type", {
          is: AgendaItemTypes.question,
          then: Yup.string().required("Answers are required"),
        }),
      })
    ),
    voting: Yup.boolean().nullable(),
  });

  const initialData: AgendaItemInitialData = {
    name: data?.values.name,
    description: data?.values.description,
    type: data?.values.type,
    //@ts-ignore
    timer: data?.values.timer ? data?.values.timer : undefined,
    answers: data
      ? data.values.answers
      : ([{ name: "" }, { name: "" }] as Array<{ name: string }>),
    voting: data?.values.vote,
  };

  if (voting && !valueAlreadySet) {
    setVoting(voting);
    valueAlreadySet = true;
  }

  const inputOptions = (values: AgendaItemInitialData) => {
    setType(type);
    switch (values.type) {
      case AgendaItemTypes.meetingItems:
        return (
          <React.Fragment>
            <FormInputField name="name" label="Name" type="text" md={12} />
            <FormInputField
              name="description"
              label="Description"
              type="text"
              multiline
              md={12}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isVoting}
                  onChange={(event) => {
                    setVoting(event.target.checked);
                  }}
                  name="voting"
                  color="primary"
                />
              }
              label="Voting"
            />
          </React.Fragment>
        );
      case AgendaItemTypes.normal:
        return (
          <React.Fragment>
            <FormInputField name="name" label="Name" type="text" md={12} />
            <FormInputField
              name="description"
              label="Description"
              type="text"
              multiline
              md={12}
            />
          </React.Fragment>
        );
      case AgendaItemTypes.ideas_and_organize:
        return (
          <React.Fragment>
            <FormInputField name="name" label="Name" type="text" md={12} />
            <FormInputField
              name="description"
              label="Description"
              type="text"
              multiline
              md={12}
            />
          </React.Fragment>
        );
        case AgendaItemTypes.ideas_vote_refine:
          return (
            <React.Fragment>
              <FormInputField name="name" label="Name" type="text" md={12} />
              <FormInputField
                name="description"
                label="Description"
                type="text"
                multiline
                md={12}
              />
            </React.Fragment>
          );
      case AgendaItemTypes.question:
        return (
          <React.Fragment>
            <FormInputField name="name" label="Question" type="text" md={12} />
            <AnswersArray values={values} />
          </React.Fragment>
        );
      default:
        return;
    }
  };

  return (
    <Dialog open={state} disableEnforceFocus={true} fullWidth>
      <DialogTitle>{`${data ? "Edit" : "Add"} Agenda Item`}</DialogTitle>
      <Formik
        initialValues={initialData}
        onSubmit={(values, actions) => {
          actions.resetForm();
          actions.setSubmitting(false);
          setState(false);
          switch (values.type) {
            case AgendaItemTypes.actionItemReview:
              values = {
                name: "Action Item Review",
                description: "Review the outstanding items for this group",
                type: AgendaItemTypes.actionItemReview,
                timer: (values.timer as number) > 0 ? values.timer : undefined,
                voting: false,
              };
              break;
            case AgendaItemTypes.metricReview:
              values = {
                name: "Metric Review",
                description: `Review the teams important numbers`,
                type: AgendaItemTypes.metricReview,
                timer: (values.timer as number) > 0 ? values.timer : undefined,
                voting: false,
              };
              break;
            case AgendaItemTypes.question:
              break;
            case AgendaItemTypes.ideas_and_organize:
              values.state = {
                round: MeetingToolTypes.ideas,
                ideaLists: [],
                organizeLists: []
              }
              break;
            case AgendaItemTypes.ideas_vote_refine:
                values.state = {
                  round: MeetingToolTypes.ideas,
                  ideaLists: [],
                  organizeLists: [],
                  votes: [],
                  list_id: new BSON.ObjectId().toHexString(),
                  refineList: []
                }
                break;
            default:
              delete values.answers;
              break;
          }
          values.voting = isVoting;
          setVoting(false);
          //@ts-ignore
          !data ? postItem(values) : updateItem(data.index, values);
        }}
        validationSchema={validation}
      >
        {({ isSubmitting, errors, dirty, values }) => (
          <Form>
            <DialogContent>
              <FormSelectField
                name="type"
                label="Type"
                options={selectOptions}
                md={12}
              />
              {inputOptions(values)}
              <FormInputField name="timer" label="Timer (mins)" type="number" />
            </DialogContent>
            <DialogActions>
              <Button
                size="large"
                color="secondary"
                onClick={() => {
                  setState(false);
                  setVoting(false);
                }}
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || !isEmpty(errors) || !dirty}
                size="large"
                color="primary"
              >
                {`${data ? "Save" : "Add"}`}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditAgendaItemDialog;
