
import { Formik, Form, FieldArray } from "formik";
import { isEmpty, includes } from "lodash";
import * as Yup from "yup";
import { MeetingTemplate, StreamSettings, Team } from "../../../../declarations/declarations";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Typography,
  IconButton,
  CircularProgress
} from "@material-ui/core";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StreamsReduxer } from "../../../../store/reduxer";
import { getLists } from "../../../../store/lists/listsActionCreator"
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes } from "../../../../store/actionTypes";
import { getAccountId } from "../../../../helpers/helpers";
import { SystemState } from "../../../../store/store";
import FormSelectField from "../../../../components/FormSelectField/FormSelectField";
import FormInputField from "../../../../components/FormInputField/FormInputField";
import { createStream } from "../../../../stitch/app";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface DispatchProps {
  updateStreams: (values: StreamSettings) => void;
  getStream: (query: object) => void;
  getList: (query: object) => void;
}

interface StateProps {
  teams: Array<Team>;
  meetingTemplates: Array<MeetingTemplate>;
}

interface FuncProps {
  stream?: any;
  allUsers: Array<[string, any]>;
  state: boolean;
  setState: (state: boolean) => void;
  toggleDrawer: () => void;
}

export interface streamInitialData {
  name: string;
  account_id: string;
  streamMember: string;
  role: string;
}

type Props = FuncProps & DispatchProps & StateProps & RouteComponentProps;

const AddStreamsDialog: React.FC<Props> = ({
  updateStreams,
  getStream,
  getList,
  state,
  setState,
  stream,
  allUsers,
  history,
  toggleDrawer
}) => {

  const validation = Yup.object().shape({
    name: Yup.string().required("Please input a name"),
    streamMember: Yup.string().required("Please input a name"),
  });

  const streamValidation = Yup.object().shape({
    streamMember: Yup.string().required("Please input a name"),
    role: Yup.string().required("Please input a name")
  });

  const [streamData, setStreamData] = React.useState<StreamSettings | undefined>(stream);
  const [streamMembers, setMembers] = React.useState<Array<{user_id: string, role: string}>>([]);
  const [isEmptyMember, setIsEmptyMember] = React.useState<boolean>(true);
  const [membersList, setMembersList] = React.useState<Array<[string, any]>>(allUsers);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    setStreamData({
      name: stream ? stream.name : '',
      lists: stream ? stream.lists : [],
      admins: stream ? stream.admins : [],
      subscribers: stream ? stream.subscribers : [],
      account_id: stream ? stream.account_id : getAccountId() as string});

      if (stream) {
        if ((stream.name && !isEmpty(stream.admins)) || !isEmpty(stream.subscribers)) {
          // setIsEmptyMember(false);
          checkStreamMembers();
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFormNameChange = (values: streamInitialData) => {
    return !!(stream && stream.name === values.name);
  }

  const checkStreamMembers = () => {
    let initialStreamMember: any[] = [];
    if (stream.subscribers) {
      stream.subscribers.forEach((user_id: string) => {
        initialStreamMember.push({
          user_id: user_id,
          role: 'subscriber'
        });

       // removeMemberFromOption(user_id);
      });
    } 
    if (stream.admins) {
      stream.admins.forEach((user_id: string) => {
        initialStreamMember.push({
          user_id: user_id,
          role: 'admin'
        });
      });
    }

    filterMembers(initialStreamMember);
    setMembers(initialStreamMember);
  }

  React.useEffect(() => {
      if (allUsers && allUsers.length > 0 && membersList.length === 0) {
        setMembersList(allUsers);
        setLoaded(true);
      } else if (!loaded) {
        setLoaded(true);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, stream]);

  const initialData: streamInitialData = {
    name: stream ? stream.name : '',
    account_id: stream ? stream.account_id : getAccountId() as string,
    streamMember: '',
    role: '',
  };

  const filterMembers = (streamMembers: Array<{user_id: string, role: string}>) => {
    let allMembersList: Array<[string, any]> = [];
    allUsers.forEach(user => {
      let alreadyUser = streamMembers.find((member) => user[0] === member.user_id);
      if (!alreadyUser) {
        allMembersList.push(user);
      }
    });

    setMembersList(allMembersList);
  }

  const removeMemberFromOption = (streamMember: string) => {
    let allMembersList = membersList;
    allMembersList = allMembersList?.filter((user) => user[0] !== streamMember);

    setMembersList(allMembersList);
  }

  const addStreamMember = (values: streamInitialData) => {
    let streamDataCopy = streamData;
    
    if (values && values.streamMember && values.role) {
      if (values.role === 'admin') {
        streamDataCopy?.admins.push(values.streamMember);
      } else {
        streamDataCopy?.subscribers.push(values.streamMember);
      }

      let members = streamMembers;
      members.push({
        user_id: values.streamMember,
        role: values.role
      });

      removeMemberFromOption(values.streamMember);
      setMembers(members);
      setStreamData(streamDataCopy);
      if (isEmptyMember === true) {
        setIsEmptyMember(false);
      }
    }
  }

  const removeMember = (key: number, userId: string) => {
    let streamDataCopy = streamData;
    let members = streamMembers.filter((member, id) => {
      if (member && member.role === 'admin') {
        if (streamDataCopy?.admins) {
          streamDataCopy.admins = streamDataCopy?.admins.filter((user_id) => user_id !== userId);
        }
      } else if (streamDataCopy?.subscribers) {
        streamDataCopy.subscribers = streamDataCopy?.subscribers.filter((user_id) => user_id !== userId);
      }

      return id !== key
    });

    let allMembersList = membersList;
    const removedMember = allUsers?.find((user) => user[0] === userId);
    if (removedMember) {
      allMembersList.push(removedMember);
      setMembersList(allMembersList);
    }

    setMembers(members);
    setStreamData(streamDataCopy);
    if (isEmptyMember === false && isEmpty(streamDataCopy?.admins) && isEmpty(streamDataCopy?.subscribers)) {
      setIsEmptyMember(true);
    } else if (isEmptyMember === true && (!isEmpty(streamDataCopy?.admins) || !isEmpty(streamDataCopy?.subscribers))) {
      setIsEmptyMember(false);
    }
  }

  const createStreamsSubmit = async(values: any) => {
    let streamsData = streamData;

    if (stream && streamsData) {
      streamsData.name = values.name;
      const data = { ...streamsData, _id: stream._id };
      await updateStreams(data);
      clearDialogData();
      setState(false);
    }else if (streamsData) {
      streamsData.name = values.name;
      const result = await createStream(streamsData, getAccountId() as string);
      await getStream({_id: result.insertedId});
      await getList({location_id: result.insertedId.toString()});
      history.push(`/meetings/streams/view/${result.insertedId.toString()}`)
      clearDialogData();
      setState(false);
      toggleDrawer()
    }
  }

  const clearDialogData = () => {
    setStreamData({
      name: '',
      lists: [],
      admins: [],
      subscribers: [],
      account_id: getAccountId() as string});
    setMembersList(allUsers);
    setMembers([]);
    setState(false);
    setIsEmptyMember(true);
  } 

  return ( !loaded ? <CircularProgress color="secondary" /> :
    <Dialog disableEnforceFocus={true} open={state} onClose={() => setState(false)}>
      <DialogTitle>{stream ? "Edit " : "Add "} Stream</DialogTitle>
      <Formik
        initialValues={initialData}
        onSubmit={async (values, actions) => {
          actions.resetForm();
          actions.setSubmitting(false);
          setState(false);
        }}
        validationSchema={validation}
      >
        {({ dirty, errors, isSubmitting, values }) => (
          <Form>
            <DialogContent>
              <Grid container>
                <FormInputField
                  name="name"
                  label="Stream Name"
                  type="text"
                  md={10}
                  xs={10}
                  required
                />
                <Formik
                initialValues={initialData}
                onSubmit={async (values, actions) => {
                  actions.resetForm();
                  actions.setSubmitting(false);
                }}
                validationSchema={streamValidation}
              >
                {({ values }) => (
                  <Form style={{ width: '100%' }}>
                <FieldArray
                  name="members"
                  render={(streamData) => (
                    <Grid container>
                      <FormSelectField
                        name="streamMember"
                        label="Stream Member"
                        options={membersList}
                        md={5}
                        xs={5}
                      />
                      <FormSelectField
                        name="role"
                        label="Role"
                        options={[
                          ['subscriber', 'subscriber'],
                          ['admin', 'admin']
                      ]}
                        md={5}
                        xs={5}
                      />
                      <Grid item xs={2} style={{ margin: "auto", marginTop: '16px' }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          onClick={() => {
                            addStreamMember(values);
                          }}
                          disabled={
                            includes(values.role, values.streamMember) ||
                            !values.streamMember || !values.role
                          }
                        >
                          Add
                        </Button>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Typography variant="h6" color="primary">
                          Stream Members
                        </Typography>
                      </Grid>
                      {streamMembers?.length > 0 ? (
                        streamMembers.map((member: any, key: any) => (
                          <Grid item xs={12} key={key}>
                            <Typography>
                              {
                                allUsers.filter(
                                  (user) => user[0] === member.user_id
                                )[0][1]
                              }&nbsp;-&nbsp;
                              {
                                member.role
                              }
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  removeMember(key, member.user_id)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Typography>
                          </Grid>
                        ))
                      ) : (
                        <Typography>Please add a team member</Typography>
                      )}
                    </Grid>
                  )}
                />
                
                </Form>
                )}
              </Formik>
              </Grid>
            </DialogContent>
            <DialogActions>
              {stream ? <Button
                color="primary"
                disabled={(!values.name || values.name === '' || isEmptyMember) && checkFormNameChange(values)}
                onClick={() => createStreamsSubmit(values)}
              >
                Save
              </Button> :
              <Button
                color="primary"
                disabled={!values.name || values.name === '' || isEmptyMember}
                onClick={() => createStreamsSubmit(values)}
              >
                Create
              </Button>}
              <Button color="secondary" onClick={() => clearDialogData()}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (state: SystemState): StateProps => {
  return {
    teams: state.data.teams,
    meetingTemplates: state.data.meetingTemplates,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, ActionTypes>): DispatchProps => {
  return {
    updateStreams: bindActionCreators(StreamsReduxer.update, dispatch),
    getStream: bindActionCreators(StreamsReduxer.getFiltered, dispatch),
    getList: bindActionCreators(getLists, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddStreamsDialog));