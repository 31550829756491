import React from 'react';
import NotesIcon from '@material-ui/icons/Notes';
import { Button, Typography } from '@material-ui/core';

interface FuncProps {
  setNoteState: () => void;
  noteActive: boolean;
}

const NoteButton: React.FC<FuncProps> = ({setNoteState, noteActive}) => {
  return !noteActive ? <Button className="note-btn white-background" onClick={setNoteState}>
      <NotesIcon className="gray2 note-icon" /> 
      <Typography className="gray2">
        See private notes
      </Typography>
  </Button> : null
}

export default NoteButton;