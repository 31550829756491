import React from 'react';
// import IdeaOrgMeetingItemList from './IdeaOrgMeetingItemList';
import MeetingItemCardList from '../../../../../../components/List/MeetingItemCardList'; 

interface FuncProps {
    location_id: string;
}

const IdeaComponent: React.FC<FuncProps> = ({location_id}) => {
    const [addItem, setAddItem] = React.useState<string>('');

    return <MeetingItemCardList listUiId="idea-list" list_id={location_id} 
        addItem={addItem}
        setAddItem={setAddItem}/>
}

export default IdeaComponent;