import React from 'react';
import { Button } from '@material-ui/core';

interface FuncProps {
    buttonFunction: () => void | undefined;
    cardId: string;
}

const ResolveOption: React.FC<FuncProps> = ({buttonFunction, cardId}) => {

    return <React.Fragment>
        <Button
            id={`${cardId}-resolve-button`}
            color="secondary"
            onClick={buttonFunction}
        >
            RESOLVE
        </Button>
    </React.Fragment>
}

export default ResolveOption;