import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import FormInputField from '../../../components/FormInputField/FormInputField';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

interface FuncProps {
    initialData: {
        firstName: string;
        lastName: string
    }
    submit: (values: any) => void;
}

const validation = Yup.object().shape({
    firstName: Yup
        .string()
        .min(3, 'at least 3 characters')
        .required('Please enter your first name'),
    lastName: Yup
        .string()
        .min(3, 'at least 3 characters')
        .required('Please enter your first name'),
});

const PersonalInfo: React.FC<FuncProps> = ({initialData, submit}) => <Formik 
    initialValues={initialData}
    onSubmit={(values, actions) => {
        actions.resetForm();
        actions.setSubmitting(false)
        submit(values);
        // history.push('/please-verify-your-email');
    }}
    validationSchema={validation}
    >
    {({ isSubmitting, dirty, errors }) => (
        <Form style={{marginLeft: '4%', marginRight: '4%', textAlign: 'center'}}>
                <Typography variant="h6">Set up your account</Typography>
                <Typography variant="body2" color="textSecondary" style={{marginBottom: "10px"}}>What's your name?</Typography>
                <FormInputField name="firstName" label="First Name" type="text" md={12}/>
                <FormInputField name="lastName" label="Last Name" type="text" md={12}/>
            <Grid item xs={12}>
                <Button 
                    type="submit" 
                    disabled={isSubmitting || !isEmpty(errors) || !dirty} 
                    size="large" 
                    color="primary" 
                    variant="contained"
                >
                    Next
                </Button>
                <Grid item xs={12}>
                    <Typography variant="overline">Page 1 of 2</Typography>
                </Grid>
            </Grid>
        </Form>
    )}
</Formik>
           
export default PersonalInfo;